<div class="" *ngIf="docObj">

    <div class="grid grid-cols-5">
        <div class="grid-col col-span-3" style="min-height:300px;">
            <div class="p-0 pb-3 text-center">  
                <div *ngIf="is_generating">
                    <app-spinner>
                    </app-spinner>
                </div>      

                <app-alert type="warning" message="Verifique os campos" *ngIf="is_invalid">
                </app-alert>

                <app-alert type="error" message="Nao foi possivel concluir. Tente novamente" 
                    *ngIf="is_error">
                </app-alert>
            </div>

            <div *ngIf="invoiceObj">
                <!--
                <app-invoice-ai-editor [editObj]="invoiceObj">
                </app-invoice-ai-editor>
                -->
            </div>

            <div *ngIf="!invoiceObj" class="pt-10">
                <p class="text-center opacity-80"
                    style="font-size:small;">Fatura n&atilde;o disponivel!</p>
            </div>
        </div>

        <div class="grid-col col-span-2">
            <div style="padding:10px">
                <div style="padding-left:10px;padding-bottom: 10px;background-color:#fff;padding-top:0px;margin-top:11px;padding-right:15px;border: 1px solid #f4f4f4;">
                    <div>            
                        <div style="float:left;margin-right:10px;height:120px;">  
                            <img src="assets/img/im blue.jpg" alt="Logo"
                                width="48px"
                                class="rounded-full border-2 pull-left border-blue-300" 
                            />

                            <div class="" style="float:right;margin-top: -11px;background: rgb(255, 255, 255);padding: 3px;border-radius: 80px;box-shadow: 0px 0px 2px rgba(0,0,0,.3);z-index: 100000;position: relative;">              
                                <img src="assets/img/lua-magician.svg" 
                                    alt="Lua Magic Tricks" 
                                    width="14px" 
                                    style="height:14px;" />
                            </div>
                        </div>

                        <div>
                            <div class="gap-[1rem] rounded-xl my-2 px-8 py-3 flex items-center cursor-pointer bg-[#F489141A] hover:bg-[#F0F0F0]" 
                                title="Abrir {{docObj.realname}}">
                                <img src="assets/img/icon/folder-2.svg" alt="Lua" class="w-8 h-8">
                                <div class="text-[0.875rem] text-black" style="font-size: x-small;"> 
                                    {{docObj.realname}}
                                </div>
                                <p class="opacity-70 flex flex-row" style="font-size: xx-small;"> 
                                    {{docObj.created_at||docObj.tms}}
                                </p>
                            </div>
                        </div>

                        <p class="mt-1 mb-2 pl-3 pt-1" style="background:#fff;">
                            <!--
                            <button class="cursor-pointer text-[small] p-2 bg-[#fff] rounded-xl font-bold text-[#00527E] m-2"
                                [disabled]="is_generating"
                                (click)="runGenerate()"
                                style="border: 1px solid rgb(0, 82, 126);">
                            </button>
                            -->

                            <span class="cursor-pointer text-small bg-white px-4 py-1 rounded-3xl text-[#00527E] border border-[#00527E]" style="font-size: small;">
                                <span *ngIf="!is_generating" (click)="runGenerate()">
                                    Executar An&aacute;lise
                                </span>
                                <span *ngIf="is_generating">
                                    Aguarde
                                </span>
                            </span>
                        </p>
                    </div>

                    <div *ngIf="genReport">
                        <div>
                            <p class="font-bold text-leftn" *ngIf="genReport.Status=='OK'"> 
                                Resposta 
                            </p>
                            <p class="font-bold text-leftn text-[#b50808]" 
                                *ngIf="genReport.Status!='OK'"> 
                                Oops, houve um erro
                            </p>
                            <p class="mb-0 ml-15" style="margin-top:-8px">
                                <span class="opacity-70" style="font-size:x-small">
                                    {{resp_time}}
                                </span>
                            </p>
                        </div>
                        
                        <div *ngIf="is_error || genReport.Status!='OK'">
                            <div *ngIf="genReport.Errors">
                                <p>Tenho detalhes: </p>
                                <p *ngFor="let Err of genReport.Errors">
                                    {{Err}}
                                </p>
                            </div>
                        </div>

                        <p class="border p-2 text-[#333]" style="font-size:small">
                            {{genReport.answer}}
                        </p>
                    </div>

                </div>
            </div>

            <p class="mt-2 mb-3">
            </p>
        </div>
    </div>

    <div style="background-color:#f4f4f4;">
    </div>
</div>
