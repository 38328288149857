<div class="grid grid-cols-1">
    <div class="grid-col text-center" style="padding:20px">
        <img src="assets/img/apps/ODOO.webp" alt="Odoo Logo" 
            style="margin:auto;" 
            class="w-17 h-15 sm:w-20 sm:h-8" />
        <h2 class="opacity-70">Sincronizar Fatura com <strong>ODOO</strong></h2>
    </div>

    <div class="grid-col text-center" style="padding:20px;padding-top:10px;">
        <div class="" style="max-width: 300px;margin: auto;">        
            <app-alert type="warning" 
                message="Esta fatura nao se encontra sincronizada ainda"
                *ngIf="is_not_found && (!is_loading_sync&&!is_loading_sync)">
            </app-alert>
            <app-alert type="error" 
                message="Nao foi possivel verificar o estado"
                *ngIf="is_error && (!is_loading_sync&&!is_loading_sync)">
            </app-alert>

            <app-alert type="error" 
                message="Nao foi possivel Sincronizar. Tente novamente"
                *ngIf="is_sync_error && (!is_loading_sync&&!is_loading_sync)">
            </app-alert>

            <div *ngIf="is_synced" class="mt-3">            
                <app-alert type="success"  
                    message="Sincronizada com o id {{sync_status.invoice_id}}.">
                </app-alert>
            </div>

            <app-alert type="warning" 
                message="Sincronizacao em progresso..."
                *ngIf="is_loading_sync">
            </app-alert>

            <app-alert type="warning" 
                message="Verificando..."
                *ngIf="is_loading">
            </app-alert>
        </div>

        <p class="mt-1 mb-1   ">
            <span class="cursor-pointer p-2  rounded-xl text-sm  m-2 justify-center rounded-xl p-2 text-[#fff] bg-[#00527E] m-2 border border-[#fff]"
                (click)="VerifySync()">
                Verificar
            </span>

            <span *ngIf="sync_status" class="cursor-pointer p-2  rounded-xl text-sm  m-2 justify-center rounded-xl p-2 text-[#fff] bg-[#724b68] m-2 border border-[#fff]">
                <a href="{{sync_status.link}}"
                    target="__blank" 
                    style="">
                    Abrir no ODOO
                </a>
            </span>

            <span class="cursor-pointer p-2  rounded-xl text-sm  m-2 justify-center rounded-xl p-2 text-[#fff] bg-[#00527E] m-2 border border-[#fff]"
                *ngIf="!is_synced"
                (click)="SyncOdoo()">
                Sincronizar
            </span>
        </p>

    </div>
</div>

