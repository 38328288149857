import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ChatService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-luaxapps',
  templateUrl: './luaxapps.component.html',
  styleUrls: ['./luaxapps.component.scss'],
})
export class LuaxappsComponent implements OnInit {
  is_error: boolean = false;
  is_loading: boolean = false;
  onboard_prof: any = null;
  onbd_prompts: any[] = [];

  constructor(
    private router: Router, 
    private chatService: ChatService
  )  {
  }

  AV_SOON(msg: any) {}

  OpenApp(appName: string) {
    switch (appName) {
      // accounting
      case 'ACCOUNTS_REPORT':
        this.router.navigate(['/accounts-report']);
        break;
      case 'BUDGET_REPORT':
        this.router.navigate(['/budget-report']);
        break;
      case 'DVA':
        this.router.navigate(['/dva']);
        break;
      case 'FINANCIAL_REPORT':
        this.router.navigate(['/financial-report']);
        break;
      case 'INVENTORY_REPORT':
        this.router.navigate(['/inventory-report']);
        break;
      case 'STATEMENT':
        this.router.navigate(['/statement']);
        break;
      default:
        this.router.navigate(['/apps/' + appName + '/index/0.0.1-SNAPSHOT']);
    }
  }

  openPage(name: string) {
    this.router.navigate([name]);
  }

  openHelpGuide() {
    this.router.navigate(['/guide']);
  }

  openMode(mode: any) {
    // console.info("OPEN-MODE: ", mode)
  }

  ngOnInit() {
    // this.ExecuteOnboard()
  }
}
