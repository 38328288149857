import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

@Component({
    selector: 'app-analysis-work',
    templateUrl: './analysis-work.component.html',
})
export class AnalysisWorkComponent implements OnInit {
    @Input() analysisReq: any = null;
    @Input() onFinish: any = null;
    @Input() onSubmit: any = null;
    @Input() inputStatus: any = null;
    @Input() fileid: any = null;
    @Input() inputObj: any = null;
    @ViewChild('modal') modal:any;
    @ViewChild('bimodal') bimodal:any;
    luaBIRequest:any = null

    is_loading:boolean = false;
    is_error:boolean = false;
    is_invalid:boolean = false;
    errors:any = null;
    keyCache: any = null
    is_following: boolean = false
    watch_jid:any = -1
    previewReport:any = null
    watchConf:any = null
    editAnalysisObj:any = null
    objectCache:any = {}
    is_deleted:boolean = false

    constructor(
        private chatService: ChatService,
        private fb: FormBuilder
    ) {
        this.onCloseEdit = this.onCloseEdit.bind(this);
        this.OnSubmitReportEdit = this.OnSubmitReportEdit.bind(this);
        this.onSaveBI = this.onSaveBI.bind(this);
    }

    ngOnInit() {
        // console.info("this.inputStatus: ", this.analysisReq)
        const keyCache = this.analysisReq.Id;
        this.keyCache = keyCache;

        if (this.inputStatus=='EXECUTE' && this.inputObj) {
            this.objectCache[keyCache] = {
                'Status' : 'EXECUTED',
                'Response' : this.inputObj,
            }

            this.watchConf = {
                Watch : {
                    receipt: this.inputObj.receipt,
                }
            }

        } else {        
            setTimeout(()=>{
                this.CheckAnalysis(keyCache, this.analysisReq)
            }, 670);
        }
    }

    onSaveBI(payload:any) {

    }

    OpenBI() {
        const keyCache = this.keyCache;
        // console.info("OpenBI: ", this.objectCache[keyCache].Response)
        // console.info("analysisReq: ", this.analysisReq)
        // return;

        if (this.objectCache[keyCache] && this.objectCache[keyCache].Response) {
            const Report = this.objectCache[keyCache].Response;

            const extras:any = Report.extras;
            const groupname:any = this.analysisReq.groupname;

            if (extras && extras.output) {
                const respObj:any = extras.output;
                const report:any = respObj.obj;
                const BIRequest = {
                    fileid: this.analysisReq.doc.fileid,
                    groupname: this.analysisReq.groupname,
                    report: report,
                }

                // console.info("BIRequest: ", BIRequest)
                this.luaBIRequest = BIRequest;
                this.bimodal.openModal()

            }  else {
                alert("Nao foi possivel Abrir (extras esta vazio)");
            }

        } else {
        }

    }

    onCloseBI() {
        this.luaBIRequest = null;
    }

    ReEvaluate() {
        if (!confirm("Re-evaluar a analise?")) {
            return;
        }

        this.previewReport = null;
        const keyCache = this.analysisReq.Id;
        this.ExecuteAnalysis(keyCache, this.analysisReq);
    }

    CheckAnalysis(keyCache:any, analysisReq:any) {
        var cStatus=null;
        if (this.objectCache[keyCache]) {
            cStatus = this.objectCache[keyCache];
        }

        if (cStatus && cStatus.Status&& cStatus.Status=='LOADING') {
            return;
        }

        if (cStatus==null) {
            this.ExecuteAnalysis(keyCache, analysisReq);

        } else if(cStatus.Status&& cStatus.Status=='FAILED') {
            this.ExecuteAnalysis(keyCache, analysisReq);

        } else if(cStatus.Status&& cStatus.Status=='FOLLOW') {
            // this.FollowAnalysis(keyCache, analysisReq);
        }
    }

    OnSubmitReportEdit(payload:any) {
        if (this.onSubmit) {
            this.onSubmit(payload);
        }
        this.modal.closeModal()
        this.onCloseEdit()
    }

    ngOnDestroy() {
        if (this.watch_jid>-1) {
            clearInterval(this.watch_jid)
        }
    }

    json_stringify(data:any) {
        return JSON.stringify(data)
    }

    FollowAnalysis(keyCache:any, analysisReq:any, Watch:any) {
        const payload = {
            'ops' : 'GetQueueObject',
            'queue' : Watch.follow[0],
            'receipt' : Watch.qid, 
        };

        const Key = `${payload['queue']}__${payload['receipt']}`
        this.is_following = true;

        this.chatService.ExecuteTask("lua/DocSystemAPI", payload, (doc_resp:any) => {
            this.is_following = false;
            if (doc_resp.Status=="OK" && doc_resp.Resp) {
                if (doc_resp.Resp[Key]) {
                    clearInterval(this.watch_jid);

                    this.watch_jid = -1;
                    this.objectCache[keyCache] = {
                        'Status' : 'EXECUTED',
                        'Response' : doc_resp.Resp[Key],
                    }

                    this.is_deleted = false;

                    if (this.onFinish) {
                        this.onFinish({
                            req: analysisReq,
                            res: doc_resp.Resp[Key],
                        })
                    }
                }
            } else {
            }

        }, (error:any) => {
            this.is_following = false;
            setTimeout(() => {
            }, 230);
        });
    }

    onCloseEdit() {
        this.editAnalysisObj = null;
    }

    EditAnalysis() {
        const keyCache:any = this.keyCache;
        if (!this.objectCache[keyCache] || this.modal==undefined) {
            return
        }

        this.editAnalysisObj = null
        const analysisResult:any = this.objectCache[keyCache].Response;

        setTimeout(()=>{
            // this.editAnalysisObj
            console.info("analysisResult: ", this.modal);
            this.editAnalysisObj = analysisResult;
            this.modal.openModal()
            // <button (click)="modal.openModal()" class="bg-[#00527E] text-white px-4 py-2 rounded">Abrir Modal</button>
        }, 120)
    }

    ClosePreview() {
        this.previewReport = null;
    }

    PreviewReport(Report: any) {
        this.previewReport = null;
        // console.info("Report: ", Report)
        // objectCache[keyCache].Response
        const extras:any = Report.extras;
        const groupname:any = this.analysisReq.groupname;
        if (extras && extras.output) {
            const respObj:any = extras.output;
            const report:any = respObj.obj;
            // console.info("respObj: ", respObj);
            // report[groupname] = respObj.obj;
            // console.info("report: ", report);
            this.previewReport = {
                report: report,
                groupname: groupname
            }
        }  else {
            alert("Nao foi possivel Pre-visualizar (extras is not valid)")
        }
    }

    DeleteAnalysis() {
        if (!confirm("Desejam mesmo eliminar esta analise?")) {
            return;
        }

        // console.info("this.watchConf: ", this.watchConf)
        const Watch:any = this.watchConf.Watch;
        const analysisReq:any = this.analysisReq;
        const fileid:any = analysisReq.fileid 
        const groupDoc:any = analysisReq.doc
        const groupName:any = analysisReq.groupname;

        const payload:any = {
            'ops' : 'DeleteAnalysis',
            // 'queue' : Watch.follow[0],
            'queue' : `${fileid}_anly`,
            'qid' : Watch.receipt,
            'groupname' : groupName.replace("#", ""),
            'fileid' : fileid,
        }

        // console.info("DeleteAnalysis: ", payload)
        this.chatService.ExecuteTask("lua/DocSystemAPI", payload, (doc_resp:any) => {
            console.info("doc_resp: ", doc_resp)
            if (doc_resp.Status=='OK') {
                this.is_deleted=true;
            }
        }, (error:any) => {
            console.error("E: ", error)
            setTimeout(() => {
            }, 230);
        });

    }

    ExecuteAnalysis(keyCache:any, analysisReq:any) {
        const fileid:any = analysisReq.fileid 
        const groupDoc:any = analysisReq.doc
        const groupName:any = analysisReq.groupname;
        const payload = {
            // 'ops' : 'BuildGroupReport',
            'ops' : 'SendInferenceRequest',
            'fileid' : fileid,
            'groupname' : groupName,
            'filters' : {},
        }

        this.objectCache[keyCache] = {
            'Status' : 'LOADING'
        }

        this.chatService.ExecuteTask("lua/DocSystemAPI", payload, (doc_resp:any) => {
            // console.info("SendInferenceRequest: ", doc_resp)
            setTimeout(() => {
                if (doc_resp.Status=="OK" && doc_resp.Watch) {
                    this.objectCache[keyCache] = {
                        'Status' : 'FOLLOW',
                        'Watch' : doc_resp.Watch,
                    }

                    this.watchConf = {
                        'Watch' : doc_resp.Watch,
                        'Status' : 'WATCHING'
                    }

                    this.watch_jid = setInterval(() => {
                        this.FollowAnalysis(keyCache, analysisReq, doc_resp.Watch);
                    }, 5200);
                } else {
                    this.objectCache[keyCache] = {
                        'Status' : 'FAILED',
                        'Details' : "STATUS NOT OK",
                    }
                }
            }, 250);

        }, (error:any) => {
            setTimeout(() => {
                this.objectCache[keyCache] = {
                    'Status' : 'FAILED',
                    'Details' : error,
                }
            }, 230);
        });
    }

}
