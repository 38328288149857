<app-user-layout>

    <div class="flex flex-row justify-between ">
        <div>
            <p class="sm:-mt-2 md:-mt-3 mb-3 md:-ml-1" (click)="closeCurrentFile()" *ngIf="currentDocOpen!==null">
                <span
                class="cursor-pointer text-sm  font-bold text-[#00527E] m-2 flex flex-row  ">
                <img src="assets/img/icon/Group.svg " class="w-4 h-4 mr-1"/>
                Voltar
                </span>
            </p>
        <h3 class="text-2xl font-bold text-left mb-3 "> Documentos </h3>
    </div>
    <div *ngIf="currentDocOpen === null" class="flex justify-between items-center mb-4">
        <div class="flex space-x-3">
            <p class="cursor-pointer flex flex-row mt-1 mb-1 px-4 py-2 rounded-3xl text-[#00527E] m-2 border border-[#00527E]  "
                (click)="onRefreshDocList()">
                <img src="assets/img/icon/Arrows.svg" alt="" class="w-5 h-5" /> Actualizar
            </p>
            <!--
            <button
                class="border p-2 rounded-full bg-[#2571a1] text-white border-[#5BACAD] flex items-center"
                (click)="onRefreshDocList()">
                Carregar
            </button>
            <p class="  px-4 py-2 rounded-3xl  text-[#fff] bg-[#00527E]  border border-[#fff]  ">
                <span class="cursor-pointer  text-sm   m-2 justify-center flex flex-row"
                    (click)="fileobj.click()">
                    <input type="file" class="hidden" #fileobj (change)="uploadDoc($event)" />
                    <img src="assets/img/Vectorwhite.svg" class="w-5 h-5 mr-2"/>
                    Carregar documento
                </span>
            </p>
            -->
        </div>
        <div class="flex space-x-5">
        </div>
    </div>


    </div>

    <div class="mt-4 shadow-xl">

        <div class="w-full  p-10 ">
            <div class="tab-header flex flex-row justify-between mb-6">
                <div>
                    <div *ngIf="currentDocOpen === null"
                        class="tab-header-item cursor-pointer py-2 px-4  rounded-tl rounded-tr text-black font-semibold">
                        Lista de Documentos
                    </div>
                    <div *ngIf="currentDocOpen !== null"
                        class="tab-header-item cursor-pointer py-2 px-4  rounded-tl rounded-tr text-black font-semibold">
                        <p>{{ currentDocOpen['realname'] }}</p>
                    </div>
                </div>
                <div class="justify-end items-end"   style="position: relative;">
                    <input type="text" placeholder="Pesquisar..." class="border border-gray-300 px-5 py-1 rounded-full"
                        style="padding-left: 40px;" />
                    <img src="assets/img/icon/search.svg" alt="" c
                        style="position: absolute; top: 50%; left: 10px; transform: translateY(-50%);" />
                </div>
            </div>


            <div class=" p-2 tab-content md:p-10 border border-gray-300 rounded-xl ">

                <div *ngIf="currentDocOpen===null">

                    <div class="flex justify-between items-center mb-0 ">
                        <div>
                            <h3 class="font-bold text-sm font-poppins title-padding mb-5">
                                Mostrando {{objectList.length}} items
                                <!-- Mostrando 10 de 20 -->
                            </h3>
                        </div>

                    </div>

                   
                </div>
                <div class="col-span-1 md:col-span-2">
                    <app-alert type="error" message="Nao foi possivel concluir o upload. Tente novamente"
                        *ngIf="is_upload_error">
                    </app-alert>

                    <app-alert type="success" message="Carregado com sucesso" *ngIf="is_upload_succ">
                    </app-alert>
                </div>

                <div class="col-span-1 md:col-span-2" *ngIf="is_loading==true || is_uploading">
                    <div class="flex items-center rounded-xl rounded-tl-none p-[1rem] w-[7rem] ">
                        <app-spinner>
                        </app-spinner>
                    </div>
                </div>


                <div *ngIf="is_loading==false && objectList.length>0">
                    <div *ngIf="currentDocOpen===null">
                        <app-fatura [objectList]="objectList" [onSelect]="openFileItem" class="md:pb-8">
                        </app-fatura>
                    </div>

                    <div *ngIf="currentDocOpen!==null">

                        <div class="mt-3 mb-1">
                            
                            <app-alert type="info" message="Carregando detalhes"
                                *ngIf="loadCache[currentDocOpen.fileid].Status=='LOADING'">
                            </app-alert>
                            <app-alert type="error" message="Nao foi possivel carregar os detalhes"
                                *ngIf="loadCache[currentDocOpen.fileid].Status=='FAILED'">
                            </app-alert>
                        </div>

                        <div *ngIf="loadCache[currentDocOpen.fileid].Status=='DETAILS_LOAD'">
                            <div class=" mb-5 p-2">
                                <!-- <h3>DOCUMENTO</h3> -->
                                <div class="grid grid-cols-2">
                                    <div class="grid-col ">
                                        <div class="header">
                                            <div class="invoice-info">
                                                <h2 class="text-left opacity-70" style="font-size:small">
                                                    Documentos / {{ currentDocOpen['fileid'] }}
                                                </h2>
                                                <h1 class="font-semibold text-2xl pb-1">
                                                    {{currentDocOpen['realname']}}
                                                </h1>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="grid-col text-right">
                                        <div class="grid grid-cols-2" *ngIf="false">
                                            <div class="grid-col">
                                                <p class="text-right opacity-70" style="font-size:x-small;">
                                                    &Iacute;ndice de <br />Complexidade
                                                </p>
                                                <h1 class=" pb-1">
                                                    <span class="font-semibold text-6xl">
                                                        {{
                                                        loadCache[currentDocOpen.fileid].Details.info_group_response.doc_cplx.info.cplx_index
                                                        }}
                                                    </span>
                                                </h1>
                                            </div>

                                            <div class="grid-col">
                                                <p class="text-right opacity-70" style="font-size:x-small;">
                                                    Estimativa de <br />itera&ccedil;&otilde;es
                                                </p>
                                                <h1 class=" pb-1">
                                                    <span class="font-semibold text-6xl opacity-70">
                                                        {{
                                                        loadCache[currentDocOpen.fileid].Details.info_group_response.doc_cplx.info.est_iterations
                                                        }}
                                                    </span>
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-5"></div>

                                <div class="grid grid-cols-1 ">
                                    <div class="grid-col">
                                        <p class="mt-1 mb-2">
                                            <span
                                                (click)="genPDFPreview()"
                                                *ngIf="!is_run_preview && !previewPDF"
                                                class="cursor-pointer text-sm px-8 py-2 rounded-3xl font-bold text-white m-2 mr-1 border border-[#2571a1] bg-[#2571a1]">
                                                Pr&eacute;-Visualizar
                                            </span>
                                            <span
                                                (click)="closePDFPreview()"
                                                *ngIf="previewPDF"
                                                class="cursor-pointer text-sm px-8 py-2 rounded-3xl font-bold text-white m-2 mr-1 border border-[#00527E] bg-[#00527E]">
                                                Fechar
                                            </span>
                                            <!--
                                            <span
                                                (click)="openOdooSync()"
                                                *ngIf="!odooSync"
                                                class="cursor-pointer text-sm px-8 py-2 rounded-3xl font-bold text-[#00527E] m-2 ml-1 border border-[#00527E] ">
                                                ODOO
                                            </span>
                                            <span
                                                (click)="closeOdooSync()"
                                                *ngIf="odooSync"
                                                class="cursor-pointer text-sm px-8 py-2 rounded-3xl font-bold text-[#00527E] m-2 ml-1 border border-[#00527E] ">
                                                FECHAR ODOO
                                            </span>
                                            -->
                                        </p>
                                    </div>

                                    <div class="grid-col">
                                        <div *ngIf="previewPDF" style="max-height:400px;overflow: hidden;overflow-y: scroll;background-color:#f4f4f4">
                                            <app-pdf-preview [pdfUrl]="previewPDF.preview_url">
                                            </app-pdf-preview>
                                        </div>

                                        <div *ngIf="odooSync" style="min-height: 500px;max-height:700px;overflow: hidden;overflow-y: scroll;background-color:#f4f4f4">
                                            <app-report-odoo [doc]="currentDocOpen">
                                            </app-report-odoo>
                                        </div>

                                        <!--
                                        <h3>Modelo DPR</h3>
                                        <h3>Analise & Resumo</h3>
                                        <h3>Descritivos do documento</h3>
                                        <h3>Analise Bancaria</h3>
                                        <h3>Listagem de Produtos Recursiva</h3>
                                        -->
                                    </div>
                                </div>

                                <div class="grid grid-cols-1 mt-2">
                                    <div class="grid-col " *ngIf="currentDocOpen.fileid+'#REPORTS' as keyCache">
                                        <hr />

                                        <h3 class="text-2xl font-extrabold text-left mt-5 mb-2 pl-4">
                                            Relatórios
                                        </h3>
                                        <p class="mt-1 mb-2 pl-3">
                                            <span
                                                class="cursor-pointer text-[small] px-8 py-2 rounded-3xl bg-[#fff]  font-bold text-[#00527E] m-2"
                                                (click)="queryDocumentObject(currentDocOpen.fileid, 'REPORTS')"
                                                style="border: 1px solid rgb(0, 82, 126);">
                                                Actualizar
                                            </span>
                                        </p>


                                        <div *ngIf="objectCache[keyCache]" class="mt-5 pl-3 pr-3">
                                            <app-alert type="info" message="Carregando relatórios"
                                                *ngIf="objectCache[keyCache].Status=='LOADING'">
                                            </app-alert>

                                            <app-alert type="error" message="Nao foi possivel carregar os relatórios"
                                                *ngIf="objectCache[keyCache].Status=='FAILED'">
                                            </app-alert>

                                            <div class="grid-col"
                                                *ngIf="objectCache[keyCache].info && objectCache[keyCache].info.length==0">
                                                <div class="text-center">
                                                    <p class="text-sm  m-0"
                                                        style="color:#000;font-weight:bold">
                                                        Nenhum relatório encontrado
                                                    </p>
                                                    <p class="text-sm opacity-70 m-0">
                                                        Dirija-se a 
                                                        <a href="/document-analysis" 
                                                            style="color:#2571a1!important;font-weight:bold;" 
                                                            class="text-[#2571a1] text-underline text-bold">
                                                             Análise 
                                                        </a>
                                                        adicionar relatórios automatizados.
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="mt-5 pl-3 pr-3"
                                                *ngIf="objectCache[keyCache].Status=='LOADED' && objectCache[keyCache].info">

                                                <div class="grid-col" *ngIf="objectCache[keyCache].info.length>0">
                                                    <p class="opacity-70 m-0">
                                                        {{ objectCache[keyCache].info.length}}
                                                        relatórios encontrados.
                                                    </p>
                                                </div>

                                                <div class="grid grid-cols-2">
                                                    <div class="grid-col "
                                                        *ngFor="let blockObj of objectCache[keyCache].info">

                                                        <div
                                                            class="panel mb-2 mr-1 p-2 border border-gray-300 pl-3 rounded-xl ">
                                                            <div class="panel-header pt-2 pb-2 pr-4">
                                                                <div
                                                                    class="flex flex-col md:flex-row justify-between items-center">
                                                                    <!-- Nome -->
                                                                    <span
                                                                        class="text-sm text-[#1422a9] font-medium md:pl-[9px] truncate sm:text-xs  md:w-auto w-ful">
                                                                        {{
                                                                        (loadCache[currentDocOpen.fileid].DOC_MAP[blockObj.name].label)
                                                                        || blockObj.name.toUpperCase()
                                                                        }}
                                                                    </span>

                                                                    <!-- Espaço reservado para o texto branco (não visível na tela) -->
                                                                    <span class="text-sm text-white"
                                                                        style="padding-right: 9px; font-size: xx-small;"></span>

                                                                    <!-- Botões -->
                                                                    <div class="flex flex-col md:flex-row items-center">
                                                                        <!--
                                                                        <span
                                                                            class="cursor-pointer pr-2 text-[small] bg-[#fff] rounded-xl font-bold text-[#00527E] mb-2 md:mb-0"
                                                                            style="font-size: x-small;">
                                                                            <img src="assets/img/icon/thumbsUp.svg"
                                                                                alt="Like this report" class="w-50"
                                                                                style="display: inline !important;" />
                                                                        </span>
                                                                        <span
                                                                            class="cursor-pointer pl-2 text-[small] p-2 bg-[#fff] rounded-xl font-bold text-[#00527E]"
                                                                            style="font-size: x-small;">
                                                                            Gerar+
                                                                        </span>
                                                                        -->
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div class="panel-content"
                                                                style="height:200px;max-height: 400px;overflow: hidden;overflow-y: scroll;">
                                                                <div class="grid grid-cols-1 mt-0">
                                                                    <div class="grid-col" *ngIf="blockObj.report">
                                                                        <app-doc-gen-report 
                                                                            [report]="blockObj.report"
                                                                            [groupname]="blockObj.name">
                                                                        </app-doc-gen-report>
                                                                    </div>
                                                                    <div class="grid-col" *ngIf="!blockObj.report">
                                                                        <p>Sem Relatorio para {{
                                                                            blockObj.name.toUpperCase() }} </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="mt-5"></div>

                                <div class="grid grid-cols-1 mt-5" *ngIf="currentDocOpen">
                                    <div class="grid-col">
                                        <!--
                                        <app-generative-ai-iterator [doc]="currentDocOpen"
                                            [assistantName]="'genai/BigAssistant'">
                                        </app-generative-ai-iterator>
                                        -->
                                    </div>

                                    <div class="grid-col mt-5">
                                        <!--
                                        <h3 class="text-2xl font-extrabold text-left mt-5 mb-0">
                                            Regras &amp; Parametros
                                        </h3>
                                        <hr />

                                        <h3 class="text-2xl font-extrabold text-left mt-5 mb-0">
                                            Feedback
                                        </h3>
                                        <hr />
                                        -->
                                    </div>
                                </div>

                                <div class="grid grid-cols-1 mt-5">
                                </div>
                            </div>
                        </div>
                        <div class="mb-5"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</app-user-layout>