<style type="text/css">
    table.op-table input {
        background: #c1c6e1;
        border-radius: 0px;
        box-shadow: 0px 0px 0px rgba(0,0,0,0);
        font-size: 12px;
    }
</style>
<div *ngIf="is_loading">
    <div class="flex items-center justify-center rounded-xl rounded-tl-none p-[1rem] w-[7rem] ">
        <app-spinner>
        </app-spinner>
    </div>
</div>

<app-alert type="success" 
    message="{{resp_msg || 'O Relatorio Modelo DPR 106 foi gerado com sucesso'}}"
    *ngIf="is_created">
</app-alert>

<app-alert type="error" 
    message="{{ error_msg || 'Nao foi possivel concluir. Verifique todos os campos' }}"
    *ngIf="is_error">
</app-alert>

<app-alert type="error" 
    message="{{ error_msg || 'Houve um erro no processamento' }}"
    *ngIf="is_server_error">
</app-alert>

<app-alert type="warning" 
    message="Alerta: Verifique todos os campos"
    *ngIf="anexoForForm && !anexoForForm.valid && !is_loading">
</app-alert>

<div>
    <form [formGroup]="anexoForForm" (ngSubmit)="onSubmit()" class=" mx-auto">

        <div class="mb-4 pb-4 mt-2 border-b">
            <!-- <h2 class="text-lg font-bold mb-2">Detalhes Gerais</h2> -->
            <div class="grid grid-cols-3 gap-5 ">
                <div class="grid-col">
                    <div class="" style="border:1px solid #ccc">
                        <p style="background-color:#ccc;color:#fff">Detalhes</p>
                        <table style="width:100%">
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="form-field flex items-center">
                                            <label for="ano" class="mr-2" style="font-size:small">
                                                Nif:
                                            </label>
                                            <input type="number"
                                              placeholder="NIF"
                                              formControlName="nif"
                                              required
                                              style="font-size:small;padding-left:4px;padding-right: 4px;" 
                                              class="flex-1 outline-none"
                                            />
                                        </div>

                                        <div class="form-field flex items-center">
                                            <label for="ano" class="mr-2" style="font-size:small">
                                                Período (Ano):
                                            </label>
                                            <input type="number"
                                              placeholder="2022"
                                              formControlName="periodo_ano"
                                              required
                                              style="font-size:small;padding-left:4px;padding-right: 4px;" 
                                              class="flex-1 outline-none"
                                            />
                                        </div>

                                        <div class="form-field flex items-center">
                                            <label for="ano" class="mr-2" style="font-size:small">
                                                Per&iacute;odo (M&ecirc;s):
                                            </label>
                                            <select formControlName="periodo_mes" required>
                                                <option value="01">Janeiro</option>
                                                <option value="02">Fevereiro</option>
                                                <option value="03">Março</option>
                                                <option value="04">Abril</option>
                                                <option value="05">Maio</option>
                                                <option value="06">Junho</option>
                                                <option value="07">Julho</option>
                                                <option value="08">Agosto</option>
                                                <option value="09">Setembro</option>
                                                <option value="10">Outubro</option>
                                                <option value="11">Novembro</option>
                                                <option value="12">Dezembro</option>
                                            </select>
                                        </div>

                                        <div class="form-field flex items-center">
                                            <label for="ano" class="mr-2" style="font-size:small">
                                                exist_oper:
                                            </label>
                                            <select formControlName="exist_oper" required>
                                                <option value="0">C/ operações (activas e/ou passivas)</option>
                                                <option value="1">inexistência de operações</option>
                                                <option value="2">única operação tributável e pela primeira vez</option>
                                            </select>
                                        </div>
                                    </td>

                                    <td>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                
                <div class="grid-col">
                    <div class="" style="border:1px solid #ccc">
                        <p style="background-color:#ccc;color:#fff">Tipo Declaracao / Tipo Anexos</p>
                        <table style="width:100%">
                            <tbody>
                                <tr>
                                    <td style="width:50%!important;">
                                        <select formControlName="anex_dec" required>
                                            <option value="1">No Prazo</option>
                                            <option value="2">Fora do prazo</option>
                                            <option value="4">Substituição</option>
                                        </select>

                                        <div class="form-field flex items-center">
                                            <label class="mr-2" style="font-size:x-small">
                                                Registo do Técnico
                                            </label>
                                            <input type="text"
                                              placeholder="num_ordem_toc"
                                              formControlName="num_ordem_toc"
                                              required
                                              style="font-size:small;padding-left:2px;padding-right: 4px;" 
                                              class="flex-1 border outline-none"
                                            />
                                        </div>

                                        <div class="form-field flex items-center">
                                            <label class="mr-2" style="font-size:x-small">
                                                Nif do Técnico
                                            </label>
                                            <input type="number"
                                              placeholder="nif_toc"
                                              formControlName="nif_toc"
                                              required
                                              style="font-size:small;padding-left:2px;padding-right: 4px;" 
                                              class="flex-1 border outline-none"
                                            />
                                        </div>

                                    </td>

                                    <td style="width:50%!important;">
                                        <p style="font-size:small;padding-left: 5px;">
                                            <input id="anex_cli" formControlName="anex_cli" 
                                                type="checkbox" 
                                                class="focus:outline-none focus:border-blue-500" 
                                                style="display:inline" 
                                            />
                                            Cliente 
                                        </p>
                                        <p style="font-size:small;padding-left: 5px;">
                                            <input id="anex_for" formControlName="anex_for" type="checkbox" 
                                                class=" " 
                                                style="display:inline" 
                                            />
                                            Fornecedor
                                        </p>
                                        <p style="font-size:small;padding-left: 5px;">
                                            <input id="cli_reg" formControlName="cli_reg" type="checkbox" 
                                                class=""
                                                style="display:inline" 
                                            />
                                            Reg Clientes
                                        </p>
                                        <p style="font-size:small;padding-left: 5px;">
                                            <input id="for_reg" formControlName="for_reg" type="checkbox" 
                                                class=""
                                                style="display:inline" 
                                            />
                                            Reg Fornecedores
                                        </p>
                                    </td>
                                </tr>

                                <tr>
                                    <td colspan="2">
                                        <div class="form-field flex items-center">
                                            <textarea placeholder="Observacoes" rows="1"
                                                formControlName="obs"
                                                style="font-size:small;padding-left:4px;padding-right: 4px;"></textarea>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                
                <div class="grid-col">
                    <div class="" style="border:1px solid #ccc">
                        <p style="background-color:#ccc;color:#fff">Datas</p>
                        <table style="width:100%">
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="form-field flex items-center">
                                            <label for="ano" class="mr-2" style="font-size:x-small">
                                                C&oacute;digo de Area Local:
                                            </label>
                                            <input type="number"
                                              placeholder="cd_af"
                                              formControlName="cd_af"
                                              required
                                              style="font-size:small;padding-left:4px;padding-right: 4px;" 
                                              class="flex-1 outline-none"
                                            />
                                        </div>

                                        <div class="form-field flex items-center">
                                            <label for="ano" class="mr-2" style="font-size:x-small">
                                                Data de preenchimento
                                            </label>
                                            <input type="date"
                                              placeholder="dt_apresentacao"
                                              formControlName="dt_apresentacao"
                                              required
                                              style="font-size:small;padding-left:4px;padding-right: 4px;" 
                                              class="flex-1 outline-none"
                                            />
                                        </div>

                                        <div class="form-field flex items-center">
                                            <label for="ano" class="mr-2" style="font-size:x-small">
                                                Repartição  de Entrega
                                            </label>
                                            <input type="text"
                                              placeholder="loc_apresentacao"
                                              formControlName="loc_apresentacao"
                                              required
                                              style="font-size:small;padding-left:4px;padding-right: 4px;" 
                                              class="flex-1 outline-none"
                                            />
                                        </div>
                                        <div class="form-field flex items-center">
                                            <label class="mr-2" style="font-size:x-small">
                                                Data de Recepção
                                                (opcional)
                                            </label>
                                            <input type="date"
                                              placeholder="dt_recepcao"
                                              formControlName="dt_recepcao"
                                              style="font-size:small;padding-left:4px;padding-right: 4px;" 
                                              class="flex-1 outline-none"
                                            />
                                        </div>
                                    </td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>

        <div class="mb-4 pb-4 mt-0 border-b"
            style="background-color:#f4f4f4;">
            <div class="grid grid-cols-1">
                <div class="grid-col p-2">
                    <table style="width:100%" class="op-table">
                        <thead>
                            <tr>
                                <th>
                                    TIPO DE OPERAÇÃO
                                </th>
                                <th>
                                    Base Tributável
                                </th>
                                <th>
                                    Imposto / Sujeito passivo
                                </th>
                                <th class="text-right">
                                    Imposto / Estado
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="bg-[#fff] pl-2 pr-2"
                                    style="border-bottom: 1px solid #ccc;"> 
                                    <span style="font-size:x-small"> Taxa Normal </span> 
                                </td>

                                <td>
                                    <p style="float: left;font-size: x-small;padding:7px;background-color:#000;color:#fff;padding-top: 8px;padding-bottom: 6px;">01</p>
                                    <input id="R1" 
                                        formControlName="R1" 
                                        type="number" 
                                        class="px-3 py-2 w-90 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R1" 
                                        style="font-size:x-small"
                                        required 
                                    />
                                </td>
                                <td></td>
                                <td class="text-left">
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">02</p>
                                    <input id="R2" 
                                        formControlName="R2" 
                                        type="number" 
                                        class="px-3 py-2 w-90 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R2" 
                                        style="font-size:x-small"
                                        required 
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td class="bg-[#fff]"
                                    style="border-bottom: 1px solid #ccc;"> <span style="font-size:x-small"> Taxa Especial (03) </span> </td>

                                <td>
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">03</p>
                                    <input id="R3" 
                                        formControlName="R3" 
                                        type="number" 
                                        class="w-90 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R3" 
                                        style="font-size:x-small"
                                        required 
                                    />
                                </td>
                                <td></td>
                                <td class="text-left">
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">04</p>
                                    <input id="R4" 
                                        formControlName="R4" 
                                        type="number" 
                                        class="w-90 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R4" 
                                        style="font-size:x-small"
                                        required 
                                    />
                                </td>
                            </tr>

                            <tr>
                               <td class="bg-[#fff]"
                                    style="border-bottom: 1px solid #ccc;"> 
                                    <span style="font-size:x-small"> Valor IVA Recebido (05) </span> 
                                </td>

                                <td>
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">05</p>
                                    <input id="R5" 
                                        formControlName="R5" 
                                        type="number" 
                                        class="w-90 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R5" 
                                        style="font-size:x-small"
                                        required 
                                    />
                                </td>
                                <td></td>
                                <td class="text-left">
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">06</p>
                                    <input id="R6" 
                                        formControlName="R6" 
                                        type="number" 
                                        class="w-90 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R6" 
                                        style="font-size:x-small"
                                        required 
                                    />
                                </td>
                            </tr>

                            <tr>
                               <td class="bg-[#fff]"
                                style="border-bottom: 1px solid #ccc;"> <span style="font-size:x-small"> Oper. de IVA Liquidado (07) </span> </td>

                                <td>
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">07</p>
                                    <input id="R7" 
                                        formControlName="R7" 
                                        type="number" 
                                        class="w-90 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R7" 
                                        style="font-size:x-small"
                                        required 
                                    />
                                </td>
                                <td></td>
                                <td class="text-right">
                                </td>
                            </tr>

                            <tr class="">
                                <td colspan="4" class="text-left pt-2 pb-2">
                                    <span style="font-size:small">
                                        Transmissões de bens e prestação de serviços:
                                    </span>
                                </td>
                            </tr>

                            <tr>
                               <td class="bg-[#fff]"
                                style="border-bottom: 1px solid #ccc;"> <span style="font-size:x-small"> Isento com direito a dedução (08) </span> </td>

                                <td>
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">08</p>
                                    <input id="R8" 
                                        formControlName="R8" 
                                        type="number" 
                                        class="w-90 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R8" 
                                        style="font-size:x-small"
                                        required 
                                    />
                                </td>
                                <td></td>
                                <td class="text-right">
                                </td>
                            </tr>

                            <tr>
                               <td class="bg-[#fff]"
                                style="border-bottom: 1px solid #ccc;"> <span style="font-size:x-small"> Isento sem direito a dedução (09) </span> </td>

                                <td>
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">09</p>
                                    <input id="R9" 
                                        formControlName="R9" 
                                        type="number" 
                                        class="w-90 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R9" 
                                        style="font-size:x-small"
                                        required 
                                    />
                                </td>
                                <td></td>
                                <td class="text-right">
                                </td>
                            </tr>

                            <tr>
                               <td class="bg-[#fff]"
                                style="border-bottom: 1px solid #ccc;"> <span style="font-size:x-small"> Não tributados (10) </span> </td>

                                <td>
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">10</p>
                                    <input id="R10" 
                                        formControlName="R10" 
                                        type="number" 
                                        class="w-90 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R10" 
                                        style="font-size:x-small"
                                        required 
                                    />
                                </td>
                                <td></td>
                                <td class="text-right">
                                </td>
                            </tr>

                            <tr class="">
                                <td colspan="4" class="text-left pt-2 pb-2">
                                    <span class="mt-4">
                                    </span>
                                </td>
                            </tr>

                            <tr>
                                <td class="bg-[#fff]"
                                    style="border-bottom: 1px solid #ccc;"> 
                                    <span style="font-size:x-small"> 
                                        Aquisições de serviços prestadores sem domicílio em Cabo Verde
                                    </span> 
                                </td>

                                <td>
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">11</p>
                                    <input id="R11" 
                                        formControlName="R11" 
                                        type="number" 
                                        class="w-90 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R11" 
                                        style="font-size:x-small"
                                        required 
                                    />
                                </td>

                                <td>
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">12</p>
                                    <input id="R12" 
                                        formControlName="R12" 
                                        type="number" 
                                        class="w-90 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R12" 
                                        style="font-size:x-small"
                                        required 
                                    />
                                </td>

                                <td class="">
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">13</p>
                                    <input id="R13" 
                                        formControlName="R13" 
                                        type="number" 
                                        class="w-90 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R13" 
                                        style="font-size:x-small"
                                        required 
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td class="bg-[#fff]"
                                    style="border-bottom: 1px solid #ccc;"> 
                                    <span style="font-size:x-small"> 
                                        <!--
                                        Aquisição dos serviços efetuados nos termos do art. 2º, alínea f) do RIVA -
                                        Construção Civil, em que o IVA foi liquidado pelo declarante.
                                        -->
                                        Aquisição dos serviços RIVA - Construção Civil (14)
                                    </span> 
                                </td>

                                <td>
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">14</p>
                                    <input id="R14" 
                                        formControlName="R14" 
                                        type="number" 
                                        class="w-90 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R14" 
                                        style="font-size:x-small"
                                        required 
                                    />
                                </td>

                                <td>
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">15</p>
                                    <input id="R15" 
                                        formControlName="R15" 
                                        type="number" 
                                        class="w-90 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R15" 
                                        style="font-size:x-small"
                                        required 
                                    />
                                </td>

                                <td class="">
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">16</p>
                                    <input id="R16" 
                                        formControlName="R16" 
                                        type="number" 
                                        class="w-90 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R16" 
                                        style="font-size:x-small"
                                        required 
                                    />
                                </td>
                            </tr>

                            <tr class="">
                                <td class="text-left pt-2 pb-2" colspan="4">
                                    <span style="font-size:x-small">
                                        Transmissões de bens e prestações de serviços efectuadas ao sujeito passivo declarante.
                                    </span>
                                </td>
                            </tr>

                            <tr>
                               <td class="bg-[#fff]"
                                    style="border-bottom: 1px solid #ccc;"
                                    > <span style="font-size:x-small"> Investimentos </span> </td>

                                <td>
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">17</p>
                                    <input id="R17" 
                                        formControlName="R17" 
                                        type="number" 
                                        class="w-90 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R17" 
                                        style="font-size:x-small"
                                        required 
                                    />
                                </td>
                                <td>
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">18</p>
                                    <input id="R18" 
                                        formControlName="R18" 
                                        type="number" 
                                        class="w-90 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R18" 
                                        style="font-size:x-small"
                                        required 
                                    />
                                </td>
                                <td class="text-right">
                                </td>
                            </tr>

                            <tr>
                               <td class="bg-[#fff]"
                                    style="border-bottom: 1px solid #ccc;"> 
                                    <span style="font-size:x-small"> Inventários </span> 
                                </td>

                                <td>
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">19</p>
                                    <input id="R19" 
                                        formControlName="R19" 
                                        type="number" 
                                        class="w-90 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R19" 
                                        style="font-size:x-small"
                                        required 
                                    />
                                </td>
                                <td>
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">20</p>
                                    <input id="R20" 
                                        formControlName="R20" 
                                        type="number" 
                                        class="w-90 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R20" 
                                        style="font-size:x-small"
                                        required 
                                    />
                                </td>
                                <td class="text-right">
                                </td>
                            </tr>

                            <tr>
                               <td class="bg-[#fff]"
                                    style="border-bottom: 1px solid #ccc;"> 
                                    <span style="font-size:x-small"> Outros Bens de Consumo </span> 
                                </td>

                                <td>
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">21</p>
                                    <input id="R21" 
                                        formControlName="R21" 
                                        type="number" 
                                        class="w-90 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R21" 
                                        style="font-size:x-small"
                                        required 
                                    />
                                </td>
                                <td>
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">22</p>
                                    <input id="R22" 
                                        formControlName="R22" 
                                        type="number" 
                                        class="w-90 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R22"
                                        style="font-size:x-small"
                                        required 
                                    />
                                </td>
                                <td class="text-right">
                                </td>
                            </tr>

                            <tr>
                               <td class="bg-[#fff]"
                                    style="border-bottom: 1px solid #ccc;"> 
                                    <span style="font-size:x-small"> Serviços </span> 
                                </td>

                                <td>
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">23</p>
                                    <input id="R23" 
                                        formControlName="R23" 
                                        type="number" 
                                        class="w-90 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R23" 
                                        style="font-size:x-small"
                                        required 
                                    />
                                </td>
                                <td>
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">24</p>
                                    <input id="R24" 
                                        formControlName="R24" 
                                        type="number" 
                                        class="w-90 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R24"
                                        style="font-size:x-small"
                                        required 
                                    />
                                </td>
                                <td class="text-right">
                                </td>
                            </tr>

                            <tr class="mt-2 mb-2">
                                <td colspan="4"></td>
                            </tr>

                            <tr>
                               <td class="bg-[#fff]"
                                    style="border-bottom: 1px solid #ccc;"> <span style="font-size:x-small"> Imposto Dedutível nas importações de bens efetuadas pelo SP </span> </td>

                                <td>
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">25</p>
                                    <input id="R25" 
                                        formControlName="R25" 
                                        type="number" 
                                        class="w-90 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R25" 
                                        style="font-size:x-small"
                                        required 
                                    />
                                </td>
                                <td>
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">26</p>
                                    <input id="R26" 
                                        formControlName="R26" 
                                        type="number" 
                                        class="w-90 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R26"
                                        style="font-size:x-small"
                                        required 
                                    />
                                </td>
                                <td class="text-right">
                                </td>
                            </tr>

                            <tr>
                               <td class="bg-[#fff]"
                                    style="border-bottom: 1px solid #ccc;"> 
                                    <span style="font-size:x-small"> Regularizações mensais ou anuais comunicadas pela Admin. Fiscal </span> 
                                </td>

                                <td>
                                </td>
                                <td>
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">27</p>
                                    <input id="R27" 
                                        formControlName="R27" 
                                        type="number" 
                                        class="w-90 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R27"
                                        style="font-size:x-small"
                                        required 
                                    />
                                </td>

                                <td class="">
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">28</p>
                                    <input id="R28" 
                                        formControlName="R28" 
                                        type="number" 
                                        class="w-90 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R28"
                                        style="font-size:x-small"
                                        required 
                                    />
                                </td>
                            </tr>

                            <tr>
                               <td class="bg-[#fff]"
                                    style="border-bottom: 1px solid #ccc;"> 
                                    <span style="font-size:x-small"> Regularizações mensais ou anuais, exceto as comunicadas pela Admin. Fiscal
                                     </span> </td>

                                <td>
                                </td>
                                <td>
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">29</p>
                                    <input id="R29" 
                                        formControlName="R29" 
                                        type="number" 
                                        class="w-90 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R29"
                                        style="font-size:x-small"
                                        required 
                                    />
                                </td>

                                <td class="">
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">30</p>
                                    <input id="R30" 
                                        formControlName="R30" 
                                        type="number" 
                                        class="w-90 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R30"
                                        style="font-size:x-small"
                                        required 
                                    />
                                </td>
                            </tr>

                            <tr>
                               <td class="bg-[#fff] text-right pr-5" colspan="3"> 
                                    <span style="font-size:small;font-weight:bold;"> 
                                    PERCENTAGEM ESTIMADA ( dedução parcial pro rata ) 31
                                     </span> 
                                 </td>

                                <td class="">
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">31</p>
                                    <input id="R31" 
                                        formControlName="R31" 
                                        type="number" 
                                        class="w-90 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R31"
                                        style="font-size:x-small"
                                    />
                                </td>
                            </tr>

                            <tr class="mt-2 pt-2">
                                <td class="text-center"> 
                                    <span style="font-weight:bold;"> 
                                        SOMAS
                                     </span> 
                                 </td>

                                <td>
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">32</p>
                                    <p class="p-1 pt-2 pb-2 text-right" style="background-color:#ccc">
                                        {{counters.R32|number}}
                                    </p>
                                    <p style="font-size:x-small;" class="pt-1 text-right">
                                        <span  class="opacity-80">(32=01+03+05+07+08+09+10+11+14+17+19+21+23+25)</span>
                                    </p>
                                </td>

                                <td>
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">33</p>
                                    <p class="p-1 pt-2 pb-2 text-right" style="background-color:#ccc">
                                        {{counters.R33 | number }}
                                    </p>
                                    <p style="font-size:x-small;" class="pt-1 text-right">
                                        <span  class="opacity-30">
                                            (33=12+15+18+20+22+24+26+27+29)
                                        </span>
                                    </p>
                                </td>

                                <td class="">
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">34</p>
                                    <p class="p-1 pt-2 pb-2 text-right" style="background-color:#ccc">
                                        {{counters.R34 | number }}
                                    </p>
                                    <p style="font-size:x-small;" class="pt-1 text-right">
                                        <span class="opacity-30">
                                            (34=02+04+06+13+16+28+30)
                                        </span>
                                    </p>
                                </td>
                            </tr>

                            <tr>
                               <td class="bg-[#fff] text-right pr-5" colspan="2"> 
                                    <span style="font-size:small;font-weight:bold;"> 
                                    Apuramento do período
                                     </span> 
                                 </td>

                                <td class="">
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">35</p>
                                    <p class="p-1 pt-2 pb-2 text-right" style="background-color:#ccc">
                                        {{counters.R35 | number  }}
                                    </p>
                                    <p style="font-size:x-small;" class="pt-1 text-right">
                                        <span class="opacity-30">
                                            (35 = 34 - 33)
                                        </span>
                                    </p>
                                </td>

                                <td class="">
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">36</p>
                                    <p class="p-1 pt-2 pb-2 text-right" style="background-color:#ccc">
                                        {{counters.R36 | number }}
                                    </p>
                                    <p style="font-size:x-small;" class="pt-1 text-right">
                                        <span class="opacity-30">
                                            (36 = 33 - 34)
                                        </span>
                                    </p>
                                </td>
                            </tr>

                            <tr>
                               <td class="bg-[#fff] text-right pr-5" colspan="2"> 
                                    <span style="font-size:small;font-weight:bold;"> 
                                    Excesso a reportar dos períodos anteriores
                                    (se estiver dentro no prazo) -  37
                                     </span> 
                                 </td>

                                <td class="">
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">37</p>
                                    <input id="R37" 
                                        formControlName="R37" 
                                        type="number" 
                                        class="w-90 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R37"
                                        style="font-size:x-small"
                                    />
                                </td>
                            </tr>

                            <tr>
                               <td class="bg-[#fff] text-right pr-5" colspan="2"> 
                                    <span style="font-size:small;font-weight:bold;"> 
                                    IMPOSTO A PAGAR AO ESTADO
                                    </span> 
                                 </td>

                                <td class="">
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">38</p>
                                    <p class="p-1 pt-2 pb-2 text-right" style="background-color:#ccc">
                                        {{counters.R38 | number }}
                                    </p>
                                    <p style="font-size:x-small;" class="pt-1 text-right">
                                        <span  class="opacity-80">
                                            (38 = 35-37)
                                        </span>
                                    </p>
                                </td>
                            </tr>

                            <tr>
                               <td class="bg-[#fff] text-right pr-5" colspan="2"> 
                                    <span style="font-size:small;font-weight:bold;"> 
                                    CRÉDITO DE IMPOSTO
                                    </span> 
                                 </td>

                                <td class="text-right">
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">39</p>
                                    <p class="p-1 pt-2 pb-2 text-right" style="background-color:#ccc">
                                        {{ counters.R39 | number }}
                                    </p>
                                    <p style="font-size:x-small;" class="pt-1 text-right">
                                        <span  class="opacity-80">
                                            (39 = 36+37)
                                        </span>
                                    </p>
                                </td>
                            </tr>

                            <tr>
                               <td class="bg-[#fff] text-right pr-5" colspan="2"> 
                                    <span style="font-size:small;font-weight:bold;"> 
                                        1. REPORTE PARA O PERÍODO SEGUINTE - 40
                                    </span> 
                                 </td>

                                <td class="">
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">40</p>
                                    <input id="R40" 
                                        formControlName="R40" 
                                        type="number" 
                                        class="w-95 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R40"
                                        style="font-size:x-small"
                                    />
                                </td>
                            </tr>

                            <tr>
                               <td class="bg-[#fff] text-right pr-5" colspan="2"> 
                                    <span style="font-size:small;font-weight:bold;"> 
                                        PEDIDO DE REEMBOLSO
                                    </span> 
                                 </td>

                                <td class="">
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">41</p>
                                    <input id="R41" 
                                        formControlName="R41" 
                                        type="number" 
                                        class="w-95 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R41"
                                        style="font-size:x-small"
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td colspan="4">
                                    <p class="pt-2 pb-2" style="font-size:x-small">
                                        A - Valores de base tributável inscritos nos campos 01 e 03
                                    </p>
                                </td>
                            </tr>

                            <tr>
                               <td class="bg-[#fff] text-right pr-5" colspan="2"> 
                                    <span style="font-size:x-small;font-weight:bold;"> 
                                        Adiantamento transmissões de bens e prestação de serviços tributadas
                                    </span> 
                                 </td>

                                <td class="">
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">42</p>
                                    <input id="R42" 
                                        formControlName="R42" 
                                        type="number" 
                                        class="w-95 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R42"
                                        style="font-size:x-small"
                                    />
                                </td>
                            </tr>
                            <tr>
                               <td class="bg-[#fff] text-right pr-5" colspan="2"> 
                                    <span style="font-size:x-small;font-weight:bold;"> 
                                        Amostras e ofertas para além do limite legal
                                    </span> 
                                 </td>

                                <td class="">
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">43</p>
                                    <input id="R43" 
                                        formControlName="R43" 
                                        type="number" 
                                        class="w-95 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R43"
                                        style="font-size:x-small"
                                    />
                                </td>
                            </tr>
                            <tr>
                               <td class="bg-[#fff] text-right pr-5" colspan="2"> 
                                    <span style="font-size:x-small;font-weight:bold;"> 
                                        Operações sujeitas a tributação da margem
                                    </span> 
                                 </td>

                                <td class="">
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">44</p>
                                    <input id="R44" 
                                        formControlName="R44" 
                                        type="number" 
                                        class="w-95 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R44"
                                        style="font-size:x-small"
                                    />
                                </td>
                            </tr>

                            <tr>
                               <td class="bg-[#fff] text-right pr-5" colspan="2"> 
                                    <span style="font-size:x-small;font-weight:bold;"> 
                                        Operações efetuadas ao abrigo das alíneas e) e f) do nº 3 do art. 3º e do nº 2 do art. 4º do RIVA 
                                    </span> 
                                 </td>
                                <td class="">
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">45</p>
                                    <input id="R45" 
                                        formControlName="R45" 
                                        type="number" 
                                        class="w-95 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R45"
                                        style="font-size:x-small"
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td colspan="4">
                                    <p class="pt-2 pb-2" style="font-size:x-small">
                                        B - Valores de base tributável inscritas no campo 08
                                    </p>
                                </td>
                            </tr>

                            <tr>
                               <td class="bg-[#fff] text-right pr-5" colspan="2"> 
                                    <span style="font-size:x-small;font-weight:bold;"> 
                                        Operações destinadas à exportação
                                    </span> 
                                 </td>
                                <td class="">
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">46</p>
                                    <input id="R46" 
                                        formControlName="R46" 
                                        type="number" 
                                        class="w-95 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R46"
                                        style="font-size:x-small"
                                    />
                                </td>
                            </tr>
                            <tr>
                               <td class="bg-[#fff] text-right pr-5" colspan="2"> 
                                    <span style="font-size:x-small;font-weight:bold;"> 
                                        Operações efetuadas nos termos do Decreto-Lei 88/2005 de 26 de Dez
                                    </span> 
                                 </td>
                                <td class="">
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">47</p>
                                    <input id="R47" 
                                        formControlName="R47" 
                                        type="number" 
                                        class="w-95 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R47"
                                        style="font-size:x-small"
                                    />
                                </td>
                            </tr>
                            <tr>
                               <td class="bg-[#fff] text-right pr-5" colspan="2"> 
                                    <span style="font-size:x-small;font-weight:bold;"> 
                                        Bens da Lista Anexa
                                    </span> 
                                 </td>
                                <td class="">
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">48</p>
                                    <input id="R48" 
                                        formControlName="R48" 
                                        type="number" 
                                        class="w-95 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R48"
                                        style="font-size:x-small"
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td colspan="4">
                                    <p class="pt-2 pb-2" style="font-size:x-small">
                                        C - Operações efetuadas nos termos do Decreto-Lei nº 16/2004, de 20 de Maio
                                    </p>
                                </td>
                            </tr>
                            <tr>
                               <td class="bg-[#fff] text-right pr-5" colspan="2"> 
                                    <span style="font-size:x-small;font-weight:bold;"> 
                                        Faturas de prestação de serviços emitadas (Valor Faturado)
                                    </span> 
                                 </td>
                                <td class="">
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">49</p>
                                    <input id="R49" 
                                        formControlName="R49" 
                                        type="number" 
                                        class="w-95 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R49"
                                        style="font-size:x-small"
                                    />
                                </td>
                            </tr>

                            <tr>
                               <td class="bg-[#fff] text-right pr-5" colspan="2"> 
                                    <span style="font-size:x-small;font-weight:bold;"> 
                                        Recibos de prestação de serviços faturados (Valor Recebido) 
                                    </span> 
                                 </td>
                                <td class="">
                                    <p style="float: left;font-size: x-small;padding:5px;background-color:#000;color:#fff">50</p>
                                    <input id="R50" 
                                        formControlName="R50" 
                                        type="number" 
                                        class="w-95 px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                        placeholder="R50"
                                        style="font-size:x-small"
                                    />
                                </td>
                            </tr>



                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="grid grid-cols-2" style="min-height:40px">
            <div class="grid-col text-center p-4">
                <button type="submit" 
                    class="px-4 py-2 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600">
                    <span *ngIf="is_loading">Aguarde</span>
                    <span *ngIf="!is_loading">
                        Criar Modelo de Rosto
                    </span>
                </button>
            </div>

            <div class="grid-col p-4">
                <div *ngIf="is_created">
                    <div style="max-width:300px;margin:auto;">
                        <app-alert type="success" 
                            message="{{resp_msg || 'O Relatorio Modelo DPR 106 foi gerado com sucesso'}}"
                            *ngIf="is_created">
                        </app-alert>
                    </div>

                    <div *ngIf="resposta && resposta.url_dl"
                        style="max-width:300px;margin:auto;" class="text-center">
                        <p class="opacity-30" style="font-size:small">
                            Pode tamb&eacute;m efectuar o download abaixo:
                        </p>

                        <p class="mt-1 mb-1  ">
                            <span class="rounded-xl p-2 text-[#fff] bg-[#00527E] m-2 border border-[#fff] cursor-pointer p-2  rounded-xl text-sm   m-2 justify-center">
                                <a href="{{resposta.url_dl}}" target="__blank">
                                    Download
                                </a>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>

    </form> 

</div>


