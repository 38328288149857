<app-user-layout>
    <p class="mt-4 mb-5 sm:mt-2 sm:mb-3" (click)="goBack()">
        <span class="cursor-pointer text-sm font-bold text-[#00527E] m-2 flex flex-row items-center">
            <img src="assets/img/icon/Group.svg" class="w-4 h-4 mr-1 sm:mr-0" />
            VOLTAR
        </span>
    </p>

    <div class="flex flex-row justify-between ">
        <div>
            <h3 class="text-2xl sm:text-xl font-bold text-left mb-3">Explorador</h3>
            <h2 class="text-left opacity-70">
                <span class="cursor-pointer">Lua Apps</span>
                /&nbsp;<span class="text-[#00527E]">Explorador</span>
            </h2>

        </div>

        <div *ngIf="currentDocOpen === null" class="flex justify-between items-center mb-4">
            <div class="flex space-x-5">
                <p class="cursor-pointer mt-1 mb-1 flex flex-col sm:flex-row">
                    <span class="flex flex-row px-4 py-2 rounded-3xl text-[#00527E] m-2 border border-[#00527E] sm:w-auto w-full"
                        (click)="LoadFolders(true)">
                        <img src="assets/img/icon/Arrows.svg" alt="" class="w-5 h-5" /> Actualizar
                    </span>
                    <span class="flex flex-row px-4 py-2 rounded-3xl text-[#00527E] m-2 border border-[#00527E] sm:w-auto w-full"
                        (click)="createFolder()">
                        <img src="assets/img/icon/folder-open.svg" alt="" class="w-5 h-5" /> Criar Pasta
                    </span>
                </p>
                
            </div>

            <div class="flex space-x-5">
            </div>
        </div>
    </div>

    <!-- <div>
    <p class="mt-4 mb-5" (click)="goBack()">
        <span
        class="cursor-pointer text-sm  font-bold text-[#00527E] m-2 flex flex-row  ">
        <img src="assets/img/icon/Group.svg " class="w-4 h-4 mr-1"/>
            VOLTAR
        </span>
    </p>
    <div class="flex flex-row justify-between">
        <img src="assets/img/apps/ODOO.webp" alt="Odoo Logo" class="w-20 h-25 sm:w-40 sm:h-50 pull-left" />
        <span (click)="FetchInvoices(false)"
            class="cursor-pointer flex flex-row rounded-3xl px-6 py-2 text-sm p-2 bg-[#00527E] font-bold text-white m-2 ml-0 border border-[#00527E]">
            <img src="assets/img/icon/Arrowswhite.svg" alt="" class="w-5 h-5 mr-2 mt-[0.5]" />
            ACTUALIZAR
        </span>
    </div>
    <h2 class="text-left opacity-70">
        <span class="cursor-pointer">Lua Apps</span>
        /&nbsp;
        <span class="text-[#00527E]">ODOO</span>
    </h2>
</div> -->

    <div class="mt-4 shadow-xl ">
        <div class="  p-10 ">
            <div class="tab-header flex flex-row justify-between mb-6">
                <div>
                    <div *ngIf="currentDocOpen === null"
                        class="tab-header-item cursor-pointer py-2 px-4  rounded-tl rounded-tr text-black font-semibold">
                        Pastas
                    </div>
                    <!--
                    <div *ngIf="currentDocOpen !== null"
                        class="tab-header-item cursor-pointer py-2 px-4  rounded-tl rounded-tr text-black font-semibold">
                        <p>{{ currentDocOpen['name'] }}</p>
                    </div>
                    -->
                </div>
                <div class="relative w-full sm:w-auto">
                    <input type="text" placeholder="Pesquisar..." class="border border-gray-300 px-5 py-1 rounded-full w-full sm:w-auto" style="padding-left: 40px;" />
                    <img src="assets/img/icon/search.svg" alt="" class="absolute top-1/2 left-3 transform -translate-y-1/2" />
                </div>
                
            </div>


            <div class=" p-2 tab-content md:p-10 border border-gray-300 rounded-xl ">
                <app-alert type="error" message="Nao foi possivel carregar as pastas. Tente novamente" *ngIf="is_error">
                </app-alert>

                <div class="text-center p-2" *ngIf="is_loading">
                    <app-spinner>
                    </app-spinner>
                </div>

                <div *ngIf="currentDocOpen===null && !is_error && objectList && objectList.length>0">
                    <div class="flex justify-between items-center mb-0 ">
                        <div>
                            <h3 class="font-bold text-sm font-poppins title-padding mb-5">
                                Mostrando {{objectList.length}} pastas
                                <!-- Mostrando 10 de 20 -->
                            </h3>
                        </div>
                    </div>

                    <div class="grid grid-cols-1">
                        <div class="grid-col" *ngIf="objectList">
                            <div class="table-auto rounded-3xl overflow-x-auto" style="width:100%">
                                <table class="text-sm" style="width:100%">
                                    <thead class="bg-[#EAF2F2] bg-opacity-60 mb-10 ">
                                        <tr class="rounded-tr-3xl">
                                            <th class=" p-3 pl-5 rounded-bl-3xl  mb-10   " style="text-align:start;">
                                                Nome</th>
                                            <th class="p-3  mb-10" style="text-align: left;">Criado em</th>
                                            <th class=" rounded-br-3xl  mb-10">Actions</th>
                                    </thead>
                                    <tbody class="mt-52">
                                        <tr *ngFor="let folder of objectList" (click)="openFolder(folder)"
                                            class="cursor-pointer pb-10 hover:bg-[#EAF2F2] ">
                                            <td class="p-1 cpointer text-left pl-5" style="text-align: left;">
                                                <p>
                                                    <img src="assets/img/icon/folder-open.svg" alt="Lua"
                                                        style="display:inline;width:30px;margin-right: 5px;" />
                                                    <span class="text-bold">
                                                        {{ folder['name'] }}
                                                    </span>
                                                </p>
                                            </td>

                                            <td class="p-3" style="text-align: left;">
                                                {{ folder['created_at'] }}
                                            </td>

                                            <td class="text-center">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                        </div>
                    </div>
                </div>

                <div *ngIf="currentDocOpen!==null">
                    <p class="" (click)="closeCurrentFile()" *ngIf="currentDocOpen!==null">
                        <span class="cursor-pointer text-sm  font-bold text-[#00527E] m-2 flex flex-row  ">
                            <img src="assets/img/icon/Group.svg " class="w-4 h-4 mr-1" />
                            Pastas
                            <span class="opacity-70"
                                style="font-size:small">&nbsp;/&nbsp;{{currentDocOpen['name']}}</span>
                        </span>
                    </p>

                    <app-explorer-folder [folder]="currentDocOpen">
                    </app-explorer-folder>
                </div>

            </div>
        </div>
    </div>


    <app-modal #createFolderModal title="Criar Pasta" [onClose]="closeCreateFolder">
        <div class="bg-white shadow-md rounded px-5 pt-6 pb-8 mb-1">
            <div class="grid grid-cols-1">
                <div class="grid-col" *ngIf="is_cloading">
                    <app-spinner>
                    </app-spinner>
                </div>

                <div class="grid-col" *ngIf="is_cerror">
                    <app-alert type="error" message="Nao foi possivel criar a pasta.">
                    </app-alert>
                </div>

                <div class="grid-col" *ngIf="is_created">
                    <app-alert type="success" message="Pasta criada com sucesso">
                    </app-alert>
                </div>
            </div>

            <div class="flex items-center bg-login">
                <form class="p-5" *ngIf="!is_created">
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm mb-2" for="email">
                            Nome da pasta
                        </label>

                        <input
                            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="folder_name" type="text" required placeholder="Minha Pasta" [(ngModel)]="newFolder.name"
                            name="folder_name" />
                    </div>

                    <p class="cursor-pointer mt-0 mb-1 flex ">
                        <span
                            class=" flex flex-row px-4 py-2 rounded-3xl text-[#fff] bg-[#00527E] m-2 border border-[#00527E]"
                            *ngIf="!is_cloading" (click)="requestNewFolder()">
                            <!-- <img src="assets/img/icon/Arrows.svg" alt="" class="w-5 h-5" />  -->
                            Criar
                        </span>
                        <span
                            class=" flex flex-row px-4 py-2 rounded-3xl text-[#fff] bg-[#00527E] m-2 border border-[#00527E]"
                            *ngIf="is_cloading">
                            <!-- <img src="assets/img/icon/Arrows.svg" alt="" class="w-5 h-5" />  -->
                            Aguarde
                        </span>
                    </p>
                </form>
            </div>
        </div>
    </app-modal>

</app-user-layout>