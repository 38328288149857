import { Component, Input, OnInit, ElementRef, ViewChild  } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ChatService } from 'src/app/services/chat.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import * as Highcharts from 'highcharts';


@Component({
  selector: 'app-aibi',
  templateUrl: './aibi.component.html',
  styleUrls: ['./aibi.component.scss']
})
export class AibiComponent implements OnInit{
    filterForm: FormGroup;
    is_loading:boolean = false;
    is_error:boolean = false;
    is_ready:boolean = false;
    reportData:any = {};
    Highcharts: typeof Highcharts = Highcharts;
    Graphs:any = {}

    // this.invoiceTable.nativeElement.scrollIntoView({ behavior: 'smooth' });

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private chatService: ChatService
    ) { 
        this.filterForm = this.fb.group({
            query: [null, Validators.required],
            startDate: [null, false],
            endDate: [null, false],
            // state: ['draft', Validators.required],
        });
    }

    ngOnInit() {
        setTimeout(()=>{
            this.UpdateBI();
        }, 120);
    }

    goBack() {
        this.router.navigate(['/luaxapps']);   
    }

    UpdateBI() {
        this.InvoiceBI()
    }

    RenderGraph(Name:any, Text:any, Data:any) {
        // Data = [{
        //     type: 'pie', // Defina o tipo da série como 'pie' para um gráfico de pizza
        //     name: 'Categories',
        //     data: [
        //         {
        //             name: 'Category A',
        //             y: 50 // Exemplo de dado para o gráfico de pizza
        //         }, 
        //         {
        //             name: 'Category B',
        //             y: 30 // Outro exemplo de dado
        //         }, 
        //         {
        //             name: 'Category C',
        //             y: 20 // Mais um exemplo de dado
        //         }
        //     ]
        // }]
        // supplier_graph
        var chartOptions: Highcharts.Options = {
            chart: {
                type: 'pie'
            },
            title: {
                text: Text
            },
            series: Data
        };

        this.Graphs[Name]={
            options: chartOptions,
        };
    }

    InvoiceBI() {
        this.is_error=false;
        this.is_loading=true;
        this.reportData['invoice'] = null;

        // setTimeout(()=>{
        //     this.is_loading=false;
        //     this.reportData = {
        //     }
        // }, 80);

        const payload = {
            ops: 'InvoiceBI'
        }

        this.chatService.ExecuteTask('lua/BIServiceAPI', payload, (doc_resp: any) => {
            const object_: any = doc_resp.object_ || {};
            this.is_loading=false;
            setTimeout(() => {
                this.is_loading = false;
                if (doc_resp.Status == 'OK') {
                    this.reportData['invoice']=doc_resp.report;

                    if (doc_resp.report.supplier_dist) {
                        var rData = [{
                            type: 'pie',
                            name: '7898',
                            data: doc_resp.report.supplier_dist.map((E:any)=>{
                                return {
                                    name: E.supplier_nif,
                                    y: E.count
                                }
                            })
                        }];
                        setTimeout(()=>{
                            this.RenderGraph("supplier", "", rData)
                        }, 98);
                    }

                    if (doc_resp.report.folder_dist) {
                        setTimeout(()=>{
                            var rData = [{
                                type: 'pie',
                                name: '456',
                                data: doc_resp.report.folder_dist.map((E:any)=>{
                                    return {
                                        name: E.folder,
                                        y: E.count
                                    }
                                })
                            }];
                            this.RenderGraph("folders", "", rData)
                        }, 398);
                    }

                    if (doc_resp.report.state_dist) {
                        setTimeout(()=>{
                            var rData = [{
                                type: 'pie',
                                name: '123',
                                data: doc_resp.report.state_dist.map((E:any)=>{
                                    return {
                                        name: E.invoice_state,
                                        y: E.count
                                    }
                                })
                            }];
                            this.RenderGraph("state", "", rData)
                        }, 898);
                    }

                } else {
                    this.is_error = true;
                }
            }, 60);
    
        }, (error: any) => {
            this.is_error = true;
            this.is_loading = false;
        });
    }
}
