import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoundedIconButtomComponent } from '../rounded-icon-buttom/rounded-icon-buttom.component';
import { InputChat } from 'src/app/models/ComponentInput';
import { ChatService } from 'src/app/services/chat.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'app-input-chat',
    standalone: true,
    imports: [CommonModule, RoundedIconButtomComponent],
    templateUrl: './input-chat.component.html',
    styleUrls: ['./input-chat.component.scss'],
})

export class InputChatComponent {
    @Input() chatData: InputChat = {classInput: '', classContainer: '',};
    @Input() btnClass = '!bg-[#2CC784]';
    @Input() iconUrl = 'assets/img/seta.svg';
    @Input() inputValue?: string;
    @Output() btnClick = new EventEmitter<void>();
    @Output() inputValueChange: EventEmitter<string> = new EventEmitter<string>();

    private subscription: Subscription;
    private currentEditIndex: number | null = null; 

    constructor(private chatService: ChatService ,private router: Router,) {
        this.subscription = this.chatService.editMessage$.subscribe(
            ({ message, index }) => this.setEditInput(message, index) 
        );
    }

    onInputChange(event: Event): void {
        this.inputValue = (event.target as HTMLInputElement).value;
        this.inputValueChange.emit(this.inputValue);
    }

    handelKeyDown(ev: any) {   
        if (ev.key === 'Enter') {
            ev.preventDefault();
            this.btnClick.emit();
            if (this.currentEditIndex !== null && this.inputValue) {
                this.chatService.resetChatAndSendNewPrompt(this.currentEditIndex, this.inputValue);
                this.currentEditIndex = null; // Limpa o índice de edição
                this.inputValue = ''; // Limpa o valor do input
            }
        }
    }

    uploadFile(e:any){
        this.chatService.onFileSelected(e)
        this.router.navigate(['/chat']);
    }

    onBtnClick() {
        this.btnClick.emit()
    }

    setEditInput(message: string, index: number) { // Modificado
        this.inputValue = message;
        this.inputValueChange.emit(this.inputValue);
        this.currentEditIndex = index; // Adicionado
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
