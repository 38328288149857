import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ChatService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-trainsystem',
  templateUrl: './trainsystem.component.html',
  styleUrls: ['./trainsystem.component.scss']
})
export class TrainsystemComponent implements OnInit {
    stmcg_obj:any = {
        id: '',
        created_at:'',
        org_id:'',
        Status: 'OK',
        name: "TEST_0001",
        conf: {},
        memgroup_list: [
            {
                title: 'M1',
                context: 'M1',
                context_id: 'WEB',
                primaryColor: '',
                llm_model : "NO_MODEL",
                header_bgcolor: '#152272',
                header_textcolor: "#fff",
                classname: "",
                description: `
                    Short Term Memory most recent conversations. no analysis or quality performed.
                    Interesting for time-sequenced questions present in a conversation.
                    Great for attention seeking patterns, do not rely on this Group to give you
                    correct or meaningful answer. Aditional processing or combine output with query
                    to other groups (M2, M3, M4)
                `,
                conf:{},
                content: '',
                streamData: [
                    { symbol: '^IXIC', lastPrice: '17,726.94', change: '-144.28', percentChange: '-0.81%' },
                    { symbol: 'ARM', lastPrice: '163.40', change: '+5.07', percentChange: '+3.20%' },
                ]
            },
            {
                title: 'M2',
                context: 'M2',
                context_id: 'WEB',
                primaryColor: '',
                classname: "",
                header_bgcolor: '#071045',
                header_textcolor: "#fff",
                llm_model : "NO_MODEL",
                description: `
                    M2 Memory Group provides value-added refinements of question and answers.
                    M2 group answers may also be imcomplete or devoid of determinism, but it's
                    considered higher quality than M1, and provides foundation for the remainder levels:(M1,M3,M4)
                    aditional processing or combine with others groups is still ideal but not required.
                `,
                conf:{},
                content: '',
                streamData: [
                    { symbol: 'DIS', lastPrice: '95.75', change: '-1.04', percentChange: '-1.07%' },
                    { symbol: 'ARM', lastPrice: '163.40', change: '+5.07', percentChange: '+3.20%' },
                    { symbol: 'ALNY', lastPrice: '237.83', change: '+1.13', percentChange: '+0.48%' }
                ]
            },
            {
                title: 'M3',
                context: 'M3',
                context_id: 'WEB',
                primaryColor: '',
                classname: "",
                header_bgcolor: '#071045',
                llm_model : "NO_MODEL",
                header_textcolor: "#fff",
                description: `
                    M3 MGroup takes longer time to build but its future proof and is really for rich questions.
                    M3 deals with reports, facts, intructions, jsons, relational storage, block storage, serpapi(google search)
                    and structrued information. This information has been analysed, parsed, stored possibly using apis like DocAnalysis and BlkStore.
                    A high degree of refinement and correctness if the foundation of the M3 Group.
                `,
                conf:{},
                content: '',
                streamData:[],
            },
            {
                title: 'M4',
                context: 'M4',
                context_id: 'WEB',
                primaryColor: '',
                classname: "",
                header_bgcolor: '#071045',
                llm_model : "NO_MODEL",
                header_textcolor: "#fff",
                description: `
                    M4 Memory Group MGroup takes longer time to build but its future proof and is really for rich questions.
                    M3 deals with reports, facts, intructions, jsons, relational storage, block storage, serpapi(google search)
                `,
                conf:{},
                content: '',
                streamData:[
                    { symbol: '^IXIC', lastPrice: '17,726.94', change: '-144.28', percentChange: '-0.81%' },
                    { symbol: 'GPS', lastPrice: '22.66', change: '-0.17', percentChange: '-0.74%' },
                    { symbol: 'BMY', lastPrice: '42.65', change: '+0.26', percentChange: '+0.63%' },
                ], 
            },
            // {
            //     title: 'M2', 
            //     context_id: 'M1WEB',
            //     primaryColor: '',
            //     description: `
            //         Increased layer Short Term Memory most recent conversations. no analysis or quality performed`,
            //     conf:{},
            //     content: '' 
            // },
            // { 
            //     title: 'M3', 
            //     content: '' 
            // },
            // {
            //     title: 'M4', 
            //     content: '' 
            // }
        ]
    };

    constructor(
        private router: Router,
        private chatService: ChatService
    ) { 
        // console.info("[home] chatService: ", chatService);
    }

    ngOnInit() {
        console.info("TrainsystemComponent: ngOnInit()");
    }

    submitConsoleRequest() {
    }

    refreshONB() {
        // console.info("refreshONB: ");
        // this.ExecuteOnboard();
    }
}
