import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-pdf-preview',
  templateUrl: './pdf-preview.component.html',
  styleUrls: ['./pdf-preview.component.scss']
})
export class PdfPreviewComponent implements OnChanges {
  @Input() pdfUrl!: string;
  @ViewChild('pdfContainer', { static: true }) pdfContainer!: ElementRef;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['pdfUrl'] && changes['pdfUrl'].currentValue) {
      this.displayPdf(changes['pdfUrl'].currentValue);
    }
  }

  displayPdf(url: string) {
    // Remove any existing iframe
    this.pdfContainer.nativeElement.innerHTML = '';

    // Create a new iframe to display the PDF using PDF.js viewer
    const viewerUrl = `https://mozilla.github.io/pdf.js/web/viewer.html?file=${encodeURIComponent(url)}`;
    const iframe = document.createElement('iframe');
    iframe.src = viewerUrl;
    iframe.width = '100%';
    iframe.height = '600px'; // Adjust height as needed
    iframe.style.border = 'none'; // Optional: Remove border around iframe

    // Append the iframe to the container
    this.pdfContainer.nativeElement.appendChild(iframe);
  }
}
