<div class="relative">
  <div class="overflow-hidden">
    <div class="flex transition-transform duration-300 ease-in-out" [ngStyle]="{'transform': 'translateX(' + translateX + ')'}">
      <div *ngFor="let item of items" class="w-full flex-shrink-0">
        <img [src]="item.src" [alt]="item.alt" class="w-full h-auto">
      </div>
    </div>
  </div>
  <button (click)="prev()" class="absolute top-1/2 left-4 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full">‹</button>
  <button (click)="next()" class="absolute top-1/2 right-4 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full">›</button>
</div>
