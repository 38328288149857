<!-- welcome.component.html -->
<div class="grid grid-cols-1 lg:grid-cols-2 gap-2 space-x-1">
  <div class="p-8 md:p-20 ">
    <div class="flex flex-col lg:flex-row justify-center items-center gap-2 mb-6 -ml-44">
      <img src="assets/img/im blue.jpg" alt="Logo" class="rounded-full w-24 h-24 border-4 border-blue-300 mb-4" />
      <h1 class="text-6xl font-extrabold text-[#414141]">Welcome</h1>
    </div>
    <p class="text-lg text-left  ml-5 mb-4 text-[#414141]">
      Olá!!
    </p>
    <p class="text-lg  mb-6 text-justify text-[#414141] -mr-36 ml-5 w-[700px]">
      Sou Lua, sua assistente virtual, aqui para ajudar com informações e facilitar suas tarefas diárias. Seja
      bem-vindo(a) e conte comigo para o que precisar! Se é novo(a) por aqui, ajuste a Lua às suas necessidades ou leia
      o Guia & Ajuda para aproveitar ao máximo.
    </p>

    <div class=" flex justify-end space-x-2  mt-24 md:-mr-28">
      <button (click)="goToGuide()"
        class="px-8 py-2  text-[#00527E] border border-[#00527E] hover:bg-[#00527E] rounded-3xl transition duration-300">
        Guia
      </button>
      <button (click)="startExploring()"
        class="px-8 py-2 text-white bg-[#00527E] hover:bg-[#00527E] rounded-3xl transition duration-300">
        Login
      </button>
    </div>
  </div>
  <div class=" hidden lg:flex  flex-row items-center justify-center">
    <img src="assets/img/icon/Rectangle.svg" alt="" class="w-15 h-15 mb-4 mt-32">
    <img src="assets/img/icon/banner2.svg" alt="" class="w-15 h-[90%]">
  </div>
</div>