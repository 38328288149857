<form [formGroup]="editorForm" 
    (ngSubmit)="OnSubmit()" class=""
    *ngIf="editorForm">
    <div class="grid grid-cols-3">
        <div class="grid-col col-span-2">        
            <!-- ALERTS -->
            <div class="p-3">
                <div *ngIf="is_loading">
                    <app-spinner>
                    </app-spinner>
                </div>

                <div *ngIf="is_created" class="mt-1 p-2 pt-20 pb-30 text-center"
                    style="max-width:300px;margin:auto;padding-bottom:30px;">
                    <app-alert type="success" message="{{'FATURA CRIADA COM SUCESSO'}}">
                    </app-alert>          

                    <p class="opacity-80 mt-2"
                        style="font-size:x-small">
                        A factura foi criada com sucesso
                        com o id <strong>{{resp_msg.id}}</strong> na 
                        pasta <strong>{{ resp_msg.folder_name }}</strong>.
                    </p>          

                    <p class="cursor-pointer mt-2 mb-1  "
                        (click)="AddMore()">
                        <span class=" px-2 py-1 rounded-3xl text-[#00527E] bg-[#fff] m-2 ml-0 border border-[#00527E]"
                            style="font-size:small">
                            Adicionar Mais
                        </span>
                    </p>
                </div>

                <app-alert type="error" 
                    message="{{ error_msg || 'Nao foi possivel concluir. Verifique todos os campos' }}"
                    *ngIf="is_error">
                </app-alert>

                <app-alert type="warning" 
                    message="Alerta: Verifique todos os campos"
                    *ngIf="!editorForm.valid && !is_loading && !is_error">
                </app-alert>

                <div *ngIf="errors">
                    <div *ngFor="let E of errors" class="mt-2">
                        <app-alert type="error" [message]="E">
                        </app-alert>
                    </div>
                </div>

                <div *ngIf="warnings && warnings.length>0 && !is_created">
                    <div *ngIf="ignore_warning">
                        <p class="text-[#00527E] pl-2"
                            style="">{{warnings.length}} alertas.</p>
                        <p class="cursor-pointer mt-0 mb-1 flex "
                            (click)="AtivateAlerts()">
                            <span class=" px-2 py-1 rounded-3xl text-[#00527E] bg-[#fff] m-2 border border-[#00527E]"
                                style="font-size:small">
                                Mostrar Alertas
                            </span>
                        </p>
                    </div>

                    <div *ngIf="!ignore_warning">
                        <div style="float:left;margin-right:10px;height:100px">  
                            <img src="assets/img/im blue.jpg" alt="Logo"
                                width="48px"
                                class="rounded-full border-2 pull-left border-blue-300" 
                            />
                        </div>

                        <div>                        
                            <h3 class="text-2xl font-bold text-leftn"> Um momento. </h3>
                            <p style="font-size:small;" class="opacity-80">
                                Parece que esta submiss&atilde;o não cumpre algumas recomenda&ccedil;&otilde;es.
                            </p>
                            <p style="font-size:small;" class="opacity-80">
                                Pode ignorar estas alertas e submeter, Mas isso pode causar inconveni&ecirc;ncias
                                durante o processo de cria&ccedil;&atilde;o do modelo de iva.
                            </p>
    
                            <p class="cursor-pointer mt-0 mb-1 flex "
                                (click)="IgnoreWarningsAndSubmit()">
                                <span class=" px-2 py-1 rounded-3xl text-[#00527E] bg-[#fff] m-2 ml-0 border border-[#00527E]"
                                    style="font-size:x-small">
                                    Ignorar &amp; Submeter
                                </span>
                            </p>
                        </div>

                        <div *ngFor="let W of warnings" class="mt-2">
                            <app-alert type="warning" [message]="W">
                            </app-alert>
                        </div>

                    </div>

                </div>
            </div>

            <div class="grid grid-cols-2" *ngIf="!is_created">            
                <div class="grid-col p-4">
                    <div class="items-center">
                        <p style="font-size:small;" for="supplier_name" class="mr-2">
                            Pasta
                            <span style="font-size: x-small;color: orangered;">*</span>
                        </p>

                        <input id="folder_name" 
                            formControlName="folder_name" 
                            type="text" 
                            required 
                            style="font-size:small;" 
                            class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                        />

                        <span style="font-size:xx-small;"
                            class="opacity-80">
                            O nome da pasta aonde vai ficar esta fatura, 
                            n&atilde;o pode exceder 200 caracteres, insens&iacute; <br/>a Mai&uacute;scula e Min&uacute;scula. Vai ser criada se n&atilde;o existir.
                        </span>
                    </div>
                </div>

                <div class="grid-col p-4">
                </div>

                <div class="grid-col p-4">
                    <div class="items-center">
                        <p style="font-size:small;" for="supplier_name" class="mr-2">
                            Desig. Fornecedor
                            <span style="font-size: x-small;color: orangered;">*</span>
                        </p>
                        <input id="supplier_name" 
                            formControlName="supplier_name" 
                            type="text" 
                            required 
                            style="font-size:small;" 
                            class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                        />
                        <span style="font-size:xx-small;"
                            class="opacity-80">
                            Designa&ccedil;&atilde;o / Nome / Raz&atilde;o Social
                        </span>
                    </div>

                    <div class=" items-center">
                        <p style="font-size:small;" for="supplier_nif" class="mr-2">
                            Fornecedor NIF
                            <span style="font-size: x-small;color: orangered;">*</span>
                        </p>
                        <input id="supplier_nif" 
                            formControlName="supplier_nif" 
                            type="text" 
                            required
                            style="font-size:small;" 
                            class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                        />
                        <span style="font-size:xx-small;"
                            class="opacity-80">
                            NIF deve conter no minimo 9 algarismos.
                        </span>
                    </div>

                    <div class="items-center">
                        <p style="font-size:small;" for="invoice_lang" class="mr-2">
                            Idioma
                            <span style="font-size:xx-small;" class="opacity-80">Opcional</span>
                        </p>
                        <input id="invoice_lang" 
                            formControlName="invoice_lang" 
                            type="text" 
                            style="font-size:small;" 
                            class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                        />
                    </div>
                </div>

                <div class="grid-col p-4">
                    <div class="items-center">
                        <p style="font-size:small;"   for="customer_name" class="mr-2">
                            Desig. Cliente
                            <span style="font-size: x-small;color: orangered;">*</span>
                        </p>
                        <input id="customer_name" 
                            formControlName="customer_name" 
                            type="text" 
                            required 
                            style="font-size:small;" 
                            class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                        />
                        <span style="font-size:xx-small;"
                            class="opacity-80">
                            Designa&ccedil;&atilde;o / Nome / Raz&atilde;o Social
                        </span>
                    </div>

                    <div class="items-center">
                        <p style="font-size:small;" for="customer_nif" class="mr-2">
                            Cliente NIF
                            <span style="font-size: x-small;color: orangered;">*</span>
                        </p>
                        <input id="customer_nif" 
                            formControlName="customer_nif" 
                            type="text" 
                            required 
                            style="font-size:small;" 
                            class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                        />
                        <span style="font-size:xx-small;"
                            class="opacity-80">
                            NIF deve conter no minimo 9 algarismos.
                        </span>
                    </div>
                </div>

                <div class="grid-col p-4">
                    <div class="items-center">
                        <p style="font-size:small;" for="invoice_date" class="mr-2">
                            Data da Fatura
                            <span style="font-size: x-small;color: orangered;">*</span>
                        </p>
                        <input id="invoice_date" 
                            formControlName="invoice_date" 
                            type="date" 
                            required 
                            style="font-size:small;" 
                            class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                        />
                    </div>
                    <div class="items-center">
                        <p style="font-size:small;" for="invoice_due_date" class="mr-2">
                            Data de Vencimento
                            <span style="font-size:xx-small;" class="opacity-80">Opcional</span>
                        </p>
                        <input id="invoice_due_date" 
                            formControlName="invoice_due_date" 
                            type="date" 
                            style="font-size:small;" 
                            class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                        />
                    </div>
                </div>

                <div class="grid-col p-4">
                    <div class="items-center">
                        <p style="font-size:small;" for="invoice_id" class="mr-2">
                            Nr da Fatura
                            <span style="font-size: x-small;color: orangered;">*</span>
                        </p>
                        <input id="invoice_id" 
                            formControlName="invoice_id" 
                            type="text" 
                            required 
                            style="font-size:small;" 
                            class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                        />
                        <span style="font-size:xx-small;"
                            class="opacity-80">
                            Identificador da Fatura presente no documento.
                        </span>
                    </div>
                    <div class="items-center">
                        <p style="font-size:small;" for="invoice_origin" class="mr-2">
                            Origem
                            <span style="font-size: x-small;color: orangered;">*</span>
                        </p>
                        <input id="invoice_origin" 
                            formControlName="invoice_origin" 
                            type="text" 
                            required 
                            style="font-size:small;" 
                            class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                        />
                        <span style="font-size:xx-small;"
                            class="opacity-80">
                            C&oacute;digo ISO 3166-1 do Pa&iacute;s. Correspondente ao modelo 106.
                        </span>
                    </div>
                </div>

                <div class="grid-col p-4">
                    <!-- invoice_ref -->
                    <div class="items-center">
                        <p style="font-size:small;" for="invoice_ref" class="mr-2">
                            Refer&ecirc;ncia
                            <span style="font-size:xx-small;"
                                class="opacity-80">
                            Opcional
                            </span>
                        </p>
                        <input id="invoice_ref" 
                            formControlName="invoice_ref" 
                            type="text" 
                            style="font-size:small;" 
                            class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                        />
                        <span style="font-size:xx-small;"
                            class="opacity-80">
                            Recomendado: Referência pode ser interna ou externa, ou mesmo valor que o Nr da Fatura
                        </span>
                    </div>
                </div>

                <div class="grid-col p-4">
                    <div class="items-center">
                        <p style="font-size:small;" for="payment_ref" class="mr-2">
                            Refer&ecirc;ncia de Pagamento
                            <span style="font-size:xx-small;" class="opacity-80">Opcional</span>
                        </p>
                        <input id="payment_ref" 
                            formControlName="payment_ref" 
                            type="text" 
                            style="font-size:small;" 
                            class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                        />
                        <span style="font-size:xx-small;"
                            class="opacity-80">
                            Recomendado
                        </span>
                    </div>
                </div>

                <div class="grid-col p-4">
                    <div class="items-center">
                        <p style="font-size:small;" for="invoice_currency" class="mr-2">
                            Moeda/Currency
                            <span style="font-size: x-small;color: orangered;">*</span>
                        </p>
                        <input id="invoice_currency" 
                            formControlName="currency" 
                            type="text" 
                            required
                            style="font-size:small;" 
                            class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                        />
                        <span style="font-size:xx-small;"
                            class="opacity-80">
                            CVE, EUR, USD, JPY, ...
                        </span>
                    </div>

                    <div class="items-center">
                        <p style="font-size:small;" for="invoice_subtotal" class="mr-2">
                            Subtotal
                            <span style="font-size: x-small;color: orangered;">*</span>
                        </p>
                        <input id="invoice_subtotal" 
                            formControlName="invoice_subtotal" 
                            type="number" 
                            required
                            style="font-size:small;" 
                            class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                        />
                        <span style="font-size:xx-small;"
                            class="opacity-80">
                            Recomenda&ccedil;&atilde;o: Total da venda/compra sem Iva.
                            Subtotal n&atilde;o pode ser superior a Total.
                        </span>
                    </div>
                </div>

                <div class="grid-col p-4">
                    <div class="items-center">
                        <p style="font-size:small;" for="invoice_total" class="mr-2">
                            Total
                            <span style="font-size: x-small;color: orangered;">*</span>
                        </p>
                        <input id="invoice_total" 
                            formControlName="invoice_total" 
                            type="number" 
                            required
                            style="font-size:small;" 
                            class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                        />
                        <span style="font-size:xx-small;"
                            class="opacity-80 text-italic">
                            Sugestão: <i>Total = Subtotal + (Subtotal*Iva)</i>
                        </span>
                    </div>

                    <div class="items-center">
                        <p style="font-size:small;" for="invoice_total" class="mr-2">
                            Iva %
                            <span style="font-size: x-small;color: orangered;">*</span>
                        </p>
                        <input id="invoice_iva" 
                            formControlName="invoice_iva" 
                            type="number" 
                            required
                            style="font-size:small;" 
                            class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                        />
                        <span style="font-size:xx-small;"
                            class="opacity-80">
                            <i>0.15 = 15%</i>
                        </span>
                    </div>

                </div>
            </div>
        </div>

        <div class="grid-col col-span-1" >
            <div *ngIf="!is_created">  
               <!-- <div class="p-2">
                    <h3 class="text-2xl font-bold text-leftn"> Está a criar uma fatura. </h3>
                    <h2 class="text-left opacity-70">
                        <span class="cursor-pointer">Lua Apps</span> /&nbsp; 
                        <span class="text-[#00527E]">FATURAS LUA </span> 
                    </h2>
                </div>-->

                <p class="cursor-pointer mt-0 mb-1 flex  justify-end">
                    <button class=" flex flex-row px-4 py-2 rounded-3xl text-[#fff] bg-[#00527E] m-2 border border-[#00527E]"
                        *ngIf="!is_loading"
                        type="submit">
                        <!-- <img src="assets/img/icon/Arrows.svg" alt="" class="w-5 h-5" />  -->
                        Criar Fatura
                    </button>

                    <span class="flex flex-row  px-4 py-2 rounded-3xl text-[#fff] bg-[#00527E] m-0 border border-[#00527E]"
                        *ngIf="is_loading">
                        <img src="assets/img/icon/Arrows.svg" alt="" class="w-5 h-5" /> 
                        Aguarde
                    </span>
                </p>
            </div>

        </div>
    </div>


</form>

