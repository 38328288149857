import { ViewportScroller } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChatService } from 'src/app/services/chat.service';
import { samples } from './sample';
import { subscribe } from 'graphql';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit {
    currentPrompt: string = '';
    selectedTab: string = 'youCanAsk';
    samples:any = []
    catList:any = null
    is_loading:boolean = false;
    is_error:boolean = false

    constructor(
        private http: HttpClient,
        private route: ActivatedRoute,
        private viewportScroller: ViewportScroller,
        private chatService: ChatService
    ) {
        this.onSelectQA = this.onSelectQA.bind(this);
    }

    onSelectQA(QA:any) {
        this.onSend(QA.question);
    }

    ChooseCat(Cat:any) {
        console.info("ChooseCat: ", Cat);
        const prompt:any = `
            <p><strong>${Cat.name}</strong></p>
        `
        this.samples.push({
            type: 'text',
            isqa_list: true,
            qalist: Cat.qa_list,
            content: [prompt],
            perfil: 'assets/img/temp/profile.svg',
        });
    }

    ngOnInit() {
        if (this.catList==null) {
            this.LoadCategories();
        }
    }

    handlePayment() {
        if (this.selectedTab === '') {
            this.selectedTab = 'youCanAsk';
        } else {
            this.selectedTab = '';
        }
    }

    handleSuggestions() {
        if (this.selectedTab !== 'youCanAsk') {
            this.selectedTab = 'youCanAsk';
        }
    }

    LoadCategories(){
        const payload = {
            'ops' : 'FetchCategory',
        }

        if (this.is_loading) {
            return;
        }

        // this.is_loading = true;
        // this.is_error = false;
        this.catList = null;

        this.chatService.ExecuteTask("TrainSystem", payload, (doc_resp:any) => {
            this.is_loading=false;
            if (doc_resp.Status=='OK') {
                this.catList = doc_resp.list;
            } else {
                this.is_error = true;
            }

        }, (error:any) => {
            console.error("E: ", error);
            // this.is_loading=false;
            // this.is_error = true;
        });
    }

    onSend(prompt?: string) {
        if (prompt?.trim() != '') {
            // never use global state this way
            // you ll lose hours of your life on useless debug
            // if (this.chatService.loading) {
            //     return;
            // }
            // this.chatService.onSend(prompt);
            const reqObj={
                question: prompt,
            }

            this.samples.push({
                type: 'you',
                content: [prompt],
                perfil: 'assets/img/temp/profile.svg',
            });

            this.ExecuteChat(reqObj);
            this.viewportScroller.scrollToAnchor('footer');

            const inputElement = document.querySelector('.input-container input');
            if (inputElement) {
                (inputElement as HTMLInputElement).value = '';
            }

            this.currentPrompt = '';
        }
    }

    scrollToBottom() {
    }

    getSamples() {
        return samples;
    }

    ExecuteChat(payload:any) {
        this.is_loading=true;
        this.chatService.ExecuteTask("core/LuaAssistant", payload, (resp:any) => {
            this.is_loading=false;
            setTimeout(()=>{            
                console.log('Response', resp);
                const NewMsg = { 
                    content:{
                        text: resp.answer
                    }, 
                    report: resp.report,
                    type: 'text' ,
                    Status: resp.Status,
                    actions: [],
                }

                if (resp.actions&&resp.actions.length>0) {
                    NewMsg.actions = resp.actions;
                }

                this.samples.push(NewMsg);
            }, 80);

            document.body.scrollIntoView({
                behavior: 'smooth',
                block: 'end', 
                inline: 'nearest',
            });

        }, (error:any) => {
            console.error("E: ", error);
            this.is_loading=false;
            this.is_error = true;
        });

    }
}
