<div class="
  w-full
  fixed z-[9999]
  md:hidden
  antialiased
  bg-gradient-to-r
  from-pink-300
  via-purple-300
  to-indigo-400
">
  <header>
    <nav class=" py-2
      flex flex-wrap
      items-center
      justify-between
      w-full
      p2-4
      md:py-0
      px-4
      text-lg text-white   
      !bg-[#fff]
    " id="m-nav">
      
      <!-- Ícone do menu hamburguer -->
      <div id="hamburger-menu" class="md:hidden" (click)="toggleMobileMenu()">
        <img src="assets/img/icon/menu-icon.svg" alt="Menu">
      </div>

      <!-- Logo -->
      <div class="flex-grow">
        <div [routerLink]="'/'" class="flex justify-center">
          <img alt="logo" src="assets/img/logoNosi.svg">
        </div>
      </div>
   
        <!-- Dropdown de perfil -->
        <div class="relative">
          <img class="cursor-pointer rounded-full w-10 h-10 border-2 border-blue-300"
            src="assets/img/im blue.jpg" alt="Profile Image" (click)="toggleDropdown()" />
          <div *ngIf="dropdownOpen" class="absolute right-0 mt-2 bg-white text-black rounded-md shadow-lg w-32">
            <ul class="py-1">
              <li><span class="font-semibold">{{ userName }}</span></li>
              <li><a href="/my-account" class="block px-4 py-2 hover:bg-gray-100">Minha conta</a></li>
              <li><a href="#" class="block px-4 py-2 hover:bg-gray-100">Logout</a></li>
            </ul>
          </div>
        </div>
 
      <!-- Seção do perfil e notificações no topo -->
      <div class="flex items-center space-x-4">
        <!-- Notificações -->
        <div class="relative">
          <img class="cursor-pointer w-6 h-6" src="assets/img/icon/Notif.svg" alt="Notification Icon" />
          <!-- Badge de notificação (opcional) -->
          <span class="absolute top-0 right-0 inline-block w-2 h-2 bg-red-600 rounded-full"></span>
        </div>

      </div>
      
      <!-- Menu lateral mobile -->
      <div class="h-screen rounded-r-[15px] rounded-b-[15px] overflow-y-auto bg-[#115290] pt-8" id="mobile-menu"
        [ngClass]="isMobileMenuOpen ? 'active block' : 'none'">
        
        <!-- Botão de fechar o menu -->
        <div class="flex justify-between items-center mt-[15px] px-[15px] text-white">
          <span (click)="toggleMobileMenu()">
            <img src="assets/img/icon/menu-icon-white.svg" alt="">
          </span>
        </div>

        <!-- Itens do menu lateral -->
        <div class="md:flex mt-4 items-center px-[15px] text-black">
          <div *ngFor="let item of sideMenu; let i = index">
            <div class="border-b-2 border-b-[#2869A7]">
              <div class="flex justify-between items-center cursor-pointer text-[12px] text-white font-normal 
                md:hover:bg-[#9EB0C1] md:hover:rounded-[8px] md:hover:p-[4px] md:hover:text-[#fff] p-[4px]"
                [routerLink]="item.route">
                <span class="text-[14px] font-semibold">{{ item.title }}</span>
                <span><img [src]="item.whiteIcon" alt=""></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
</div>
