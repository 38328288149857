import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accounts-payable-and-receivable-report',
  templateUrl: './accounts-payable-and-receivable-report.component.html',
  styleUrls: ['./accounts-payable-and-receivable-report.component.scss'],
})
export class AccountsPayableAndReceivableReportComponent implements OnInit {
  isLoading: boolean = true;
  accountsData: any = null;

  constructor() {}

  ngOnInit() {
    this.fetchAccountsData();
  }

  fetchAccountsData() {
    setTimeout(() => {
      this.accountsData = {
        payables: [
          { name: 'Supplier A', amount: 30000, dueDate: '2023-09-15' },
          { name: 'Supplier B', amount: 15000, dueDate: '2023-09-30' },
        ],
        receivables: [
          { name: 'Customer X', amount: 45000, dueDate: '2023-09-10' },
          { name: 'Customer Y', amount: 25000, dueDate: '2023-09-20' },
        ],
      };
      this.isLoading = false;
    }, 1000);
  }
}
