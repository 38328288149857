import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-doc-report-editor',
  templateUrl: './fatura-details.component.html',
  styleUrls: ['./fatura-details.component.scss']
})
export class FaturaDetailsComponent {
    @Input() report : any;
    @Input() groupname: any;
    reportObj:any = null
    constructor(private chatService: ChatService) {
    }

    ngOnInit() {
        const groupName = this.groupname;
        console.info("this.report: ", this.report);
        if (this.report.R) {
            this.reportObj = this.report;
        } else {        
            const keys:any = Object.keys(this.report);
            if (this.report[groupName]) {
                this.reportObj = this.report[groupName];
            } else if (keys>0) {
                this.reportObj = this.report;
            }
        }

        if (this.reportObj) {
            this.reportObj.R = this.reportObj.R.filter((X:any) => !X.key.startsWith("K00"))
        }
    }
  
    ngAfterViewChecked() {
    }
}
