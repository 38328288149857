import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './views/home/home.component';
import { ChatComponent } from './views/chat/chat.component';
import { FaturaFormComponent } from './views/fatura/fatura-form/fatura-form.component';
import { AuthGuard } from './services/guard';
import { FormComponent } from './form/form.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { GuideComponent } from './views/guide/guide.component';
import { HelpComponent } from './views/help/help.component';
import { TrainsystemComponent } from './views/trainsystem/trainsystem.component';
import { ReportsComponent } from './views/reports/reports.component';
import { AibiComponent } from './views/aibi/aibi.component';
import { AiInboxComponent } from './views/ai-inbox/ai-inbox.component';
import { DocCenterComponent } from './views/doc-center/doc-center.component';
import { DocumentsComponent } from './views/documents/documents.component';
import { DocumentAnalysisComponent } from './views/document-analysis/document.component';
import { LuaEntityComponent } from './views/lua-entities/lua-entity.component';

import { LuaxappsComponent } from './views/luaxapps/luaxapps.component';
import { MyAccountComponent } from './views/my-account/my-account.component';
import { NotificationsComponent } from './views/notifications/notifications.component';
import { AlertasComponent } from './views/alertas/alertas.component';
import { DynamicPageComponent } from './views/dynamic-page/dynamic-page.component';
import { AnalyticsComponent } from './views/analytics/analytics.component';
// import { LuaXlsFinancialComponent } from './lua-xls-financial/lua-xls-financial.component';
import { MonitoringComponent } from './monitoring/monitoring.component';
import { WelcomeToLuaComponent } from './welcome-to-lua/welcome-to-lua.component';
// import { LuaEntertainComponent } from './views/lua-entertain/lua-entertain.component';
import { LunaHumanComponent } from './views/luna-human/luna-human.component';
import { DocExplorerComponent } from './views/doc-explorer/doc-explorer.component';
import { AccountsPayableAndReceivableReportComponent } from './components/accounts-payable-and-receivable-report/accounts-payable-and-receivable-report.component';
import { BudgetVsActualReportComponent } from './components/budget-vs-actual-report/budget-vs-actual-report.component';
import { DvaComponent } from './components/dva/dva.component';
import { FinancialRatiosReportComponent } from './components/financial-ratios-report/financial-ratios-report.component';
import { InventoryReportComponent } from './components/inventory-report/inventory-report.component';
import { StatementOfChangesInEquityComponent } from './components/statement-of-changes-in-equity/statement-of-changes-in-equity.component';

//import { PerformanceComponent } from './components/performance/performance.component';

const routes: Routes = [
  // { path: '', component: LandpageComponent },
  { path: '', component: WelcomeToLuaComponent },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'chat', component: ChatComponent, canActivate: [AuthGuard] },
  { path: 'assistant', component: ChatComponent, canActivate: [AuthGuard] },
  { path: 'performance', component: FormComponent, canActivate: [AuthGuard] },
  {
    path: 'auth',
    loadChildren: () =>
      import('./views/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  { path: 'guide', component: GuideComponent },
  { path: 'help', component: HelpComponent, canActivate: [AuthGuard] },
  {
    path: 'trainsystem',
    component: TrainsystemComponent,
    canActivate: [AuthGuard],
  },
  { path: 'reports', component: ReportsComponent, canActivate: [AuthGuard] },
  { path: 'aibi', component: AibiComponent, canActivate: [AuthGuard] },
  { path: 'quadros-bi', component: AibiComponent, canActivate: [AuthGuard] },
  {
    path: 'documents',
    component: DocExplorerComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'documents-explorer',
    component: DocExplorerComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'document-analysis',
    component: DocumentAnalysisComponent,
    canActivate: [AuthGuard],
  },

  {path: 'lua-entity', component: LuaEntityComponent, canActivate: [AuthGuard]},
  {path: 'lua-human', component: LunaHumanComponent, canActivate: [AuthGuard]},
  {path: 'luaxapps', component: LuaxappsComponent, canActivate: [AuthGuard] },
  {path: 'my-account', component: MyAccountComponent, canActivate: [AuthGuard]},

  { path: 'ai-inbox', component: AiInboxComponent, canActivate: [AuthGuard] },
  { path: 'lua-inbox', component: AiInboxComponent, canActivate: [AuthGuard] },
  {
    path: 'notifications',
    component: NotificationsComponent,
    canActivate: [AuthGuard],
  },
  { path: 'alertas', component: AlertasComponent, canActivate: [AuthGuard] },
  {
    path: 'apps/:appName/:pageName/:version',
    component: DynamicPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'analitics',
    component: AnalyticsComponent,
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'lua-xls-financial',
  //   component: LuaXlsFinancialComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'monitoring',
    component: MonitoringComponent,
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'lua-entertain',
  //   component: LuaEntertainComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'doc-center',
    component: DocCenterComponent,
    canActivate: [AuthGuard],
  },
  /* Reports */
  {
    path: 'accounts-report',
    component: AccountsPayableAndReceivableReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'budget-report',
    component: BudgetVsActualReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'dva',
    component: DvaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'financial-report',
    component: FinancialRatiosReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'inventory-report',
    component: InventoryReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'statement',
    component: StatementOfChangesInEquityComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
