import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-financial-ratios-report',
  templateUrl: './financial-ratios-report.component.html',
  styleUrls: ['./financial-ratios-report.component.scss'],
})
export class FinancialRatiosReportComponent implements OnInit {
  isLoading: boolean = true;
  ratiosData: any = null;

  constructor() {}

  ngOnInit() {
    this.fetchRatiosData();
  }

  fetchRatiosData() {
    setTimeout(() => {
      this.ratiosData = {
        ratios: [
          { name: 'Current Ratio', value: 1.5 },
          { name: 'Quick Ratio', value: 1.2 },
          { name: 'Debt to Equity Ratio', value: 0.8 },
          { name: 'Return on Equity', value: 0.15 },
        ],
      };
      this.isLoading = false;
    }, 1000);
  }
}
