<div class="">
    <p>Tipo de relat&oacute;rio: {{ groupname}} </p>
    <div *ngIf="reportObj">
        <div class="items-center flex">
            <table class="table mb-8 md:w-[100%]  sm:w-[100%]">
                <tbody>
                    <tr *ngIf="reportObj.R.length==0">
                        <td colspan="2">
                            Nenhuma informa&ccedil;&atilde;o gerada para este relat&oacute;rio.
                        </td>
                    </tr>
                    <tr *ngFor="let Row of reportObj.R">
                        <td> 
                            <p style="font-size:small" class="opacity-50">{{ Row['key'] }}</p>
                         </td>
                        <td>
                            <p style="font-size:small;color: #333;" class="">
                                {{ Row[Row['key']] }}
                            </p>
                          </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>


</div>