import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ChatService } from '../services/chat.service';
// Importe o serviço aqui

interface XmlReport {
  header: {
    ano: string;
    mes: string;
    cd_af: string;
    nif: string;
  };
  linhas: Array<{
    origem: string;
    nif: string;
    designacao: string;
    tp_doc: string;
    num_doc: string;
    data: string;
    vl_fatura: number;
    vl_base_incid: number;
    tx_iva: number;
    iva_sup: number;
    direito_ded: number;
    iva_ded: number;
    tipologia: string;
    linha_dest_mod: number;
  }>;
  dt_entrega: string;
  total_fatura: number;
  total_base_incid: number;
  total_suportado: number;
  total_dedutivel: number;
  docname: string;
  groupname: string;
  user_id: string;
  date: string;
}

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
  anexoForForm: FormGroup;
  @Input() xmlReport: any;
  resposta: any; // Variável para armazenar a resposta
  mostrarFormulario: boolean = true;
  constructor(
    private fb: FormBuilder,
    private chatService: ChatService 
  ) {
    this.anexoForForm = this.fb.group({
      ano: [null, Validators.required],
      mes: [null, Validators.required],
      cd_af: [null, Validators.required],
      nif: [null, Validators.required],
      linhas: this.fb.array([]),
      dt_entrega: [null, Validators.required],
      total_fatura: [null, Validators.required],
      total_base_incid: [null, Validators.required],
      total_suportado: [null, Validators.required],
      total_dedutivel: [null, Validators.required],
    });
  }

  ngOnInit() {
    if (this.xmlReport) {
      this.populateForm(this.xmlReport);
    }
  }

  get linhas(): FormArray {
    return this.anexoForForm.get('linhas') as FormArray;
  }

  addLinha() {
    const linhaForm = this.fb.group({
      origem: [null, Validators.required],
      nif: [null, Validators.required],
      designacao: [null, Validators.required],
      tp_doc: [null, Validators.required],
      num_doc: [null, Validators.required],
      data: [null, Validators.required],
      vl_fatura: [null, Validators.required],
      vl_base_incid: [null, Validators.required],
      tx_iva: [null, Validators.required],
      iva_sup: [null, Validators.required],
      direito_ded: [null, Validators.required],
      iva_ded: [null, Validators.required],
      tipologia: [null, Validators.required],
      linha_dest_mod: [null, Validators.required],
    });

    this.linhas.push(linhaForm);
  }

  removeLinha(index: number) {
    this.linhas.removeAt(index);
  }

  populateForm(data: XmlReport) {
    this.anexoForForm.patchValue({
      ano: data.header.ano,
      mes: data.header.mes,
      cd_af: data.header.cd_af,
      nif: data.header.nif,
      dt_entrega: data.dt_entrega,
      total_fatura: data.total_fatura,
      total_base_incid: data.total_base_incid,
      total_suportado: data.total_suportado,
      total_dedutivel: data.total_dedutivel,
      docname: data.docname,
      groupname: data.groupname,
      user_id: data.user_id,
      date: data.date
    });
    
    this.linhas.clear();
    data.linhas.forEach(linha => {
      const linhaForm = this.fb.group({
        origem: [linha.origem, Validators.required],
        nif: [linha.nif, Validators.required],
        designacao: [linha.designacao, Validators.required],
        tp_doc: [linha.tp_doc, Validators.required],
        num_doc: [linha.num_doc, Validators.required],
        data: [linha.data, Validators.required],
        vl_fatura: [linha.vl_fatura, Validators.required],
        vl_base_incid: [linha.vl_base_incid, Validators.required],
        tx_iva: [linha.tx_iva, Validators.required],
        iva_sup: [linha.iva_sup, Validators.required],
        direito_ded: [linha.direito_ded, Validators.required],
        iva_ded: [linha.iva_ded, Validators.required],
        tipologia: [linha.tipologia, Validators.required],
        linha_dest_mod: [linha.linha_dest_mod, Validators.required],
      });
      this.linhas.push(linhaForm);
    });
    
  }

  onSubmit(): void {
    if (this.anexoForForm.valid) {
      const formData = this.anexoForForm.value;
  
      const payload = {
        header: {
          ano: formData.ano,
          mes: formData.mes,
          cd_af: formData.cd_af,
          nif: formData.nif
        },
        linhas: formData.linhas.map((linha: any) => ({
          origem: linha.origem,
          nif: linha.nif,
          designacao: linha.designacao,
          tp_doc: linha.tp_doc,
          num_doc: linha.num_doc,
          data: linha.data,
          vl_fatura: linha.vl_fatura,
          vl_base_incid: linha.vl_base_incid,
          tx_iva: linha.tx_iva,
          iva_sup: linha.iva_sup,
          direito_ded: linha.direito_ded,
          iva_ded: linha.iva_ded,
          tipologia: linha.tipologia,
          linha_dest_mod: linha.linha_dest_mod
        })),
        Id: this.xmlReport.Id,
        docname: this.xmlReport.docname,
        groupname: this.xmlReport.groupname,
        user_id: this.xmlReport.user_id,
        date: this.xmlReport.date, 
      };
  
      // Inclui as linhas adicionadas manualmente se existirem
      if (this.linhas.length > formData.linhas.length) {
        const manuallyAddedLinhas = this.linhas.controls.slice(formData.linhas.length).map(linhaForm => ({
          origem: linhaForm.value.origem,
          nif: linhaForm.value.nif,
          designacao: linhaForm.value.designacao,
          tp_doc: linhaForm.value.tp_doc,
          num_doc: linhaForm.value.num_doc,
          data: linhaForm.value.data,
          vl_fatura: linhaForm.value.vl_fatura,
          vl_base_incid: linhaForm.value.vl_base_incid,
          tx_iva: linhaForm.value.tx_iva,
          iva_sup: linhaForm.value.iva_sup,
          direito_ded: linhaForm.value.direito_ded,
          iva_ded: linhaForm.value.iva_ded,
          tipologia: linhaForm.value.tipologia,
          linha_dest_mod: linhaForm.value.linha_dest_mod
        }));
  
        payload.linhas.push(...manuallyAddedLinhas);
      }
  
      this.chatService.submitForm(payload).subscribe(
        (response: any) => {
          this.resposta = response; 
          this.mostrarFormulario = false; 
        },
        (error: any) => {
          console.error('Erro ao submeter formulário:', error);
        }
      );
    } else {
      
    }
  }
  executarAcao(action: any): void {
    this.chatService.onSend(action);
  }
  
}
