import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { RoundedIconButtonInterface } from 'src/app/models/ComponentInput';
import { Subscription } from 'rxjs';
import { ChatService } from 'src/app/services/chat.service';
import { ViewportScroller } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { LanguageService } from 'src/app/services/language.service';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
    selector: 'app-user-layout',
    templateUrl: './user-layout.component.html',
    styleUrls: ['./user-layout.component.scss'],
})
export class UserLayoutComponent implements OnInit {
    menuOpen: boolean = false;
    sidebarExpanded = false;
    sideMenu: any[];
    index: number = -1;
    authUser:any = null
    indexOfColapse: number = -1;
    dropdownOpen = false;
    userName: string = '';
    url?: string;

    private scrollSusbscription: Subscription | undefined;
    currentPrompt: string = '';

    selectedLanguage: string = 'en';
    languages = [
        { code: 'en', name: 'En', flag: 'assets/img/en.svg' },
        { code: 'pt', name: 'Pt', flag: 'assets/img/pt.svg' },
    ];

    constructor(
        private _router: Router,
        private chatService: ChatService,
        private viewportScroller: ViewportScroller,
        private accountService: ChatService,
        private elementRef: ElementRef,
        private themeService: ThemeService,
        private languageService: LanguageService // Inject the language service
    ) {
        this.url = _router.url.split('/')[1];
        this.sideMenu = [
          // {
          //   route: '/home',
          //   baseIcon: 'assets/img/lua-magician.svg',
          //   activeIcon: 'assets/img/lua-magician.svg',
          //   whiteIcon: 'assets/img/lua-magician.svg',
          //   title: 'Lua AI',
          //   showColor: true,
          //   expanded: false,
          // },

          {
            route: '/home',
            baseIcon: 'assets/img/sideIcon/menuHomeBlank.svg',
            activeIcon: 'assets/img/sideIcon/menuHomeGlow.svg',
            whiteIcon: 'assets/img/HomeWhite.svg',
            title: 'Home',
            expanded: false,
          },
          {
            route: '/documents',
            name: 'Documentos',
            baseIcon: 'assets/img/icon/documentos.svg',
            activeIcon: 'assets/img/icon/documentosBlue.svg',
            whiteIcon: 'assets/img/documentosWhite.svg',
            title: 'Explorador',
            expanded: false,
          },
          {
            route: '/assistant',
            name: 'Assistente',
            baseIcon: 'assets/img/icon/Chat escuro.svg',
            activeIcon: 'assets/img/icon/Chat laranja.svg',
            whiteIcon: 'assets/img/ChatWhite.svg',
            title: 'Assistente',
            expanded: false,
          },
          {
            route: '/apps/LUA_INVOICE/index/0.0.1-SNAPSHOT',
            name: 'Faturas',
            baseIcon: 'assets/img/icon/Factura menu (2).svg',
            activeIcon: 'assets/img/icon/FaturaBlue.svg',
            whiteIcon: 'assets/img/reportswhite.svg',
            title: 'Faturas',
            expanded: false,
          },
          {
            route: '/document-analysis',
            name: 'Análise',
            baseIcon: 'assets/img/icon/Analise.svg',
            activeIcon: 'assets/img/icon/Analiseblue.svg',
            whiteIcon: 'assets/img/icon/Analisewhite.svg',
            title: 'Análise',
            expanded: false,
          },
          {
            route: '/aibi',
            name: 'Lua BI',
            baseIcon: 'assets/img/icon/reports.svg',
            activeIcon: 'assets/img/icon/reportsBlue.svg',
            whiteIcon: 'assets/img/reportswhite.svg',
            title: 'Lua BI',
            expanded: false,
          },
          {
            route: '/luaxapps',
            name: 'Lua Apps',
            baseIcon: 'assets/img/icon/copy.svg',
            activeIcon: 'assets/img/icon/copy-Azul.svg',
            whiteIcon: 'assets/img/icon/copy-Branco.svg',
            title: 'LuaApps',
            expanded: false,
          },
          /*  {
            route: '/dashboard',
            name: 'Dashboard',
            baseIcon: 'assets/img/icon/element-2.svg',
            activeIcon: 'assets/img/icon/element-1.svg',
            whiteIcon: 'assets/img/ChatWhite.svg',
            title: 'Dashboard',
            expanded: false,
          },*/

          // {
          //   route: '/chat',
          //   name: 'Chat',
          //   baseIcon: 'assets/img/icon/Chat escuro.svg',
          //   activeIcon: 'assets/img/icon/Chat laranja.svg',
          //   whiteIcon: 'assets/img/ChatWhite.svg',
          //   title: 'Chat',
          //   expanded: false,
          // },

          {
            route: '/guide',
            name: 'Guia & Ajuda',
            baseIcon: 'assets/img/icon/info-circle.svg',
            activeIcon: 'assets/img/icon/info-circleWh.svg',
            whiteIcon: 'assets/img/icon/infoCriculoBranco.svg',
            title: 'Guia & Ajuda',
            expanded: false,
          },

          /*
         
         /* {
            route: '/lua-reports',
            name: 'Contabilidade',
            baseIcon: 'assets/img/icon/contabilidade.svg',
            activeIcon: 'assets/img/icon/documentosBlue.svg',
            whiteIcon: 'assets/img/documentosWhite.svg',
            title: 'Contabilidade',
            expanded: false,
          },
          {
            route: '/alerts',
            name: 'Alertas',
            baseIcon: 'assets/img/icon/alertas.svg',
            activeIcon: 'assets/img/icon/documentosBlue.svg',
            whiteIcon: 'assets/img/documentosWhite.svg',
            title: 'Alertas',
            expanded: false,
          },*/
        ];
    }

    chatFooter: boolean = false;
    ngOnInit(): void {
        this.chatFooter = !this._router.url.includes('/assistant/chat');
        this.selectedLanguage = this.languageService.getCurrentLanguage();
        this.loadAccountInfo();
    }

    toggleMenu() {
        this.menuOpen = !this.menuOpen;
    }

    toggleSidebar() {
        this.sidebarExpanded = !this.sidebarExpanded;
    }

    toggleDropdown() {
        this.dropdownOpen = !this.dropdownOpen;
    }

    onMenuClick(i: number, question: any) {
        this.index = i;
        this.chatService.sendData(question);
        // this._router.navigate([this.sideMenu[i].route]);
    }
    toggleTheme() {
        this.themeService.toggleTheme();
    }
  
    isDarkMode() {
        return this.themeService.isDarkMode();
    }

    fetchData(p: any, headers: HttpHeaders) {
        // this.chatService.setData(samples);
        // Access the JSON array
        if (p.q) this.onSend(p.q);
        else this.chatService.loading = false;
    }

    selectedTab: string = 'youCanAsk';
    handlePayment() {
        if (this.selectedTab === '') {
            this.selectedTab = 'youCanAsk';
        } else {
            this.selectedTab = '';
        }
    }

    handleSuggestions() {
        if (this.selectedTab !== 'youCanAsk') {
            this.selectedTab = 'youCanAsk';
        }
    }

    onSend(prompt?: string) {
        if (prompt?.trim() != '') {
            if (this.chatService.loading) {
                return;
            }

            this.chatService.onSend(prompt);
            this.viewportScroller.scrollToAnchor('inputchat');

            const inputElement = document.querySelector('.input-container input');
            if (inputElement) {
                (inputElement as HTMLInputElement).value = '';
            }

            this.currentPrompt = '';
            if (this.url == '') this._router.navigate(['/chat']);
        }
    }

    scrollToBottom() {}
    getSamples() {
        return this.chatService.samples;
    }

    logout() {
        if (!confirm("Deseja mesmo sair?")) {
            return;
        }

        localStorage.removeItem('AUTH_CREDS');
        setTimeout(()=>{
            this._router.navigate(['/auth/login']);
        }, 120);
    }

    goHome() {
        this._router.navigate(['/home']);
    }

    switchLanguage(language: string) {
        this.languageService.switchLanguage(language);
    }

    onLanguageChange(event: Event) {
        const selectElement = event.target as HTMLSelectElement;
        this.languageService.switchLanguage(selectElement.value);
    }
    
    getFlag(code: string): string {
        const lang = this.languages.find((language) => language.code === code);
        return lang ? lang.flag : '';
    }

    loadAccountInfo() {
        this.accountService.getAccountInfo().subscribe({
            next: (data:any) => {
                console.info("USER: ", data);
                this.authUser = data;
                this.userName = data.name;
            },
            error: (err:any) => {
                console.error('Erro ao carregar informações da conta', err);
            }
        });
    }
}
