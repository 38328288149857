<div class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50" *ngIf="isVisible">
  <div class="bg-white rounded-lg shadow-lg p-6 max-w-lg w-full">
    <div class="flex justify-between items-center">
      <h2 class="text-xl font-semibold">{{ title }}</h2>
      <button (click)="closeModal()" class="text-gray-400 hover:text-gray-600">
        &times;
      </button>
    </div>
    <div class="mt-4">
      <ng-content></ng-content>
    </div>

    <div class="flex justify-end mt-4"
      *ngIf="showCloseBtn">
      <button (click)="closeModal()" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Fechar</button>
    </div>
  </div>
</div>
