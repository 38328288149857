<div class="mx-auto mt-10 " style="">
    <h2 class="text-left opacity-70">Relatórios / Criar Relatorio de BI </h2>
    <h1 class="font-semibold text-4xl pb-10 " >RELATORIO DE BI</h1>

    <form class="mt-5" 
        (ngSubmit)="createReport()">
        <div class="grid grid-cols-1">
            <div class="">
                <!-- <app-alert type="success" message="Well done!"></app-alert> -->
                <!-- <app-alert type="info" message="Heads up!"></app-alert> -->
                <!-- <app-alert type="warning" message="Warning!"></app-alert> -->
                <!-- <app-alert type="error" message="Oh snap!"></app-alert> -->
            </div>

            <div *ngIf="is_created===true">            
                <app-alert type="success"  message="Criado com sucesso">
                </app-alert>
                <label for="reportName" class="w-1/5">
                    Criada o com Id {{ created_id }}
                </label> 
            </div>

            <app-alert type="warning" 
                message="Aguarde..."
                *ngIf="is_loading">
            </app-alert>

            <app-alert type="error" 
                message="Nao foi possivel concluir"
                *ngIf="is_error">
            </app-alert>

            <app-alert type="error" 
                message="Verifique todos os campos"
                *ngIf="is_invalid">
            </app-alert>
        </div>

        <div class="grid grid-cols-2" *ngIf="!is_created">
            <div class="grid-col items-center">
                <label for="reportName" class="w-1/5">
                    Escolha um nome para este relat&oacute;rio
                </label> 
                <!-- Ajustando a largura para w-1/4 -->
               
                <div class="flex flex-row"> 
                    <input type="text" 
                        id="reportName" 
                        name="reportName" 
                        [(ngModel)]="reportObjReq.reportName"
                        class="form-input mt-1 block w-3/5 p-2 border-2 rounded-2xl" 
                    />
                </div>
            </div>

            <div class="grid-col items-center">
                <div class="grid-col items-center">
                    <div>                    
                        <label for="start_date" class="w-1/5">
                            Data para iniciar a leitura
                        </label> 
                        <!-- Ajustando a largura para w-1/4 -->
                       
                        <div class="flex flex-row"> 
                            <input type="date" 
                                id="start_date"
                                name="start_date" 
                                [(ngModel)]="reportObjReq.start_date"
                                required
                                class="form-input mt-1 block w-3/5 p-2 border-2 rounded-2xl"
                            />
                        </div>
                    </div>

                    <div>                    
                        <label for="end_date" class="w-1/5">
                            Data para terminar a leitura
                        </label> 
                        <!-- Ajustando a largura para w-1/4 -->
                        <div class="flex flex-row"> 
                            <input type="date" 
                                id="end_date" 
                                name="end_date" 
                                [(ngModel)]="reportObjReq.end_date"
                                required
                                class="form-input mt-1 block w-3/5 p-2 border-2 rounded-2xl"   
                            />
                        </div>
                    </div>

                </div>
            </div>

            <div class="grid-col items-center">
                <button class="mt-2 mb-1" type="submit">
                    <span class="cursor-pointer text-[small] py-2 px-6 bg-[#fff] rounded-3xl font-bold text-[#00527E] m-2"
                          *ngIf="is_loading===false"
                          style="border: 1px solid rgb(0, 82, 126);"> 
                        CRIAR
                    </span>
                    <span class="cursor-pointer text-[small] p-2 bg-[#fff] rounded-xl font-bold text-[#00527E] m-2"
                        *ngIf="is_loading===true"
                        style="border: 1px solid rgb(0, 82, 126);"> 
                      AGUARDE...
                    </span>
                </button>
            </div>
    
            <div class="grid mb-20">
                <hr />
            </div>
        </div>
    </form>


</div>