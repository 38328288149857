
<app-user-layout>

    <div class="grid grid-cols-1 text-left text-[#414141] ">
        <div class="grid-col" *ngIf="appName?.toUpperCase()=='ODOO'">
            <app-native-odoo 
                [appVersion]="version"
                [appPage]="pageName">
            </app-native-odoo>
        </div>

        <div class="grid-col" *ngIf="appName?.toUpperCase()=='DPR_106'">
            <app-native-dpr
                [appVersion]="version"
                [appPage]="pageName">
            </app-native-dpr>
        </div>

        <div class="grid-col" *ngIf="appName?.toUpperCase()=='LUA_INVOICE'">
            <app-lua-invoice
                [appVersion]="version"
                [appPage]="pageName">
            </app-lua-invoice>
        </div>

        <div class="grid-col" *ngIf="appName?.toUpperCase()=='EMAIL_INBOX'">
            <app-email-inbox
                [appVersion]="version"
                [appPage]="pageName">
            </app-email-inbox>
        </div>

    </div>

</app-user-layout>
