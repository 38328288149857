<app-user-layout>
    <div class="flex flex-wrap gap-52">
        <div class="w-full sm:w-1/2 md:w-1/3 mb-4">
            <app-bar-chart></app-bar-chart>
        </div>
        <div class="w-full sm:w-1/2 md:w-1/3 mb-4">
            <app-pie-chart></app-pie-chart>
        </div>
        <div class="w-full sm:w-1/2 md:w-1/3 mb-4">
            <app-line-chart></app-line-chart>
        </div>
        <app-calender></app-calender>
        
    </div>
    <app-tab-component></app-tab-component>
    <app-counter></app-counter>
    <app-accordion></app-accordion>
    <app-card-list></app-card-list>
    <app-alert type="success" message="Well done!"></app-alert>
<app-alert type="info" message="Heads up!"></app-alert>
<app-alert type="warning" message="Warning!"></app-alert>
<app-alert type="error" message="Oh snap!"></app-alert>

</app-user-layout>