<div class="stock-performance flex flex-col items-center p-5 max-w-4xl mx-auto">
 
  <div class=" gap-4 mb-4">
    <h1 class="font-bold text-3xl text-left">$18.55884</h1>
<div class="flex flex-row">
    <!-- Dados Adicionais -->
    <div class="text-gray-700 font-semibold mr-">
      <div>Total Gain:</div>
      <div>Day Gain:</div>
      <div>Cash Holdings:</div>
    </div>
    <div class="stock-info flex flex-col">
      <div class="total-gain mb-2 flex items-center">
        <span class="text-gray-800">{{ stockData.totalGain | currency }}</span>
      </div>
      <div class="day-gain mb-2 flex items-center">
        <span class="text-gray-800">{{ stockData.dayGain | currency }}</span>
      </div>
      <div class="cash-holdings mb-2 flex items-center">
        <span class="text-gray-800">{{ stockData.cashHoldings | currency }}</span>
      </div>
    </div>
 

  <!-- Gráfico e Legenda -->
  <div class="chart-container -mt-20 w-full max-w-2xl flex flex-col items-center">
    <canvas id="lineChart"  class="w-20 h-12"></canvas>
    <div class="legend  flex items-center">
      <div class="w-4 h-4 bg-teal-500 mr-2"></div>
      <span class="text-gray-800 font-semibold">Senoidal Line</span>
    </div>
  </div>
</div>
</div>
