import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-statement-of-changes-in-equity',
  templateUrl: './statement-of-changes-in-equity.component.html',
  styleUrls: ['./statement-of-changes-in-equity.component.scss'],
})
export class StatementOfChangesInEquityComponent implements OnInit {
  isLoading: boolean = true;
  equityData: any = null;

  constructor() {}

  ngOnInit() {
    this.fetchEquityData();
  }

  fetchEquityData() {
    setTimeout(() => {
      this.equityData = {
        period: '2023',
        changes: [
          { description: 'Share Capital', amount: 100000 },
          { description: 'Retained Earnings', amount: 50000 },
          { description: 'Other Reserves', amount: 20000 },
        ],
      };
      this.isLoading = false;
    }, 1000);
  }
}
