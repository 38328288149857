import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-fatura-details',
  templateUrl: './fatura-details.component.html',
  styleUrls: ['./fatura-details.component.scss']
})
export class FaturaDetailsComponent {
    @Input() report : any;
    constructor(private chatService: ChatService) {
    }
  
    ngAfterViewChecked() {
    }

}
