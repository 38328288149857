import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent {
  @Input()
  faqs = [
    { question: 'Como envio faturas para o Lua?', answer: 'Você pode enviar faturas para o Lua de duas maneiras: pelo chat, anexando o arquivo ou texto da fatura, ou por e-mail, enviando o arquivo para o endereço de e-mail designado do Lua.', open: false },
    { question: 'modelos 106 com base nas faturas?', answer: 'Após o envio das faturas, o Lua processa os dados e gera automaticamente os modelos 106 necessários. Você pode solicitar o modelo 106 diretamente no chat, e o Lua fornecerá o documento gerado.', open: false },
    { question: 'Como o Lua realiza a análise de faturas?', answer: 'O Lua analisa as faturas enviadas verificando detalhes e integridade das informações. Após a análise, fornece relatórios detalhados e feedback sobre as faturas.', open: false },
    { question: 'Como faço perguntas no Lua?', answer: 'Simplesmente digite sua pergunta no chat do Lua. O sistema responderá com base nas informações disponíveis e nas análises realizadas.', open: false },
    { question: 'Como pedir a lua para gerar gráficos?', answer: 'Peça ao Lua para gerar gráficos baseados nas faturas analisadas ou nas informações disponíveis. O Lua criará gráficos com base nos dados fornecidos e exibirá os resultados diretamente no chat.', open: false },
    { question: 'Onde encontro suporte técnico para o Lua?', answer: 'Você pode utilizar o chat do Lua para fazer perguntas ou relatar problemas técnicos. Além disso, pode entrar em contato com o suporte técnico do Lua pelo e-mail designado.', open: false },
  ];

  faqsLeft: any[] = [];
  faqsRight: any[] = [];

  ngOnInit() {
    // Divide os FAQs em dois grupos
    this.faqsLeft = this.faqs.slice(0, Math.ceil(this.faqs.length / 2));
    this.faqsRight = this.faqs.slice(Math.ceil(this.faqs.length / 2));
  }

  toggleAnswer(selectedItem: any) {
    this.faqs.forEach(item => {
      if (item === selectedItem) {
        item.open = !item.open; // Alterna o estado do FAQ clicado
      } else {
        item.open = false; // Fecha os outros FAQs
      }
    });
  }
}
