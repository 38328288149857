import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ChatService } from '../../../services/chat.service';
// Importe o serviço aqui


@Component({
  selector: 'app-sync-odoo',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class AppSyncOdoo implements OnInit {
    @Input() reports: any;
    @Input() doc: any;

    is_loading:boolean = false
    error_msg:any = false
    sync_status:any = null
    is_error:boolean = false
    is_not_found:boolean = false
    is_synced:boolean = false
    is_sync_error:boolean = false
    is_loading_sync:boolean = false

    constructor(
        private fb: FormBuilder,
        private chatService: ChatService,
        private sanitizer: DomSanitizer
    ) {
    }

    ngOnInit() {
        console.info("[app-sync-odoo] Doc: ", this.doc, this.reports);
        this.VerifySync();
    }

    VerifySync() {
        if (this.is_loading) {
            return;
        }

        const payload:any = {
            'ops' : 'VerifySyncOdoo',
            'fileid' : this.doc.id,
            'name' : this.doc.name,
            'title' : this.doc.title,
        }

        this.is_loading=true;
        this.is_error=false;
        this.is_not_found=false;
        this.is_synced=false;
        this.sync_status=null;

        this.chatService.ExecuteTask("lua/DocSystemAPI", payload, (doc_resp:any) => {
            setTimeout(()=>{
                this.is_loading=false;
                if (doc_resp.Status=='OK') {
                    this.is_synced = true;
                    this.sync_status = doc_resp.object_;
                } else if (doc_resp.Status=='NOT_FOUND') {
                    this.is_not_found=true;
                } else {
                    this.is_error=true;
                }
            }, 670);

        }, (error:any) => {
            this.is_loading=false;
            this.is_error=true;
        });
    }

    SyncOdoo() {
        if (this.is_loading_sync) {
            return;
        }

        const payload:any = {
            'ops' : 'SyncOdoo',
            'fileid' : this.doc.id,
            'invoice' : {
                'reports' : this.reports
            }
        }

        this.is_loading_sync=true;
        this.is_synced=false;
        this.sync_status=false;
        this.is_not_found=false;
        this.is_sync_error=false;

        this.chatService.ExecuteTask("lua/DocSystemAPI", payload, (doc_resp:any) => {
            this.is_loading_sync=false;
            if (doc_resp.Status=='OK') {
                this.is_synced=true;
                this.sync_status = doc_resp.object_;
            } else if (doc_resp.Status=='NOT_FOUND') {
                this.is_not_found=true;
            } else {
                this.is_sync_error=true;
            }

        }, (error:any) => {
            this.is_loading_sync=false;
            this.is_sync_error=true;
        });
    }
}
