<div class="" *ngIf="editorForm">
    <!-- <p>Tipo de relat&oacute;rio: {{ groupname}} </p> -->
    <!-- FORM EDITOR -->
    <app-alert type="warning" 
        message="Aguarde..."
        *ngIf="is_loading">
    </app-alert>

    <app-alert type="error" 
        message="Nao foi possivel criar o relatorio. Tente novamente"
        *ngIf="is_error">
    </app-alert>

    <app-alert type="error" 
        *ngIf="is_invalid"
        message="{{ error_msg || 'Nao foi possivel concluir. Verifique todos os campos' }}">
    </app-alert>


    <form [formGroup]="editorForm" (ngSubmit)="SubmitForm()" class="max-w-lg mx-auto">

        <div class="grid grid-cols-2 gap-5 " style="max-height:400px;overflow:hidden;overflow-y:scroll">
            <div class="form-field" *ngFor="let Row of rowList">
                <label for="{{ Row.Field }} " class="mr-2">{{ Row.Label }}</label>
                <input id="ano" formControlName="{{Row.Field}}" 
                    type="{{Row.InputType}}" 
                    class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                />
            </div>
        </div>

        <div class="grid grid-cols-1" *ngIf="!is_done">
            <div class="grid-col">
                <div class="flex justify-center mt-6 space-x-3 sm:flex-col sm:space-y-3 sm:space-x-0">
                  <button type="submit" 
                      class="px-4 py-2 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600">
                      Gravar
                  </button>
                </div>
            </div>
        </div>


    </form>
</div>
