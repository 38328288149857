import { Component, Input } from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-documents',
    // templateUrl: './docs-v2.component.html',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent {
    @Input() prompts: any[] = [
    ];

    currentIndex: number = 0;
    is_loading:boolean = false;
    show_group_docs: boolean = false;
    is_error:boolean = false;
    errors: any[] = []
    onRefreshCall:any = () => {}
    objectList:any[] = []
    objectStream:any = null

    queueList:any = null;
    loadCache:any = {}
    objectCache:any = {}
    currentDocOpen:any = null;

    is_loading_queue:boolean = false;
    is_uploading:boolean = false;
    is_upload_error:boolean = false;
    is_upload_succ:boolean = false;
    is_peeking:boolean = false
    peek_error:boolean = false

    is_preview_err:boolean = false
    is_run_preview:boolean = false
    is_run_interup:boolean = false
    previewPDF:any = null
    odooSync:any = false

    constructor(
        private chatService: ChatService,
        public translate: TranslateService
    ) {   
    }

    ngOnInit() {
        this.openFileItem = this.openFileItem.bind(this);
        this.LoadDocuments(true)
    }

    closePDFPreview() {
        this.is_run_preview=false;
        this.is_preview_err=false;
        this.is_run_interup=true;
        this.previewPDF=null;
    }

    openOdooSync(){
        this.odooSync=true;
    }

    closeOdooSync(){
        this.odooSync=false;
    }

    genPDFPreview() {
        if (this.is_run_preview || !this.currentDocOpen) {
            return;
        }

        this.is_run_preview=true;
        this.is_preview_err=false;
        this.previewPDF=null;
        const fileid=this.currentDocOpen.fileid;

        const payload = {
            'ops' : 'GenPDFPreview',
            'fileid' : fileid,
        }

        this.chatService.ExecuteTask("lua/DocSystemAPI", payload, (doc_resp:any) => {
            this.is_run_preview=false;
            // if (this.is_run_interup) {
            //     this.is_run_interup=false;
            //     return;
            // }
            console.info("GenPDFPreview: ", doc_resp);
            this.previewPDF=doc_resp;
        }, (error:any) => {
            this.is_run_preview=false;
            this.is_preview_err=true;
            console.error("E: ", error)
        });
    }

    toggleGroupDocs() {
        this.show_group_docs = !this.show_group_docs;
    }

    executeGroup(fileid:any, groupDoc:any) {
        const groupName:any = groupDoc.group_name;
        const keyCache = fileid+"#"+groupName;
        const payload = {
            'ops' : 'GenDocGroup',
            'fileid' : fileid,
            'groupName' : groupName,
            'filters' : {},
        }

        this.objectCache[keyCache] = {
            'Status' : 'LOADING'
        }

        this.chatService.ExecuteTask("lua/DocSystemAPI", payload, (doc_resp:any) => {
            // console.info("queryDocumentObject: ", doc_resp)
            const object_:any = doc_resp.object_ || {};
            const obj:any = {
                'Status' : 'LOADED',
                'keyCache' : keyCache,
                '_object' : object_,
            }

            // console.info("object: ", object_);
            if (object_ && object_.Status=="OK") {
                const info:any = object_.info;
                console.info("info: ", info);
                // if (info && (info.Status=="OK"||(info.length&&info.length>0))) {
                //     obj['info'] = info
                // }
            } else {
            }

            this.objectCache[keyCache] = obj;

        }, (error:any) => {
            this.objectCache[keyCache] = {
                'Status' : 'FAILED',
                'Details' : error,
            }
        });
    }

    queryDocumentObject(fileid:any, objectName:any) {
        const keyCache = fileid+"#"+objectName;
        const payload = {
            'ops' : 'QueryDocObject',
            'fileid' : fileid,
            'objectName' : objectName,
            'filters' : {},
        }

        // if(this.objectCache[keyCache]) {
        //     const entry=this.objectCache[keyCache];
        //     // console.info("entry: ", entry);
        //     return;
        // }

        this.objectCache[keyCache] = {
            'Status' : 'LOADING'
        }

        this.chatService.ExecuteTask("lua/DocSystemAPI", payload, (doc_resp:any) => {
            // console.info("queryDocumentObject: ", doc_resp)
            const object_:any = doc_resp.object_ || {};
            const obj:any = {
                'Status' : 'LOADED',
                'keyCache' : keyCache,
                '_object' : object_,
            }

            // console.info("object: ", object_);
            if (object_ && object_.Status=="OK") {
                const info:any = object_.info;
                console.info("info: ", info);
                // if (info && (info.Status=="OK"||(info.length&&info.length>0))) {
                // if (info.Status=="OK") {
                    obj['info'] = info
                // }
            } else {
                this.objectCache[keyCache] = {
                    'Status' : 'FAILED',
                    'Details' : "Nao foi possivel concluir.",
                }
            }

            this.objectCache[keyCache] = obj;

        }, (error:any) => {
            this.objectCache[keyCache] = {
                'Status' : 'FAILED',
                'Details' : error,
            }
        });
    }

    onRefreshDocList() {
        this.objectList = [];
        this.LoadDocuments();
    }

    closeCurrentFile() {
        this.currentDocOpen = null;
    }

    uploadDoc(event:any) {
        const input = event.target as HTMLInputElement;
        var file:any = false;
        if (input.files && input.files.length > 0) {
            file = input.files[0];
        }

        if (!file) {
            return
        }

        this.is_uploading=true;
        this.is_upload_error=false;
        this.is_upload_succ = false;

        this.chatService.Upload(file, (doc_resp:any) => {
            console.info("doc_resp: ", doc_resp);

            this.is_uploading=false;
            this.is_upload_succ = true;

            setTimeout(()=>{
                this.onRefreshDocList();
                this.is_upload_succ = false;
            }, 856);


        }, (error:any) => {
            this.is_uploading=false;
            this.is_upload_error=true;
        })
    }

    openFileItem(item:any) {
        this.currentDocOpen = null;

        if(this.loadCache[item.fileid]) {
            const entry=this.loadCache[item.fileid];
            this.currentDocOpen = item;

            if (entry.Status && entry.Status=='LOADING') {
            }

            if (entry.Status && entry.Status=='DETAILS_LOAD') {
            }

        } else {
            this.loadCache[item.fileid] = {
                'Status' : 'LOADING'
            }

            const payload = {
                'ops' : 'DescribeFile',
                'fileid' : item.fileid,
                'info_list' : [
                    'blocks',
                    'reports',
                    'doc_cplx'
                ],
                'filters' : {},
            }

            this.chatService.ExecuteTask("lua/DocSystemAPI", payload, (doc_resp:any) => {
                setTimeout(() => {

                    if (doc_resp.Status=="OK") {
                        const docMap:any = {};
                        if (doc_resp.info_group_response) {
                            doc_resp.info_group_response.groups_doc.forEach((X:any) => {
                                docMap[X.group_name] = X;
                            });
                        }

                        this.loadCache[item.fileid] = {
                            'Status' : 'DETAILS_LOAD',
                            'Details' : doc_resp,
                            "DOC_MAP" : docMap,
                        }

                    } else {
                        this.loadCache[item.fileid] = {
                            'Status' : 'FAILED',
                            'Details' : "Oops, parece que nao correu tudo bem"
                        }
                    }

                }, 130);

                setTimeout(()=>{
                    this.queryDocumentObject(item.fileid, 'REPORTS')
                }, 150);

            }, (error:any) => {
                this.loadCache[item.fileid] = {
                    'Status' : 'FAILED',
                    'Details' : error,
                }
            });
        }

        setTimeout(()=>{
            this.currentDocOpen = item;
        }, 120);

        // this.objectList = [];
        // this.LoadDocuments();
    }

    LoadDocuments(init_first=false) {
        // console.info("LoadDocuments():");
        // return;
        if (this.is_loading) {
            return;
        }

        this.is_loading = true;
        this.is_error = false;
        this.objectList = [];

        const payload = {
            'ops' : 'ListFiles',
            'filters' : {},
        }

        this.chatService.ExecuteTask("lua/DocSystemAPI", payload, (doc_resp:any) => {
            setTimeout(()=>{
                this.is_loading = false;
                if (doc_resp.hasOwnProperty("Status")) {
                    const Status = doc_resp["Status"];
                    if (Status=="OK" && doc_resp.hasOwnProperty("list")) {
                        const list = doc_resp['list'];
                        this.objectList = list;

                        // if (init_first && list.length>0) {
                        //     setTimeout(()=>{
                        //         this.openFileItem(list[0]);
                        //     }, 25);
                        // }
                    } else {
                    }
                }

            }, 123);

        }, (error:any) => {
            console.info("error: ", error)
            this.is_loading = false
            this.is_error = true
        });
    }
}
