import { Component, Input, OnInit, ElementRef, ViewChild  } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ChatService } from 'src/app/services/chat.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';


@Component({
  selector: 'app-lua-entity',
  templateUrl: './lua-entity.component.html',
})
export class LuaEntityComponent implements OnInit{
    filterForm: FormGroup;
    is_loading:boolean = false;
    is_error:boolean = false;
    is_ready:boolean = false;

    is_cloading:boolean = false;
    is_cerror:boolean = false;
    is_created:boolean = false;
    createObj:any = {
        name: '',
        nif: 0,
        email: '',
        sector: 'SERVICOS',
    }

    objectList:any = [];
    @ViewChild('createModal') createEntModal: any;
    // this.invoiceTable.nativeElement.scrollIntoView({ behavior: 'smooth' });

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private chatService: ChatService
    ) { 
        // console.info("[home] chatService: ", chatService);
        this.filterForm = this.fb.group({
            query: [null, Validators.required],
            // state: ['draft', Validators.required],
        });
    }

    ngOnInit() {
        setTimeout(()=>{
            this.LoadEntities();
        }, 120);
    }

    goBack() {
        this.router.navigate(['/luaxapps']);   
    }

    createEntity() {
        this.createEntModal.openModal();
    }

    closeCModal() {
        this.resetCreateObj();
        this.createEntModal.closeModal();
    }

    resetCreateObj() {
        this.createObj = {
            name: '',
            nif: 0,
            email: '',
            sector: 'SERVICOS',
        }        
    }

    createEntityApi() {
        const obj:any = JSON.parse(JSON.stringify(this.createObj));
        obj.nif = obj.nif+"";

        if (!obj.name || obj.name.trim().length==0) {
            return;
        }
        if (!obj.nif || obj.nif.trim().length==0) {
            return;
        }
        if (!obj.email || obj.email.trim().length==0) {
            return;
        }
        if (!obj.sector || obj.sector.trim().length==0) {
            return;
        }

        const payload = {
            'ops' : 'CreateEntity',
            'obj' : obj,
        }

        this.is_cloading=true;
        this.is_cerror=false;
        this.is_created=false;

        this.chatService.ExecuteTask("lua/Entity", payload, (doc_resp:any) => {
            this.is_cloading=false;
            if (doc_resp.Status=='OK') {
                this.is_created=true;
                this.createObj = {
                    name: '',
                    nif: 0,
                    email: '',
                    sector: 'SERVICOS',
                }
                setTimeout(()=>{
                    this.is_created=false;
                    this.closeCModal();
                }, 560);
            } else {
                this.is_cerror=true;
            }

        }, (error:any) => {
            this.is_cloading=false;
            this.is_cerror=true;
        });
    }

    openEntity(ENT:any) {
    }

    LoadEntities() {
        this.is_error=false;
        this.is_loading=true;
        this.objectList = [];

        const payload = {
            'ops' : 'ScanEntities',
            'filters' : {},
        }

        this.chatService.ExecuteTask("lua/Entity", payload, (doc_resp:any) => {
            setTimeout(()=>{
                this.is_loading = false;
                if (doc_resp.Status=="OK") {
                    this.objectList = doc_resp.list;
                } else {
                    this.is_error = true
                }
            }, 123);

        }, (error:any) => {
            console.info("error: ", error)
            this.is_loading = false
            this.is_error = true
        });
    }
}


