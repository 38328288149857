<app-user-layout>
    <div class="grid grid-cols-5 justify-center md:-ml-14">
        <div class=" col-span-1 bg-white  overflow-hidden overflow-y-scroll p-1 border border-[#5BACAD66] rounded-md  shadow-xl">
            <P class="text-2xl font-extrabold text-[#414141] mt-3 p-2"> My account</P>
            <div class="justify-center mt-3" style="position: relative;">
                <input type="text" placeholder="Pesquisar..." class="border border-gray-300 px-2 py-1 rounded-full w-56"
                    style="padding-left: 40px;" />
                <img src="assets/img/icon/search.svg" alt="" c
                    style="position: absolute; top: 50%; left: 10px; transform: translateY(-50%);" />
            </div>


            <div class="min-h-[400px]  max-h-[900px] mt-5">
                <!-- Conteúdo aqui -->


                <div *ngFor="let thread of threadList.list" (click)="selectThread(thread)"
                    [ngClass]="{'selected-thread': selectedThread === thread}"
                    class="mb-3 thread-item cursor-pointer p-3 hover:bg-[#00527E1A]">
                    <div style="float:left;display: inline;height: 40px;width: 40px;padding-top:5px">
                        <img src="assets/img/lua/Lua-planet.png" alt=""
                            *ngIf="thread.from_user=='system'||thread.from_user=='luna.ai'||thread.from_user=='luna.fatura'"
                            style="width:30px;height:30px" />
                    </div>
                    <p class="m-0" style="font-size:large">
                        <span>{{thread.from_user}}</span>
                    </p>
                    <p class="m-0 opacity-70" style="font-size:x-small">
                        <span>{{thread.count}} messages</span>
                    </p>
                </div>

            </div>


        </div>

        <div class=" col-span-4 ml-2 border border-[#5BACAD66] rounded-md shadow-xl"
            style="min-height:200px;;overflow:hidden;overflow-y: scroll;">
            <div class="mt-3 mb-1">
                <app-alert type="info" message="Carregando o Inbox" *ngIf="is_loading">
                </app-alert>
                <app-alert type="error" message="Nao foi possivel carregar o Inbox" *ngIf="is_error">
                </app-alert>
            </div>

            <div *ngIf="currentThread && convCache[currentThread.from_user]" style="padding: 12px;padding-top:0px">
                <!-- <p>Messages from {{currentThread.from_user}}</p> -->
                <div class="grid grid-cols-1 mt-4">
                    <app-alert type="info" message="Carregando as Conversas" *ngIf="is_loading_conv">
                    </app-alert>
                    <app-alert type="error" message="Nao foi possivel as conversas!" *ngIf="is_loading_conv_error">
                    </app-alert>
                    <p class="mt-4 text-right ml-auto mb-4" >
                        <span
                            class="cursor-pointer text-[small] px-8 py-2 bg-[#fff] rounded-3xl font-bold text-[#00527E] m-2"
                            style="border: 1px solid #00527E;" (click)="LoadConversation(currentThread)"> Check New
                            Messages </span>
                    </p>
                    
                </div>

                <div class="grid grid-cols-1 mt-3">
                    <div class="grid-col mb-3" *ngFor="let conv of convCache[currentThread.from_user].list">

                        <div style="padding:10px;border-radius:10px;" class="bg-[#EAF2F2]">
                            <p class="opacity-70" style="font-size:small">{{ conv.title }}</p>
                            <p class="opacity-70" style="font-size:x-small">{{ conv.created_at }}</p>
                            <p>
                                {{ conv.msg }}
                            </p>
                        </div>

                    </div>
                </div>
            </div>
            <div class=" inputchat ">
                <div class="fixed bottom-12  w-[70%] px-16   flex items-center  md:ml-10 z-50 pb-8 inputchat">
                    <div class="px-2 md:px-0 w-full">
                        <app-input-chat class="w-full"
                            [chatData]="{classContainer: '', classInput: '!h-[3rem] w-full'}"></app-input-chat>
                    </div>
                </div>
            </div>

        </div>
            
    </div>

</app-user-layout>