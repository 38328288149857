import { Component, Input, ViewChild } from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

@Component({
    selector: 'app-doc-explorer',
    templateUrl: './doc-explorer.component.html',
})
export class DocExplorerComponent {
    @Input() prompts: any[] = [
    ];

    @ViewChild('createFolderModal') createFolderModal: any;

    currentIndex: number = 0;
    is_loading:boolean = false;
    is_error:boolean = false;
    errors: any[] = []
    onRefreshCall:any = () => {}
    objectList:any[] = []
    objectStream:any = null

    queueList:any = null;
    loadCache:any = {}
    objectCache:any = {}
    newFolder:any = {name: ''}
    currentDocOpen:any = null;

    constructor(
        private chatService: ChatService,
        private router: Router,
        public translate: TranslateService,
        private fb: FormBuilder
    ) {   
    }

    goBack() {
        this.router.navigate(['/luaxapps']);
    }

    createFolder() {
        this.createFolderModal.openModal()
    }

    is_cloading:boolean = false
    is_cerror:boolean = false
    is_created:boolean = false

    requestNewFolder() {
        const newFolder=this.newFolder;
        if (!newFolder.name || newFolder.name.trim().length==0) {
            return;
        }

        const payload = {
            'ops' : 'CreateFolder',
            'name' : newFolder.name,
        }

        this.is_cloading=true;
        this.is_cerror=false;
        this.is_created=false;

        this.chatService.ExecuteTask("lua/DocSystemAPI", payload, (doc_resp:any) => {
            this.is_cloading=false;
            if (doc_resp.Status=='OK') {
                this.is_created=true;
                this.newFolder.name = '';
                this.objectList = [{
                    'id':doc_resp.id, 
                    'name':newFolder.name
                }].concat(this.objectList||[]);

                setTimeout(()=>{
                    this.is_created=false;
                    this.closeCreateFolder();
                }, 1560);

            } else {
                this.is_cerror=true;
            }

        }, (error:any) => {
            this.is_cloading=false;
            this.is_cerror=true;
        });
    }

    closeCreateFolder() {
        this.createFolderModal.closeModal()
    }

    ngOnInit() {
        this.openFolder = this.openFolder.bind(this);
        this.LoadFolders(true)
    }

    onRefreshDocList() {
        this.objectList = [];
        this.LoadFolders();
    }

    closeCurrentFile() {
        this.currentDocOpen = null;
    }

    openFolder(item:any) {
        this.currentDocOpen = null;
        // if(this.loadCache[item.fileid]) {
        //     const entry=this.loadCache[item.fileid];
        //     this.currentDocOpen = item;
        //     if (entry.Status && entry.Status=='LOADING') {
        //     }
        //     if (entry.Status && entry.Status=='DETAILS_LOAD') {
        //     }
        // } else {
        //     this.loadCache[item.fileid] = {
        //         'Status' : 'LOADING'
        //     }
        //     const payload = {
        //         'ops' : 'DescribeFile',
        //         'fileid' : item.fileid,
        //         'info_list' : [
        //             'blocks',
        //             'reports',
        //             'doc_cplx'
        //         ],
        //         'filters' : {},
        //     }
        //     this.chatService.ExecuteTask("lua/DocSystemAPI", payload, (doc_resp:any) => {
        //         setTimeout(() => {

        //             // if (doc_resp.Status=="OK") {
        //             //     const docMap:any = {};
        //             //     if (doc_resp.info_group_response) {
        //             //         doc_resp.info_group_response.groups_doc.forEach((X:any) => {
        //             //             docMap[X.group_name] = X;
        //             //         });
        //             //     }

        //             //     this.loadCache[item.fileid] = {
        //             //         'Status' : 'DETAILS_LOAD',
        //             //         'Details' : doc_resp,
        //             //         "DOC_MAP" : docMap,
        //             //     }

        //             // } else {
        //             //     this.loadCache[item.fileid] = {
        //             //         'Status' : 'FAILED',
        //             //         'Details' : "Oops, parece que nao correu tudo bem"
        //             //     }
        //             // }

        //         }, 130);

        //         // setTimeout(()=>{
        //         //     this.queryDocumentObject(item.fileid, 'REPORTS')
        //         // }, 150);
        //     }, (error:any) => {
        //         this.loadCache[item.fileid] = {
        //             'Status' : 'FAILED',
        //             'Details' : error,
        //         }
        //     });
        // }
        setTimeout(()=>{
            this.currentDocOpen = item;
        }, 120);
    }

    LoadFolders(init_first=false) {
        // console.info("LoadDocuments():");
        // return;
        if (this.is_loading) {
            return;
        }

        this.is_loading = true;
        this.is_error = false;
        this.objectList = [];

        const payload = {
            'ops' : 'GetFolders',
            'filters' : {},
        }

        this.chatService.ExecuteTask("lua/DocSystemAPI", payload, (doc_resp:any) => {
            setTimeout(()=>{
                this.is_loading = false;
                if (doc_resp.hasOwnProperty("Status")) {
                    const Status = doc_resp["Status"];
                    if (Status=="OK" && doc_resp.hasOwnProperty("list")) {
                        const list = doc_resp['list'];
                        this.objectList = list;
                        // if (init_first && list.length>0) {
                        //     setTimeout(()=>{
                        //         this.openFileItem(list[0]);
                        //     }, 25);
                        // }
                    } else {
                        this.is_error = true;
                    }
                }

            }, 123);

        }, (error:any) => {
            console.info("error: ", error)
            this.is_loading = false
            this.is_error = true
        });
    }
}
