<app-user-layout>
    <p class="mt-4 mb-5" (click)="goBack()">
        <span
        class="cursor-pointer text-sm  font-bold text-[#00527E] m-2 flex flex-row  ">
        <img src="assets/img/icon/Group.svg " class="w-4 h-4 mr-1"/>
            VOLTAR
        </span>
    </p>
    <div class="flex flex-row justify-between ">
        <div>
            <h3 class="text-2xl font-bold text-left mb-3 "> Entidades </h3>
            <h2 class="text-left opacity-70">
                <span class="cursor-pointer">Lua Apps</span>
                /&nbsp;
                <span class="text-[#00527E]">Entidades</span>
            </h2>
        </div>

        <div class="flex justify-between items-center mb-4">
            <div class="flex space-x-5">
                <p class="cursor-pointer mt-1 mb-1 flex ">
                    <span class=" flex flex-row px-4 py-2 rounded-3xl text-[#00527E] m-2 border border-[#00527E]"
                        (click)="LoadEntities()">
                        <img src="assets/img/icon/Arrows.svg" alt="" class="w-5 h-5" /> Actualizar
                    </span>
                    <span class=" flex flex-row px-4 py-2 rounded-3xl text-[#00527E] m-2 border border-[#00527E]"
                        (click)="createEntity()">
                        <img src="assets/img/icon/folder-open.svg" alt="" class="w-5 h-5" /> 
                        Criar Entidade
                    </span>
                </p>
            </div>

            <div class="flex space-x-5">
            </div>
        </div>
    </div>

    <div class="grid grid-cols-1 mt-3">
        <div class="grid-col">
            <div class="text-center p-2" *ngIf="is_loading">
                <app-spinner>
                </app-spinner>
            </div>

            <app-alert type="error" 
                message="Nao foi possivel concluir. Tente novamente"
                *ngIf="is_error"></app-alert>
        </div>
    </div>

    <div class="grid grid-cols-1">
        <div class="grid-col" *ngIf="objectList">
            <div class="table-auto overflow-hidden"
                style="width:100%">
                <table class="text-sm" style="width:100%">
                    <thead  class="bg-[#EAF2F2] bg-opacity-60 mb-10 ">
                        <tr class="">
                            <th class=" p-3 pl-5 rounded-bl-3xl mb-10" style="text-align:start;">
                                Nome
                            </th>
                            <th class="p-3  mb-10"  style="text-align: left;">
                                NIF
                            </th>
                            <th class="p-3  mb-10"  style="text-align: left;">
                                Contacto
                            </th>
                            <th class="p-3  mb-10"  style="text-align: left;">
                                Pa&iacute;s
                            </th>
                            <th class="p-3  mb-10"  style="text-align: left;">
                                Criado em
                            </th>
                            <th  class=" rounded-br-3xl  mb-10"></th>
                    </thead>
                    <tbody  class="mt-52" >
                        <tr *ngIf="objectList.length==0">
                            <td colspan="10" class="text-center">
                                <p class="opacity-30 p-5" style="font-size:small;">
                                    Nenhum resultado encontrado.
                                </p>
                            </td>
                        </tr>
                        <tr *ngFor="let ENT of objectList" 
                            (click)="openEntity(ENT)"
                            class="cursor-pointer pb-10 hover:bg-[#EAF2F2] " >
                            <td  class="p-1 cpointer text-left pl-5"
                                 style="text-align: left;">
                                 <p >       
                                    <img src="assets/img/icon/folder-open.svg" alt="Lua" style="display:inline;width:30px;margin-right: 5px;" />
                                    <span class="text-bold">
                                        {{ ENT['name'] }}
                                    </span>
                                 </p>
                            </td>

                            <td class="p-3" style="text-align: left;">
                                {{ ENT['nif'] }}
                            </td>

                            <td class="p-3" style="text-align: left;">
                                {{ ENT['email'] }},
                                {{ ENT['phonenumber'] }}
                            </td>

                            <td class="p-3" style="text-align: left;">
                                {{ ENT['country'] }}
                            </td>

                            <td class="p-3" style="text-align: left;">
                                {{ ENT['created_at'] }}
                            </td>

                            <td class="text-center">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <app-modal #createModal title="Criar Entidade" [onClose]="closeCModal">
        <div class="bg-white shadow-md rounded px-5 pt-6 pb-8 mb-1">
            <div class="grid grid-cols-1">
                <div class="grid-col" *ngIf="is_cloading">
                    <app-alert type="info" 
                        message="Aguarde...">
                    </app-alert>
                </div>

                <div class="grid-col" *ngIf="is_cerror">
                    <app-alert type="error" 
                        message="Nao foi possivel criar a entidade.">
                    </app-alert>
                </div>

                <div class="grid-col" *ngIf="is_created">
                    <app-alert type="success" 
                        message="Entidade criada com sucesso">
                    </app-alert>
                </div>
            </div>

            <div class="flex items-center bg-login">
                <form class="p-5" *ngIf="!is_created">
                    <div class="mb-4" >
                        <label class="block text-gray-700 text-sm mb-2" for="entity_name">
                            Nome
                            <span style="font-size:small">(<span style="color:orangered;">*</span>)</span>
                        </label>

                        <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="ent_name" 
                            type="text"
                            required 
                            placeholder="Company Lda" 
                            [(ngModel)]="createObj.name" 
                            name="entity_name" 
                        />
                    </div>

                    <div class="mb-4" >
                        <label class="block text-gray-700 text-sm mb-2" for="entity_name">
                            Email
                            <span style="font-size:small">(<span style="color:orangered;">*</span>)</span>
                        </label>

                        <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="ent_email" 
                            type="email"
                            required 
                            placeholder="info@exemplo" 
                            [(ngModel)]="createObj.email" 
                            name="entity_email" 
                        />
                    </div>

                    <div class="mb-4" >
                        <label class="block text-gray-700 text-sm mb-2" for="nif">
                            NIF
                            <span style="font-size:small">(<span style="color:orangered;">*</span>)</span>
                        </label>

                        <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="nif" 
                            type="number"
                            required 
                            placeholder="000234" 
                            [(ngModel)]="createObj.nif" 
                            name="entity_nif" 
                        />
                        <p class="opacity-30" style="font-size:x-small">
                            Deve conter no minimo 9 n&uacute;meros.
                        </p>
                    </div>

                    <div class="mb-4" >
                        <label class="block text-gray-700 text-sm mb-2" for="entity_name">
                            Sector de Atividade
                            <span style="font-size:small">(<span style="color:orangered;">*</span>)</span>
                        </label>

                        <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="ent_name" 
                            type="text"
                            required 
                            placeholder="Financial" 
                            [(ngModel)]="createObj.sector" 
                            name="entity_sector" 
                        />
                    </div>

                    <div class="mb-4" >
                        <label class="block text-gray-700 text-sm mb-2" for="entity_address">
                            Endere&ccedil;o
                        </label>
                        <textarea class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Avenida Santiago" 
                            [(ngModel)]="createObj.address" 
                            name="entity_address" 
                        ></textarea>
                    </div>

                    <p class="cursor-pointer mt-0 mb-1 flex ">
                        <span class=" flex flex-row px-4 py-2 rounded-3xl text-[#fff] bg-[#00527E] m-2 border border-[#00527E]"
                            *ngIf="!is_cloading"
                            (click)="createEntityApi()">
                            <!-- <img src="assets/img/icon/Arrows.svg" alt="" class="w-5 h-5" />  -->
                            Criar
                        </span>
                        <span class=" flex flex-row px-4 py-2 rounded-3xl text-[#fff] bg-[#00527E] m-2 border border-[#00527E]"
                            *ngIf="is_cloading">
                            <!-- <img src="assets/img/icon/Arrows.svg" alt="" class="w-5 h-5" />  -->
                            Aguarde
                        </span>
                    </p>
                </form>
            </div>
        </div>
    </app-modal>

</app-user-layout>