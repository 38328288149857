<style type="text/css">
    .fill-1 {
        /*color:green;*/
        color:#00527E;
        font-weight:bold;   
    }
    .ai-filled {display:none;}
    .fill-1 .ai-filled {display: inline!important;}
</style>

<div style="padding:10px;right: 144px;position: absolute;min-width: 250px;">
    <div style="padding-left:10px;padding-bottom: 10px;background-color:#fff;padding-top:0px;margin-top:11px;padding-right:15px;min-height: 80px;border: 1px solid #f4f4f4;border-radius: 11px;box-shadow:0px 0px 7px rgba(0, 0, 0, .3);z-index:1000;">
        <div class="pt-3">
            <div style="float:left;margin-right:10px;height:120px;">  
                <img src="assets/img/im blue.jpg" alt="Logo"
                    width="48px"
                    class="rounded-full border-2 pull-left border-blue-300" 
                />

                <div class="" style="float:right;margin-top: -11px;background: rgb(255, 255, 255);padding: 3px;border-radius: 80px;box-shadow: 0px 0px 2px rgba(0,0,0,.3);z-index: 100000;position: relative;">              
                    <img src="assets/img/lua-magician.svg" 
                        alt="Lua Magic Tricks" 
                        width="14px" 
                        style="height:14px;" />
                </div>
            </div>
        </div>

        <div style="min-height:50px">        
            <div class="pl-3 mt-2" *ngIf="genReport">
                <div>
                    <p class="font-bold text-leftn" *ngIf="genReport.Status=='OK'"> 
                        Resposta 
                    </p>
                    <p class="font-bold text-leftn text-[#b50808]" 
                        *ngIf="genReport.Status!='OK'"> 
                        Oops, houve um erro
                    </p>
                    <p class="mb-0 ml-15" style="margin-top:-8px">
                        <span class="opacity-70" style="font-size:x-small">
                            {{resp_time}}
                        </span>
                    </p>
                </div>
                
                <div *ngIf="is_error || genReport.Status!='OK'">
                    <div *ngIf="genReport.Errors">
                        <p>Tenho detalhes: </p>
                        <p *ngFor="let Err of genReport.Errors">
                            {{Err}}
                        </p>
                    </div>
                </div>

                <p class="border p-2 text-[#333]" style="font-size:small">
                    {{genReport.answer}}
                </p>
            </div>

        </div>

        <div style="min-height:50px;background-color:#f4f4f4;">
            <p class="mt-1 mb-1 pl-1 pt-1" style="background:#f4f4f4;">
                <span class="cursor-pointer rounded-3xl text-[#00527E] text-left" style="font-size: small;">
                    <span *ngIf="!loadingCache['entity'] || loadingCache['entity'].S!='LOADING'" 
                        (click)="RunGenTask('lua/InvoiceAnalysisService', 'entity')">
                        An&aacute;lise de Entidade
                    </span>
                    <span *ngIf="loadingCache['entity']&&loadingCache['entity'].S=='LOADING'">
                        Aguarde
                    </span>
                </span>
            </p>

            <p class="mt-1 mb-1 pl-1 pt-1" style="background:#f4f4f4;">
                <span class="cursor-pointer rounded-3xl text-[#00527E] text-left" 
                    style="font-size: small;">
                    <span *ngIf="!loadingCache['num'] || loadingCache['num'].S!='LOADING'" 
                        (click)="RunGenTask('lua/InvoiceNumAnalysis', 'num')">
                        An&aacute;lise Fiscal/Trib.
                    </span>
                    <span *ngIf="loadingCache['num']&&loadingCache['num'].S=='LOADING'">
                        Aguarde
                    </span>
                </span>
            </p>

            <p class="mt-1 mb-1 pl-1 pt-1 text-center" style="background:#f4f4f4">
                <span class="cursor-pointer rounded-3xl text-[#00527E] text-center" 
                    style="font-size: small;"
                *ngIf="!previewPDF"
                (click)="genPDFPreview()">
                    Pr&eacute;-Visualizar
                </span>
            </p>

            <!-- !editorForm.valid && !is_loading && !is_error -->

            <p class="cursor-pointer mt-0 mb-1 text-center mt-2 ">
                <button class=" px-1 py-2 pl-10 pr-10 rounded-3xl text-[#fff] bg-[#00527E] border-[#00527E]"
                    style="font-size:small;" 
                    *ngIf="!is_loading && editorForm.valid"
                    (click)="OnSubmit()"
                    type="button">
                    <!-- <img src="assets/img/icon/Arrows.svg" alt="" class="w-5 h-5" />  -->
                    Criar Fatura
                </button>
                <span class=" px-1 py-2 rounded-3xl text-[#fff] bg-[#00527E] m-0 border border-[#00527E]"
                    *ngIf="is_loading">
                    <img src="assets/img/icon/Arrows.svg" alt="" class="w-5 h-5" /> 
                    Aguarde
                </span>
                <span class=" rounded-3xl text-[#333] m-0  opacity-80"   style="font-size:x-small;" 
                    *ngIf="!editorForm.valid">
                    <!-- <img src="assets/img/icon/Arrows.svg" alt="" class="w-5 h-5" />  -->
                    Formul&aacute;rio esta inv&aacute;lido.
                </span>
            </p>
        </div>


    </div>
    <!--
    <div style="margin-top:-15px;z-index:10;">
        <div class="gap-[1rem] my-2 px-8 py-3 flex items-center cursor-pointer bg-[#F489141A] hover:bg-[#F0F0F0]" 
            title="Abrir {{docObj.realname}}">
            <img src="assets/img/icon/folder-2.svg" alt="Lua" class="w-8 h-8">
            <div class="text-[0.875rem] text-black" style="font-size: x-small;"> 
                {{docObj.realname}}
                <p class="opacity-70 flex flex-row" style="font-size: xx-small;"> 
                    {{docObj.created_at||docObj.tms}}
                </p>
            </div>
        </div>
    </div>
    -->
</div>

<form [formGroup]="editorForm" 
    (ngSubmit)="OnSubmit()" class=""
    *ngIf="editorForm">
    <div class="grid grid-cols-2">
        <div class="grid-col" 
            style="max-height:500px;overflow:hidden;overflow-y:scroll;">        
            <!-- ALERTS -->
            <div class="p-3">
                <div *ngIf="is_loading">
                    <app-spinner>
                    </app-spinner>
                </div>

                <div *ngIf="is_created" class="mt-1 p-2 pt-20 pb-30 text-center"
                    style="max-width:300px;margin:auto;padding-bottom:30px;">
                    <app-alert type="success" message="{{'FATURA CRIADA COM SUCESSO'}}">
                    </app-alert>          

                    <p class="opacity-80 mt-2"
                        style="font-size:x-small">
                        A factura foi criada com sucesso
                        com o id <strong>{{resp_msg.id}}</strong> na 
                        pasta <strong>{{ resp_msg.folder_name }}</strong>.
                    </p>          

                    <p class="cursor-pointer mt-2 mb-1  "
                        (click)="AddMore()">
                        <span class=" px-2 py-1 rounded-3xl text-[#00527E] bg-[#fff] m-2 ml-0 border border-[#00527E]"
                            style="font-size:small">
                            Adicionar Mais
                        </span>
                    </p>
                </div>

                <app-alert type="error" 
                    message="{{ error_msg || 'Nao foi possivel concluir. Verifique todos os campos' }}"
                    *ngIf="is_error">
                </app-alert>

                <app-alert type="warning" 
                    message="Alerta: Verifique todos os campos"
                    *ngIf="!editorForm.valid && !is_loading && !is_error">
                </app-alert>

                <div *ngIf="errors">
                    <div *ngFor="let E of errors" class="mt-2">
                        <app-alert type="error" [message]="E">
                        </app-alert>
                    </div>
                </div>

                <div *ngIf="warnings && warnings.length>0 && !is_created">
                    <div *ngIf="ignore_warning">
                        <p class="text-[#00527E] pl-2"
                            style="">{{warnings.length}} alertas.</p>
                        <p class="cursor-pointer mt-0 mb-1 flex "
                            (click)="AtivateAlerts()">
                            <span class=" px-2 py-1 rounded-3xl text-[#00527E] bg-[#fff] m-2 border border-[#00527E]"
                                style="font-size:small">
                                Mostrar Alertas
                            </span>
                        </p>
                    </div>

                    <div *ngIf="!ignore_warning">
                        <div style="float:left;margin-right:10px;height:100px">  
                            <img src="assets/img/im blue.jpg" alt="Logo"
                                width="48px"
                                class="rounded-full border-2 pull-left border-blue-300" 
                            />
                        </div>

                        <div>                        
                            <h3 class="text-2xl font-bold text-leftn"> Um momento. </h3>
                            <p style="font-size:small;" class="opacity-80">
                                Parece que esta submiss&atilde;o não cumpre algumas recomenda&ccedil;&otilde;es.
                            </p>
                            <p style="font-size:small;" class="opacity-80">
                                Pode ignorar estas alertas e submeter, Mas isso pode causar inconveni&ecirc;ncias
                                durante o processo de cria&ccedil;&atilde;o do modelo de iva.
                            </p>
    
                            <p class="cursor-pointer mt-0 mb-1 flex "
                                (click)="IgnoreWarningsAndSubmit()">
                                <span class=" px-2 py-1 rounded-3xl text-[#00527E] bg-[#fff] m-2 ml-0 border border-[#00527E]"
                                    style="font-size:x-small">
                                    Ignorar &amp; Submeter
                                </span>
                            </p>
                        </div>

                        <div *ngFor="let W of warnings" class="mt-2">
                            <app-alert type="warning" [message]="W">
                            </app-alert>
                        </div>

                    </div>

                </div>
            </div>

            <div class="items-center">
                <p style="font-size:small;" for="supplier_name" class="mr-2">
                    Pasta
                    <span style="font-size: x-small;color: orangered;">*</span>
                </p>

                <input id="folder_name" 
                    formControlName="folder_name" 
                    type="text" 
                    required 
                    style="font-size:small;" 
                    class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                />

                <span style="font-size:xx-small;"
                    class="opacity-80">
                    O nome da pasta aonde vai ficar esta fatura, 
                    n&atilde;o pode exceder 200 caracteres, insens&iacute;vel a Mai&uacute;scula e Min&uacute;scula. Vai ser criada se n&atilde;o existir.
                </span>
            </div>

            <div class="" *ngIf="false">
                <!-- <div *ngIf="!is_created" class="border-[#00527E]">   -->
                <div class="border-[#00527E]">

                </div>
            </div>

            <div class="grid grid-cols-2" *ngIf="!is_created">

                <div class="grid-col p-4">
                    <div class="items-center">
                        <p style="font-size:small;" for="supplier_name" class="mr-2">
                            <span class="fill-{{editMap['supplier_name']}}">
                                <span class="ai-filled">
                                    <img src="assets/img/lua-magician.svg" width="14px" 
                                        style="height:14px;display:inline;" />
                                </span>
                                Desig. Fornecedor
                            </span>
                            <span style="font-size: x-small;color: orangered;">*</span>
                        </p>
                        <input id="supplier_name" 
                            formControlName="supplier_name" 
                            type="text" 
                            required 
                            style="font-size:small;" 
                            class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                        />
                        <span style="font-size:xx-small;"
                            class="opacity-80">
                            Designa&ccedil;&atilde;o / Nome / Raz&atilde;o Social
                        </span>
                    </div>

                    <div class=" items-center">
                        <p style="font-size:small;" for="supplier_nif" class="mr-2">
                            <span class="fill-{{editMap['supplier_nif']}}">
                            <span class="ai-filled">
                                <img src="assets/img/lua-magician.svg" width="14px" 
                                    style="height:14px;display:inline;" />
                            </span>
                            Fornecedor NIF
                            </span>
                            <span style="font-size: x-small;color: orangered;">*</span>
                        </p>
                        <input id="supplier_nif" 
                            formControlName="supplier_nif" 
                            type="text" 
                            required
                            style="font-size:small;" 
                            class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                        />
                        <span style="font-size:xx-small;"
                            class="opacity-80">
                            NIF deve conter no minimo 9 algarismos.
                        </span>
                    </div>

                    <div class="items-center">
                        <p style="font-size:small;" for="invoice_lang" class="mr-2">
                            <span class="fill-{{editMap['invoice_lang']}}">
                                <span class="ai-filled">
                                    <img src="assets/img/lua-magician.svg" width="14px" 
                                        style="height:14px;display:inline;" />
                                </span>
                            Idioma
                            </span>
                            <span style="font-size:xx-small;" class="opacity-80">Opcional</span>
                        </p>
                        <input id="invoice_lang" 
                            formControlName="invoice_lang" 
                            type="text" 
                            style="font-size:small;" 
                            class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                        />
                    </div>
                </div>

                <div class="grid-col p-4">
                    <div class="items-center">
                        <p style="font-size:small;"   for="customer_name" class="mr-2">
                            <span class="fill-{{editMap['customer_name']}}">
                                <span class="ai-filled">
                                    <img src="assets/img/lua-magician.svg" width="14px" 
                                        style="height:14px;display:inline;" />
                                </span>
                            Desig. Cliente
                            </span>
                            <span style="font-size: x-small;color: orangered;">*</span>
                        </p>
                        <input id="customer_name" 
                            formControlName="customer_name" 
                            type="text" 
                            required 
                            style="font-size:small;" 
                            class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                        />
                        <span style="font-size:xx-small;"
                            class="opacity-80">
                            Designa&ccedil;&atilde;o / Nome / Raz&atilde;o Social
                        </span>
                    </div>

                    <div class="items-center">
                        <p style="font-size:small;" for="customer_nif" class="mr-2">
                            <span class="fill-{{editMap['customer_nif']}}">
                                <span class="ai-filled">
                                    <img src="assets/img/lua-magician.svg" width="14px" 
                                        style="height:14px;display:inline;" />
                                </span>
                            Cliente NIF
                            </span>
                            <span style="font-size: x-small;color: orangered;">*</span>
                        </p>
                        <input id="customer_nif" 
                            formControlName="customer_nif" 
                            type="text" 
                            required 
                            style="font-size:small;" 
                            class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                        />
                        <span style="font-size:xx-small;"
                            class="opacity-80">
                            NIF deve conter no minimo 9 algarismos.
                        </span>
                    </div>
                </div>


                <div class="grid-col p-4">
                    <div class="items-center">
                        <p style="font-size:small;" for="invoice_date" class="mr-2">
                            <span class="fill-{{editMap['invoice_date']}}">
                                <span class="ai-filled">
                                    <img src="assets/img/lua-magician.svg" width="14px" 
                                        style="height:14px;display:inline;" />
                                </span>
                            Data da Fatura
                            </span>
                            <span style="font-size: x-small;color: orangered;">*</span>
                        </p>
                        <input id="invoice_date" 
                            formControlName="invoice_date" 
                            type="date" 
                            required 
                            style="font-size:small;" 
                            class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                        />
                        <span *ngIf="editMap['invoice_date']==1 && loadingCache && loadingCache['num']"
                            style="font-size:x-small"
                            class="opacity-80">
                            Preenchido com <u>{{loadingCache['num']['invoice_date']}}</u>
                        </span>
                    </div>
                    <div class="items-center mt-1">
                        <p style="font-size:small;" for="invoice_due_date" class="mr-2">
                            <span class="fill-{{editMap['invoice_due_date']}}">
                                <span class="ai-filled">
                                    <img src="assets/img/lua-magician.svg" width="14px" 
                                        style="height:14px;display:inline;" />
                                </span>
                            Data de Vencimento
                            </span>
                            <span style="font-size:xx-small;" class="opacity-80">Opcional</span>
                        </p>
                        <input id="invoice_due_date" 
                            formControlName="invoice_due_date" 
                            type="date" 
                            style="font-size:small;" 
                            class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                        />
                        <span *ngIf="editMap['invoice_due_date']==1 && loadingCache && loadingCache['num']"
                            style="font-size:x-small"
                            class="opacity-80">
                            Preenchido com <u>{{loadingCache['num']['invoice_due_date']}}</u>
                        </span>
                    </div>
                </div>

                <div class="grid-col p-4">
                    <div class="items-center">
                        <p style="font-size:small;" for="invoice_id" class="mr-2">
                            <span class="fill-{{editMap['invoice_id']}}">
                                <span class="ai-filled">
                                    <img src="assets/img/lua-magician.svg" width="14px" 
                                        style="height:14px;display:inline;" />
                                </span>

                            Nr da Fatura
                            </span>
                            <span style="font-size: x-small;color: orangered;">*</span>
                        </p>
                        <input id="invoice_id" 
                            formControlName="invoice_id" 
                            type="text" 
                            required 
                            style="font-size:small;" 
                            class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                        />
                        <span style="font-size:xx-small;"
                            class="opacity-80">
                            Identificador da Fatura presente no documento.
                        </span>
                    </div>
                    <div class="items-center">
                        <p style="font-size:small;" for="invoice_origin" class="mr-2">
                            <span class="fill-{{editMap['invoice_origin']}}">
                                <span class="ai-filled">
                                    <img src="assets/img/lua-magician.svg" width="14px" 
                                        style="height:14px;display:inline;" />
                                </span>

                            Origem
                            </span>
                            <span style="font-size: x-small;color: orangered;">*</span>
                        </p>
                        <input id="invoice_origin" 
                            formControlName="invoice_origin" 
                            type="text" 
                            required 
                            style="font-size:small;" 
                            class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                        />
                        <span style="font-size:xx-small;"
                            class="opacity-80">
                            C&oacute;digo ISO 3166-1 do Pa&iacute;s. Correspondente ao modelo 106.
                        </span>
                    </div>
                </div>


                <div class="grid-col p-4">
                    <!-- invoice_ref -->
                    <div class="items-center">
                        <p style="font-size:small;" for="invoice_ref" class="mr-2">
                            <span class="fill-{{editMap['invoice_ref']}}">
                                <span class="ai-filled">
                                    <img src="assets/img/lua-magician.svg" width="14px" 
                                        style="height:14px;display:inline;" />
                                </span>

                            Refer&ecirc;ncia
                            </span>
                            <span style="font-size:xx-small;"
                                class="opacity-80">
                            Opcional
                            </span>
                        </p>
                        <input id="invoice_ref" 
                            formControlName="invoice_ref" 
                            type="text" 
                            style="font-size:small;" 
                            class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                        />
                        <span style="font-size:xx-small;"
                            class="opacity-80">
                            Recomendado: Referência pode ser interna ou externa, ou mesmo valor que o Nr da Fatura
                        </span>
                    </div>
                </div>

                <div class="grid-col p-4">
                    <div class="items-center">
                        <p style="font-size:small;" for="payment_ref" class="mr-2">
                            <span class="fill-{{editMap['payment_ref']}}">
                                <span class="ai-filled">
                                    <img src="assets/img/lua-magician.svg" width="14px" 
                                        style="height:14px;display:inline;" />
                                </span>

                            Refer&ecirc;ncia de Pagamento   
                            </span>
                            <span style="font-size:xx-small;" class="opacity-80">Opcional</span>
                        </p>
                        <input id="payment_ref" 
                            formControlName="payment_ref" 
                            type="text" 
                            style="font-size:small;" 
                            class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                        />
                        <span style="font-size:xx-small;"
                            class="opacity-80">
                            Recomendado
                        </span>
                    </div>
                </div>



                <div class="grid-col p-4">
                    <div class="items-center">
                        <p style="font-size:small;" for="invoice_currency" class="mr-2">
                            <span class="fill-{{editMap['currency']}}">
                                <span class="ai-filled">
                                    <img src="assets/img/lua-magician.svg" width="14px" 
                                        style="height:14px;display:inline;" />
                                </span>

                            Moeda/Currency
                            </span>
                            <span style="font-size: x-small;color: orangered;">*</span>
                        </p>
                        <input id="invoice_currency" 
                            formControlName="currency" 
                            type="text" 
                            required
                            style="font-size:small;" 
                            class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                        />
                        <span style="font-size:xx-small;"
                            class="opacity-80">
                            CVE, EUR, USD, JPY, ...
                        </span>
                    </div>

                    <div class="items-center">
                        <p style="font-size:small;" for="invoice_subtotal" class="mr-2">
                            <span class="fill-{{editMap['invoice_subtotal']}}">
                                <span class="ai-filled">
                                    <img src="assets/img/lua-magician.svg" width="14px" 
                                        style="height:14px;display:inline;" />
                                </span>

                            Subtotal
                            </span>
                            <span style="font-size: x-small;color: orangered;">*</span>
                        </p>
                        <input id="invoice_subtotal" 
                            formControlName="invoice_subtotal" 
                            type="number" 
                            required
                            style="font-size:small;" 
                            class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                        />
                        <span style="font-size:xx-small;"
                            class="opacity-80">
                            Recomenda&ccedil;&atilde;o: Total da venda/compra sem Iva.
                            Subtotal n&atilde;o pode ser superior a Total.
                        </span>
                    </div>
                </div>

                <div class="grid-col p-4">
                    <div class="items-center">
                        <p style="font-size:small;" for="invoice_total" class="mr-2">
                            <span class="fill-{{editMap['invoice_total']}}">
                                <span class="ai-filled">
                                    <img src="assets/img/lua-magician.svg" width="14px" 
                                        style="height:14px;display:inline;" />
                                </span>
                            Total
                            </span>
                            <span style="font-size: x-small;color: orangered;">*</span>
                        </p>
                        <input id="invoice_total" 
                            formControlName="invoice_total" 
                            type="number" 
                            required
                            style="font-size:small;" 
                            class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                        />
                        <span style="font-size:xx-small;"
                            class="opacity-80 text-italic">
                            Sugestão: <i>Total = Subtotal + (Subtotal*Iva)</i>
                        </span>
                    </div>

                    <div class="items-center">
                        <p style="font-size:small;" for="invoice_iva" class="mr-2">
                            <span class="fill-{{editMap['invoice_iva']}}">
                                <span class="ai-filled">
                                    <img src="assets/img/lua-magician.svg" width="14px" 
                                        style="height:14px;display:inline;" />
                                </span>
                            Iva %
                            </span>
                            <span style="font-size: x-small;color: orangered;">*</span>
                        </p>
                        <input id="invoice_iva" 
                            formControlName="invoice_iva" 
                            type="number" 
                            required
                            style="font-size:small;" 
                            class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                        />
                        <span style="font-size:xx-small;"
                            class="opacity-80">
                            <i>0.15 = 15%</i>
                        </span>
                    </div>

                    <div class="items-center">
                        <p style="font-size:small;" for="invoice_discount" class="mr-2">
                            <span class="fill-{{editMap['invoice_discount']}}">
                                <span class="ai-filled">
                                    <img src="assets/img/lua-magician.svg" width="14px" 
                                        style="height:14px;display:inline;" />
                                </span>
                            Descontos
                            </span>
                            <span style="font-size: x-small;color: orangered;">*</span>
                        </p>
                        <input id="invoice_discount" 
                            formControlName="invoice_discount" 
                            type="number" 
                            [disabled]="true"
                            style="font-size:small;" 
                            class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                        />
                        <span style="font-size:xx-small;"
                            class="opacity-80">
                            <i>Campo de leitura</i>
                        </span>
                    </div>

                </div>
            </div>
        </div>

        <div class="grid-col" style="height:500px;overflow: hidden;background-color:#f4f4f4">

            <div *ngIf="previewPDF">
                <app-pdf-preview [pdfUrl]="previewPDF.preview_url">
                </app-pdf-preview>
            </div>
        </div>
    </div>

</form>

