import { Component, Input, OnInit } from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';


@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
})
export class TextComponent implements OnInit {
  @Input() content?: string[];
  @Input() typed?: boolean = false;
  typedContent: string = '';
  currentIndex: number = 0;

  ngOnInit() {
    if (this.content && this.typed !== true) {
      this.typeTextCharacterByCharacter();
    } else {
      this.typedContent = this.content![this.currentIndex];
    }
  }

  private typeTextCharacterByCharacter() {
    const text = this.content![this.currentIndex];
    this.typeTextRecursively(text, 0, this.typedContent);
  }
  constructor(
    private chatService: ChatService,
  
  ) {}

  private typeTextRecursively(
    text: string,
    index: number,
    currentText: string
  ) {
    if (index <= text.length) {
      this.typedContent = currentText + text.slice(0, index) + '|';
      index++;
      setTimeout(() => this.typeTextRecursively(text, index, currentText), 25);
    } else {
      this.typedContent = currentText + '\n' + text;
      this.currentIndex++;
      if (this.currentIndex < this.content!.length) {
        setTimeout(() => this.typeTextCharacterByCharacter(), 1000);
      } 
    }
  }
}
