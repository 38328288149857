<app-user-layout>
  <div *ngIf="isLoading" class="loading">Loading...</div>
  <div *ngIf="!isLoading">
    <h3 class="text-2xl font-bold text-left">Relatório de Contas a Pagar e Receber</h3>
    <div class="shadow-xl mt-4 p-10">
      <div class="bg-white border border-gray-200 rounded-lg p-4">
      <h3>Contas a Pagar</h3>
      <table>
        <thead>
          <tr>
            <th class="pb-4 pt-2 pl-2 rounded-tl-3xl rounded-bl-3xl text-left">Nome</th>
            <th>Montante (R$)</th>
            <th class="text-center rounded-tr-3xl rounded-br-3xl">Data de Vencimento</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let payable of accountsData.payables">
            <td>{{ payable.name }}</td>
            <td class="text-center">{{ payable.amount }}</td>
            <td class="text-center">{{ payable.dueDate }}</td>
          </tr>
        </tbody>
      </table>
      </div> 
    </div>
    <div class="shadow-2xl mt-4 p-10">
    <h3>Contas a Receber</h3>
    <table>
      <thead>
        <tr>
          <th class="pb-4 pt-2 pl-2 rounded-tl-3xl rounded-bl-3xl text-left">Nome</th>
          <th>Montante (R$)</th>
          <th class="text-center rounded-tr-3xl rounded-br-3xl">Data de Vencimento</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let receivable of accountsData.receivables">
          <td>{{ receivable.name }}</td>
          <td class="text-center">{{ receivable.amount }}</td>
          <td class="text-center">{{ receivable.dueDate }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  </div>
</app-user-layout>