import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

@Component({
    selector: 'app-invoice-ai-editor',
    templateUrl: './COMP.component.html',
})
export class InvoiceAIEditor implements OnInit {
    @Input() editObj:any = null;
    @Input() docObj:any = null;

    editorForm: any = null;
    is_loading:boolean = false
    error_msg:any = false
    resp_msg:any = false
    is_error:boolean = false
    is_ready:boolean = false
    is_created:boolean = false
    errors:any = null
    warnings:any = null
    ignore_warning:any = false
    editMap:any = {}

    is_generating:boolean = false;
    is_gen_error:boolean = false;
    resp_time:any = false;
    genReport:any = null;

    previewPDF:any = false

    constructor(
        private chatService: ChatService,
        private fb: FormBuilder
    ) {
    }

    genPDFPreview() {
        // if (this.is_run_preview || !this.currentDocOpen) {
        //     return;
        // }
        this.previewPDF=null;
        const fileid=this.docObj.fileid;
        const payload = {
            'ops' : 'GenPDFPreview',
            'fileid' : fileid,
        }
        this.chatService.ExecuteTask("lua/DocSystemAPI", payload, (doc_resp:any) => {
            // this.is_run_preview=false;
            // console.info("GenPDFPreview: ", doc_resp);
            if (doc_resp.Status=='OK') {
                this.previewPDF=doc_resp;
            } else {
                // this.is_preview_err=true;
            }
        }, (error:any) => {
            // this.is_run_preview=false;
            // this.is_preview_err=true;
            console.error("E: ", error)
        });
    }

    ngOnInit() {
        console.info("[InvoiceEditor] editObj: ", this.editObj)
        setTimeout(() => {
            const Lib:any = window;
            var today:any = '';
            if (Lib.moment) {
                today = Lib.moment().format("YYYY-MM-DD");
            }

            const Conf = {
                supplier_name: [null, Validators.required],
                supplier_nif: [null, Validators.required],
                customer_name: [null, Validators.required],
                customer_nif: [null, Validators.required],
                invoice_lang: ['PT', Validators.required],
                folder_name: ['default', Validators.required],
                supplier_id: ['', false], // entity_id
                customer_id: ['', false], // entity_id

                invoice_state: ['DRAFT', Validators.required],
                invoice_date: [today, Validators.required],
                invoice_due_date: [today, Validators.required],
                invoice_id: ['DOC_ID', Validators.required],
                invoice_origin: ['', Validators.required],
                invoice_ref: ['REF', Validators.required],
                payment_ref: ['PAYMENT_REF', Validators.required],

                invoice_discount: [0, Validators.required],
                invoice_total: [0, Validators.required],
                invoice_subtotal: [0, Validators.required],
                invoice_iva: [0.15, Validators.required],
                currency: ['CVE', Validators.required],
            }

            // Am doing this explicitly until i finished
            // the annimation on fields too.
            if (this.editObj && this.editObj.currency) {
                Conf.currency[0] = this.editObj.currency;
                this.editMap['currency']=1
            }
            if (this.editObj && this.editObj.invoice_ref) {
                Conf.invoice_ref[0] = this.editObj.invoice_ref;
                this.editMap['invoice_ref']=1
            }
            if (this.editObj && this.editObj.invoice_id) {
                Conf.invoice_id[0] = this.editObj.invoice_id;
                this.editMap['invoice_id']=1
            }
            if (this.editObj && this.editObj.payment_ref) {
                Conf.payment_ref[0] = this.editObj.payment_ref;
                this.editMap['payment_ref']=1
            }
            if (this.editObj && this.editObj.customer_nif) {
                Conf.customer_nif[0] = this.editObj.customer_nif;
                this.editMap['customer_nif']=1
            }
            if (this.editObj && this.editObj.invoice_iva) {
                Conf.invoice_iva[0] = this.editObj.invoice_iva;
                this.editMap['invoice_iva']=1
            }
            if (this.editObj && this.editObj.invoice_subtotal) {
                Conf.invoice_subtotal[0] = this.editObj.invoice_subtotal;
                this.editMap['invoice_subtotal']=1
            }
            if (this.editObj && this.editObj.invoice_total) {
                Conf.invoice_total[0] = this.editObj.invoice_total;
                this.editMap['invoice_total']=1
            }
            if (this.editObj && this.editObj.customer_name) {
                Conf.customer_name[0] = this.editObj.customer_name;
                this.editMap['customer_name']=1
            }

            if (this.editObj && this.editObj.supplier_name) {
                Conf.supplier_name[0] = this.editObj.supplier_name;
                this.editMap['supplier_name']=1
            }
            if (this.editObj && this.editObj.customer_id) {
                Conf.customer_id[0] = this.editObj.customer_id;
            }
            if (this.editObj && this.editObj.supplier_id) {
                Conf.supplier_id[0] = this.editObj.supplier_id;
            }

            if (this.editObj && this.editObj.invoice_lang) {
                this.editMap['invoice_lang']=1
                Conf.invoice_lang[0] = this.editObj.invoice_lang;
            }
            if (this.editObj && this.editObj.supplier_nif) {
                this.editMap['supplier_nif']=1
                Conf.supplier_nif[0] = this.editObj.supplier_nif;
            }
            if (this.editObj && this.editObj.invoice_date) {
                this.editMap['invoice_date']=1
                Conf.invoice_date[0] = this.editObj.invoice_date;
            }
            if (this.editObj && this.editObj.invoice_due_date) {
                this.editMap['invoice_due_date']=1
                Conf.invoice_due_date[0] = this.editObj.invoice_due_date;
            }

            if (this.editObj && this.editObj.invoice_origin) {
                this.editMap['invoice_origin']=1
                Conf.invoice_origin[0] = this.editObj.invoice_origin;
            }
            if (this.editObj && this.editObj.origin) {
                this.editMap['invoice_origin']=1
                Conf.invoice_origin[0] = this.editObj.origin;
            }

            this.editorForm = this.fb.group(Conf);
        }, 80);
    }

    IgnoreWarningsAndSubmit() {
        this.ignore_warning=true;
        this.warnings = [];
        this.OnSubmit();
    }

    AtivateAlerts() {
        this.ignore_warning=false;        
    }

    AddMore() {
        this.is_created=false;
        this.is_error = false;
        this.errors = false;
        this.warnings = false;
    }

    OnSubmit() {
        this.is_error = false;
        this.error_msg = false;
        this.resp_msg = false;
        this.errors = false;
        this.is_created = false;
        this.warnings = false;

        if (this.editorForm.valid) {
            const formData:any = this.editorForm.value;
            // console.info("formData: ", formData);
            var Errs:any = []; // have to please the compiler
            const Warns:any = []; // have to please the compiler
            const invoice_subtotal=formData.invoice_subtotal;
            const invoice_total=formData.invoice_total;
            const invoice_iva=formData.invoice_iva;
            const folder_name=formData.folder_name;

            if (invoice_subtotal > invoice_total) {
                Errs.push("Subtotal não pode ser superior a Total!");
            }
            if (formData.supplier_nif.length<9) {
                Errs.push("Nif do fornecedor contem menos de 9 algarismos");
            }
            if (formData.customer_nif.length<9) {
                Errs.push("Nif do cliente contem menos de 9 algarismos");
            }

            if (Errs.length>0) {
                this.errors=Errs;
                return
            }
            Errs = null; // make sure nobody else uses this

            if (formData.supplier_nif==formData.customer_nif) {
                Warns.push("Definiu o mesmo NIF para o cliente e o fornecedor");
            }
            if (formData.supplier_name==formData.customer_name) {
                Warns.push("Definiu o mesmo nome para o cliente e o fornecedor");
            }

            if (invoice_subtotal==0) {
                Warns.push("O Subtotal foi definido como zero.")
            }
            if (invoice_total==0) {
                Warns.push("O Total foi definido como zero.")
            }
            if (formData.invoice_id=='DOC_ID') {
                Warns.push("O Nr da Fatura não foi preenchido. Causará problemas no modelo de iva")
            }
            if (invoice_iva==0) {
                Warns.push("O IVA foi definido como zero.")
            }

            if (invoice_total>0 && invoice_subtotal==0) {
                Warns.push("Definiu o total em "+invoice_total+", mas o subtotal definido como zero.")
            }

            if (Warns.length>0) {
                this.warnings = Warns;
                if (!this.ignore_warning){
                    return
                }
            }

            this.is_error = false;
            this.is_created = false;
            this.is_loading = true;

            const payload = {
                operation: 'CreateInvoice',
                folder_name: folder_name,
                obj: formData,
            };

            this.chatService.ExecuteTask('core/LuaInvoiceService', payload, (doc_resp: any) => {
                setTimeout(() => {
                    this.is_loading = false;
                    if (doc_resp.Status=='OK') {
                        this.is_created = true;
                        this.resp_msg = doc_resp;
                    } else {
                        if (doc_resp.Errors&&doc_resp.Errors.length) {
                            this.errors = doc_resp.Errors;
                        }
                        this.is_error = true;
                    }
                }, 120);
            }, (error: any) => {
                this.is_error = true;
                this.is_loading = false;
                setTimeout(()=>{
                    if (error.Errors&&error.Errors.length) {
                        this.errors = error.Errors;
                    }
                }, 100);
            });

        } else {
            this.is_error=true;
        }
    }

    now() {
        const Lib:any = window;
        return Lib.moment().format("HH:mm:ss");
    }

    loadingCache:any = {}
    RunGenTask(TaskName:any, K:any) {
        this.loadingCache[K] = {
            'S' : "LOADING"
        }

        const payload:any = {
            'operation' : 'GenerateInvoiceAnalysis',
            'qobj' : {
                'extras' : {
                    'fileid' : this.docObj.fileid,                        
                }
            }
        }

        this.chatService.ExecuteTask(TaskName, payload, (doc_resp:any) => {
            setTimeout(() => {            
                if (doc_resp.Status=="OK") {
                    this.loadingCache[K] = {
                        'S' : "OK"
                    }
                    if (doc_resp.invoiceObj) {
                        const Keys = Object.keys(doc_resp.invoiceObj);
                        this.editorForm.patchValue(doc_resp.invoiceObj);
                        Keys.forEach((FK:any) => {
                            this.editMap[FK]=1;
                            this.loadingCache[K][FK]=doc_resp.invoiceObj[FK];
                        });
                    }
                } else {
                    this.loadingCache[K] = {
                        'S' : "ERROR"
                    }
                }
            }, 569);

        }, (error:any) => {
            this.loadingCache[K] = {
                'S' : "ERROR"
            }
        })
    }

    runGenerate() {
        this.is_gen_error=false;
        this.is_generating=true;
        this.genReport=null;
        this.resp_time='';
        this.editMap = {};

        const payload:any = {
            'operation' : 'GenerateInvoiceAnalysis',
            'qobj' : {
                'extras' : {
                    'fileid' : this.docObj.fileid,                        
                }
            }
        }

        // const TaskName="lua/InvoiceAnalysisService";
        const TaskName="lua/InvoiceNumAnalysis";

        this.chatService.ExecuteTask(TaskName, payload, (doc_resp:any) => {
            // console.info("doc_resp: ", doc_resp);
            setTimeout(() => {            
                this.is_generating=false;
                this.resp_time = this.now()
                this.genReport = doc_resp;

                if (doc_resp.Status=="OK") {
                    if (doc_resp.invoiceObj) {
                        const Keys = Object.keys(doc_resp.invoiceObj);
                        this.editorForm.patchValue(doc_resp.invoiceObj);
                        Keys.forEach((K:any) => {
                            this.editMap[K]=1;
                        });
                    }
                } else {
                    this.is_gen_error=true;
                }
            }, 569);

        }, (error:any) => {
            this.is_generating=false;
            this.is_gen_error=true;
        })
    }


    Execute(task:any, payload:any) {
    }
}

