import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  modulesExtracted:boolean | null = null
  constructor(
    private router:Router,
    ) {}

  async canActivate(modules: string[]) {
    var buf:any = localStorage.getItem("AUTH_CREDS");
    var pk:any = false;
    if (buf!=null) {
        pk = JSON.parse(buf)
    
    return true;  }
    if (!pk) {
        this.router.navigate(['/welcome']);
return false;
    }
return false; 
   
  }
  
 
  
}

export const ModuleGuard: CanActivateFn = (route:ActivatedRouteSnapshot, state:RouterStateSnapshot) => {
  return inject(AuthGuard).canActivate(route.data['modules']);
};