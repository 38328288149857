<div class="input-container w-full  {{chatData.classContainer}} ">
  <div >
  
    <input type="text" (keydown)="handelKeyDown($event)" placeholder="Inicie a conversa..."
      class="bg-white border-solid border border-[#BBCAD2] rounded-full text-black !w-4/5 h-[4.254rem] ps-5 pe-16 focus:outline-none duration-150 {{chatData.classInput}} "
      (input)="onInputChange($event)" [value]="inputValue" />
    <div class="input-icon">
      <input  type="file" class="hidden" #file (change)="uploadFile($event)" />
      <img class="h-[1.25rem] w-[1.25rem] md:mr-4" (click)="file.click()" src="assets/img/Vector.svg" alt="" />
    </div>
  </div>
  <div class="input-icon-right">

  </div>
</div>