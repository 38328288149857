<app-mobile-menu [dark]="true" [sideMenu]="sideMenu"></app-mobile-menu>

<div class="flex justify-center" [ngClass]="{
    'bg-image': url === 'chat' || url === 'luaxapps',
    'bg-white': url !== 'chat' && url !== 'luaxapps'
}">
    <!-- NAVBAR -->
    <div class="fixed top-0 left-28 right-0 flex items-center justify-between h-[70px] z-20 px-4 md:px-5"
        style="box-shadow:0px 0px 5px rgba(0,0,0,.3);background:#fff;" 
        [ngClass]="{ 'bg-white': url !== 'chat' && url !== 'luaxapps' }">
        <div class="flex-1 p-4 cursor-pointer"
            (click)="goHome()">
            <div style="height:40px;display:inline;border-right: 1px solid #f4f4f4;padding-right: 10px;">
                <img src="assets/img/SINTAXY.svg" width="90px" style="display:inline" alt="Logo" />
            </div>

            <div style="width:40px;display:inline;padding-left: 10px;"
                class="opacity-90">
                <p style="font-size:x-small;font-weight:bold;display:inline;">
                    <img src="assets/img/lua/LuaAIPreto.png" width="60px" style="display:inline" alt="Logo" />
                    <!-- <br/>Powered By -->
                </p>
            </div>
        </div>

        <div class="flex items-center pr-20" style="padding: 2px;background: #5BACAD1F;border-radius: 40px;margin-left: 4px;padding-right: 11px;padding-left: 6px;">
            <!-- Imagem do Perfil -->
            <img class="rounded-full w-10 h-10 border-2 border-blue-300" 
                src="assets/img/im blue.jpg" alt="Profile Image" 
            />

            <div class="relative ml-4" *ngIf="authUser">
                <div class="items-center cursor-pointer" 
                    (click)="toggleDropdown()">
                    <p class="p-0" style="font-size:small;font-weight: bold;">
                        <span class="ml-2 text-gray-700">{{ authUser.name.toUpperCase() }}</span>
                    </p>
                    <p class="p-0 opacity-80" style="font-size:x-small">
                        <span class="ml-2 text-gray-700">{{ authUser.email }}</span>
                        <img class="ml-2 transition-transform duration-200" 
                            [ngClass]="dropdownOpen ? 'rotate-180' : 'rotate-0'" 
                            src="assets/img/icon/chevron_down.svg" 
                            alt="Dropdown Icon"
                            style="display:inline" />
                    </p>
                </div>

                <div *ngIf="dropdownOpen" 
                    style="min-width:400px;" 
                    class="absolute right-0 mt-2  bg-white shadow-lg rounded-md">
                    <ul class="py-1">
                        <li>
                            <div class="grid grid-cols-1">
                                <div class="grid-col mb-2">
                                    <p class="p-0" style="font-size:small">
                                        <span class="ml-2 text-gray-700">{{ authUser.name.toUpperCase() }}</span>
                                    </p>
                                    <p class="p-0 mb-1" style="font-size:x-small">
                                        <span class="ml-2 text-gray-700">{{ authUser.email }}</span>
                                    </p>
                                    <hr/>
                                </div>

                                <div class="grid-col mt-1">
                                    <div class="grid grid-cols-2">
                                        <div class="grid-col">
                                        </div>
                                        <div class="grid-col">
                                            <ul>
                                                <li>
                                                    <a href="/my-account" class="block px-4 py-2 text-gray-700 hover:bg-gray-100">Minha conta</a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0)" 
                                                        (click)="logout()"
                                                        class="block px-4 py-2 text-gray-700 hover:bg-gray-100">Logout</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- Ícone de Notificações -->
            <div>            
                <img class="ml-4 cursor-pointer w-6 h-6" src="assets/img/icon/Notif.svg" alt="Notification Icon" />
            </div>
            

            <div>            
                <!-- <img class="ml-4 cursor-pointer w-6 h-6" src="assets/img/icon/Notif.svg" alt="Notification Icon" /> -->
                <img src="assets/img/icon/luabi.svg" alt="Lua" class="w-8 h-8" />
            </div>
        </div>
        <div class="ml-4">
            <label class="inline-flex items-center cursor-pointer">
                <input type="checkbox" class="hidden" (change)="toggleTheme()" [checked]="isDarkMode()" />
                <span class="relative">
                    <span class="block w-10 h-6 bg-gray-200 rounded-full shadow-inner"></span>
                    <span class="absolute  w-4 h-4 bg-white border rounded-full shadow inset-y-0 left-0 transition-transform duration-300 ease-in-out"
                        [ngStyle]="{ 'transform': isDarkMode() ? 'translateX(1.5rem)' : 'translateX(0)' }">
                    </span>
                </span>
            </label>
        </div>
        
    </div>

    <!-- Side Menu para telas md e maiores -->
    <div class="fixed top-0 left-0 h-screen overflow-y-auto bg-[#F5F5F5] hidden md:flex flex-col pt-7 px-7 text-black z-30"
        [ngStyle]="{ width: sidebarExpanded ? '160px' : '80px' }">
        <!-- Itens do Menu -->
        <ng-container>
            <div class="hover:scale-105 active:scale-90 transform ease-in-out flex items-center cursor-pointer mb-6">
                <div class="text-[#00527E]" (click)="goHome()">                
                    <img src="assets/img/lua-magician.svg" class="text-[#00527E]" 
                        alt=""
                        height="20px" 
                        style="height:25px;max-height:25px" />
                    <div style="margin-top:-8px;margin-left:0px">                    
                        <img src="assets/img/lua-magician.svg" class="text-[#00527E]" 
                            alt=""
                            style="height:10px;display:inline;max-height:10px"  />
                        <img src="assets/img/lua-magician.svg" class="text-[#00527E]" 
                            alt=""
                            style="margin-left: 4px;height:10px;display:inline;max-height:10px"  />
                    </div>
                </div>

                <div *ngIf="sidebarExpanded" [ngClass]="{
                    'text-[#004E7E]': sidebarExpanded,
                    'text-[#000]': !sidebarExpanded,
                    }" class="ml-2 text-[14px] font-normal">
                    Lua AI
                </div>
            </div>
            
        </ng-container>

        <ng-container *ngFor="let item of sideMenu; let i = index">
            <div class="hover:scale-105 active:scale-90 transform ease-in-out flex items-center cursor-pointer mb-6"
                [routerLink]="item.route">
                <img [src]="item.route !== '/' + url ? item.baseIcon : item.activeIcon" alt="" style="max-height:20px" />
                <div *ngIf="sidebarExpanded" [ngClass]="{
                    'text-[#004E7E]': item.route === '/' + url||item.showColor,
                    'text-[#000]': item.route !== '/' + url
                    }" class="ml-2 text-[14px] font-normal">
                    {{ item.title }}
                </div>
            </div>
        </ng-container>

        <!-- Botão de Logout -->
        <div class="mt-auto flex flex-col items-center gap-4 cursor-pointer mb-5">
            <button class="text-sm" (click)="logout()">
                <img src="assets/img/logout.svg" alt="Logout" />
                <span *ngIf="sidebarExpanded">Logout</span>
            </button>
        </div>
    </div>

    <!-- Botão para expandir/retrair o menu -->
    <div class="fixed top-[90px] z-30 hidden md:block" [ngStyle]="{
        left: sidebarExpanded ? 'calc(160px - 10px)' : 'calc(80px - 10px)',
        transform: 'translateY(-50%)'
        }" (click)="toggleSidebar()">
        <img *ngIf="sidebarExpanded" src="assets/img/icon/close-icon.svg" alt="Close Sidebar" />
        <img *ngIf="!sidebarExpanded" src="assets/img/icon/open-icon.svg" alt="Open Sidebar" />
    </div>

    <!-- Main Content -->
    <div [ngStyle]="{ 'margin-left': sidebarExpanded ? '160px' : '80px' }" 
        class="w-full relative z-10">
        <div class="mt-[70px] relative md:mt-[10px] md:pl-[72px] md:pt-[88px] md:pr-[72px] p-5 w-full max-w-screen-2xl mx-auto text-black">
            <ng-content></ng-content>
        </div>
    </div>
</div>