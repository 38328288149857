import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ChatService } from '../../../services/chat.service';

@Component({
    selector: 'app-dpr-106-rosto',
    templateUrl: './form-v2.component.html',
    styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnDestroy {
    anexoForForm: FormGroup;
    @Input() xmlReport: any;
    resposta: any; // Variável para armazenar a resposta
    mostrarFormulario: boolean = false;
    reportsTotals:any = {}

    is_running_rep:boolean = false;
    is_loading:boolean = false
    error_msg:any = false
    resp_msg:any = false
    is_server_error:boolean = false

    is_error:boolean = false
    is_ready:boolean = false
    is_created:boolean = false
    update_job:any = null
    count_rows:any = null;

    counters:any = {
        R32: 0,
        R33: 0,
        R34: 0,
        R35: 0,
        R38: 0,
        R36: 0, // apenas preenchivel dentro do prazo legal
    }

    constructor(
        private fb: FormBuilder,
        private chatService: ChatService 
    ) {
        const FMap:any = {
            anex_dec: [1, Validators.required],
            anex_cli: [true, Validators.required],
            anex_for: [true, Validators.required],
            cli_reg: [false, Validators.required],
            for_reg: [false, Validators.required],

            nif: ['270149503', Validators.required],
            cd_af: ['223', Validators.required],
            periodo_ano: ['2024', Validators.required],
            periodo_mes: ['08', Validators.required],

            exist_oper: [1, Validators.required],
            dt_apresentacao: [null, false],
            loc_apresentacao: ['Praia', Validators.required],

            cpRows: this.fb.array([]),

            nif_toc: [null, Validators.required],
            num_ordem_toc: ['0001', Validators.required],

            dt_recepcao: [null, false],
            obs: ['', false],

            // alt_cadastro: [0, Validators.required],
        }

        const count_rows = [];

        for(var i=0; i<50; i++){
            const name:any = "R"+(i+1);
            FMap[name] = [0, Validators.required];
            count_rows.push(1);
        }

        this.count_rows=count_rows;
        this.anexoForForm = this.fb.group(FMap);
    }

    ngOnInit() {
        setTimeout(()=>{        
            const Lib:any = window;
            const year:any = Lib.moment().format("YYYY");
            const month:any = Lib.moment().format("MM");
            const today:any = Lib.moment().format("YYYY-MM-DD");

            // const FMap:any = {
            //     anex_dec: [1, Validators.required],
            //     anex_cli: [true, Validators.required],
            //     anex_for: [true, Validators.required],
            //     cli_reg: [false, Validators.required],
            //     for_reg: [false, Validators.required],

            //     nif: ['270149503', Validators.required],
            //     cd_af: ['223', Validators.required],
            //     periodo_ano: [year, Validators.required],
            //     periodo_mes: [month, Validators.required],

            //     exist_oper: [1, Validators.required],
            //     dt_apresentacao: [null, false],
            //     loc_apresentacao: ['Praia', Validators.required],

            //     cpRows: this.fb.array([]),

            //     nif_toc: [null, Validators.required],
            //     num_ordem_toc: ['0001', Validators.required],

            //     dt_recepcao: [null, false],
            //     obs: ['', false],

            //     // alt_cadastro: [0, Validators.required],
            // }

            // const count_rows = [];

            // for(var i=0; i<50; i++){
            //     const name:any = "R"+(i+1);
            //     FMap[name] = [0, Validators.required];
            //     count_rows.push(1);
            // }

            // this.count_rows=count_rows;
            // this.anexoForForm = this.fb.group(FMap);
            this.mostrarFormulario = true;
        }, 80);

        // if (this.xmlReport) {
        // }

        this.update_job = setInterval(()=>{
            this.Reports();
        }, 1000);
    }

    ngOnDestroy() {
        if (this.update_job!=null) {
            clearInterval(this.update_job);
        }
    }

    populateForm(data: any) {
    }

    get linhas():any {
        return this.count_rows;
    }

    addLinha() {
    }

    removeLinha(index: number) {
    }

    onChangeField() {
    }

    fti(fieldname:any) {
        const formDataVal = this.anexoForForm.value;
        // console.info("formDataVal: ", formDataVal);
        return formDataVal[fieldname];
    }

    Reports() {
        if (this.is_running_rep) {
            return;
        }

        this.is_running_rep = true;
        // this.reportsTotals = null;
        // serie: string;
        // nao_liq_imp: string;
        // iva_liq: number;

        setTimeout(()=>{
            try {
                this.counters.R32 = this.fti('R1')+this.fti('R3')+
                        this.fti('R5') + this.fti('R7') +
                        this.fti('R8') + this.fti('R9') +
                        this.fti('R10') + this.fti('R11') +
                        this.fti('R14') + this.fti('R17') +
                        this.fti('R19') + this.fti('R21') +
                        this.fti('R23') + this.fti('R25');

                this.counters.R33 = this.fti('R12')+this.fti('R15')+
                        this.fti('R18') + this.fti('R20') +
                        this.fti('R22') + this.fti('R24') +
                        this.fti('R26') + this.fti('R27') +
                        this.fti('R29');

                this.counters.R34 = this.fti('R2')+this.fti('R4')+
                        this.fti('R6') + this.fti('R13') +
                        this.fti('R16') + this.fti('R28') +
                        this.fti('R30');

                const R31:any = this.fti('R31');
                var R34:any = this.counters.R34; 
                var R33:any = this.counters.R33;

                this.counters.R35=0;
                this.counters.R36=0;
                this.counters.R38=0;
                this.counters.R39=0;
                const R37:any=this.fti('R37');

                if (R34 > R33) {
                    this.counters.R35 = R34-R33;
                    if (this.counters.R35<R37) {
                        this.counters.R39 = R37-this.counters.R35
                    } else {
                        this.counters.R38 = this.counters.R35-R37;
                    }

                } else if (R33 > R34) {
                    this.counters.R36 = R33-R34;
                    this.counters.R39 = this.counters.R36+R37;
                }
            } catch (e) {
                // e

            } finally {
                this.is_running_rep = false;                
            }

        }, 320)

    }

    totalOf(group:any) {
        return 0;
    }

    onSubmit() {
        this.is_error = false;
        this.is_created = false;
        this.error_msg = false;
        this.resp_msg = false;
        this.is_server_error = false;
        this.resposta = null;

        if (this.anexoForForm.valid) {
            this.is_loading = true;
            const formData = this.anexoForForm.value;
            // const formDataVal = this.anexoForForm.value;
            const payload:any = {
                values: formData,
                docname: this.xmlReport.docname,
                groupname: this.xmlReport.groupname,
            };

            Object.keys(this.counters).forEach((K:any) => {
                payload.values[K] = this.counters[K];
            })

            this.chatService.ExecuteTask("dpr/XMLProcRosto", payload, (response: any) => {
                this.is_loading=false;
                if (response.Status=="OK") {
                    this.is_created=true;
                    this.resposta = response; 
                    this.resp_msg = response.answer || false;
                    this.mostrarFormulario = false; 
                } else {
                    // this.is_error=true;
                    this.is_server_error=true;
                    this.error_msg="Erro ao submeter formulário";
                }

            }, (error: any) => {
                this.is_loading=false;
                this.is_error=true;
                console.error('Erro ao submeter formulário', error);
            });

        } else {
            this.is_server_error=true;
        }
    }

}
