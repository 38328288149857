import { Component } from '@angular/core';

@Component({
  selector: 'app-lua-entertain',
  templateUrl: './lua-entertain.component.html',
  styleUrls: ['./lua-entertain.component.scss']
})
export class LuaEntertainComponent {

}
