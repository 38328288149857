<div class="container mx-auto p-4">
  <table class="table-auto w-full bg-white border border-gray-200 rounded-lg shadow-md">
    <thead>
      <tr class="text-gray-800 bg-gray-100">
        <th class="px-4 py-2">Nome do Contrato</th>
        <th class="px-4 py-2">Data do Último Negócio</th>
        <th class="px-4 py-2">Preço de Exercício</th>
        <th class="px-4 py-2">Último Preço</th>
        <th class="px-4 py-2">Lance</th>
        <th class="px-4 py-2">Oferta</th>
        <th class="px-4 py-2">Variação</th>
        <th class="px-4 py-2">% Variação</th>
        <th class="px-4 py-2">Volume</th>
        <th class="px-4 py-2">Open Interest</th>
        <th class="px-4 py-2">Volatilidade Implícita</th>
      </tr>
    </thead>
    <tbody class="bg-blue-300 bg-opacity-15">
      <tr *ngFor="let option of options" class="border-b border-gray-200">
        <td class="px-4 py-2">{{ option.nomeContrato }}</td>
        <td class="px-4 py-2">{{ option.dataUltimoNegocio }}</td>
        <td class="px-4 py-2">{{ option.precoExercicio | number:'1.2-2' }}</td>
        <td class="px-4 py-2">{{ option.ultimoPreco | number:'1.2-2' }}</td>
        <td class="px-4 py-2">{{ option.lance | number:'1.2-2' }}</td>
        <td class="px-4 py-2 text-green-500">{{ option.oferta | number:'1.2-2' }}</td>
        <td class="px-4 py-2">{{ option.variacao | number:'1.2-2' }}</td>
        <td class="px-4 py-2 text-green-500">{{ option.porcentagemVariacao | number:'1.2-2' }}%</td>
        <td class="px-4 py-2">{{ option.volume }}</td>
        <td class="px-4 py-2">{{ option.openInterest }}</td>
        <td class="px-4 py-2">{{ option.volatilidadeImplicita | number:'1.2-2' }}%</td>
      </tr>
    </tbody>
  </table>
</div>
