<style type="text/css">
    .chat-container h1 ,
    .chat-container h2,
    .chat-container h3,
    .chat-container h4,
    .chat-container h5,
    .chat-container h6
    {
        font-weight: bold;
    }

    a {
        color:  blue;
    }

    .chat-container p {
        line-height: 1;
        margin-bottom: 1px;
    }

    .chat-container small {
        font-size:small;
        margin-bottom: 1px;
    }

    .chat-container ul {
        margin-bottom: 1px;
    }

</style>
<div style="height:calc(100vh - 251px);overflow: hidden;overflow-y: scroll;">

<div style="" class="pr-5 pl-5 chat-container">

<div #chatContainer *ngFor="let item of content, let i = index" 
    class="{{item.type == 'you'?'flex justify-end':''}}
.run-animation ">

    <ng-container *ngIf="item.content">
        <app-card-chat [content]="content" [index]="i" [ogText]="item.content" [type]="item.type"
            [text]="item.type == 'you' ? item.content : item.type == 'module-list'? 
    'Yieldvestor offers easy-to-follow courses for those interested in learning more about fixed-income investments.': null"
            [prompts]="item?.prompts?.length > 0 ? item.prompts : item?.suggestions?.length > 0?item.suggestions:null"
            [iconRightUrl]="item.type == 'you' ? item.perfil : ''" [CardChatData]="{
      cardClass:
        item.type == 'you' ? 'you-background rounded-tr-none' : 'bg-[#F2F2F2] w-[70%]' 
    }">
            <ng-container *ngIf="item.type == 'text'">
                <app-text *ngIf="item.type == 'text'" 
                    [content]="item.content.text?[item.content.text]:item.content"
                    [typed]="item.typed" />
                <div *ngIf="item.isqa_list">
                    <p class="pl-4 cursor-pointer" 
                        *ngFor="let QA of item.qalist"
                        (click)="openQA(QA)">
                        * {{ QA['question'] }}
                    </p>
                    <p style="font-size:x-small" class="opacity-70">Choose a question</p>
                </div>
            </ng-container>

            <ng-container *ngIf="item.type == 'html'"> 
                <div [innerHTML]="item.content.text"></div>
            </ng-container>

            <!--CHART VIEWS-->


            <ng-container *ngIf="item.type == 'sceenario'">
                <div class="overflow-auto">
                    <div class="min-w-[200%] md:min-w-0">
                        <!-- <app-sceenario [content]="item.content" /> -->
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="item.type == 'scatter'">
                <div class="overflow-auto">
                    <div class="min-w-[200%] md:min-w-0">
                        <!-- <app-scatter-chart></app-scatter-chart> -->
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="item.type == 'model'">
                <!-- <app-model></app-model> -->
            </ng-container>

        </app-card-chat>
    </ng-container>
</div>

</div>


</div>
<!-- <div *ngIf="!widget" class="h-[30vh]"></div> -->