import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

@Component({
  selector: 'app-doc-report-form',
  templateUrl: './fatura-details.component.html',
})
export class FaturaDetailsComponent {
    @Input() report : any;
    @Input() groupname: any;
    @Input() fileid: any;
    @Input() OnSubmit:any = null;

    reportObj:any = null
    rowList:any[] = []
    editorForm: any;
    is_form_failed:boolean = false;
    is_loading:boolean = false;
    is_invalid:boolean = false;
    is_done:boolean = false;
    error_msg:any = false

    is_created:any = false;
    is_creating:any = true;
    is_error:any = false;


    constructor(
        private fb: FormBuilder,
        private chatService: ChatService) 
    {
    }

    ngOnInit() {
        // editorForm
        // if (this.report.R) {
        //     this.reportObj = this.report;
        // } else {        
        //     const keys:any = Object.keys(this.report);
        //     if (this.report[groupName]) {
        //         this.reportObj = this.report[groupName];
        //     } else if (keys>0) {
        //         this.reportObj = this.report;
        //     }
        // }
        // if (this.reportObj) {
        //     this.reportObj.R = this.reportObj.R.filter((X:any) => !X.key.startsWith("K00"))
        // }
        // console.info("report: ", this.report)
        this.InitForm()
    }

    InitForm() {
        const groupName:any = this.groupname;
        const report:any = this.report;
        this.is_form_failed=false;

        try{            
            // console.info("this.report: ", report, groupName);
            const Rows:any = report.extras.output.obj.R;
            const fieldGroup:any ={}
            const rowFields:any = [];

            Rows.forEach((Row:any) => {
                if (Row.key.startsWith("K00")) {
                    return;
                }

                const formField:any = Row.key
                    .replaceAll("\"", '')
                    .trim()
                    .replaceAll("'", "");

                var rValue = Row[Row.key];
                if (rValue) {
                    rValue = rValue.trim()
                        .replaceAll("<unknown>", "")
                }

                // fieldGroup[formField] = [Row[Row.key], Validators.required];
                // fieldGroup[formField] = [rValue, Validators.required];
                fieldGroup[formField] = [rValue, false];
                rowFields.push({
                    Field: formField,
                    Label: Row.key,
                    InputType: Row.input_type ?? 'text',
                    Value: rValue,
                });
            });

            const bigForm = this.fb.group(fieldGroup);
            this.editorForm = bigForm;
            this.rowList = rowFields;

        } catch (e) {
            this.is_form_failed=true;
        }
    }

    SubmitForm() {
        if (!this.editorForm) {
            return;
        }

        if (this.editorForm.valid) {
            const formData:any = this.editorForm.value;
            const payloadData:any = {}

            this.rowList.forEach((X:any) => {
                payloadData[X.Field] = formData[X.Field]
            });

            const payload = {
                ops : 'UpdateDocReport',
                payload: payloadData,
                fileid: this.fileid,
                groupname: this.groupname
            }

            this.is_loading = true;
            this.is_error = false;
            this.chatService.ExecuteTask("lua/DocSystemAPI", payload, (doc_resp:any) => {
                this.is_loading=false;
                setTimeout(() => {
                    if (doc_resp.Status=="OK") {
                        if (this.OnSubmit) {
                            this.OnSubmit({
                                groupname: this.groupname,
                                fileid: this.fileid,
                                payload: payloadData,
                            })
                        }
                    } else {
                        this.is_error=true;
                    }
                }, 430);

            }, (error:any) => {
                this.is_loading=false;
            });

        } else {
            // this.error_msg
            this.is_invalid = true;
        }
    }
  
    ngAfterViewChecked() {
    }
}
