import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-budget-vs-actual-report',
  templateUrl: './budget-vs-actual-report.component.html',
  styleUrls: ['./budget-vs-actual-report.component.scss'],
})
export class BudgetVsActualReportComponent implements OnInit {
  isLoading: boolean = true;
  budgetData: any = null;

  constructor() {}

  ngOnInit() {
    this.fetchBudgetData();
  }

  fetchBudgetData() {
    setTimeout(() => {
      this.budgetData = {
        items: [
          { description: 'Revenue', budgeted: 200000, actual: 180000 },
          { description: 'Expenses', budgeted: 150000, actual: 145000 },
          { description: 'Net Profit', budgeted: 50000, actual: 35000 },
        ],
      };
      this.isLoading = false;
    }, 1000);
  }
}
