<div class="flex flex-row justify-between "
    *ngIf="currentQueue===null">
    <div>
        <p class="sm:-mt-2 md:-mt-3 mb-3 md:-ml-1" (click)="goHome()">
            <span
            class="cursor-pointer text-sm  font-bold text-[#00527E] m-2 flex flex-row  ">
            <img src="assets/img/icon/Group.svg " class="w-4 h-4 mr-1"/>
            Voltar
            </span>
        </p>
        <h3 class="text-2xl font-bold text-left mb-3 "> Email Inbox </h3>
    </div>

    <div *ngIf="currentInbox === null" class="flex justify-between items-center mb-4">
        <div class="flex space-x-3">
            <p class="cursor-pointer flex flex-row mt-1 mb-1 px-4 py-2 rounded-3xl text-[#00527E] m-2 border border-[#00527E] " (click)="Refresh()">
                <img src="assets/img/icon/Arrows.svg" 
                    alt="" 
                    class="w-5 h-5" 
                /> Actualizar
            </p>

            <!--
            <button
                class="border p-2 rounded-full bg-[#2571a1] text-white border-[#5BACAD] flex items-center"
                (click)="onRefreshDocList()">
                Carregar
            </button>
            <p class="  px-4 py-2 rounded-3xl  text-[#fff] bg-[#00527E]  border border-[#fff]  ">
                <span class="cursor-pointer  text-sm   m-2 justify-center flex flex-row"
                    (click)="fileobj.click()">
                    <input type="file" class="hidden" #fileobj (change)="uploadDoc($event)" />
                    <img src="assets/img/Vectorwhite.svg" class="w-5 h-5 mr-2"/>
                    Carregar documento
                </span>
            </p>
            -->
        </div>

        <div class="flex space-x-5">
        </div>
    </div>
</div>


<div class="w-full">
    <!--
    <div class="tab-header flex  flex-rowborder-gray-300 justify-between">
        <div class="tab-header-item cursor-pointer py-2 px-8 rounded-3xl bg-[#00527E] text-white mb-4">
            Filas (RQueue)
        </div>
    
        <div>
            <p class="cursor-pointer mt-1 mb-1 rounded-3xl py-2 px-6 text-[#00527E] m-2 border border-[#00527E]  " 
                (click)="LoadQueue()" *ngIf="currentQueue==null">
                Actualizar
            </p>
        </div>
    </div>
    -->

    <!-- VIEW RQUEUE -->
     
    <div class=" p-2  md:p-10 border border-gray-300 rounded-xl shadow-xl"
        *ngIf="currentQueue!==null">

        <div class="grid grid-cols-1">

            <div class="grid-col mt-2 mb-3">
                <div class="header">
                    <p class="sm:-mt-2 md:-mt-3 mb-3 md:-ml-1" (click)="closeCurrentQueue()">
                        <span
                        class="cursor-pointer text-sm  font-bold text-[#00527E] m-2 flex flex-row  ">
                        <img src="assets/img/icon/Group.svg " class="w-4 h-4 mr-1"/>
                        Fechar
                        </span>
                    </p>

                    <div class="invoice-info">
                        <h2 class="text-left opacity-70" style="font-size:small">
                            Inbox
                        </h2>
                        <h1 class="font-semibold text-2xl pb-1">
                            {{ currentQueue['Name'] }}
                        </h1>
                    </div>
                </div>

                <p class="mt-2 mb-1">
                    <span (click)="PeekQueue(currentQueue)"
                        class="cursor-pointer text-sm p-2 rounded-xl font-bold text-[#2571a1] m-2 border border-[#2571a1] bg-[#fff]">
                        Actualizar
                    </span>
                </p>

                <div>
                    <app-alert type="info" message="Carregando detalhes"
                        *ngIf="currentQueue.is_peeking">
                    </app-alert>
                    <app-alert type="error" message="Nao foi possivel carregar os objectos"
                        *ngIf="currentQueue.is_peek_error">
                    </app-alert>
                </div>
            </div>

            <div class="grid-col mt-1 mb-3" *ngIf="!currentQueue.objectStream || currentQueue.objectStream===null">
                <p class="">
                    Sem objects ainda.
                </p>
            </div>

            <div class="grid-col mt-5 p-5 bg-[#f4f4f4]"
                *ngIf="currentQueue.objectStream">

                <p *ngIf="currentQueue.objectStream.Resp.length==0" class="p-2 opacity-70">
                    Nenhum objecto encontrado.
                </p>
                
                <p *ngIf="currentQueue.objectStream.Resp.length>0"  class="p-2 opacity-70">
                    {{currentQueue.objectStream.Resp.length}}
                    objectos na queue.
                </p>
                
                <table class="text-sm w-100" style="width:100%">
                    <thead>
                        <tr class="bg-gray-300 bg-opacity-60 rounded-2xl">
                            <th class="p-3 tex-left" style="text-align: left;">Time</th>
                            <th class="p-3 tex-left" style="text-align: left;">Name</th>
                            <th  class="p-3 text-left" style="text-align: left;">Receipt</th>
                            <th class="p-3 text-left" style="text-align: left;">Context</th>
                            <th ></th>
                        </tr>
                    </thead>
                    <tbody >

                        <tr *ngFor="let OBJ of currentQueue.objectStream.Resp" 
                            title="OBJ['description']" 
                            (click)="debugObj(OBJ)"
                            class="cursor-pointer pb-10 hover:bg-[#F0F0F0]">
                            <td class="p-1"  style="text-align: left;">
                                <p class=" m-0" style="font-size:x-small">
                                    <span>{{ OBJ['created_at'] }}</span>
                                </p>
                            </td>

                            <td class="p-1"  style="text-align: left;">
                                <p class=" m-0" style="font-size:x-small">
                                    <span>{{ OBJ['name'] }}</span>
                                </p>
                            </td>

                            <td  style="text-align: left;">
                                <p class="text-sm pl-0" style="font-size:x-small;">
                                    {{ OBJ['receipt'] }}
                                </p>
                            </td>

                            <td class="p-3"  style="text-align: left;">
                                <p class="text-sm pl-0" style="font-size:x-small;">
                                    {{ OBJ['context'] }}#{{ OBJ['context_id'] }}#
                                </p>
                            </td>

                            <td>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>

        </div>

    </div>


    <!-- LIST RQUEUE -->
    <div class=" p-2 tab-content md:p-10 border border-gray-300 rounded-xl shadow-xl"
        *ngIf="currentQueue===null">
        <div class="grid grid-cols-1">
            <div class="grid-col">
                <div class="flex justify-between items-center mb-0 ">
                    <div *ngIf="queueList">
                        <h3 class="font-bold text-sm font-poppins title-padding">
                            Mostrando {{queueList.Resp.length}} items
                        </h3>
                    </div>
                    <div *ngIf="!queueList">
                        <h3 class="font-bold text-sm font-poppins title-padding">
                            Sem items carregados.
                        </h3>
                    </div>

                    <div style="position: relative;">
                        <!--<input type="text" placeholder="Pesquisar..."
                            class="border border-gray-300 px-5 py-1 rounded-full" style="padding-left: 40px;" />-->
                        <img src="assets/img/icon/search.svg" alt="" c
                            style="position: absolute; top: 50%; left: 10px; transform: translateY(-50%);" />
                    </div>
                </div>

               
            </div>

            <div class="grid-col"
                *ngIf="queueList">
                <table class="text-sm w-100" style="width:100%">
                    <thead>
                        <tr class="bg-[#EAF2F2] bg-opacity-60 rounded-2xl">
                            <th  class="p-3 rounded-tl-xl rounded-bl-xl"  style="text-align: left;">Queue</th>
                            <th class="p-3"  style="text-align: left;">Contem</th>
                            <th class="rounded-tr-xl rounded-br-xl"  style="text-align: center;padding-right:4px;">Actions</th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr *ngFor="let QU of queueList.Resp" 
                            class="cursor-pointer pb-10 hover:bg-[#EAF2F2]">
                            <td (click)="OpenQueue(QU)">
                                <p class="text-sm pl-2">
                                    {{ QU['Name'] }}
                                </p>
                            </td>

                            <td class="p-3"
                                (click)="OpenQueue(QU)">
                                {{ QU['Obj_c'] }} items
                            </td>

                            <td style="padding-right:4px;text-align: center;">
                                <p class="text-sm pl-5">
                                    <span class="cursor-pointer mt-1 mb-1 rounded-3xl py-2 px-6 text-[#00527E] m-2 border "
                                        (click)="OpenQueue(QU)">
                                        Abrir
                                    </span>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>

    </div>
</div>
