<div *ngIf="is_loading">
    <div class="flex items-center justify-center rounded-xl rounded-tl-none p-[1rem] w-[7rem] ">
        <app-spinner>
        </app-spinner>
    </div>
</div>

<div >

  <form [formGroup]="anexoForForm" (ngSubmit)="onSubmit()" class="">
    <div class="grid grid-cols-1 ">

        <div class="grid-col mt-5" formArrayName="linhas"
            style="max-height:400px;background: #f4f4f4;padding: 2px;overflow: hidden;overflow-y: scroll;">
            <div style="padding:5px">            
                <h2 class="text-lg font-bold mb-2">Modelo Clientes</h2>

                <app-alert type="error" 
                    message="{{ error_msg || 'Nao foi possivel concluir. Verifique todos os campos' }}"
                    *ngIf="is_error">
                </app-alert>

                <app-alert type="warning" 
                    message="Alerta: Verifique todos os campos"
                    *ngIf="!anexoForForm.valid && !is_loading">
                </app-alert>
            </div>

            <table>
                <thead>
                    <tr>
                        <th style="font-size:small" class="opacity-30">Origem</th>
                        <th style="font-size:small" class="opacity-30">NIF</th>
                        <th style="font-size:small" class="opacity-30">Design.</th>
                        <th style="font-size:small" class="opacity-30">Tp Doc</th>
                        <th style="font-size:small" class="opacity-30">Nr Doc</th>
                        <th style="font-size:small" class="opacity-30">Serie</th>
                        <th style="font-size:small" class="opacity-30">Data</th>

                        <th style="font-size:x-small;color:#00527E;" title="Valor da Fatura" class="opacity-30">Val Fatr.</th>
                        <th style="font-size:xx-small;color:#00527E;" class="opacity-30">Base Incdnt.</th>
                        <th style="font-size:x-small;color:#00527E;" class="opacity-30">Taxa Iva</th>
                        <th style="font-size:x-small;color:#00527E;" class="opacity-30">Iva Liq.</th>

                        <th style="font-size:xx-small" class="opacity-30">LinhaDstMod.</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let linha of linhas.controls; let i = index" 
                        [formGroupName]="i" class="bg-gray-200 rounded-lg p-6 shadow-md">
                        <td>
                            <input id="origem{{ i }}" formControlName="origem" type="text" class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                            placeholder="Origem" 
                                style="font-size:small"
                            />
                        </td>
                        <td>
                            <input id="nif{{ i }}" formControlName="nif" type="text" class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" placeholder="NIF" style="font-size:small" />
                        </td>
                        <td>
                            <input id="designacao{{ i }}" formControlName="designacao" type="text" class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                            placeholder="designacao" style="font-size:small" />
                        </td>

                        <td>
                            <input id="tp_doc{{ i }}" formControlName="tp_doc" type="text" class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" placeholder="Tipo de Documento" style="font-size:small" />
                        </td>

                        <td>
                            <input id="num_doc{{ i }}" formControlName="num_doc" type="text" class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" placeholder="Número do Documento" style="font-size:small" />
                        </td>

                        <td>
                            <input id="serie{{ i }}" formControlName="serie" type="text" class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" placeholder="Serie" style="font-size:small" />
                        </td>

                        <td>
                            <input id="data{{ i }}" formControlName="data" type="date" 
                                class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                placeholder="Data" style="font-size:small"
                            />
                        </td>

                        <td>
                            <input id="vl_fatura{{ i }}" formControlName="vl_fatura" type="number" class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" placeholder="Valor da Fatura"
                            style="font-size:small" 
                            />
                        </td>

                        <td>
                            <input id="vl_base_incid{{ i }}" formControlName="vl_base_incid" type="number" class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" placeholder="Valor Base Incidente"
                            style="font-size:small" />
                        </td>

                        <td>
                            <input id="tx_iva{{ i }}" formControlName="tx_iva" type="number" class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500"
                                placeholder="Taxa de IVA"
                                style="font-size:small" />
                        </td>

                        <td>
                            <input id="iva_liq{{ i }}" formControlName="iva_liq" type="number" class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" placeholder="IVA Liquido"
                            style="font-size:small" />
                        </td>

                        <td>
                            <button type="button" (click)="removeLinha(i)" 
                                style="position: relative;background:orangered;z-index:100000;float:right!important;width:15px;margin-top: 4px;height:15px;border-radius:90px;" 
                                class="text-red-500  hover:text-red-700 focus:outline-none">
                                <!-- <span style="padding:10px;">R</span> -->
                            </button>

                            <input id="linha_dest_mod{{ i }}" formControlName="linha_dest_mod" type="number" class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" placeholder="Linha Dest Mod"
                            style="font-size:small;margin-top:-12px;" />

                        </td>

                    </tr>
                </tbody>
            </table>
        </div>

        <div class="grid-col mt-0 pt-5 pb-5 pl-3" style="background:#f4f4f4">
            <div class="grid grid-cols-2">
                <div class="grid-col">
                    <p>
                        <span (click)="addLinha()" 
                            class="px-4 py-2 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600">
                            Adicionar Linha
                        </span>
                    </p>
                </div>

                <div class="grid-col">
                    <h3 class="" style="color:#333;font-weight: bold;">Totais</h3>

                    <div class="grid grid-cols-2">
                        <div class="grid-col">
                            <table *ngIf="reportsTotals">
                                <tbody>
                                    <tr>
                                        <td class="text-left">
                                            <span style="font-size:x-small;"
                                                class="opacity-90">
                                                Total Valor Fatura
                                            </span>
                                        </td>

                                        <td>
                                            <p class="p-4"></p>
                                        </td>

                                        <td class="text-right">
                                            <span style="font-size:small;">
                                                {{reportsTotals.vl_fatura| number}}
                                            </span>
                                            <span style="font-weight:bold;font-size: small;">
                                                CVE
                                            </span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="text-left">
                                            <span style="font-size:x-small"
                                                class="opacity-90">
                                                Base de Incid&ecirc;ncia
                                            </span>
                                        </td>

                                        <td>
                                            <p class="p-4"></p>
                                        </td>

                                        <td class="text-right">
                                            <span style="font-size:small">
                                                {{reportsTotals.vl_base_incid | number}}
                                            </span>
                                            <span style="font-weight:bold;font-size: small;">
                                                CVE
                                            </span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="text-left"
                                            class="opacity-90">
                                            <span style="font-size:x-small">
                                                Total Liquidado
                                            </span>
                                        </td>

                                        <td>
                                            <p class="p-4"></p>
                                        </td>

                                        <td class="text-right">
                                            <span style="font-size:small">
                                                {{reportsTotals.total_liquidado | number}}
                                            </span>
                                            <span style="font-weight:bold;font-size: small;">
                                                CVE
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="grid-col">
                        </div>
                    </div>

                    <p style="font-size:small" class="mt-3">
                        <span class="cursor-pointer text-small bg-white px-4 py-1 rounded-3xl text-[#00527E] m-2 border border-[#00527E]"
                            *ngIf="!is_running_rep"
                            style="font-size:small" 
                            (click)="Reports()">
                            Actualizar
                        </span>
                        <span class="cursor-pointer text-small bg-white px-4 py-1 rounded-3xl text-[#00527E] m-2 border border-[#00527E]"
                            *ngIf="is_running_rep"
                            style="font-size:small">
                            Actualizando
                        </span>
                    </p>
                </div>
            </div>
        </div>

        <div class="grid-col mt-5">
            <!-- Parte não repetida -->
            <div class="mb-4 pb-4 border-b">
                <h2 class="text-lg font-bold mb-2">Detalhes do Cabe&ccedil;alho</h2>
                <div class="grid grid-cols-2 gap-5 ">
                    <div class="form-field flex items-center">
                        <label for="ano" class="mr-2">Ano:</label>
                        <input id="ano" formControlName="ano" type="number" 
                            class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500"
                            style="font-size:small" />
                    </div>

                    <div class="form-field flex items-center">
                        <label for="mes" class="mr-2">Mês:</label>
                        <select formControlName="mes" required>
                            <option value="1">Janeiro</option>
                            <option value="2">Fevereiro</option>
                            <option value="3">Março</option>
                            <option value="4">Abril</option>
                            <option value="5">Maio</option>
                            <option value="6">Junho</option>
                            <option value="7">Julho</option>
                            <option value="8">Agosto</option>
                            <option value="9">Setembro</option>
                            <option value="10">Outubro</option>
                            <option value="11">Novembro</option>
                            <option value="12">Dezembro</option>
                        </select>
                    </div>

                    <div class="form-field flex items-center">
                        <label for="cd_af" class="mr-2">Código AF:</label>
                        <input id="cd_af" formControlName="cd_af" type="text" 
                            class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500"
                            style="font-size:small" />
                    </div>

                    <div class="form-field flex items-center">
                        <label for="nif" class="mr-2">NIF:</label>
                        <input id="nif" formControlName="nif" type="text" 
                            class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500"
                            style="font-size:small" />
                    </div>
                </div>
            </div>

            <button type="submit"
                *ngIf="anexoForForm.valid" 
                class="px-4 py-2 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600">
                <span *ngIf="is_loading">Aguarde</span>
                <span *ngIf="!is_loading">Criar Modelo</span>
            </button>            
        </div>
    </div>

  </form>
  
</div>

<div *ngIf="!mostrarFormulario" class="mt-3">
    <div *ngIf="is_created">
        <div style="max-width:300px;margin:auto;">
            <app-alert type="success" 
                message="{{resp_msg || 'O Relatorio Modelo DPR 106 foi gerado com sucesso'}}"
                *ngIf="is_created">
            </app-alert>
        </div>

        <div *ngIf="resposta && resposta.url_dl"
            style="max-width:300px;margin:auto;" class="text-center">
            <p class="opacity-30" style="font-size:small">
                Pode tamb&eacute;m efectuar o download abaixo:
            </p>

            <p class="mt-1 mb-1  ">
                <span class="rounded-xl p-2 text-[#fff] bg-[#00527E] m-2 border border-[#fff] cursor-pointer p-2  rounded-xl text-sm   m-2 justify-center">
                    <a href="{{resposta.url_dl}}" target="__blank">
                        Download
                    </a>
                </span>
            </p>
        </div>
    </div>

</div>
