import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

@Component({
    selector: 'app-invoice-editor',
    templateUrl: './COMP.component.html',
})
export class InvoiceEditor implements OnInit {
    @Input() editObj:any = null;
    editorForm: any = null;
    is_loading:boolean = false
    error_msg:any = false
    resp_msg:any = false
    is_error:boolean = false
    is_ready:boolean = false
    is_created:boolean = false
    errors:any = null
    warnings:any = null
    ignore_warning:any = false

    constructor(
        private chatService: ChatService,
        private fb: FormBuilder
    ) {
    }

    ngOnInit() {
        // console.info("[InvoiceEditor] editObj: ", this.editObj)
        setTimeout(()=>{        
            const Lib:any = window;
            var today:any = '';
            if (Lib.moment) {
                today = Lib.moment().format("YYYY-MM-DD");
            }

            this.editorForm = this.fb.group({
                supplier_name: [null, Validators.required],
                supplier_nif: [null, Validators.required],
                customer_name: [null, Validators.required],
                customer_nif: [null, Validators.required],
                invoice_lang: ['PT', Validators.required],
                folder_name: ['default', Validators.required],
                // supplier_id: ['', Validators.required], // entity_id
                // customer_id: ['', Validators.required], // entity_id

                invoice_state: ['DRAFT', Validators.required],
                invoice_date: [today, Validators.required],
                invoice_due_date: [today, Validators.required],
                invoice_id: ['DOC_ID', Validators.required],
                invoice_origin: ['CV', Validators.required],
                invoice_ref: ['REF', Validators.required],
                payment_ref: ['PAYMENT_REF', Validators.required],

                invoice_total: [0, Validators.required],
                invoice_subtotal: [0, Validators.required],
                invoice_iva: [0.15, Validators.required],
                currency: ['CVE', Validators.required],
            });
        }, 80);
    }

    IgnoreWarningsAndSubmit() {
        this.ignore_warning=true;
        this.warnings = [];
        this.OnSubmit();
    }

    AtivateAlerts() {
        this.ignore_warning=false;        
    }

    AddMore() {
        this.is_created=false;
        this.is_error = false;
        this.errors = false;
        this.warnings = false;
    }

    OnSubmit() {
        this.is_error = false;
        this.error_msg = false;
        this.resp_msg = false;
        this.errors = false;
        this.is_created = false;
        this.warnings = false;

        if (this.editorForm.valid) {
            const formData:any = this.editorForm.value;
            // console.info("formData: ", formData);
            var Errs:any = []; // have to please the compiler
            const Warns:any = []; // have to please the compiler
            const invoice_subtotal=formData.invoice_subtotal;
            const invoice_total=formData.invoice_total;
            const invoice_iva=formData.invoice_iva;
            const folder_name=formData.folder_name;

            if (invoice_subtotal > invoice_total) {
                Errs.push("Subtotal não pode ser superior a Total!");
            }
            if (formData.supplier_nif.length<9) {
                Errs.push("Nif do fornecedor contem menos de 9 algarismos");
            }
            if (formData.customer_nif.length<9) {
                Errs.push("Nif do cliente contem menos de 9 algarismos");
            }

            if (Errs.length>0) {
                this.errors=Errs;
                return
            }
            Errs = null; // make sure nobody else uses this

            if (formData.supplier_nif==formData.customer_nif) {
                Warns.push("Definiu o mesmo NIF para o cliente e o fornecedor");
            }
            if (formData.supplier_name==formData.customer_name) {
                Warns.push("Definiu o mesmo nome para o cliente e o fornecedor");
            }

            if (invoice_subtotal==0) {
                Warns.push("O Subtotal foi definido como zero.")
            }
            if (invoice_total==0) {
                Warns.push("O Total foi definido como zero.")
            }
            if (formData.invoice_id=='DOC_ID') {
                Warns.push("O Nr da Fatura não foi preenchido. Causará problemas no modelo de iva")
            }
            if (invoice_iva==0) {
                Warns.push("O IVA foi definido como zero.")
            }

            if (invoice_total>0 && invoice_subtotal==0) {
                Warns.push("Definiu o total em "+invoice_total+", mas o subtotal definido como zero.")
            }

            if (Warns.length>0) {
                this.warnings = Warns;
                if (!this.ignore_warning){
                    return
                }
            }

            this.is_error = false;
            this.is_created = false;
            this.is_loading = true;

            const payload = {
                operation: 'CreateInvoice',
                folder_name: folder_name,
                obj: formData,
            };

            this.chatService.ExecuteTask('core/LuaInvoiceService', payload, (doc_resp: any) => {
                setTimeout(() => {
                    this.is_loading = false;
                    if (doc_resp.Status=='OK') {
                        this.is_created = true;
                        this.resp_msg = doc_resp;
                    } else {
                        if (doc_resp.Errors&&doc_resp.Errors.length) {
                            this.errors = doc_resp.Errors;
                        }
                        this.is_error = true;
                    }
                }, 120);
            }, (error: any) => {
                this.is_error = true;
                this.is_loading = false;
                setTimeout(()=>{
                    if (error.Errors&&error.Errors.length) {
                        this.errors = error.Errors;
                    }
                }, 100);
            });

        } else {
            this.is_error=true;
        }
    }

    Execute(task:any, payload:any) {
    }
}

