import { Component, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-screen-capture',
  templateUrl: './screen-capture.component.html',
  styleUrls: ['./screen-capture.component.scss']
})
export class ScreenCaptureComponent implements AfterViewInit {

  videoElement: HTMLVideoElement | null = null;
  canvasElement: HTMLCanvasElement | null = null;
  captureStream: MediaStream | null = null;
  capturedImageUrl: string | null = null; // Adicionado para armazenar a URL da imagem capturada

  constructor() { }

  async startCapture() {
    try {
      this.captureStream = await navigator.mediaDevices.getDisplayMedia({ video: true });
      if (this.videoElement) {
        this.videoElement.srcObject = this.captureStream;
      }
    } catch (err) {
      console.error("Error: " + err);
    }
  }

  stopCapture() {
    if (this.captureStream) {
      const tracks = this.captureStream.getTracks();
      tracks.forEach(track => track.stop());
      this.captureStream = null;
    }
  }

  captureImage() {
    if (this.canvasElement && this.videoElement) {
      const context = this.canvasElement.getContext('2d');
      if (context) {
        this.canvasElement.width = this.videoElement.videoWidth;  // Ajusta a largura do canvas
        this.canvasElement.height = this.videoElement.videoHeight; // Ajusta a altura do canvas
        context.drawImage(this.videoElement, 0, 0, this.canvasElement.width, this.canvasElement.height);
        this.capturedImageUrl = this.canvasElement.toDataURL('image/png'); // Atualiza a URL da imagem capturada
      } else {
        console.error('Failed to get 2D context');
      }
    }
  }

  ngAfterViewInit() {
    this.videoElement = document.querySelector('video') as HTMLVideoElement;
    this.canvasElement = document.querySelector('canvas') as HTMLCanvasElement;

    if (!this.videoElement || !this.canvasElement) {
      console.error('Failed to initialize video or canvas elements');
    }
  }
}
