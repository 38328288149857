<app-user-layout>
    <p class="mt-4 mb-5" (click)="goBack()">
        <span class="cursor-pointer text-sm  font-bold text-[#00527E] m-2 flex flex-row  ">
            <img src="assets/img/icon/Group.svg " class="w-4 h-4 mr-1" />
            VOLTAR
        </span>
    </p>

    <div class=" flex flex-row justify-between">
        <div>
            <h3 class="text-2xl font-bold text-left"> AI - Análise Documental </h3>
            <h2 class="text-left opacity-70">
                <span class="cursor-pointer">Lua Apps</span>
                /&nbsp;
                <span class="text-[#00527E]">Análise Documental</span>
            </h2>
        </div>
        <div>
            <p class="cursor-pointer md:-mt-2 mb-1 flex flex-row items-center rounded-3xl px-4 md:px-6 py-2 bg-[#00527E] text-white m-2 border border-[#00527E] justify-center md:justify-start"
                (click)="onRefreshDocList()" *ngIf="currentDocOpen == null">
                <img src="assets/img/icon/Arrowswhite.svg" alt="Atualizar" class="w-5 h-5 mr-2 mt-[0.1rem]" />
                <span class="text-sm md:text-base">Atualizar</span>
            </p>
        </div>


    </div>

    <div class="mt-4 shadow-xl">

        <div class="w-full  p-10 ">
            <div class="tab-header flex  -ml-3">
                <div *ngIf="currentDocOpen === null"
                    class="tab-header-item cursor-pointer py-2 px-4  rounded-tl rounded-tr text-xl text-black font-semibold">
                    Lista
                </div>
                <div *ngIf="currentDocOpen !== null"
                    class="tab-header-item cursor-pointer py-2 px-4  rounded-tl rounded-tr text-black font-semibold">
                    <p>{{ currentDocOpen['realname'] }}</p>
                </div>
            </div>


            <div class=" p-2 tab-content md:p-10 border border-gray-300 rounded-xl">

                <div *ngIf="currentDocOpen===null">

                    <div class="flex justify-between items-center mb-0 ">
                        <div>
                            <h3 class="font-bold text-sm font-poppins title-padding">
                                Mostrando {{objectList.length}} items
                                <!-- Mostrando 10 de 20 -->
                            </h3>
                        </div>
                        <div style="position: relative;">
                            <div style="display:none">
                                <input type="text" placeholder="Pesquisar..."
                                    class="border border-gray-300 px-5 py-1 rounded-full" style="padding-left: 40px;" />
                                <img src="assets/img/icon/search.svg" alt="" c
                                    style="position: absolute; top: 50%; left: 10px; transform: translateY(-50%);" />
                            </div>

                        </div>
                    </div>

                    <div class="flex justify-between items-center mb-4">
                        <div class="flex space-x-3">
                            <!--
                            <button
                                class="border p-2 rounded-full bg-[#2571a1] text-white border-[#5BACAD] flex items-center"
                                (click)="onRefreshDocList()">
                                Carregar
                            </button>
                            <p class="mt-1 mb-1 rounded-xl p-2 text-[#fff] bg-[#00527E] m-2 border border-[#fff]  ">
                                <span class="cursor-pointer p-2  rounded-xl text-sm   m-2 justify-center"
                                    (click)="fileobj.click()">
                                    <input type="file" class="hidden" #fileobj (change)="uploadDoc($event)" />
                                    Carregar documento
                                </span>
                            </p>
                            -->
                        </div>
                        <div class="flex space-x-5">
                        </div>
                    </div>
                </div>
                <div class="col-span-1 md:col-span-2">
                    <app-alert type="error" message="Nao foi possivel concluir o upload. Tente novamente"
                        *ngIf="is_upload_error">
                    </app-alert>

                    <app-alert type="success" message="Carregado com sucesso" *ngIf="is_upload_succ">
                    </app-alert>
                </div>

                <div class="col-span-1 md:col-span-2" *ngIf="is_loading==true || is_uploading">
                    <div class="flex items-center rounded-xl rounded-tl-none p-[1rem] w-[7rem] ">
                        <app-spinner>
                        </app-spinner>
                    </div>
                </div>


                <div *ngIf="is_loading==false && objectList.length>0">
                    <div *ngIf="currentDocOpen===null">
                        <app-fatura [objectList]="objectList" [enableQueue]="false" [showFolder]="true"
                            [onSelect]="openFileItem" class="md:pb-8">
                        </app-fatura>
                    </div>

                    <div *ngIf="currentDocOpen!==null">
                        <p class="mb-4 md:-ml-2" (click)="closeCurrentFile() " *ngIf="currentDocOpen !== null">
                            <span class="cursor-pointer text-sm  font-bold text-[#00527E] m-2 flex flex-row  ">
                                <img src="assets/img/icon/Group.svg " class="w-4 h-4 mr-1" />
                                Voltar
                            </span>
                        </p>

                        <div class="mt-3 mb-1">
                            <app-alert type="info" message="Carregando detalhes"
                                *ngIf="loadCache[currentDocOpen.fileid].Status=='LOADING'">
                            </app-alert>
                            <app-alert type="error" message="Nao foi possivel carregar os detalhes"
                                *ngIf="loadCache[currentDocOpen.fileid].Status=='FAILED'">
                            </app-alert>
                        </div>

                        <div *ngIf="loadCache[currentDocOpen.fileid].Status=='DETAILS_LOAD'">
                            <div class=" mb-5 p-2 md:-mt-5">
                                <!-- <h3>DOCUMENTO</h3> -->
                                <div class="grid grid-cols-2">
                                    <div class="grid-col ">
                                        <div class="header">
                                            <div class="invoice-info">
                                                <h2 class="text-left opacity-70" style="font-size:small">
                                                    Documentos / {{ currentDocOpen['fileid'] }}
                                                </h2>
                                                <h1 class="font-semibold text-2xl pb-1">
                                                    {{currentDocOpen['realname']}}
                                                </h1>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="grid-col text-right">
                                        <div class="grid grid-cols-2" *ngIf="false">
                                            <div class="grid-col">
                                                <p class="text-right opacity-70" style="font-size:x-small;">
                                                    &Iacute;ndice de <br />Complexidade
                                                </p>
                                                <h1 class=" pb-1">
                                                    <span class="font-semibold text-6xl">
                                                        {{
                                                        loadCache[currentDocOpen.fileid].Details.info_group_response.doc_cplx.info.cplx_index
                                                        }}
                                                    </span>
                                                </h1>
                                            </div>

                                            <div class="grid-col">
                                                <p class="text-right opacity-70" style="font-size:x-small;">
                                                    Estimativa de <br />itera&ccedil;&otilde;es
                                                </p>
                                                <h1 class=" pb-1">
                                                    <span class="font-semibold text-6xl opacity-70">
                                                        {{
                                                        loadCache[currentDocOpen.fileid].Details.info_group_response.doc_cplx.info.est_iterations
                                                        }}
                                                    </span>
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-5"></div>

                                <div class="grid grid-cols-1 ">

                                    <div class="grid-col">
                                        <span (click)="genPreview()"
                                            *ngIf="!is_run_preview && !previewPDF && !preview_url"
                                            class="cursor-pointer text-sm px-8 py-2 rounded-3xl font-bold text-white m-2 mr-1 border border-[#2571a1] bg-[#2571a1]">
                                            Pr&eacute;-Visualizar
                                        </span>

                                        <span (click)="closePreview()" *ngIf="previewPDF || preview_url"
                                            class="cursor-pointer text-sm px-8 py-2 rounded-3xl font-bold text-white m-2 mr-1 border border-[#00527E] bg-[#00527E]">
                                            Fechar
                                        </span>
                                        <!--
                                        <app-report-odoo [doc]="currentDocOpen">
                                        </app-report-odoo>
                                        <h3>Modelo DPR</h3>
                                        <h3>Analise & Resumo</h3>
                                        <h3>Descritivos do documento</h3>
                                        <h3>Analise Bancaria</h3>
                                        <h3>Listagem de Produtos Recursiva</h3>
                                        -->
                                    </div>
                                </div>

                                <div #genReportComp></div>

                                <div class="grid grid-cols-1 mt-4 ">
                                    <div class="grid-col " *ngIf="currentDocOpen.fileid+'#REPORTS' as keyCache">
                                        <!--
                                        <div *ngIf="currentReportGen">
                                            <app-gen-analysis [reportObj]="currentReportGen"
                                                [changeReportCall]="changeReport">
                                            </app-gen-analysis>

                                            <p class="mt-3">                                            
                                                <span
                                                    class="cursor-pointer text-[small]  bg-[#fff] rounded-3xl px-8 py-2 font-bold text-[#663399] m-2"
                                                    (click)="closeGenAnalysis()"
                                                    style="border: 1px solid #663399;">
                                                    Fechar Análise Generativa
                                                </span>
                                            </p>
                                        </div>
                                        -->

                                        <div class="flex flex-row justify-between">
                                            <h3 class="text-2xl font-extrabold text-left mt-5 mb-2 ">
                                                Relatórios
                                            </h3>

                                            <p class="mt-1 mb-2 pl-3">
                                                <span
                                                    class="cursor-pointer text-[small]  flex flex-row bg-[#fff] rounded-3xl px-8 py-2 font-bold text-[#00527E] m-2"
                                                    (click)="scanDocReports(currentDocOpen.fileid)"
                                                    style="border: 1px solid rgb(0, 82, 126);">
                                                    <img src="assets/img/icon/Arrows.svg" alt="" class="w-5 h-5" />
                                                    Actualizar
                                                </span>
                                            </p>
                                        </div>

                                        <div *ngIf="previewPDF"
                                            style="max-height:400px;overflow: hidden;overflow-y: scroll;background-color:#f4f4f4">
                                            <app-pdf-preview [pdfUrl]="previewPDF.preview_url">
                                            </app-pdf-preview>
                                        </div>

                                        <div style="max-height:500px;overflow: hidden;overflow-y: scroll;background-color:#f4f4f4"
                                            id="xlsp-{{currentDocOpen.fileid}}">
                                        </div>

                                        <!-- LIST OF GROUPS -->
                                        <!-- MOVED TO group-list.component.html 
                                        -->

                                        <div *ngIf="objectCache[keyCache]" class="mt-5 pl-3 pr-3">
                                            <app-alert type="info" message="Carregando relatórios"
                                                *ngIf="objectCache[keyCache].Status=='LOADING'">
                                            </app-alert>

                                            <app-alert type="error" message="Nao foi possivel carregar os relatórios"
                                                *ngIf="objectCache[keyCache].Status=='FAILED'">
                                            </app-alert>

                                            <app-alert type="success" message="Criado com sucesso" *ngIf="is_created">
                                            </app-alert>
                                            <app-alert type="error" message="Nao foi possivel concluir, tente novamente"
                                                *ngIf="is_create_error">
                                            </app-alert>
                                            <app-alert type="warning" message="Aguarde..." *ngIf="is_creating">
                                            </app-alert>

                                            <div class="grid-col" *ngIf="objectCache[keyCache].rep_c==0">
                                                <div class="text-center">
                                                    <p class="text-sm opacity-70 m-0">Nenhum relat&oacute;rio encontrado
                                                    </p>
                                                    <p class="text-sm opacity-70 m-0">
                                                        Consulte o grupo de Análises para adicionar
                                                        relat&oacute;rios automatizados.
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="mt-5 pl-3 pr-3" *ngIf="objectCache[keyCache].Status=='LOADED'">

                                                <div class="grid grid-cols-2">
                                                    <div class="grid-col"
                                                        *ngIf="objectCache[keyCache].Items.emission_details">
                                                        <div
                                                            class="panel mb-2 mr-1 p-2 border border-gray-300 pl-3 rounded-xl ">
                                                            <div class="panel-header pt-2 pb-2 pr-4">
                                                                <div
                                                                    class="flex flex-col md:flex-row justify-between items-center">
                                                                    <span
                                                                        class="text-sm text-[#1422a9] font-medium md:pl-[9px] truncate sm:text-xs  md:w-auto w-ful">
                                                                        Detalhes de Emiss&atilde;o
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <div class="panel-content" >
                                                                <div class="grid grid-cols-1 mt-0">
                                                                    <div class="grid-col">
                                                                        <div
                                                                            style="height:200px;max-height: 400px;overflow: hidden;overflow-y: scroll;background-color:#f4f4f4;">
                                                                            <app-doc-gen-report
                                                                                [report]="objectCache[keyCache].Items.emission_details.report"
                                                                                groupname="emission_details">
                                                                            </app-doc-gen-report>
                                                                        </div>
                                                                    </div>

                                                                    <div class="grid-col mt-5 p-3 pt-1 pl-1">
                                                                        <p class=""
                                                                            style="font-size:small;width:fit-content">
                                                                            <!--
                                                                            <span class="cursor-pointer mt-1 mb-1 px-8 py-2 rounded-3xl text-[#00527E] m-2 border border-[#00527E] "
                                                                                (click)="regenerate(objectCache[keyCache].Items.emission_details)"> 
                                                                                Regenerar
                                                                            </span>
                                                                            -->
                                                                            <span
                                                                                class="cursor-pointer mt-1 mb-1 px-8 py-2 rounded-3xl text-[#00527E] m-2 border border-[#00527E] "
                                                                                style="font-size:small;width:fit-content"
                                                                                (click)="createDocReport(objectCache[keyCache].Items.emission_details)">
                                                                                <span *ngIf="!is_creating">Criar</span>
                                                                                <span *ngIf="is_creating">Aguarde</span>
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div class="grid-col"
                                                        *ngIf="objectCache[keyCache].Items.general_details">
                                                        <div
                                                            class="panel mb-2 mr-1 p-2 border border-gray-300 pl-3 rounded-xl ">
                                                            <div class="panel-header pt-2 pb-2 pr-4">
                                                                <div
                                                                    class="flex flex-col md:flex-row justify-between items-center">
                                                                    <span
                                                                        class="text-sm text-[#1422a9] font-medium md:pl-[9px] truncate sm:text-xs  md:w-auto w-ful">
                                                                        Detalhes Gerais
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <div class="panel-content">
                                                                <div class="grid grid-cols-1 mt-0">
                                                                    <div class="grid-col">
                                                                        <div
                                                                            style="height:200px;max-height: 400px;overflow: hidden;overflow-y:scroll;background-color:#f4f4f4;">
                                                                            <app-doc-gen-report
                                                                                [report]="objectCache[keyCache].Items.general_details.report"
                                                                                groupname="general_details">
                                                                            </app-doc-gen-report>
                                                                        </div>
                                                                    </div>

                                                                    <div class="grid-col pt-2">
                                                                        <p class="pt-4 pb-3"
                                                                            style="font-size:small;width:fit-content">
                                                                            <!--
                                                                            <span (click)="regenerate(objectCache[keyCache].Items.general_details)"
                                                                                class="cursor-pointer mt-1 mb-1 px-8 py-2 rounded-3xl text-[#00527E] m-2 border border-[#00527E] w-auto ">
                                                                                Regenerar
                                                                            </span>
                                                                            -->
                                                                            <span
                                                                                class="cursor-pointer mt-1 mb-1 px-8 py-2 rounded-3xl text-[#00527E] m-2 border border-[#00527E] w-auto "
                                                                                (click)="createDocReport(objectCache[keyCache].Items.general_details)">
                                                                                <span *ngIf="!is_creating">Criar</span>
                                                                                <span *ngIf="is_creating">Aguarde</span>
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="grid-col"
                                                        *ngIf="objectCache[keyCache].Items.supplier_details">
                                                        <div
                                                            class="panel mb-2 mr-1 p-2 border border-gray-300 pl-3 rounded-xl ">
                                                            <div class="panel-header pt-2 pb-2 pr-4">
                                                                <div
                                                                    class="flex flex-col md:flex-row justify-between items-center">
                                                                    <span
                                                                        class="text-sm text-[#1422a9] font-medium md:pl-[9px] truncate sm:text-xs  md:w-auto w-ful">
                                                                        Detalhes do Fornecedor
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <div class="panel-content">
                                                                <div class="grid grid-cols-1 mt-0">
                                                                    <div class="grid-col">
                                                                        <div
                                                                            style="height:200px;max-height: 400px;overflow: hidden;overflow-y: scroll;background-color:#f4f4f4;">
                                                                            <app-doc-gen-report
                                                                                [report]="objectCache[keyCache].Items.supplier_details.report"
                                                                                groupname="supplier_details">
                                                                            </app-doc-gen-report>
                                                                        </div>
                                                                    </div>

                                                                    <div class="grid-col">
                                                                        <p class="pt-4 pb-3"
                                                                            style="font-size:small;width:fit-content">
                                                                            <!--
                                                                            <span (click)="regenerate(objectCache[keyCache].Items.supplier_details)"
                                                                            class="cursor-pointer mt-1 mb-1 px-8 py-2 rounded-3xl text-[#00527E] m-2 border border-[#00527E]  ">     
                                                                            Regenerar
                                                                            </span>
                                                                            -->
                                                                            <span
                                                                                class="cursor-pointer mt-1 mb-1 px-8 py-2 rounded-3xl text-[#00527E] m-2 border border-[#00527E] w-auto "
                                                                                (click)="createDocReport(objectCache[keyCache].Items.supplier_details)">
                                                                                <span *ngIf="!is_creating">Criar</span>
                                                                                <span *ngIf="is_creating">Aguarde</span>
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="grid-col"
                                                        *ngIf="objectCache[keyCache].Items.customer_details">
                                                        <div
                                                            class="panel mb-2 mr-1 p-2 border border-gray-300 pl-3 rounded-xl ">
                                                            <div class="panel-header pt-2 pb-2 pr-4">
                                                                <div
                                                                    class="flex flex-col md:flex-row justify-between items-center">
                                                                    <span
                                                                        class="text-sm text-[#1422a9] font-medium md:pl-[9px] truncate sm:text-xs  md:w-auto w-ful">
                                                                        Detalhes do Cliente
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <div class="panel-content">
                                                                <div class="grid grid-cols-1 mt-0">
                                                                    <div class="grid-col">
                                                                        <div
                                                                            style="height:200px;max-height: 400px;overflow: hidden;overflow-y: scroll;background-color:#f4f4f4;">
                                                                            <app-doc-gen-report
                                                                                [report]="objectCache[keyCache].Items.customer_details.report"
                                                                                groupname="customer_details">
                                                                            </app-doc-gen-report>

                                                                        </div>
                                                                    </div>

                                                                    <div class="grid-col pt-2">
                                                                        <p class="pt-3 pb-3"
                                                                            style="font-size:small;width:fit-content">
                                                                            <!--
                                                                            <span (click)="regenerate(objectCache[keyCache].Items.customer_details)"
                                                                                class="cursor-pointer mt-1 mb-1 px-8 py-2 rounded-3xl text-[#00527E] m-2 border border-[#00527E] w-auto">
                                                                                Regenerar
                                                                            </span>
                                                                            -->
                                                                            <span
                                                                                class="cursor-pointer mt-1 mb-1 px-8 py-2 rounded-3xl text-[#00527E] m-2 border border-[#00527E] w-auto"
                                                                                (click)="createDocReport(objectCache[keyCache].Items.customer_details)">
                                                                                <span *ngIf="!is_creating">Criar</span>
                                                                                <span *ngIf="is_creating">Aguarde</span>
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!--
                                                    <div class="grid-col" *ngIf="objectCache[keyCache].Items.customer_details">
                                                        <app-doc-gen-report [report]="objectCache[keyCache].Items.customer_details.report">
                                                        </app-doc-gen-report>
                                                    </div>
                                                    <div class="grid-col" *ngIf="objectCache[keyCache].Items.customer_details">
                                                        <app-doc-gen-report [report]="objectCache[keyCache].Items.customer_details.report">
                                                        </app-doc-gen-report>
                                                    </div>
                                                    <div class="grid-col" *ngIf="objectCache[keyCache].Items.customer_details">
                                                        <app-doc-gen-report [report]="objectCache[keyCache].Items.customer_details.report">
                                                        </app-doc-gen-report>
                                                    </div>
                                                    -->
                                                </div>

                                                <div class="grid grid-cols-1">
                                                    <div class="grid-col"
                                                        *ngIf="objectCache[keyCache].Items.product_list">
                                                        <div
                                                            class="panel mb-2 mr-1 p-2 border border-gray-300 pl-3 rounded-xl ">
                                                            <div class="panel-header pt-2 pb-2 pr-4">
                                                                <div
                                                                    class="flex flex-col md:flex-row justify-between items-center">
                                                                    <span
                                                                        class="text-sm text-[#1422a9] font-medium md:pl-[9px] truncate sm:text-xs  md:w-auto w-ful">
                                                                        Artigos
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <div class="panel-content">
                                                                <div class="grid grid-cols-1 mt-0">
                                                                    <div class="grid-col">
                                                                        <div
                                                                            style="height:200px;max-height: 400px;overflow: hidden;overflow-y: scroll;background-color:#f4f4f4;">
                                                                            <app-doc-gen-report
                                                                                [report]="objectCache[keyCache].Items.product_list.report"
                                                                                groupname="product_list">
                                                                            </app-doc-gen-report>
                                                                        </div>
                                                                    </div>

                                                                    <div class="grid-col p-2">
                                                                        <p class="pt-3 pb-3"
                                                                            style="font-size:small;width:fit-content">
                                                                            <!--
                                                                            <span class="cursor-pointer mt-1 mb-1 px-8 py-2 rounded-3xl text-[#00527E] m-2 border border-[#00527E]"
                                                                                (click)="regenerate(objectCache[keyCache].Items.product_list)">
                                                                                Regenerar
                                                                            </span>
                                                                            -->
                                                                            <span
                                                                                class="cursor-pointer mt-1 mb-1 px-8 py-2 rounded-3xl text-[#00527E] m-2 border border-[#00527E] "
                                                                                (click)="createDocReport(objectCache[keyCache].Items.product_list)">
                                                                                <span *ngIf="!is_creating">Criar</span>
                                                                                <span *ngIf="is_creating">Aguarde</span>
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="grid grid-cols-2">
                                                    <div class="grid-col"
                                                        *ngIf="objectCache[keyCache].Items.payment_details">
                                                        <div
                                                            class="panel mb-2 mr-1 p-2 border border-gray-300 pl-3 rounded-xl ">
                                                            <div class="panel-header pt-2 pb-2 pr-4">
                                                                <div
                                                                    class="flex flex-col md:flex-row justify-between items-center">
                                                                    <span
                                                                        class="text-sm text-[#1422a9] font-medium md:pl-[9px] truncate sm:text-xs  md:w-auto w-ful">
                                                                        Detalhes de pagamento
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <div class="panel-content">
                                                                <div class="grid grid-cols-1 mt-0">
                                                                    <div class="grid-col">
                                                                        <div
                                                                            style="height:200px;max-height: 400px;overflow: hidden;overflow-y: scroll;background-color:#f4f4f4;">
                                                                            <app-doc-gen-report
                                                                                [report]="objectCache[keyCache].Items.payment_details.report"
                                                                                groupname="payment_details">
                                                                            </app-doc-gen-report>
                                                                        </div>
                                                                    </div>

                                                                    <div class="grid-col  pt-2">
                                                                        <p class="pt-3 pb-3"
                                                                            style="font-size:small;width:fit-content">
                                                                            <!--
                                                                            <span class="cursor-pointer mt-1 mb-1 px-8 py-2 rounded-3xl text-[#00527E] m-2 border border-[#00527E]  " 
                                                                            (click)="regenerate(objectCache[keyCache].Items.payment_details)">
                                                                            Regenerar
                                                                            </span>
                                                                            -->
                                                                            <span
                                                                                class="cursor-pointer mt-1 mb-1 px-8 py-2 rounded-3xl text-[#00527E] m-2 border border-[#00527E] "
                                                                                style="font-size:small;width:fit-content"
                                                                                *ngIf="!is_creating"
                                                                                (click)="createDocReport(objectCache[keyCache].Items.payment_details)">
                                                                                <span *ngIf="!is_creating">Criar</span>
                                                                                <span *ngIf="is_creating">Aguarde</span>
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="grid-col"
                                                        *ngIf="objectCache[keyCache].Items.bank_details">
                                                        <div
                                                            class="panel mb-2 mr-1 p-2 border border-gray-300 pl-3 rounded-xl ">
                                                            <div class="panel-header pt-2 pb-2 pr-4">
                                                                <div
                                                                    class="flex flex-col md:flex-row justify-between items-center">
                                                                    <span
                                                                        class="text-sm text-[#1422a9] font-medium md:pl-[9px] truncate sm:text-xs  md:w-auto w-ful">
                                                                        Detalhes de Banco
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <div class="panel-content">
                                                                <div class="grid grid-cols-1 mt-0">
                                                                    <div class="grid-col">
                                                                        <div
                                                                            style="height:200px;max-height: 400px;overflow: hidden;overflow-y: scroll;background-color:#f4f4f4;">
                                                                            <app-doc-gen-report
                                                                                [report]="objectCache[keyCache].Items.bank_details.report"
                                                                                groupname="bank_details">
                                                                            </app-doc-gen-report>
                                                                        </div>
                                                                    </div>

                                                                    <div class="grid-col pt-2">
                                                                        <p class="pt-3 pb-3"
                                                                            style="font-size:small;width:fit-content">
                                                                            <!--
                                                                            <span class="cursor-pointer mt-1 mb-1 px-8 py-2 rounded-3xl text-[#00527E] m-2 border border-[#00527E]  "
                                                                            (click)="regenerate(objectCache[keyCache].Items.bank_details)">
                                                                            Regenerar
                                                                            </span>
                                                                            -->
                                                                            <span
                                                                                class="cursor-pointer mt-1 mb-1 px-8 py-2 rounded-3xl text-[#00527E] m-2 border border-[#00527E] "
                                                                                style="font-size:small;width:fit-content"
                                                                                *ngIf="!is_creating"
                                                                                (click)="createDocReport(objectCache[keyCache].Items.bank_details)">
                                                                                <span *ngIf="!is_creating">Criar</span>
                                                                                <span *ngIf="is_creating">Aguarde</span>
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="grid grid-cols-2">
                                                    <div class="grid-col"
                                                        *ngIf="objectCache[keyCache].Items.total_without_tax">
                                                        <div
                                                            class="panel mb-2 mr-1 p-2 border border-gray-300 pl-3 rounded-xl ">
                                                            <div class="panel-header pt-2 pb-2 pr-4">
                                                                <div
                                                                    class="flex flex-col md:flex-row justify-between items-center">
                                                                    <span
                                                                        class="text-sm text-[#1422a9] font-medium md:pl-[9px] truncate sm:text-xs  md:w-auto w-ful">
                                                                        Total sem iva/imposto
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <div class="panel-content">
                                                                <div class="grid grid-cols-1 mt-0">
                                                                    <div class="grid-col">
                                                                        <div
                                                                            style="height:200px;max-height: 400px;overflow: hidden;overflow-y: scroll;background-color:#f4f4f4;">
                                                                            <app-doc-gen-report
                                                                                [report]="objectCache[keyCache].Items.total_without_tax.report"
                                                                                groupname="total_without_tax">
                                                                            </app-doc-gen-report>
                                                                        </div>
                                                                    </div>

                                                                    <div class="grid-col  pt-2">
                                                                        <p class="pt-3 pb-3"
                                                                            style="font-size:small;width:fit-content">
                                                                            <!--
                                                                            <span (click)="regenerate(objectCache[keyCache].Items.total_without_tax)"
                                                                                class="cursor-pointer mt-1 mb-1 px-8 py-2 rounded-3xl text-[#00527E] m-2 border border-[#00527E]">
                                                                            Regenerar
                                                                            </span>
                                                                            -->
                                                                            <span
                                                                                class="cursor-pointer mt-1 mb-1 px-8 py-2 rounded-3xl text-[#00527E] m-2 border border-[#00527E] "
                                                                                style="font-size:small;width:fit-content"
                                                                                *ngIf="!is_creating"
                                                                                (click)="createDocReport(objectCache[keyCache].Items.total_without_tax)">
                                                                                <span *ngIf="!is_creating">Criar</span>
                                                                                <span *ngIf="is_creating">Aguarde</span>
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="grid-col"
                                                        *ngIf="objectCache[keyCache].Items.total_with_tax">
                                                        <div
                                                            class="panel mb-2 mr-1 p-2 border border-gray-300 pl-3 rounded-xl ">
                                                            <div class="panel-header pt-2 pb-2 pr-4">
                                                                <div
                                                                    class="flex flex-col md:flex-row justify-between items-center">
                                                                    <span
                                                                        class="text-sm text-[#1422a9] font-medium md:pl-[9px] truncate sm:text-xs  md:w-auto w-ful">
                                                                        Total com iva/imposto
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <div class="panel-content">
                                                                <div class="grid grid-cols-1 mt-0">
                                                                    <div class="grid-col">
                                                                        <div
                                                                            style="height:200px;max-height: 400px;overflow: hidden;overflow-y: scroll;">
                                                                            <app-doc-gen-report
                                                                                [report]="objectCache[keyCache].Items.total_with_tax.report"
                                                                                groupname="total_with_tax">
                                                                            </app-doc-gen-report>
                                                                        </div>
                                                                    </div>

                                                                    <div class="grid-col pt-2">
                                                                        <p class="pt-3 pb-3"
                                                                            style="font-size:small;width:fit-content">
                                                                            <!--
                                                                            <span (click)="regenerate(objectCache[keyCache].Items.total_with_tax)"
                                                                                class="cursor-pointer mt-1 mb-1 px-8 py-2 rounded-3xl text-[#00527E] m-2 border border-[#00527E]">
                                                                            Regenerar
                                                                            </span>
                                                                            -->
                                                                            <span
                                                                                class="cursor-pointer mt-1 mb-1 px-8 py-2 rounded-3xl text-[#00527E] m-2 border border-[#00527E] "
                                                                                style="font-size:small;width:fit-content"
                                                                                *ngIf="!is_creating"
                                                                                (click)="createDocReport(objectCache[keyCache].Items.total_with_tax)">
                                                                                Criar
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                        <h3 class="text-2xl mt-14 font-extrabold text-left mb-0 ">
                                            Imagens
                                        </h3>
                                        <p class="pt-3 pb-3" style="font-size:small;width:fit-content">
                                            <span
                                                class="cursor-pointer mt-1 mb-1 px-8 py-2 rounded-3xl text-[#00527E] m-2 border border-[#00527E] "
                                                style="font-size:small;width:fit-content" (click)="fetchImages()">
                                                Actualizar
                                            </span>
                                        </p>

                                        <app-modal #previewImgFile title="" [onClose]="closeImgPreview">
                                            <div *ngIf="previewImage"
                                                style="max-width:100%;overflow:hidden;text-align:center;">
                                                <img src="{{previewImage.url}}" />
                                            </div>
                                        </app-modal>

                                        <div class="grid grid-cols-4 ">
                                            <div class="grid-col" *ngFor="let img of currentDocOpen.imgList"
                                                style="padding:5px;" class="cursor-pointer" title="Clique para abrir">
                                                <div style="height:150px;background-image:url('{{img.url}}');background-size:contain;background-position:center center;background-repeat: no-repeat;margin: 4px;width: 100%;border:1px solid #ccc;padding:10px"
                                                    (click)="openImage(img)">
                                                </div>
                                                <!-- 
                                                <p>{{ img.name }}</p>
                                                -->
                                            </div>
                                        </div>


                                        <h3 class="text-2xl mt-14 font-extrabold text-left mb-0 ">
                                            Grupos de Análise
                                        </h3>
                                        <p style="margin:0px" class="opacity-50 mb-3">
                                            {{ procGroup.length }} items.
                                        </p>

                                        <div class="grid grid-cols-2 ">
                                            <div class="grid-col">
                                                <div
                                                    style="padding: 10px;max-height:400px;overflow:hidden;overflow-y:scroll">
                                                    <div class="border border-gray-200 rounded-xl p-2 mb-3"
                                                        *ngFor="let groupDoc of loadCache[currentDocOpen.fileid].Details.info_group_response.groups_doc">
                                                        <h4 class="">{{ (groupDoc.label ||
                                                            groupDoc.group_name).toUpperCase() }}</h4>

                                                        <p class="mt-5 mb-5"
                                                            *ngIf="!groupDoc.IsAdd && !is_loading_queue">
                                                            <span
                                                                class="cursor-pointer text-small bg-white px-8 py-2 rounded-3xl font-bold text-[#00527E] m-2 border border-[#00527E]"
                                                                (click)="addToAnalysisGroup(currentDocOpen.fileid, groupDoc)">
                                                                Executar
                                                            </span>
                                                        </p>

                                                        <p class="mt-5 mb-5" *ngIf="groupDoc.IsAdd">
                                                            <span
                                                                class="cursor-pointer text-small bg-white px-8 py-2 rounded-3xl font-bold text-[#00527E] m-2 border">
                                                                ADICIONADO
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- (click)="executeGroup(currentDocOpen.fileid, groupDoc)"> -->

                                            <div class="grid-col">

                                                <app-alert type="success" message="HOLD ON" *ngIf="is_loading_queue">
                                                </app-alert>

                                                <div
                                                    style="padding: 10px;background:#f4f4f4;min-height: 400px;max-height:400px;overflow:hidden;overflow-y:scroll">
                                                    <div *ngIf="procGroup.length===0" class="text-center"
                                                        style="padding:10px;padding-top:40px">
                                                        <p class="opacity-70" style="font-size:small">
                                                            Sem nenhum pedido
                                                            de an&aacute;lise.
                                                        </p>
                                                        <p class="opacity-70" style="font-size:x-small">
                                                            Clique em
                                                            <span class="text-[#00527E]">
                                                                EXECUTAR
                                                            </span>
                                                            para adicionar
                                                        </p>
                                                    </div>

                                                    <div class="" *ngIf="procGroup.length>0">
                                                        <p class="mt-1 mb-1" style="font-size:small;text-align: right;">
                                                            <span 
                                                                class="cursor-pointer text-small bg-white px-3 py-1 rounded-3xl font-bold text-[#00527E] m-1 border border-[#00527E]"
                                                                (click)="cleanProcGroup()">
                                                                Limpar
                                                            </span>
                                                        </p>
                                                    </div>

                                                    <div *ngFor="let Req of procGroup">
                                                        <app-analysis-work [fileid]="currentDocOpen.fileid"
                                                            [onSubmit]="onAnalysisSubmit" [analysisReq]="Req"
                                                            [inputStatus]="Req.inputStatus" [inputObj]="Req.inputObj"
                                                            [onFinish]="onAnalysisDone">
                                                        </app-analysis-work>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-5"></div>

                            </div>
                        </div>
                        <div class="mb-5"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</app-user-layout>