import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ChatService } from '../../../services/chat.service';
// Importe o serviço aqui


@Component({
  selector: 'app-report-odoo',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
    anexoForForm: FormGroup;
    @Input() xmlReport: any;
    @Input() doc: any;
    resposta: any; // Variável para armazenar a resposta
    mostrarFormulario: boolean = true;
    PDF_PREVIEW:boolean = false
    PDF_LINK:any = ''

    is_loading:boolean = false
    error_msg:any = false
    resp_msg:any = false
    is_error:boolean = false
    is_ready:boolean = false
    is_created:boolean = false
    is_loading_preview:boolean = false
    is_preview_err:boolean = false

    constructor(
        private fb: FormBuilder,
        private chatService: ChatService,
        private sanitizer: DomSanitizer
    ) {
        this.anexoForForm = this.fb.group({
            ano: [null, Validators.required],
            mes: [null, Validators.required],
            cd_af: [null, Validators.required],
            nif: [null, Validators.required],
        });
    }

    ngOnInit() {
        console.info("Doc: ", this.doc);
        var xmlReport;

        if (!this.xmlReport) {
            xmlReport = {
                docname: this.doc.filename,
                user_id: this.doc.created_by,
                date: this.doc.tms,
                header: {
                    ano: new Date().getFullYear() + "",
                    mes: new Date().getMonth() + "",
                    nif: "",
                    cd_af: ""
                }
            }
            this.xmlReport = xmlReport; 
        } else {
            xmlReport = this.xmlReport;
        }

        this.populateForm(xmlReport);
    }

    get linhas(): FormArray {
        return this.anexoForForm.get('linhas') as FormArray;
    }

    addLinha() {
    }

    removeLinha(index: number) {
    }

    populateForm(data: any) {
        this.anexoForForm.patchValue({
            ano: data.header.ano,
            mes: data.header.mes,
            cd_af: data.header.cd_af,
            nif: data.header.nif,
            dt_entrega: data.dt_entrega,
            total_fatura: data.total_fatura,
            total_base_incid: data.total_base_incid,
            total_suportado: data.total_suportado,
            total_dedutivel: data.total_dedutivel,
            docname: data.docname,
            groupname: data.groupname,
            user_id: data.user_id,
            date: data.date
        });

    }

    onSubmit(): void {
        this.is_error = false;
        this.is_created = false;
        this.error_msg = false;
        this.resp_msg = false;

        if (this.anexoForForm.valid) {
            this.is_loading = true;
            const formData = this.anexoForForm.value;
            var xid='NEW_REPORT';
            var xmlReport = {}
            if(this.xmlReport && this.xmlReport.Id) {
                xid = this.xmlReport.Id;
            }

            const payload = {
                header: {
                  ano: formData.ano,
                  mes: formData.mes,
                  cd_af: formData.cd_af,
                  nif: formData.nif
                },
                Id: xid,
                docname: this.xmlReport.docname,
                groupname: this.xmlReport.groupname,
                user_id: this.xmlReport.user_id,
                date: this.xmlReport.date, 
            };

            this.chatService.submitForm(payload)
            .subscribe((response: any) => {
                this.is_loading=false;
                if (response.Status=="OK") {
                    this.is_created=true;
                    this.resposta = response; 
                    this.resp_msg = response.answer || false;
                    this.mostrarFormulario = false; 
                } else {
                    this.is_error=true;
                    this.error_msg=response.answer || "Erro ao submeter formulário";
                }
            }, (error: any) => {
                this.is_loading=false;
                this.is_error=true;
                console.error('Erro ao submeter formulário', error);
            });
        } else {
            this.is_error=true;
        }
    }

    executarAcao(action: any): void {
        this.chatService.onSend(action);
    }
}
