// my-account.component.ts
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChatService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef;
  accountForm!: FormGroup;

  constructor(private accountService: ChatService, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initForm();
    this.loadAccountInfo();
  }

  initForm() {
    this.accountForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: [''],
      address: [''],
    org:[''],
    previledges:[''],
      password: ['']
 

    });
  }

  loadAccountInfo() {
    this.accountService.getAccountInfo().subscribe({
      next: (data) => {
        this.accountForm.patchValue({
          name: data.name,
          email: data.email,
          phone: data.phone,
          address: data.address,
          org: data.org,
          previledges: data.previledges


        });
        
      },
      error: (err) => {
        console.error('Erro ao carregar informações da conta', err);
      }
    });
  }

  triggerFileUpload() {
    if (this.fileInput) {
      this.fileInput.nativeElement.click();
    }
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input?.files ? input.files[0] : null;

    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const imgElement = document.querySelector('img[alt="Profile Picture"]') as HTMLImageElement;
        if (imgElement) {
          imgElement.src = e.target.result;
        }
      };
      reader.readAsDataURL(file);
    }
  }

  saveChanges() {
    if (this.accountForm.valid) {
      // Função para salvar alterações
      console.log('Dados do formulário:', this.accountForm.value);
    } else {
      console.log('Formulário inválido');
    }
  }
}
