<app-user-layout>
    <div class="w-full grid grid-cols-1 justify-center items-center text-left text-[#414141]">
        <div class="flex-col justify-center items-center">
            <div class="flex flex-row mb-4 space-x-2 md:ml-12">
                <h1 class="text-4xl text-left font-extrabold text-[#414141] mt-3">
                Lua Apps
                </h1>
            </div>

            <div class="flex flex-col md:ml-12 text-justify">
                <p class="text-[#414141]">
                    Apps para an&aacute;lise, integra&ccedil;&atilde;o, producao, consumo e verificacao de
                    relatorios, Suporte especial
                </p>
                <p class="text-[#414141]">
                    para ODOO, Prodoc, Mattermost, EmailInbox, DPR.
                </p>
            </div>
        </div>

        <div class="grid grid-cols-3 gap-4 md:mt-10 w-[90%] justify-center md:ml-12 cursor-pointer">
            <!-- Explorador -->
            <div class="flex flex-row items-center justify-between rounded-xl bg-[#EAF2F2] cursor-pointer h-55 p-3 pb-7"
                (click)="openPage('documents-explorer')">
                <div class="flex flex-row justify-center items-center">
                    <img src="assets/img/icon/global-search.svg" alt="Lua" />
                    <span>Explorador</span>
                </div>
                <img src="assets/img/icon/luaAps.svg" alt="Lua" class="w-12 h-12" />
            </div>

            <!-- Análise -->
            <div class="flex flex-row items-center justify-between rounded-xl bg-[#EAF2F2] cursor-pointer h-55 p-3 pb-7"
                (click)="openPage('/document-analysis')">
                <div class="flex flex-row justify-center items-center">
                    <img src="assets/img/filter-search.svg" alt="Lua" />
                    <span>Análise</span>
                </div>
                <img src="assets/img/icon/luaAps.svg" alt="Lua" class="w-12 h-12" />
            </div>

            <div class="flex flex-row items-center justify-between rounded-xl bg-[#EAF2F2] h-55 p-3 pb-7"
                (click)="OpenApp('LUA_INVOICE')">
                <div class="flex flex-row items-center justify-between">
                    <img src="assets/img/icon/direct-inbox.svg" alt="Lua" />
                    <span>Faturas</span>
                </div>
                <img src="assets/img/icon/luaAps.svg" alt="Lua" class="w-12 h-12" />
            </div>

            <!--
            <div class="flex flex-row items-center justify-between rounded-xl bg-[#EAF2F2] h-55 p-3 pb-7"
              (click)="openPage('/reports')">
              <div class="flex flex-row items-center justify-between">
                <img src="assets/img/task-square.svg" alt="Lua" />
                <span>Relat&oacute;rios</span>
              </div>
              <img src="assets/img/icon/luaAps.svg" alt="Lua" class="w-12 h-12" />
            </div>
            -->

            <!-- Lua BI -->
            <div class="flex flex-row items-center justify-between rounded-xl bg-[#EAF2F2] h-55 p-3 pb-7"
                (click)="openPage('aibi')">
                <div class="flex flex-row items-center justify-between">
                    <img src="assets/img/icon/logoLua.svg" alt="Lua" />
                    <span>Lua BI</span>
                </div>
                <img src="assets/img/icon/luaAps.svg" alt="Lua" class="w-12 h-12" />
            </div>

            <!-- Quadros -->
            <div class="flex flex-row items-center justify-between rounded-xl bg-[#EAF2F2] h-55 p-3 pb-7"
                (click)="openPage('quadros-bi')">
                <div class="flex flex-row items-center justify-between">
                    <img src="assets/img/icon/setting.svg" alt="Lua" />
                    <span>Quadros</span>
                </div>
                <img src="assets/img/icon/luaAps.svg" alt="Lua" class="w-12 h-12" />
            </div>

            <!-- Lua Inbox -->
            <div class="flex flex-row items-center justify-between rounded-xl bg-[#EAF2F2] h-55 p-3 pb-7"
                (click)="openPage('lua-entity')">
                <div class="flex flex-row items-center justify-between">
                    <img src="assets/img/icon/security-user.svg" alt="Lua" />
                    <span>Entidades</span>
                </div>
                <img src="assets/img/icon/luaAps.svg" alt="Lua" class="w-12 h-12" />
            </div>

            <div class="flex flex-row items-center justify-between rounded-xl bg-[#EAF2F2] h-55 p-3 pb-7"
                (click)="openPage('lua-inbox')">
                <div class="flex flex-row items-center justify-between">
                    <img src="assets/img/icon/direct-inbox.svg" alt="Lua" />
                    <span>Lua Inbox</span>
                </div>
                <img src="assets/img/icon/luaAps.svg" alt="Lua" class="w-12 h-12" />
            </div>

            <div class="flex flex-row items-center justify-between rounded-xl bg-[#EAF2F2] h-55 p-3 pb-7"
                (click)="OpenApp('EMAIL_INBOX')">
                <div class="flex flex-row items-center justify-between">
                    <!-- <img src="assets/img/icon/inbox.svg" alt="Lua" /> -->
                    <span>Email Inbox</span>
                </div>
                <img src="assets/img/icon/luaAps.svg" alt="Lua" class="w-12 h-12" />
            </div>

        </div>

        <div class="grid grid-cols-1">
            <div class="grid-col">
                <div class="mt-5 p-5" style="width: 100% !important; display: block">
                    <hr />
                </div>
            </div>
        </div>

        <div class="grid grid-cols-3 gap-4 md:mt-10 w-[90%] justify-center md:ml-12">
            <!-- Lua Human -->
            <!--
            <div
            class="flex flex-row items-center justify-between rounded-xl bg-[#EAF2F2] h-14 p-3"
            (click)="OpenApp('LUNA_HUMAN')"
            >
            <div class="flex flex-row items-center justify-between">
            <img src="assets/img/icon/security-user.svg" alt="Lua" />
            <span>Lua Human</span>
            </div>
            <img src="assets/img/icon/luaAps.svg" alt="Lua" class="w-12 h-12" />
            </div>
            -->

            <!-- ANALISE FINANCEIRA -->
            <!--
            <div class="flex flex-row items-center justify-between rounded-xl bg-[#EAF2F2] h-14 p-3"
            (click)="AV_SOON('ANALISE FINANCEIRA')">
            <div class="flex flex-row items-center justify-between">
            <img src="assets/img/icon/wallet-search.svg" alt="Lua" />
            <span>ANALISE FINANCEIRA</span>
            </div>
            <img src="assets/img/icon/luaAps.svg" alt="Lua" class="w-12 h-12" />
            </div>

            <div class="flex flex-row items-center justify-between rounded-xl bg-[#EAF2F2] h-14 p-3"
            (click)="AV_SOON('GERAR RELATORIOS')">
            <div class="flex flex-row items-center justify-between">
            <img src="assets/img/icon/refresh-right-square.svg" alt="Lua" />
            <span>GERAR RELATORIOS</span>
            </div>
            <img src="assets/img/icon/luaAps.svg" alt="Lua" class="w-12 h-12" />
            </div>
            -->

            <!-- DPR - MODELO 106 -->
            <div class="flex cursor-pointer flex-row items-center justify-between rounded-xl bg-[#EAF2F2] h-14 p-3"
                (click)="OpenApp('DPR_106')">
                <div class="flex flex-row items-center justify-between">
                    <img src="assets/img/icon/infocircle.svg" alt="Lua" />
                    <span>DPR - LUA</span>
                </div>
                <img src="assets/img/icon/luaAps.svg" alt="Lua" class="w-12 h-12" />
            </div>

            <!-- OPENERP - ODOO -->
            <div class="flex cursor-pointer flex-row items-center justify-between rounded-xl bg-[#EAF2F2] h-14 p-3"
                (click)="OpenApp('ODOO')">
                <div class="flex flex-row items-center justify-between">
                    <img src="assets/img/icon/infocircle.svg" alt="Lua" />
                    <span>DPR - ODOO</span>
                </div>
                <img src="assets/img/icon/luaAps.svg" alt="Lua" class="w-12 h-12" />
            </div>

            <!--
            <div class="flex flex-row items-center justify-between rounded-xl bg-[#EAF2F2] h-14 p-3"
                (click)="openPage('/luaxapps')">
                <div class="flex flex-row items-center justify-between">
                    <img src="assets/img/icon/chart-square.svg" alt="Lua" />
                    <span>CONTABILIDADE</span>
                </div>
                <img src="assets/img/icon/luaAps.svg" alt="Lua" class="w-12 h-12" />
            </div>
            -->
        </div>

        <div class="grid grid-cols-1">
            <div class="grid-col">
                <div class="mt-5 p-5" style="width: 100% !important; display: block">
                <hr />
                </div>
            </div>
        </div>

        <div class="grid grid-cols-3 gap-4 md:mt-10 w-[90%] justify-center md:ml-12">
            <!-- Accounts Report -->
            <div class="flex flex-row items-center justify-between rounded-xl bg-[#EAF2F2] cursor-pointer h-55 p-3 pb-7"
                (click)="OpenApp('ACCOUNTS_REPORT')">
                <div class="flex flex-row justify-center items-center">
                    <img src="assets/img/task-square.svg" alt="Accounts Report" />
                    <span>Relatório de Contas a Pagar e Receber</span>
                </div>
                <img src="assets/img/icon/luaAps.svg" alt="Lua" class="w-12 h-12" />
            </div>

            <!-- Budget Report -->
            <div class="flex flex-row items-center justify-between rounded-xl bg-[#EAF2F2] cursor-pointer h-55 p-3 pb-7"
                (click)="OpenApp('BUDGET_REPORT')">
                <div class="flex flex-row justify-center items-center">
                    <img src="assets/img/task-square.svg" alt="Budget Report" />
                    <span>Relatório de Desempenho Orçamentário</span>
                </div>
                <img src="assets/img/icon/luaAps.svg" alt="Lua" class="w-12 h-12" />
            </div>

            <!-- DVA -->
            <div class="flex flex-row items-center justify-between rounded-xl bg-[#EAF2F2] cursor-pointer h-55 p-3 pb-7"
                (click)="OpenApp('DVA')">
                <div class="flex flex-row justify-center items-center">
                    <img src="assets/img/task-square.svg" alt="DVA" />
                    <span>Demonstração do Valor Adicionado</span>
                </div>
                <img src="assets/img/icon/luaAps.svg" alt="Lua" class="w-12 h-12" />
            </div>

            <!-- Financial Report -->
            <div class="flex flex-row items-center justify-between rounded-xl bg-[#EAF2F2] cursor-pointer h-55 p-3 pb-7"
                (click)="OpenApp('FINANCIAL_REPORT')">
                <div class="flex flex-row justify-center items-center">
                    <img src="assets/img/task-square.svg" alt="Financial Report" />
                    <span>Relatório de Análise de Razões Financeiras</span>
                </div>
                <img src="assets/img/icon/luaAps.svg" alt="Lua" class="w-12 h-12" />
            </div>

            <!-- Inventory Report -->
            <div class="flex flex-row items-center justify-between rounded-xl bg-[#EAF2F2] cursor-pointer h-55 p-3 pb-7"
                (click)="OpenApp('INVENTORY_REPORT')">
                <div class="flex flex-row justify-center items-center">
                    <img src="assets/img/task-square.svg" alt="Inventory Report" />
                    <span>Relatório de Inventário</span>
                </div>
                <img src="assets/img/icon/luaAps.svg" alt="Lua" class="w-12 h-12" />
            </div>

            <!-- Statement of Changes in Equity -->
            <div class="flex flex-row items-center justify-between rounded-xl bg-[#EAF2F2] cursor-pointer h-55 p-3 pb-7"
                (click)="OpenApp('STATEMENT')">
                <div class="flex flex-row justify-center items-center">
                    <img src="assets/img/task-square.svg" alt="Statement" />
                    <span>Demonstração de Mutações do PL</span>
                </div>
                <img src="assets/img/icon/luaAps.svg" alt="Lua" class="w-12 h-12" />
            </div>
        </div>
    </div>
</app-user-layout>
