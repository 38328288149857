import {Component,OnInit} from '@angular/core';

@Component({
    selector: 'app-spinner',
    templateUrl: './COMP.component.html',
})
export class AppSpinner implements OnInit {
    constructor() {}
    ngOnInit() {}
}

