import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

@Component({
    selector: 'app-bi-metrics-editor',
    templateUrl: './metrics-editor.component.html',
})
export class BIMetricsEditor implements OnInit {
    @Input() editObj:any = null
    @Input() onSave:any = null
    @Input() fileid:any = null
    editorForm:any = null
    rowList:any = []
    is_form_failed:boolean = false
    is_loading:boolean = false
    is_error:boolean = false
    is_invalid:boolean = false
    is_done:boolean = false
    error_msg:any = null
    is_created:any = false

    constructor(
        private chatService: ChatService,
        private fb: FormBuilder
    ) {
        // this.onCloseEdit = this.onCloseEdit.bind(this);
        // this.OnSubmitReportEdit = this.OnSubmitReportEdit.bind(this);
    }

    ngOnInit() {
        console.info("editObj: ", this.editObj)
        this.InitForm()
    }

    DeleteField(field:any) {
        console.info("field: ", field)
        this.rowList = this.rowList.filter((X:any) => {
            return X.Field!=field.Field;
        });
    }

    is_loading_help:boolean = false
    is_help_error:boolean = false

    StopReq() {
    }

    AskForLuaHelp() {
        const metricList:any = []
        if (!this.editorForm) {
            return;
        }


        if (this.editorForm.valid) {
            const groupname:any = this.editObj.groupname;
            // const fileid:any = this.editObj.fileid;
            const fileid:any = this.fileid;

            const formData:any = this.editorForm.value;
            const payloadData:any = {}
            const mgroup:any = 'ANALYSIS'; // REPORTS, ..., CUSTOM
            var context:any = formData['context']

            this.rowList.forEach((X:any) => {
                const fieldn_key:any = X.Field+"_N";
                const field_nval:any = formData[fieldn_key]
                if (field_nval) {
                    metricList.push({
                        value: formData[X.Field],
                        type: formData[X.Field+"_T"],
                        mgroup: mgroup,
                        fileid: fileid,
                        metric: field_nval,
                        context: context 
                    })
                }
            });
    
            const payload = {
                ops : 'AskHelp',
                obj: metricList,
            }

            this.is_loading_help=true;
            this.is_help_error=false;

            this.chatService.ExecuteTask("lua/BIServiceAPI", payload, (doc_resp:any) => {
                setTimeout(() => {
                    this.is_loading_help=false
                }, 430);

            }, (error:any) => {
                this.is_loading_help=false
                this.is_help_error=true
            });
        }

    }

    SubmitForm() {
        if (!this.editorForm) {
            return;
        }

        if (this.editorForm.valid) {
            const groupname:any = this.editObj.groupname;
            const fileid:any = this.fileid;

            const formData:any = this.editorForm.value;
            const payloadData:any = {}
            const metricList:any = []
            const mgroup:any = 'ANALYSIS'; // REPORTS, ..., CUSTOM
            var context:any = formData['context']

            this.rowList.forEach((X:any) => {
                const fieldn_key:any = X.Field+"_N";
                const field_nval:any = formData[fieldn_key]
                if (field_nval) {
                    metricList.push({
                        value: formData[X.Field],
                        type: formData[X.Field+"_T"],
                        mgroup: mgroup,
                        fileid: fileid,
                        metric: field_nval,
                        context: context 
                    })
                }
            });

            const payload = {
                ops : 'BatchMetrics',
                batch: metricList,
            }
            // console.info("payload: ", payload)
            this.is_loading = true;
            this.is_error = false;
            this.is_created = false;

            this.chatService.ExecuteTask("lua/BIServiceAPI", payload, (doc_resp:any) => {
                setTimeout(() => {
                    this.is_loading=false;
                    if (doc_resp.Status=="OK") {
                        this.is_created=true;
                    } else {
                        this.is_error=true;
                    }
                }, 430);
            }, (error:any) => {
                this.is_loading=false;
                this.is_error=true;
            });

        } else {
            // this.error_msg
            this.is_invalid = true;
        }
    }
  

    InitForm() {
        const groupName:any = this.editObj.groupname;
        const report:any = this.editObj.report;

        this.is_form_failed=false;

        try{            
            // console.info("this.report: ", report, groupName);
            const Rows:any = report.R;
            const fieldGroup:any ={            }

            const rowFields:any = [];

            Rows.forEach((Row:any) => {
                if (Row.key.startsWith("K00")) {
                    return;
                }

                const formField:any = Row.key;
                // const formField:any = Row.key
                //     .replaceAll("\"", '')
                //     .trim()
                //     .replaceAll("'", "");

                var rValue = Row[Row.key];
                if (rValue) {
                    rValue = rValue.trim()
                        .replaceAll("<unknown>", "")
                } else {
                    rValue=""
                }

                if (rValue.trim().length==0) {
                    return
                }

                // fieldGroup[formField] = [Row[Row.key], Validators.required];
                // fieldGroup[formField] = [rValue, Validators.required];
                fieldGroup[formField] = [rValue, false];
                fieldGroup[formField+"_N"] = [formField, false];
                fieldGroup[formField+"_T"] = ["text", false];
                rowFields.push({
                    Field: formField,
                    FField: formField,
                    Label: Row.key,
                    InputType: Row.input_type ?? 'text',
                    Value: rValue,
                });
            });

            fieldGroup['context'] = [groupName, false]

            const bigForm = this.fb.group(fieldGroup);
            this.editorForm = bigForm;
            this.rowList = rowFields;

        } catch (e) {
            this.is_form_failed=true;
        }
    }
}

