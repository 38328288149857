import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inventory-report',
  templateUrl: './inventory-report.component.html',
  styleUrls: ['./inventory-report.component.scss'],
})
export class InventoryReportComponent implements OnInit {
  isLoading: boolean = true;
  inventoryData: any = null;

  constructor() {}

  ngOnInit() {
    this.fetchInventoryData();
  }

  fetchInventoryData() {
    setTimeout(() => {
      this.inventoryData = {
        items: [
          { name: 'Product A', quantity: 100, value: 5000 },
          { name: 'Product B', quantity: 50, value: 2500 },
          { name: 'Product C', quantity: 200, value: 10000 },
        ],
      };
      this.isLoading = false;
    }, 1000);
  }
}
