import { Component, Input, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ChatService } from 'src/app/services/chat.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

@Component({
    selector: 'app-lua-invoice',
    templateUrl: './template.html',
})
export class BannerComponent implements OnInit {
    @Input() appVersion: any = '';
    @Input() appPage: any = '';

    errors:any = null;
    is_loading:boolean = false
    is_error:boolean = false
    hideTable:boolean = false
    is_ready:boolean = false
    objectCache:any = null

    filterForm: any;
    allow_add_bill:boolean = false
    is_filter_error:boolean = false

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private chatService: ChatService
    ) { 
        this.filterForm = this.fb.group({
            startDate: ['', Validators.required],
            endDate: ['', Validators.required],
        });
    }

    toggleTable() {
        this.hideTable = !this.hideTable;
        if (this.objectCache==null && !this.hideTable) {
            this.FetchInvoices();
        }
    }

    ngOnInit() {
        if (!this.hideTable) {
            this.FetchInvoices();
        }
    }

    FetchInvoices() {
        this.is_loading = true;
        this.is_filter_error = false;
        this.hideTable = false;
        this.errors = false;

        const objectCache:any = {
            invoices:{
                records: [],
                length: 0,
                total_count: 0,
            }
        }

        const payload = {
            operation: 'FetchInvoices',
        };

        this.is_loading = true;
        this.is_error = false;
        this.is_ready = false;

        this.objectCache = objectCache;

        this.chatService.ExecuteTask('core/LuaInvoiceService', payload, (doc_resp: any) => {
            setTimeout(() => {
                this.is_loading = false;
                if (doc_resp.Status == 'OK') {
                    objectCache.invoices.records = doc_resp.list;
                    objectCache.invoices.total_count = doc_resp.list.length;
                    objectCache.invoices.length = doc_resp.list.length;
                    this.objectCache = objectCache;
                } else {
                    this.is_error = true;
                    setTimeout(()=>{
                        if (doc_resp.Errors&&doc_resp.Errors.length) {
                            this.errors = doc_resp.Errors;
                        }
                    }, 100);
                }
            }, 60);
    
        }, (error: any) => {
            this.is_error = true;
            this.is_loading = false;

            setTimeout(()=>{
                if (error.Errors&&error.Errors.length) {
                    this.errors = error.Errors;
                }
            }, 100);
        });
    }

    filterInvoices() {
        const form = this.filterForm;
        this.is_filter_error = false;
        this.hideTable = false;

        if (form.valid) {
            const formData = form.value;
            console.info("formData: ", formData);
            var startDate = formData.startDate;
            var endDate = formData.endDate;
            var filterx: any = {};

            if (startDate) {
                filterx['start_date'] = startDate;
            }

            if (endDate) {
                filterx['end_date'] = endDate;
            }
            // console.info("filterx: ", filterx);
            this.RangeScan(filterx);
        } else {
            this.is_filter_error = true;
        }
    }

    RangeScan(filterx:any) {
        const payload = {
            operation: 'RangeScan',
            range: filterx
        };

        this.is_loading = true;
        this.is_error = false;
        this.is_ready = false;

        const localCache:any = {
            invoices:{
                records: [],
                length: 0,
                total_count: 0,
            }
        }

        this.objectCache = null;

        this.chatService.ExecuteTask('core/LuaInvoiceService', payload, (doc_resp: any) => {
            const object_: any = doc_resp.object_ || {};
            setTimeout(() => {
                this.is_loading = false;
                if (doc_resp.Status == 'OK') {
                    localCache.invoices.records = doc_resp.list;
                    localCache.invoices.length = doc_resp.list.length;
                    localCache.invoices.total_count = doc_resp.list.length;
                    this.objectCache = localCache;
                } else {
                    this.is_error = true;
                }
            }, 60);
    
        }, (error: any) => {
            this.is_error = true;
            this.is_loading = false;
        });
    }

    openInvoice(invoice:any) {
    }

    goBack() {
        this.router.navigate(['/luaxapps']);
    }
}
