<app-user-layout>
  <!-- Exibe a mensagem de carregamento enquanto os dados estão sendo carregados -->
  <div *ngIf="isLoading" class="loading">
    Loading...
  </div>
  
  <!-- Conteúdo principal exibido quando isLoading for falso -->
  <div *ngIf="!isLoading">
    <!-- Título da página referente ao relatório -->
    <h3 class="text-2xl font-bold text-left">
      Demonstração de Mutações do Patrimônio Líquido
    </h3>
    
    <!-- Parágrafo que exibe o período -->
    <p>
      Período: {{ equityData.period }}
    </p>
    
    <!-- Caixa com sombra e espaçamento para o conteúdo -->
    <div class="shadow-xl mt-4 p-10">
      <!-- Contêiner com fundo branco, borda cinza, e bordas arredondadas -->
      <div class="bg-white border border-gray-200 rounded-lg p-4">
        
        <!-- Tabela para exibir as mutações do patrimônio -->
        <table>
          <!-- Cabeçalho da tabela -->
          <thead>
            <tr>
              <!-- Coluna de descrição, alinhada à esquerda e com bordas arredondadas -->
              <th class="text-left pb-4 pt-2 pl-2 rounded-tl-3xl rounded-bl-3xl">
                Descrição
              </th>
              
              <!-- Coluna de montante, alinhada ao centro, com bordas arredondadas -->
              <th class="rounded-tr-3xl rounded-br-3xl">
                Montante (R$)
              </th>
            </tr>
          </thead>
          
          <!-- Corpo da tabela, iterando sobre as mudanças no patrimônio líquido -->
          <tbody>
            <!-- Loop para exibir cada mudança no patrimônio líquido -->
            <tr *ngFor="let change of equityData.changes">
              <!-- Descrição da mudança -->
              <td>
                {{ change.description }}
              </td>
              
              <!-- Montante da mudança, alinhado ao centro -->
              <td class="text-center">
                {{ change.amount }}
              </td>
            </tr>
          </tbody>
        </table>
        
      </div>
    </div>
  </div>
</app-user-layout>
