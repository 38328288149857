<div class="screen-capture-container p-4 bg-gray-100 rounded-lg shadow-md">
  <!-- Vídeo onde a captura será exibida -->
  <video #videoElement class="w-full h-auto border border-gray-300 rounded-md" autoplay muted></video>
  
  <!-- Canvas usado para desenhar a imagem capturada (oculto) -->
  <canvas #canvasElement class="hidden"></canvas>
  
  <!-- Controles para capturar a tela e imagem -->
  <div class="controls mt-4 flex space-x-4">
    <button (click)="startCapture()" class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
      Start Capture
    </button>
    <button (click)="stopCapture()" class="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600">
      Stop Capture
    </button>
    <button (click)="captureImage()" class="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600">
      Capture Image
    </button>
  </div>
  
  <!-- Exibição da imagem capturada -->
  <div *ngIf="capturedImageUrl" class="mt-4">
    <h3 class="text-lg font-semibold">Captured Image</h3>
    <img [src]="capturedImageUrl" alt="Captured Image" class="mt-2 max-w-full h-auto border border-gray-300 rounded-md"/>
  </div>
</div>
