import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

@Component({
  selector: 'app-luna-human',
  templateUrl: './luna-human.component.html',
  styleUrls: ['./luna-human.component.scss']
})
export class LunaHumanComponent implements OnInit {
    @Input() assistantName:any = "core/LuaHuman"
    chatForm: FormGroup;
    appConf:any = null
    @Input() inputValue?: string = "";
    @Output() btnClick = new EventEmitter<void>();
    @Output() inputValueChange: EventEmitter<string> = new EventEmitter<string>();

    qidx_seq:number = 1;
    is_loading:boolean = false
    is_error:boolean = false

    bufMessages: any[] = [
        /*
        {
            type:"you",
            content: ['hey there luna'],
            perfil: 'assets/img/temp/profile.svg',
        },
        {
            type:"text",
            content: {
                text: "Nao consegui compreender a pregunta. Poderia simplificar"
            },
            perfil: 'assets/img/temp/profile.svg',
        },
        */
    ]

    constructor(
        private chatService: ChatService,
        public translate: TranslateService,
        private router: Router,
        private fb: FormBuilder
    ) {
        this.chatForm = this.fb.group({
            msg: ['', Validators.required],
        });

        this.appConf = {
            assistantName: this.assistantName,
            version: '',
            page: '',
            settings: {},
            preferences: {},
            store: {},
            memory: {}
        }

        // this.subscription = this.chatService.editMessage$.subscribe(
        //     ({ message, index }) => this.setEditInput(message, index) 
        // );
        this.resendQuestion = this.resendQuestion.bind(this);
    }

    getNextQid() {
        this.qidx_seq += 1
        var qid = "#"+(this.qidx_seq + 1 + (Math.random()*10)).toFixed();
        return qid;
    }

    ngOnInit() {
    }

    uploadFile(event:any) {
        console.info("uploadFile: ", event);
        // this.chatService.onFileSelected(event)
        // this.router.navigate(['/chat']);
    }

    beginConversation() {
        this.qidx_seq += 1
        // console.info("this.bufMessages: ", this.bufMessages);

        var qid = "#"+(this.qidx_seq + 1 + (Math.random()*10)).toFixed()
        const msg = 'Boas,Lua. Como estas?';

        this.bufMessages.push({
            qid : qid,
            content : [msg],
            type : 'you'
        });

        this.sendQuestion(qid, msg, '');
    }

    resendQuestion(object:any) {
        console.info("resendQuestion: ", object);
        // return;

        var qid = '';
        var fqid = '';
        if (object.content&&object.content.length>0) {
            qid = object.content[0].qid;
            if (object.content.length>1) {
                fqid = object.content[1].fqid;
            }
        } else {
            qid = this.getNextQid();
        }

        const obj = {
            qid: qid,
            fqid: fqid,
            type: "you",
            content: object.text,
            perfil: 'assets/img/temp/profile.svg',
        }

        setTimeout(() => {
            // console.info("RE-SUBMIT QUESTION: ", obj);
            const is_sent = this.sendQuestion(qid, object.text[0], fqid);
            if (is_sent) {
                this.bufMessages.push(obj);
            }
        }, 120);
    }

    sendQuestion(qid:string, chatmsg:string, fqid:string) {
        if (this.is_loading) {
            return false;
        }

        this.is_loading = true;
        this.is_error = false;

        const payload:any = {
            'qid' : qid,
            'question' : chatmsg,
            'appConf' : this.appConf,
        }

        if (fqid && fqid.trim().length>0){
            payload['fqid']=fqid;
        }

        const name = this.assistantName;
        this.chatService.ExecuteTask(name, payload, (Resp:any) => {
            setTimeout(()=>{
                this.is_loading=false;
                Resp['qid'] = qid; 
                Resp['fqid'] = qid+"_answer";
                const attach = Resp;
                this.bufMessages.push(attach);
            }, 420);

        }, (error:any) => {
            this.is_error = true;
            this.is_loading = false;
            console.log('Error:', error);
            const err = {
                type:"text",
                is_error: true,
                content: {
                    text: ["Nao conseguimos falar com o assistente.",
                            "Tente novamente"]
                },
                perfil: 'assets/img/temp/profile.svg',
            }
            this.bufMessages.push(err);
        });

        return true;
    }

    // FORM CODE
    onInputChange(event: Event): void {
        this.inputValue = (event.target as HTMLInputElement).value;
        this.inputValueChange.emit(this.inputValue);
    }
    handelKeyDown(ev: any) {   
        if (ev.key === 'Enter') {
            const chatmsg = this.inputValue;
            // console.info("chatmsg: ", chatmsg);
            ev.preventDefault();
            this.qidx_seq += 1
            // console.info("this.bufMessages: ", this.bufMessages);
            if (chatmsg) {
                var qid = "#"+(this.qidx_seq + 1 + (Math.random()*10)).toFixed()
                // this.inputValue = ''; // Limpa o valor do input
                // this.btnClick.emit();
                setTimeout(()=>{                
                    var is_sent = this.sendQuestion(qid, chatmsg, '');
                    if (is_sent) {
                        this.bufMessages.push({
                            qid : qid,
                            content : [chatmsg],
                            type : 'you'
                        });
                        this.inputValue = ''; // Limpa o valor do input             
                    }
                }, 25);
            }
        }
    }

}
