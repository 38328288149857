<app-user-layout>
  <!-- Exibe a mensagem de carregamento enquanto os dados estão sendo carregados -->
  <div *ngIf="isLoading" class="loading">
    Loading...
  </div>
  
  <!-- Exibe o conteúdo principal quando isLoading for falso -->
  <div *ngIf="!isLoading">
    <!-- Título do relatório de desempenho orçamentário -->
    <h3 class="text-2xl font-bold text-left">
      Relatório de Desempenho Orçamentário (Budget vs Actual)
    </h3>
    
    <!-- Caixa com sombra e espaçamento para o conteúdo -->
    <div class="shadow-xl mt-4 p-10">
      <!-- Contêiner com fundo branco, borda cinza e bordas arredondadas -->
      <div class="bg-white border border-gray-200 rounded-lg p-4">
        
        <!-- Tabela para exibir os dados orçamentários -->
        <table>
          <!-- Cabeçalho da tabela -->
          <thead>
            <tr>
              <!-- Coluna de descrição, alinhada à esquerda, com bordas arredondadas -->
              <th class="pb-4 pt-2 pl-2 rounded-tl-3xl rounded-bl-3xl text-left">
                Descrição
              </th>
              <!-- Coluna de valor orçado -->
              <th>
                Orçado (R$)
              </th>
              <!-- Coluna de valor realizado, alinhada ao centro e com bordas arredondadas -->
              <th class="text-center rounded-tr-3xl rounded-br-3xl">
                Realizado (R$)
              </th>
            </tr>
          </thead>
          
          <!-- Corpo da tabela, iterando sobre os itens de desempenho orçamentário -->
          <tbody>
            <!-- Loop para exibir cada item do relatório -->
            <tr *ngFor="let item of budgetData.items">
              <!-- Descrição do item orçamentário -->
              <td>
                {{ item.description }}
              </td>
              
              <!-- Valor orçado para o item, alinhado ao centro -->
              <td class="text-center">
                {{ item.budgeted }}
              </td>
              
              <!-- Valor realizado para o item, alinhado ao centro -->
              <td class="text-center">
                {{ item.actual }}
              </td>
            </tr>
          </tbody>
        </table>
        
      </div>
    </div>
  </div>
</app-user-layout>
