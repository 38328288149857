<div class="p-4 flex flex-col items-center">
  <!-- Botão de Gravar -->
  <button *ngIf="isRecording === null" (click)="startRecording()" [disabled]="isRecording !== null" class="flex items-center justify-center p-2 bg-transparent border border-gray-300 rounded-full cursor-pointer hover:bg-gray-100">
    <img src="assets/img/icon/mic2.svg" alt="Start Recording" class="w-12 h-12" />
  
  </button>

  <!-- Botão de Parar -->
  <button *ngIf="isRecording" (click)="stopRecording()" class="mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600">
    <img src="assets/img/icon/pause.svg" alt="Start Recording" class="w-12 h-12" />
  </button>

  <!-- Botão de Upload -->
  <button *ngIf="!isRecording && audioChunks.length" (click)="uploadAudio()" class="mt-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600">
    Upload Audio
  </button>
</div>

<div *ngIf="audioUrl" class="mt-4">
  <h3 class="text-xl font-semibold">Recorded Audio:</h3>
  <audio [src]="audioUrl" controls class="w-full mt-2 border border-gray-300 rounded-md"></audio>
  <button (click)="startNewRecording()" class="flex items-center justify-center p-2 bg-transparent border border-gray-300 rounded-full cursor-pointer hover:bg-gray-100 mt-5">
    <img src="assets/img/icon/mic2.svg" alt="Start Recording" class="w-12 h-12" />
  </button>
</div>

<div *ngIf="serverAudioUrl" class="mt-4">
  <h3 class="text-xl font-semibold">Server Audio:</h3>
  <audio [src]="serverAudioUrl" controls class="w-full mt-2 "></audio>
</div>
