<app-user-layout>
    <div class="mt-">   
        <h3 class="text-2xl font-bold text-left"
        *ngIf="!currentReport && !enable_create_record"> 
            Relatórios
        </h3>
    </div>

    <div class="mt-4">  
        <!-- <app-tab-component></app-tab-component> -->

        <div class="grid grid-cols-1 mt-5"
            *ngIf="!currentReport && !enable_create_record">
            <div class="grid-col" 
                >
                <p class="mt-1 mb-3">
                    <span class="cursor-pointer text-[small]  bg-[#fff] px-8 py-2 rounded-3xl font-bold text-[#00527E] m-2"
                          *ngIf="is_loading===false"
                          (click)="onRefreshDocList()"
                          style="border: 1px solid rgb(0, 82, 126);"> 
                        ACTUALIZAR
                    </span>
                    <span class="cursor-pointer text-[small]  bg-[#fff] px-8 py-2  rounded-3xl font-bold text-[#00527E] m-2"
                          *ngIf="is_loading===true"
                          style="border: 1px solid rgb(0, 82, 126);"> 
                        AGUARDE...
                    </span>

                    <span class="cursor-pointer text-[small]  bg-[#fff] px-8 py-2 rounded-3xl font-bold text-[#00527E] m-2"
                        (click)="toggleCreateRep()"
                          style="border: 1px solid rgb(0, 82, 126);"> 
                        CRIAR
                    </span>
                </p>

                <div class="grid grid-cols-3 mt-5"
                    *ngIf="objectList.length > 0">
                    <div class="grid-col mb-3"
                        *ngFor="let Rep of objectList">
                        <div class="panel mb-2 cursor-pointer mr-1 p-0 border border-gray-300 pl-0 rounded-xl "
                            (click)="selectReport(Rep)">
                            <div class="panel-header pt-0 pb-2 pr-0 bg-gray-100">
                                <div class="flex flex-col">
                                    <!-- Nome -->
                                    <div style="min-height:170px;background-color: #fff;padding-top:5px;padding-bottom:5px;border-bottom:1px inset #ccc;" 
                                        class="p-1">
                                        <div style="min-height:170px;background-size:contain;background-position: center center;background-repeat: no-repeat;background-image: url('{{objectCache[Rep.Id].url}}');"
                                            *ngIf="objectCache && objectCache[Rep.Id]"
                                            >
                                        </div>
                                    </div>
                                    <div style="padding-left:0px;padding-top: 2px;border-top:1px insert #ccc" class="pt-2 hover:bg-gray-100">
                                        <p class="text-sm text-[#1422a9] font-medium pl-4 m-0">
                                            {{ Rep.object.name.toUpperCase() }}
                                        </p>
                                        <p class="text-[#1422a9] font-medium pl-4 " 
                                            style="font-size: xx-small;">
                                            {{ Rep.created_at }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="grid grid-cols-1 mt-5"
            *ngIf="enable_create_record">
           <p class="mt-1 mb-3">
                <span  class="cursor-pointer text-sm  font-bold text-[#00527E] m-2 flex flex-row  "
                   
                    (click)="toggleCreateRep()"
                     > 
                      <img src="assets/img/icon/Group.svg " class="w-4 h-4 mr-1"/>
                    VOLTAR
                </span>
            </p>

            <app-create-bi-report>
            </app-create-bi-report>
        </div>

        <div class="grid grid-cols-1 mt-5"
            *ngIf="currentReport">
            <div class="grid-col">

                <p class="mt-1 mb-3"
                    (click)="closeReport()">
                    <span class="cursor-pointer text-sm  font-bold text-[#00527E] m-2 flex flex-row  "
                          *ngIf="is_loading===true"
                         > 
                         <img src="assets/img/icon/Group.svg " class="w-4 h-4 mr-1"/>
                        VOLTAR
                    </span>
                </p>

                <!--  -->
                <app-report-dashboard 
                    [reportObj]="currentReport"
                    [onCompGoBack]="onCompGoBack">
                </app-report-dashboard>
            </div>
        </div>

        <div class="grid grid-cols-1 mt-5" 
            *ngIf="docObj">
            <div class="grid-col">
                <!-- 
                <app-alert type="info" message="Heads up!"></app-alert>
                <app-generative-ai-iterator 
                    [doc]="docObj" 
                    [assistantName]="'reports/Assistant'">
                </app-generative-ai-iterator>
                -->
            </div>
        </div>

    </div>

    <div class="mt-4">
        <!--
        <app-alert type="success" message="Well done!"></app-alert>
        <app-alert type="warning" message="Warning!"></app-alert>
        <app-alert type="error" message="Oh snap!"></app-alert>

        <app-card-list></app-card-list>
        <app-accordion></app-accordion>
        <app-tab-component></app-tab-component>
        <app-accordion></app-accordion>
        -->
    </div>

</app-user-layout>
