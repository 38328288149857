import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './views/home/home.component';
import { RoundedIconButtomComponent } from './components/rounded-icon-buttom/rounded-icon-buttom.component';
import { SharedModule } from './shared/shared.module';
import { CardChatComponent } from './components/card-chat/card-chat.component';
import { InputChatComponent } from './components/input-chat/input-chat.component';
import { KeycloakAngularModule } from 'keycloak-angular';
import { AuthInterceptor } from './services/http-interceptor.service';
import { ChatComponent } from './views/chat/chat.component';
import { ResponsesComponent } from './views/responses/responses.component';
import { DocumentAnalysisComponent } from './views/document-analysis/document.component';

import { ResponsesComponent as MsgBufComponent } from './views/msgbuf/responses.component';
import { FaturaFormComponent as CreateBIReportComp } from './components/luna-core/create-bireport/fatura-form.component';
import { GenAnalysisComponent } from './components/luna-core/gen-analysis/gen-analysis.component';

import { TextComponent } from './views/responses/views/text/text.component';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FaturaDetailsComponent } from './views/fatura/fatura-details/fatura-details.component';
import { FaturaDetailsComponent as AppDocGenReport } from './views/fatura/doc-gen-report/fatura-details.component';
import { FaturaDetailsComponent as AppDocReportEditor } from './components/luna-core/doc-report-editor/fatura-details.component';
import { FaturaDetailsComponent as AppDocReportForm } from './components/luna-core/doc-report-form/fatura-details.component';
import { DocViewComponent } from './components/luna-core/doc-view/doc-view.component';
import { AppSyncOdoo } from './components/luna-core/sync-odoo/form.component';
import { BIMetricsEditor } from './components/luna-core/bi-metrics-editor/metrics-editor.component';

import { FaturaComponent } from './views/fatura/fatura.component';
import { CommonModule } from '@angular/common';
import { AuthGuard } from './services/guard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormComponent } from './form/form.component';
import { FormComponent as DPR106FORM } from './components/luna-core/dpr-106-form/form.component';
import { FormComponent as DPR106CLI } from './components/luna-core/dpr-106-cli/form.component';
import { FormComponent as DPR106ROS } from './components/luna-core/dpr-106-rosto/form.component';
import { FormComponent as TPLREPORT_ODOO } from './components/luna-core/report-tpl-odoo/form.component';

import { GuideComponent } from './views/guide/guide.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { HelpComponent } from './views/help/help.component';
import { TrainsystemComponent } from './views/trainsystem/trainsystem.component';
import { ReportsComponent } from './views/reports/reports.component';
import { AibiComponent } from './views/aibi/aibi.component';
import { DocCenterComponent } from './views/doc-center/doc-center.component';
import { LineChartComponent } from './components/line-chart/line-chart.component';
import { PieChartComponent } from './components/pie-chart/pie-chart.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { DocumentsComponent } from './views/documents/documents.component';
import { LuaxappsComponent } from './views/luaxapps/luaxapps.component';
import { MyAccountComponent } from './views/my-account/my-account.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { CalenderComponent } from './components/calender/calender.component';
import { TabComponentComponent } from './components/tab-component/tab-component.component';
import { AiInboxComponent } from './views/ai-inbox/ai-inbox.component';
import { NotificationsComponent } from './views/notifications/notifications.component';
import { AlertasComponent } from './views/alertas/alertas.component';
import { CounterComponent } from './components/counter/counter.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { CardListComponent } from './components/card-list/card-list.component';
import { AlertComponent } from './components/alert/alert.component';
import { FaqComponent } from './components/faq/faq.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { StockTableComponent } from './components/stock-table/stock-table.component';
import { PortfolioTableComponent } from './components/portfolio-table/portfolio-table.component';
import { CrowdStrikeOutageComponent } from './components/crowd-strike-outage/crowd-strike-outage.component';
import { GenAiIteratorComponent } from './components/generative-ai-iterator/generative-ai-iterator.component';
import { GenAiIteratorComponent as NativeAppAssistant } from './components/luna-core/app-assistant/generative-ai-iterator.component';
import { OptionsTableComponent } from './components/options-table/options-table.component';
import { PlaygroundComponent } from './playground/playground.component';

import { StockPerformanceComponent } from './components/stock-performance/stock-performance.component';
import { MemgroupchatComponent } from './memgroupchat/memgroupchat.component';
import { CarousselComponent } from './components/caroussel/caroussel.component';
import { AudioComponent } from './components/audio/audio.component';
import { NavComponent } from './components/nav/nav.component';
import { StockChartComponent } from './components/stock-chart/stock-chart.component';
import { PdfPreviewComponent } from './components/pdf-preview/pdf-preview.component';
import { DynamicPageComponent } from './views/dynamic-page/dynamic-page.component';
import { BannerComponent } from './components/banner/banner.component';
import { BannerComponent as OdooApp } from './components/luna-core/odoo-app/banner.component';
import { BannerComponent as DPRApp } from './components/luna-core/dpr-app/banner.component';
import { BannerComponent as InvoiceApp } from './components/luna-core/invoice-app/comp';
import { DocExplorerFolder } from './components/luna-core/doc-explorer-folder/explorer.component';
import { AnalysisWorkComponent } from './components/luna-core/analysis-work/analysis-work.component';

import { InvoiceWizard } from './components/luna-core/invoice-wizard/COMP.component';
import { InvoiceEditor } from './components/luna-core/invoice-editor/COMP.component';
import { InvoiceAIEditor } from './components/luna-core/invoice-ai-editor/COMP.component';
import { EmailInboxApp } from './components/luna-core/email-inbox/COMP.component';

import { AppSpinner } from './components/luna-core/app-spinner/COMP.component';

import { HeroSectionComponent } from './components/hero-section/hero-section.component';
import { AnalyticsComponent } from './views/analytics/analytics.component';
import { MonitoringComponent } from './monitoring/monitoring.component';
import { LuaXlsFinancialComponent } from './lua-xls-financial/lua-xls-financial.component';
import { ReportBuilderComponent } from './report-builder/report-builder.component';
import { ReportDashboardComponent } from './components/report-dashboard/report-dashboard.component';
import { WelcomeToLuaComponent } from './welcome-to-lua/welcome-to-lua.component';
import { LunaHumanComponent } from './views/luna-human/luna-human.component';
import { VideoComponent } from './components/video/video.component';
import { LuaEntertainComponent } from './views/lua-entertain/lua-entertain.component';
import { ScreenCaptureComponent } from './components/screen-capture/screen-capture.component';
import { DocExplorerComponent } from './views/doc-explorer/doc-explorer.component';
import { ModalComponent } from './components/modal/modal.component';
import { AccountsPayableAndReceivableReportComponent } from './components/accounts-payable-and-receivable-report/accounts-payable-and-receivable-report.component';
import { BudgetVsActualReportComponent } from './components/budget-vs-actual-report/budget-vs-actual-report.component';
import { DvaComponent } from './components/dva/dva.component';
import { FinancialRatiosReportComponent } from './components/financial-ratios-report/financial-ratios-report.component';
import { InventoryReportComponent } from './components/inventory-report/inventory-report.component';
import { StatementOfChangesInEquityComponent } from './components/statement-of-changes-in-equity/statement-of-changes-in-equity.component';
import { LuaEntityComponent } from './views/lua-entities/lua-entity.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ResponsesComponent,
    TextComponent,
    ChatComponent,
    FaturaComponent,
    BIMetricsEditor,
    FaturaDetailsComponent,
    AppDocGenReport,
    AppDocReportEditor,
    AppDocReportForm,
    DocViewComponent,
    AppSyncOdoo,
    FormComponent,
    InvoiceApp,
    DPR106FORM, // for
    DPR106CLI, // cli
    DPR106ROS, // rosto
    InvoiceWizard,
    InvoiceEditor,
    InvoiceAIEditor,
    EmailInboxApp,
    AppSpinner,

    AnalysisWorkComponent,
    TPLREPORT_ODOO,
    GuideComponent,
    DashboardComponent,
    GenAnalysisComponent,
    HelpComponent,
    TrainsystemComponent,
    ReportsComponent,
    AibiComponent,
    DocCenterComponent,
    LineChartComponent,
    PieChartComponent,
    BarChartComponent,
    DocumentsComponent,
    LuaxappsComponent,
    MyAccountComponent,
    CalenderComponent,
    TabComponentComponent,
    AiInboxComponent,
    NotificationsComponent,
    AlertasComponent,
    CounterComponent,
    AccordionComponent,
    CardListComponent,
    AlertComponent,
    FaqComponent,
    StockTableComponent,
    PortfolioTableComponent,
    CrowdStrikeOutageComponent,
    GenAiIteratorComponent,
    NativeAppAssistant,
    OptionsTableComponent,
    PlaygroundComponent,
    StockPerformanceComponent,
    MemgroupchatComponent,
    CarousselComponent,
    MsgBufComponent,
    CreateBIReportComp,
    AudioComponent,
    NavComponent,
    StockChartComponent,
    PdfPreviewComponent,
    DynamicPageComponent,
    BannerComponent,
    OdooApp,
    DPRApp,
    HeroSectionComponent,
    AnalyticsComponent,
    MonitoringComponent,
    LuaXlsFinancialComponent,
    ReportBuilderComponent,
    ReportDashboardComponent,
    WelcomeToLuaComponent,
    LunaHumanComponent,
    VideoComponent,
    LuaEntertainComponent,
    ScreenCaptureComponent,
    DocumentAnalysisComponent,
    DocExplorerFolder,
    DocExplorerComponent,
    ModalComponent,
    /* Reports */
    AccountsPayableAndReceivableReportComponent,
    BudgetVsActualReportComponent,
    DvaComponent,
    FinancialRatiosReportComponent,
    InventoryReportComponent,
    StatementOfChangesInEquityComponent,
    LuaEntityComponent,
  ],
  imports: [
    MatMenuModule, // Adicionar aqui
    MatIconModule,
    FormsModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatInputModule,
    MatIconModule,
    KeycloakAngularModule,
    HttpClientModule,
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    RoundedIconButtomComponent,
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    HighchartsChartModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),

    CardChatComponent,
    InputChatComponent,
    HighchartsChartModule,
    BrowserAnimationsModule,

    // FaturaComponent,
    // FaturaDetailsComponent
  ],
  providers: [
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initializeKeycloak,
    //   multi: true,
    //   deps: [KeycloakService],
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    AuthGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
