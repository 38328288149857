import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ChatService } from 'src/app/services/chat.service';


@Component({
  selector: 'app-explorer-folder',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class DocExplorerFolder implements OnInit {
    @Input() folder: any;

    is_error:boolean = false;
    is_ready:boolean = false;
    is_created:boolean = false;
    is_loading:boolean = false;
    is_preview_err:boolean = false;
    objectList:any = null;

    currentFile:any = null

    is_uploading:boolean = false;
    is_upload_error:boolean = false;
    is_upload_succ:boolean = false;

    constructor(
        private fb: FormBuilder,
        private chatService: ChatService,
        private sanitizer: DomSanitizer
    ) {
    }

    ngOnInit() {
        this.LoadDocuments()
    }

    closeCurrentFile() {
        this.currentFile=null
    }

    OpenFile(item:any) {
        console.info("OPEN-FILE: ", item)
        this.currentFile = null;
        setTimeout(()=>{
            this.currentFile = item;
        }, 120);
    }

    LoadDocuments() {
        // console.info("LoadDocuments():");
        // return;
        if (this.is_loading) {
            return;
        }

        this.is_loading = true;
        this.is_error = false;
        this.objectList = [];

        const payload = {
            'ops' : 'GetDocsInFolder',
            'folderid' : this.folder.id,
        }

        this.chatService.ExecuteTask("lua/DocSystemAPI", payload, (doc_resp:any) => {
            setTimeout(()=>{
                this.is_loading = false;
                if (doc_resp.hasOwnProperty("Status")) {
                    const Status = doc_resp["Status"];
                    if (Status=="OK" && doc_resp.hasOwnProperty("list")) {
                        // const list = doc_resp['list'];
                        this.objectList = doc_resp;
                    } else {
                        this.is_error = true;
                    }
                } else {
                    this.is_error = true;
                }

            }, 123);

        }, (error:any) => {
            console.info("error: ", error)
            this.is_loading = false
            this.is_error = true
        });
    }

    uploadDoc(event:any) {
        const input = event.target as HTMLInputElement;
        var file:any = false;
        if (input.files && input.files.length > 0) {
            file = input.files[0];
        }

        if (!file) {
            return
        }

        this.is_uploading=true;
        this.is_upload_error=false;
        this.is_upload_succ = false;

        const folderName:string = `${this.folder['name']}`;
        this.chatService.UploadFolder(folderName, file, (doc_resp:any) => {
            console.info("doc_resp: ", doc_resp);
            this.is_uploading=false;
            this.is_upload_succ = true;

            setTimeout(()=>{
                this.LoadDocuments();
                this.is_upload_succ = false;
            }, 856);
        }, (error:any) => {
            this.is_uploading=false;
            this.is_upload_error=true;
        })
    }
}
