import { Component, Input } from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss']
})
export class ReportsComponent {
    @Input() docObj:any = {
        "created_by":"comayrton",
        "fileid":"2431-4c64-9794-7f2a244ce113",
        "filename":"2431-4c64-9794-7f2a244ce113.pdf",
        "realname":"FacturaTecnicil.PDF",
        "tms":"2024-07-19 19:07:05",
        "no_store":true
    }

    currentReport:any = null
    is_loading:boolean = false;
    show_group_docs: boolean = false;
    is_error:boolean = false;
    enable_create_record:boolean = false;
    errors: any[] = []

    objectList:any[] = []
    loadCache:any = {}
    objectCache:any = {}
    currentDocOpen:any = null

    constructor(
        private chatService: ChatService,
        public translate: TranslateService
    ) {
        this.onCompGoBack = this.onCompGoBack.bind(this);
    }

    toggleCreateRep() {
        this.enable_create_record = !this.enable_create_record;
    }

    ngOnInit() {
        this.openItem = this.openItem.bind(this);
        this.onCompGoBack = this.onCompGoBack.bind(this);
        this.LoadBReports(true);
    }

    onCompGoBack() {
        this.currentReport = null;
    }

    selectReport(repObj:any) {
        console.info("selectReport: ", repObj);
        this.currentReport = repObj;
        // this.currentReport = null;
        // setTimeout(()=>{
        // }, 120);
    }

    closeReport() {
        this.currentReport = null;
    }


    openItem(item:any) {
    }

    onRefreshDocList() {
        this.objectList = [];
        this.LoadBReports();
    }

    LoadBReports(init_first=false) {
        if (this.is_loading) {
            return;
        }

        this.is_loading = true;
        this.is_error = false;
        this.objectList = [];

        const payload = {
            ops : 'QueryBIReport',
            params : {
                context : 'BIREP:',
            },
            filters : {},
        }

        const assistName = "lua/BIServiceAPI";
        this.chatService.ExecuteTask(assistName, payload, (doc_resp:any) => {
            setTimeout(()=>{
                this.is_loading = false;
                this.objectCache = {}
                if (doc_resp.hasOwnProperty("Status")) {
                    const Status = doc_resp["Status"];
                    if (Status=="OK" && doc_resp.hasOwnProperty("object_")) {
                        const list = doc_resp['object_'];
                        list.forEach((Rep:any)=>{
                            Rep.Id = Rep.context+"#"+Rep.context_id;
                        })

                        this.objectList = list;

                        list.forEach((Rep:any) => {
                            const xRep:any = JSON.parse(JSON.stringify(Rep))
                            xRep.q_cache=true;
                            xRep.object.groups = [
                                {"groupname":"supplier_details"},
                                {"groupname":"product_list"},
                                {"groupname":"payment_details"},
                                {"groupname":"customer_details"},
                                {"groupname":"modelo_106_supplier"},
                                {"groupname":"bank_details"},
                                {"groupname":"general_details"},
                            ];

                            const payload = {
                                'ops' : 'BIResumeReport',
                                'reportObj' : xRep,
                            }

                            this.chatService.ExecuteTask("reports/SystemAPI", payload, (graph:any) => {
                                // console.info(Rep.object.name + " [graph]: ", graph);
                                if (graph.Status=="OK") {
                                    if (graph.object_ && graph.object_.list && graph.object_.list.length>0) {
                                        const obj=graph.object_.list[0];
                                        this.objectCache[xRep.Id] = {
                                            'url' : obj['url']
                                        }                                        
                                    }
                                }
                            }, (error:any) => {
                            });
                        })

                    } else {
                    }
                }

            }, 123);

        }, (error:any) => {
            console.info("error: ", error)
            this.is_loading = false
            this.is_error = true
        });
    }

}

