import { Component, Input, OnInit, ElementRef, ViewChild  } from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

@Component({
  selector: 'app-ai-inbox',
  templateUrl: './ai-inbox.component.html',
  styleUrls: ['./ai-inbox.component.scss']
})
export class AiInboxComponent implements OnInit {
    is_loading:boolean = false;
    is_error:boolean = false
    threadList:any = null
    convCache:any = {}
    loadCache:any = {}
    currentThread:any = null
    selectedThread: any;

    is_loading_conv:boolean = false
    is_loading_conv_error:boolean = false

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private chatService: ChatService,
        public translate: TranslateService
    ) {   
    }

    loadThreads() {
        if (this.is_loading) {
            return;
        }

        this.is_loading=true;
        this.is_error=false;
        this.threadList = []

        const payload = {
            'ops' : 'ScanUserThreads',
        }

        this.chatService.ExecuteTask("lua/InboxService", payload, (doc_resp:any) => {
            setTimeout(()=>{
                this.is_loading=false;
                console.info("InboxService: ", doc_resp);
                if (doc_resp.Status&&doc_resp.Status=="OK") {
                    this.threadList = doc_resp.threads;
                } else {
                    this.is_error = true;
                }

            }, 380)

        }, (error:any) => {
            setTimeout(()=>{
                this.is_loading=false;
                this.is_error=true;
            }, 380)
            console.error("E: ", error)
        });
    }

    goHome() {
        this.router.navigate(['/luaxapps']);
    }

    openThread(thread:any) {
        this.currentThread = null;
        const from_user = thread.from_user;
        console.info("openThread: ", thread);
        setTimeout(()=>{
            this.currentThread = {
                from_user: from_user
            }
            this.LoadConversation(thread);
        }, 10);

        // const from_user = thread.from_user;
        // const payload = {
        //     'ops' : 'LoadUserThreads',
        //     'from_user' : thread.from_user,
        // }

        // this.currentThread = {
        //     from_user: from_user
        // }

        // this.is_loading_conv = true;
        // this.is_loading_conv_error=false;

        // this.chatService.ExecuteTask("lua/InboxService", payload, (doc_resp:any) => {
        //     setTimeout(()=>{
        //         this.is_loading_conv = false;
        //         console.info("openThread: ", doc_resp);
        //         if (doc_resp.Status&&doc_resp.Status=="OK") {
        //             this.convCache[from_user] = doc_resp.conversation;
        //         } else {
        //             this.is_loading_conv_error=true;
        //         }
        //     }, 380);

        // }, (error:any) => {
        //     setTimeout(()=>{
        //         this.is_loading_conv = false;
        //         this.is_loading_conv_error=true;
        //     }, 380)
        // });
    }

    LoadConversation(thread:any) {
        const from_user = thread.from_user;
        const payload = {
            'ops' : 'LoadUserThreads',
            'from_user' : thread.from_user,
        }

        this.is_loading_conv = true;
        this.is_loading_conv_error=false;

        this.chatService.ExecuteTask("lua/InboxService", payload, (doc_resp:any) => {
            setTimeout(()=>{
                this.is_loading_conv = false;
                console.info("openThread: ", doc_resp);
                if (doc_resp.Status&&doc_resp.Status=="OK") {
                    this.convCache[from_user] = doc_resp.conversation;
                } else {
                    this.is_loading_conv_error=true;
                }
            }, 380);

        }, (error:any) => {
            setTimeout(()=>{
                this.is_loading_conv = false;
                this.is_loading_conv_error=true;
            }, 380)
        });
    }

    ngOnInit() {
        // this.openFileItem = this.openFileItem.bind(this);
        this.loadThreads()
    }
 
     
    
        selectThread(thread: any) {
            this.selectedThread = thread;
            this.openThread(thread); // Chama a função original
        }
  
    
}
