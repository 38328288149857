import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

//      supplier_name VARCHAR(250) NOT NULL,
//      doc_id VARCHAR(50) NOT NULL,
//      supplier_nif VARCHAR(50),
//      supplier_id VARCHAR(50), -- entity_id,
//      customer_name VARCHAR(250),
//      customer_nif VARCHAR(50),
//      customer_id VARCHAR(50), -- entity_id

//      invoice_state VARCHAR(50),
//      invoice_date VARCHAR(50),
//      invoice_due_date VARCHAR(50),
//      invoice_id  VARCHAR(250),
//      invoice_origin  VARCHAR(250),
//      invoice_ref  VARCHAR(250),
//      payment_ref  VARCHAR(250),

//      payment_details TEXT, -- json
//      bank_details TEXT, -- json
//      product_details TEXT, -- json
//      narration TEXT, -- html resume

//      invoice_total  decimal(10, 2) default 0,
//      invoice_lang  decimal(10, 2) default 0,
//      invoice_subtotal  decimal(10, 2) default 0,
//      invoice_iva  decimal(10, 2) default 0,
//      currency  decimal(10, 2) default 'CVE',

@Component({
    selector: 'app-invoice-wizard',
    templateUrl: './COMP.component.html',
})
export class InvoiceWizard implements OnInit {
    @Input() editObj:any = null
    is_loading:boolean = false
    is_error:boolean = false
    is_ready:boolean = false
    invoiceNew:any = null
    invoiceDoc:any = null
    docList:any = null

    analysisForm: FormGroup;
    is_generating:boolean = false;
    is_invalid:boolean = false;

    wizardMode:string = "INIT"

    constructor(
        private chatService: ChatService,
        private fb: FormBuilder
    ) {
        this.analysisForm = this.fb.group({
            annotation: ['', Validators.required],
        });
        this.openSelectDoc=this.openSelectDoc.bind(this);
    }

    ChangeDoc() {
        // if (!confirm("Deseja mesmo mudar de documento?")) {
        //     return;
        // }
        this.invoiceDoc = null; // triggers list
    }


    SetMode(mode:any) {
        this.wizardMode = mode;

        if (mode=='SELECT_DOC' && this.docList==null) {
            this.LoadDocuments()
        };
    }

    ngOnInit() {
        const Lib:any = window;
        const today:any = Lib.moment().format("YYYY-MM-DD");

        this.invoiceNew = {
            supplier_name: '',
            supplier_nif: '',
            supplier_id: '',
            customer_name: '',
            customer_nif: '',
            customer_id: '',

            invoice_state: 'DRAFT',
            invoice_date: today,
            invoice_due_date: today,
            invoice_id: 'DOC_ID',
            invoice_origin: 'ERP',
            invoice_ref: 'REF',
            payment_ref: 'PAYMENT_REF',

            invoice_total: 0,
            invoice_lang: 'PT',
            invoice_subtotal: 0,
            invoice_iva: 0.15,
            currency: 'CVE',
        }
    }

    openSelectDoc(doc:any) {
        console.info("doc: ", doc);
        this.invoiceDoc = doc;
    }

    LoadDocuments() {
        if (this.is_loading) {
            return;
        }

        this.is_loading = true;
        this.is_error = false;
        this.docList = [];

        const payload = {
            'ops' : 'ListFiles',
            'filters' : {},
        }

        this.chatService.ExecuteTask("lua/DocSystemAPI", payload, (doc_resp:any) => {
            setTimeout(()=>{
                this.is_loading = false;
                if (doc_resp.hasOwnProperty("Status")) {
                    const Status = doc_resp["Status"];
                    if (Status=="OK" && doc_resp.hasOwnProperty("list")) {
                        const list = doc_resp['list'];
                        this.docList = list;
                    } else {
                        this.is_error = true;
                    }
                }

            }, 123);

        }, (error:any) => {
            console.info("error: ", error)
            this.is_loading = false
            this.is_error = true
        });
    }

}

