import { Component, Input, OnInit } from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
    selector: 'app-report-dashboard',
    templateUrl: './report-dashboard.component.html',
    styleUrls: ['./report-dashboard.component.scss']
})

// XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
// XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
// XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
// fatura7-efactura.pdf    546a-46d6-a6d3-498835b4ecb5 546a-46d6-a6d3-498835b4ecb5.pdf     2024-07-20 21:10:39 
// NOSI.pdf                4ea8-4370-b83b-19de4db36a11 4ea8-4370-b83b-19de4db36a11.pdf     2024-07-20 02:57:59 
// FacturaTecnicil.PDF     f146-4dfa-a409-2a732dde7235 f146-4dfa-a409-2a732dde7235.pdf     2024-07-19 23:55:26 
// invoice_1-1.pdf         fc31-4756-90f0-3da6c2210a4f fc31-4756-90f0-3da6c2210a4f.pdf     2024-07-19 21:06:52 
// ads-elson.pdf           a2b6-430b-a075-ce2ac3f94d01 a2b6-430b-a075-ce2ac3f94d01.pdf     2024-07-19 11:41:41 
// invoice_2.pdf           089b-4176-8a0b-282426adc25e 089b-4176-8a0b-282426adc25e.pdf     2024-07-19 08:42:04 
// XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
// XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
// XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
export class ReportDashboardComponent implements OnInit {
    // @Input() reportObj:any = null;
    @Input() onCompGoBack:any = null;
    @Input() reportObj:any = null;
    // @Input() reportObj:any = {
    //     context    : "BI_DEMO",
    //     context_id : "2024-07-21T00:00:00",
    //     created_at : "2024-07-21T10:26:41",
    //     tms : "2024-07-2113:26:41",
    //     object:{
    //         name:"Meu Relatorio 912323",
    //         type:"DEFAULT",
    //         params:{
    //             start_date:"2024-07-01T00:00:00",
    //             end_date:"2024-07-24T23:59:59",
    //             context:"BI_DEMO"
    //         },
    //         files : [
    //         ],
    //         groups:[
    //             {"groupname":"supplier_details"},
    //             {"groupname":"product_list"},
    //             {"groupname":"payment_details"},
    //             {"groupname":"customer_details"},
    //             {"groupname":"modelo_106_supplier"},
    //             {"groupname":"bank_details"},
    //             {"groupname":"tax_details"},
    //             {"groupname":"total_with_tax"},
    //             {"groupname":"total_without_tax"},
    //             {"groupname":"general_details"},
    //         ],
    //         rules:[{
    //             "prompt":"Total should not exceed 10000 CVE",
    //             "params":[{"a":12,"vv":23}]
    //         }],
    //         comments:""
    //     },
    // }

    is_loading:boolean = false;
    is_error:boolean = false;
    errors: any[] = []
    objectList:any[] = []
    loadCache:any = {}
    objectCache:any = {}

    constructor(
        private chatService: ChatService,
        private router: Router,
    ) { 
    }

    ngOnInit(): void {
        const reportObj=this.reportObj;
        if (!reportObj.object.groups || reportObj.object.groups.length==0) {
            reportObj.object.groups = [
                {"groupname":"supplier_details"},
                {"groupname":"product_list"},
                {"groupname":"payment_details"},
                {"groupname":"customer_details"},
                {"groupname":"modelo_106_supplier"},
                {"groupname":"bank_details"},
                {"groupname":"tax_details"},
                {"groupname":"total_with_tax"},
                {"groupname":"total_without_tax"},
                {"groupname":"general_details"},
            ];
        }

        // console.info("reportObj: ", this.reportObj);
        setTimeout(()=>{
            this.updateReports()
        }, 230);
    }

    updateReports() {
        if (this.is_loading) {
            return;
        }

        this.is_loading = true;
        this.is_error = false;
        // this.objectList = [];

        const payload = {
            'ops' : 'GenerateReports',
            'reportObj' : this.reportObj,
        }

        const assistName = "reports/SystemAPI";
        this.chatService.ExecuteTask(assistName, payload, (doc_resp:any) => {
            setTimeout(()=>{
                if (doc_resp.Status=="OK" && doc_resp.object_) {
                    this.objectList = doc_resp.object_;
                    this.reportObj.q_cache = true;
                } else {
                    this.is_error = true;
                }             
            }, 120);

            this.is_loading = false; 

        }, (error:any) => {
            this.is_loading = false;
            this.is_error = true;
        });
    }

    renderReport(groupRef:any, groupDoc:any) {        
        console.info("renderReport(groupRef, groupDoc): ", groupRef, groupDoc);
    }

    goBack() {
        // this.router.navigate(['/report']);
        if (this.onCompGoBack) {
            this.onCompGoBack()
        }
    }
}


