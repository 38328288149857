<app-user-layout>
	<div>
	    <p class="mt-4 mb-5" (click)="goBack()">
	        <span
	        class="cursor-pointer text-sm  font-bold text-[#00527E] m-2 flex flex-row  ">
	        <img src="assets/img/icon/Group.svg " class="w-4 h-4 mr-1"/>
	            VOLTAR
	        </span>
	    </p>

	    <div class="flex flex-row justify-between">
            <!-- <img src="assets/img/icon/chart-square.svg" alt="LUA BI LOGO" class="w-20 h-25 sm:w-40 sm:h-50 pull-left" /> -->
            <h1 class="text-4xl text-left font-extrabold text-[#414141] mt-3">
                Lua - Business Inteligence
            </h1>

	        <span (click)="UpdateBI()"
	            class="cursor-pointer flex flex-row rounded-3xl px-6 py-2 text-sm p-2 bg-[#00527E] font-bold text-white m-2 ml-0 border border-[#00527E]">
	            <img src="assets/img/icon/Arrowswhite.svg" alt="" class="w-5 h-5 mr-2 mt-[0.5]" />
	            ACTUALIZAR
	        </span>
	    </div>

	    <h2 class="text-left opacity-70">
	        <span class="cursor-pointer">Apps</span>
	        /&nbsp;
	        <span class="text-[#00527E]">LUA BI</span>
	    </h2>
	</div>

    <div class="grid grid-cols-1 mt-3">
        <div class="grid-col">
            <app-alert type="warning" 
                message="Aguarde"
                *ngIf="is_loading"></app-alert>

            <app-alert type="error" 
                message="Nao foi possivel trazer os relatorios"
                *ngIf="is_error"></app-alert>
        </div>

        <div class="grid-col mt-3" *ngIf="reportData">
            <div *ngIf="reportData['invoice']">


                <div class="grid grid-cols-3">
                    <div class="grid-col p-2">
                        <!-- <app-bar-chart></app-bar-chart> -->
                        <div class="text-center border-gray-300 shadow-xl"
                            style="padding:20px;border-radius: 8px;background-color:#fff;">
                            <h1 style="font-size:75px;font-weight:bold;">
                                {{ reportData['invoice'].count | number }}
                            </h1>
                            <p class="opacity-30" style="font-size:small">Faturas</p>
                        </div>
                    </div>

                    <div class="grid-col p-2">
                        <!-- <app-pie-chart></app-pie-chart> -->
                        <div class="text-center border-gray-300 shadow-xl"
                            style="padding:20px;border-radius: 8px;background-color:#fff;">
                            <h1 style="font-size:72px;font-weight:bold;">{{ reportData['invoice'].total | number }}</h1>
                            <p class="opacity-30" style="font-size:small">Total das Faturas</p>
                        </div>
                    </div>

                    <div class="grid-col p-2">
                        <!-- <app-line-chart></app-line-chart> -->
                        <div class="text-center border-gray-300 shadow-xl"
                            style="padding:20px;border-radius: 8px;background-color:#fff;">
                            <h1 style="font-size:75px;font-weight:bold;">{{ reportData['invoice'].supplier_dist.length | number }}</h1>
                            <p class="opacity-30" style="font-size:small">
                                Fornecedores Distintos
                            </p>
                        </div>
                    </div>

                    <!-- <app-calender></app-calender> -->
                </div>

                <div class="grid grid-cols-3">
                    <div class="grid-col p-2">                    
                        <div class="text-center border-gray-300 shadow-xl"
                            style="padding:20px;min-height: 120px;border-radius: 8px;background-color:#fff;">
                            <!-- <app-pie-chart></app-pie-chart> -->

                            <div *ngIf="Graphs['supplier']">
                                <highcharts-chart [Highcharts]="Highcharts"
                                  [options]="Graphs['supplier'].options"
                                  style="height:250px;display: block;"
                                  class="w-full">
                                </highcharts-chart>
                                <!-- <div id="supplier_graph" style="height:250px;display: block;"></div> -->
                                <!--
                                <app-pie-chart [chartOptions]="Graphs['supplier'].options">
                                </app-pie-chart>
                                -->
                            </div>

                            <p class="opacity-30" style="font-size:small">
                                Por Fornecedor
                            </p>
                        </div>
                    </div>

                    <div class="grid-col p-2">                    
                        <div class="text-center border-gray-300 shadow-xl"
                            style="padding:20px;min-height: 120px;border-radius: 8px;background-color:#fff;">
                            <!-- <app-pie-chart></app-pie-chart> -->

                            <div *ngIf="Graphs['folders']">
                                <highcharts-chart
                                    id="234"
                                  [Highcharts]="Highcharts"
                                  [options]="Graphs['folders'].options"
                                  style="height:250px;display: block;"
                                  class="w-full">
                                </highcharts-chart>
                                <!-- <div id="folder_graph" style="height:250px;display: block;"></div> -->
                            </div>

                            <p class="opacity-30" style="font-size:small">Por Pasta</p>
                        </div>
                    </div>

                    <div class="grid-col p-2">                    
                        <div class="text-center border-gray-300 shadow-xl"
                            style="padding:20px;min-height: 120px;border-radius: 8px;background-color:#fff;">
                            <!-- <app-pie-chart></app-pie-chart> -->
                            <div *ngIf="Graphs['state']">
                                <highcharts-chart
                                    id="234"
                                  [Highcharts]="Highcharts"
                                  [options]="Graphs['state'].options"
                                  style="height:250px;display: block;"
                                  class="w-full">
                                </highcharts-chart>
                                <!-- <div id="state_graph" style="height:250px;display: block;"></div> -->
                            </div>
                            <p class="opacity-30" style="font-size:small">
                                Estado das faturas
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <!--
    -->

</app-user-layout>