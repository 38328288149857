import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ChatService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

/*  
LOCAL CONTEXT DOC BECAUSE I HAVE GOLDFISH MEMORY. 

FOR APP_RECENT_PROMPTS, we have these parameters

@Input() prompts: any[] = [
    // { key: 'PROMPTS.invoiceDetails', url: '/assistant/chat', title: 'Learn', img: 'assets/img/icon/assistantCircuit.svg' },
    // { key: 'PROMPTS.paymentDetails', url: '/assistant/chat', title: 'Learn', img: 'assets/img/icon/assistantCircuit.svg' },
    // { key: 'PROMPTS.bankDetails', url: '/assistant/chat', title: 'Learn', img: 'assets/img/icon/assistantCircuit.svg' },
    // { key: 'PROMPTS.productList', url: '/assistant/chat', title: 'Learn', img: 'assets/img/icon/assistantCircuit.svg' },
  ];

  @Input() prompts_v2: any[] = [
    // { key: 'PROMPTS_V2.sendInvoice', url: '/assistant/chat', title: 'Learn', img: 'assets/img/icon/assistantCircuit.svg' },
    // { key: 'PROMPTS_V2.sendProduct', url: '/assistant/chat', title: 'Learn', img: 'assets/img/icon/assistantCircuit.svg' },
    // { key: 'PROMPTS_V2.odooIntegration', url: '/assistant/chat', title: 'Learn', img: 'assets/img/icon/assistantCircuit.svg' },
    // { key: 'PROMPTS_V2.model', url: '/assistant/chat', title: 'Learn', img: 'assets/img/icon/assistantCircuit.svg' },
  ];

*/
export class HomeComponent implements OnInit {
    samples:any[] = [];
    currentPrompt:string = ""
    is_loading:boolean = false
    is_error:boolean = false
    onboard_prof:any = null
    onbd_prompts:any[] = []

    constructor(
        private router: Router,
        private chatService: ChatService
    ) { 
        // console.info("[home] chatService: ", chatService);
    }

    refreshONB() {
        // console.info("refreshONB: ");
        this.ExecuteOnboard();
    }

    queryDocumentObject(fileid:any, objectName:any) {
    }

    AV_SOON(name:any) {
        alert(name+ " vai estar disponivel em breve. Obrigado pelo interesse.");
    }

    OpenApp(name:any) {
        this.router.navigate(['/apps/'+name+"/index/0.0.1-SNAPSHOT"]);        
        // alert(name+ " vai estar disponivel em breve. Obrigado pelo interesse.");
    }

    openPage(name:string) {
        this.router.navigate([name]);        
    }

    openHelpGuide() {
        this.router.navigate(['/guide']);
    }

    openMode(mode:any) {
        // console.info("OPEN-MODE: ", mode)
    }

    uploadFile(event:any) {
        console.info("uploadFile: ", event);
        this.chatService.onFileSelected(event)
        this.router.navigate(['/chat']);
    }

    ngOnInit() {
        this.ExecuteOnboard()
    }

    executeGroup(groupDoc:any) {
        console.info("executeGroup: ", groupDoc)
    }

    openDoc(doc:any,i:any) {
        setTimeout(()=>{
            // this.router.navigate(['document-analysis', 'doc_id='+]);
            window.open('document-analysis?doc_id='+doc['fileid'])
        }, 70);
    }

    openMemConversation(memory_item: any) {
        // console.info('openMemConversation: ', memory_item);
        const prompt = memory_item['prompt']
        this.chatService.onSend(prompt);

        setTimeout(()=>{
            this.router.navigate(['chat']);
        }, 120);
    }

    ExecuteOnboard() {
        if (this.is_loading) {
            return;
        }

        this.is_loading = true
        this.is_error = false
        this.onboard_prof = null;

        this.chatService.OnboardUser((onboard_resp:any) => {
            setTimeout(() => {
                const onbd_prompts = onboard_resp['prompts'] 
                this.onbd_prompts = onbd_prompts;

                const stm_stream = onboard_resp['stm_stream'];
                var stm_stream_l:any[] = [];

                if (stm_stream) {
                    const keys = Object.keys(stm_stream);
                    stm_stream_l = keys.map(function(K){
                        const obj = stm_stream[K];
                        return {
                            'key':'', 
                            'url': '/assistant/chat', 
                            'title':  obj['q'],
                            'prompt' : obj['q'], 
                            'answer' : obj['a'], 
                            'tms' : K,
                            'id' : obj['Id'],
                            'img': 'assets/img/icon/assistantCircuit.svg' 
                        }
                    })
                }

                // console.info("stm_stream_l: ", stm_stream_l);

                this.onboard_prof = {
                    'title' : onboard_resp['answer'],
                    'answer_details' : onboard_resp['answer_details'],
                    'history' : onboard_resp['history'],
                    'stm_stream' : stm_stream_l,
                    // 'stm_stream' : onboard_resp['stm_stream'],
                    'preferences' : onboard_resp['preferences'],
                    'recent_docs' : onboard_resp['recent_docs'],
                    'profile' : onboard_resp['profile'],
                }
                this.is_loading = false;
            }, 150);

        }, (error:any) => {
            console.error("error: ", error)
            this.is_loading = false
            this.is_error = true
        });
    }

    goToChat(){
        if(this.currentPrompt?.trim() !=""){
            this.router.navigate(['assistant/chat'],{queryParams:{prompt:this.currentPrompt}});
        }
    }
}
