import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ChatService } from 'src/app/services/chat.service';


@Component({
  selector: 'app-doc-view',
  templateUrl: './doc-view.component.html',
})
export class DocViewComponent implements OnInit {
    @Input() folder: any;
    @Input() doc: any;
    show_group_docs: boolean = false;
    is_error:boolean = false;
    errors: any[] = []
    onRefreshCall:any = () => {}
    objectList:any[] = []
    procGroup:any = null
    objectCache:any = {};
    loadCache:any = {};
    objectStream:any = null;
    is_creating:boolean = false;
    preview_url:any = null;
 
    is_run_preview:boolean = false
    is_preview_err:boolean = false
    previewPDF:any = null
    xlsGrid:any = null

    is_loading:boolean = false;
    is_loading_errors:boolean = false;

    constructor(
        private fb: FormBuilder,
        private chatService: ChatService,
        private sanitizer: DomSanitizer
    ) {
    }

    ngOnInit() {
        // console.info("doc-view: ", this.doc);
        // this.LoadDocuments()
        this.QueryReports()
    }

    genPreview() {
        const doc:any = this.doc;
        var filename:any = '';
        if (doc.title) {
            filename=doc.title.toLowerCase()
        } else if (doc.name) {
            filename=doc.name.toLowerCase();
        } else if (doc.filename) {
            filename=doc.filename.toLowerCase();
        } else if (doc.realname) {
            filename=doc.realname.toLowerCase();
        }

        if (filename.endsWith('.pdf')) {
            this.genPDFPreview();
        } else if (filename.endsWith(".xls")||filename.endsWith(".xlsx")) {
            this.genXLSPreview();
            // application/vnd.ms-excel
        } else {
            alert("Ficheiro "+filename+ " nao suportado! Apenas Excel e PDF.");
        }
    }

    genXLSPreview() {
        // from: https://docs.sheetjs.com/docs/demos/grid/xs#reading-data and
        //       https://docs.sheetjs.com/xspreadsheet/
        if (this.is_run_preview) {
            return;
        }

        const doc:any = this.doc;

        this.is_run_preview=true;
        this.is_preview_err=false;
        this.preview_url=null;

        const fileid:any=this.doc.id;
        const payload = {
            // 'ops' : 'GetFilePublicUrl',
            'ops' : 'GenPDFPreview',
            'fileid' : fileid,
        }

        this.chatService.ExecuteTask("lua/DocSystemAPI", payload, (doc_resp:any) => {
            this.is_run_preview=false;
            console.info("genPreview: ", doc_resp);
            if (doc_resp.Status!='OK') {
                this.is_preview_err=true;
                return;
            }

            const url:any = doc_resp.preview_url;
            fetch(url).then((res:any) => {
                if(!res.ok) {
                    throw new Error("fetch failed");
                }
                return res.arrayBuffer();

            }).then((ab:any) => {
                this.is_run_preview=false;
                // have to make this hack to please ts compiler
                const Lib:any = window; 
                const XLSX:any = Lib.XLSX;

                try {
                    // var data = new Uint8Array(ab);
                    const elId:any = 'xlsp-'+doc.id;
                    const node:any = document.getElementById(elId);
                    // var wb:any = XLSX.read(data, {type:"array"});
                    // const ws = wb.Sheets[wb.SheetNames[0]];
                    // const html_buf = XLSX.utils.sheet_to_html(ws);
                    // if (node) {
                    //     node.innerHTML = html_buf;
                    // }
                    this.preview_url=url;
                    const grid = Lib.x_spreadsheet(node);
                    // grid.loadData(Lib.stox(XLSX.read(data, {type:'array'})));
                    grid.loadData(Lib.stox(XLSX.read(ab)));
                    this.xlsGrid = grid;

                } catch (e) {
                    this.is_preview_err=true;
                }

            }, (Error:any) => {
                this.is_run_preview=false;
                this.is_preview_err=true;
            });

        }, (error:any) => {
            this.is_run_preview=false;
            this.is_preview_err=true;
        }); 
    }

    genPDFPreview() {
        if (this.is_run_preview) {
            return;
        }

        this.is_run_preview=true;
        this.is_preview_err=false;
        this.previewPDF=null;

        const fileid:any=this.doc.id;
        const payload = {
            'ops' : 'GenPDFPreview',
            'fileid' : fileid,
        }

        this.chatService.ExecuteTask("lua/DocSystemAPI", payload, (doc_resp:any) => {
            this.is_run_preview=false;
            console.info("genPreview: ", doc_resp);
            this.previewPDF=doc_resp;

        }, (error:any) => {
            this.is_run_preview=false;
            this.is_preview_err=true;
            console.error("E: ", error)
        });        
    }

    closePreview() {
        this.is_run_preview=false;
        this.is_preview_err=false;
        this.previewPDF=null;

        if (this.xlsGrid) {
            // clean
        }

        if (this.preview_url) {
            const doc:any=this.doc;
            const elId:any = 'xlsp-'+doc.id;
            this.preview_url=null;
            const node:any = document.getElementById(elId);
            if (node) {
                node.innerHTML = '';
            }
        }
        
        this.xlsGrid = null;
    }

    createDocReport(details:any) {
    }

    QueryReports() {
        const fileid = this.doc.id;
        const objectName = "REPORTS";
        const payload:any = {
            'ops' : 'QueryDocObject',
            'fileid' : fileid,
            'objectName' : objectName,
            'filters' : {},
        }

        this.loadCache = null;
        this.is_loading_errors = false;
        this.is_loading = true;

        this.chatService.ExecuteTask("lua/DocSystemAPI", payload, (doc_resp:any) => {
            this.is_loading = false;
            // console.info("queryDocumentObject: ", doc_resp)
            const object_:any = doc_resp.object_ || {};
            const obj:any = {
                'Status' : 'LOADED',
                '_object' : object_,
            }

            // console.info("object: ", object_);
            if (object_ && object_.Status=="OK") {
                const info:any = object_.info;
                console.info("info: ", info);
                // if (info && (info.Status=="OK"||(info.length&&info.length>0))) {
                // if (info.Status=="OK") {
                    obj['info'] = info
                // }
                this.loadCache = obj;

            } else {
                this.is_loading_errors = true;
            }

        }, (error:any) => {
            this.is_loading = false;
            this.is_loading_errors = true;
        });
    }

}
