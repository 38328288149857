import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ChatService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-fatura',
  templateUrl: './document-list.component.html',
  styleUrls: ['./fatura.component.scss']
})
export class FaturaComponent {
    @Input() enableQueue:boolean = false
    @Input() showFolder:boolean = false
    @Input() onSelect:any = (item:any) => {}
    @Input() objectList:any[] = []

    constructor(
        private chatService: ChatService,
        private router: Router) {
    }

    onSelectCallb(item:any) {
        if (this.onSelect) {
            try {
                this.onSelect(item)                
            } catch (e) {
                console.error("E: ", e);
            }
        }
    }

    AddDocToQueue(doc:any) {
        const qobj={
            receipt: doc.fileid, // here, we set the receipt so that the job for the same document is preserved
            name: doc.realname||doc.fileid,
            context: "DOC",
            context_id: doc.fileid,
            // q_conf:{
                // analyzer: "GPT_Analysis", // LAM_Analysis, MIS_Analysis, ...
                // "analyzer" : "core/DefaultAnalyzer000213", ## analyzer can be different from Queue.Analyzer
                // "racommit": True, ## Controls whether should be removed after commit
                // "creplace": True, ## whteter to replace or conditional put
            // },
            extras: {
                fileid: doc.fileid,
                source: 'LUA_WEB',
                docGroup:[
                    'supplier_details',
                    'customer_details',
                    'payment_details',
                    'general_details',
                    'tax_details',
                    'emission_details',
                    'bank_details',
                    'total_with_tax',
                    'total_without_tax',
                ]
            }
        }

        if (doc.is_queuing) {
            return;
        }

        doc.is_queuing=true;

        const payload = {
            'ops' : "QueueAddObj",
            'name' : 'documents',
            'obj' : qobj,
        }

        this.chatService.ExecuteTask("core/RefineryService", payload, (doc:any) => {
            console.info("doc: ", doc);
            doc.is_queuing=false;
            doc.isInQueue=true;
        }, (error:any) => {
            doc.is_queuing=false;
            console.error("E: ", error);
        })
    }

    goToDetails(){
        this.router.navigate(['/fatura/details/1']);
    }
}
