<button
  class="text-white rounded-full bg-yv-rounded-icon flex justify-center items-center relative  {{
    buttonData.btnClass 
  }}"
  (click)="onBtnClick()"
>
  <img
    class="absolute top-1 left-8"
    src="assets/img/icon/red-dot.svg"
    alt="icon"
    *ngIf="buttonData.isDotVisible"
  />
  <img
    class="{{ buttonData.iconClass }}"
    [src]="buttonData.iconUrl"
    alt="icon"
  />
</button>
