import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
  OnInit
} from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

@Component({
    selector: 'app-gen-analysis',
    templateUrl: './gen-analysis.component.html',
})
export class GenAnalysisComponent implements OnInit {
    @Input() docObj: any = null;
    @Input() changeReportCall: any = null;

    analysisForm: FormGroup;

    is_generating:boolean = false;
    is_error:boolean = false;
    resp_time:any = false;
    is_invalid:boolean = false;
    errors:any = null;
    genReport:any = null;
    responseData:any = null;
    invoiceObj:any = null

    constructor(
        private chatService: ChatService,
        private fb: FormBuilder
    ) {
        this.analysisForm = this.fb.group({
            annotation: ['', Validators.required],
        });
    }

    ngOnInit() {
    }

    setMode(mode:any) {
    }

    onChangeReport() {
    }

    ngAfterViewChecked() {
    }

    now() {
        const Lib:any = window;
        return Lib.moment().format("HH:mm:ss");
    }

    runGenerate() {
        const form = this.analysisForm;
        this.is_error=false;
        this.is_generating=true;
        this.invoiceObj=null;
        this.genReport=null;
        this.resp_time='';

        const payload:any = {
            'operation' : 'GenerateInvoiceAnalysis',
            'qobj' : {
                'extras' : {
                    'fileid' : this.docObj.fileid,                        
                }
            }
        }

        // const TaskName="lua/InvoiceAnalysisService";
        const TaskName="lua/InvoiceNumAnalysis";
        this.chatService.ExecuteTask(TaskName, payload, (doc_resp:any) => {
            // console.info("doc_resp: ", doc_resp);
            setTimeout(() => {            
                this.is_generating=false;
                this.resp_time = this.now()
                this.genReport = doc_resp;

                if (doc_resp.Status=="OK") {
                    if (doc_resp.invoiceObj) {
                        this.invoiceObj = doc_resp.invoiceObj;
                    }
                } else {
                    this.is_error=true;
                }
            }, 569);

        }, (error:any) => {
            this.is_generating=false;
            this.is_error=true;
        })
    }

    onSubmit() {
        const form = this.analysisForm;
        this.is_error=false;
        this.is_invalid=false;

        if (form.valid) {
            const formData = form.value;
            const annotation = formData.annotation.trim();
            if (annotation>1500) {
                this.is_invalid=true;
                return;
            }

        } else {
            this.is_invalid=true;
        }
    }

}
