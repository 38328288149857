import { Component } from '@angular/core';

@Component({
  selector: 'app-memgroupchat',
  templateUrl: './memgroupchat.component.html',
  styleUrls: ['./memgroupchat.component.scss']
})
export class MemgroupchatComponent {

}
