import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dva',
  templateUrl: './dva.component.html',
  styleUrls: ['./dva.component.scss'],
})
export class DvaComponent implements OnInit {
  isLoading: boolean = true;
  dvaData: any = null;

  constructor() {}

  ngOnInit() {
    this.fetchDvaData();
  }

  fetchDvaData() {
    setTimeout(() => {
      this.dvaData = {
        totalValueAdded: 150000,
        distributions: [
          { description: 'Employees', amount: 50000 },
          { description: 'Government', amount: 30000 },
          { description: 'Shareholders', amount: 40000 },
          { description: 'Reinvested', amount: 30000 },
        ],
      };
      this.isLoading = false;
    }, 1000);
  }
}
