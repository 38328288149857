import { Component } from '@angular/core';
export interface OptionData {
  nomeContrato: string;
  dataUltimoNegocio: string;
  precoExercicio: number;
  ultimoPreco: number;
  lance: number;
  oferta: number;
  variacao: number;
  porcentagemVariacao: number;
  volume: number;
  openInterest: number;
  volatilidadeImplicita: number;
}
@Component({
  selector: 'app-options-table',
  templateUrl: './options-table.component.html',
  styleUrls: ['./options-table.component.scss']
})
export class OptionsTableComponent {
  options: OptionData[] = [
    {
      nomeContrato: 'BMY240719000032000',
      dataUltimoNegocio: '2024-07-19 2:33PM EDT',
      precoExercicio: 32.00,
      ultimoPreco: 10.73,
      lance: 9.00,
      oferta: 12.10,
      variacao: 2.13,
      porcentagemVariacao: 24.77,
      volume: 9,
      openInterest: 20,
      volatilidadeImplicita: 661.52
    },
    {
      nomeContrato: 'BMY240719000034000',
      dataUltimoNegocio: '2024-07-18 11:16AM EDT',
      precoExercicio: 34.00,
      ultimoPreco: 10.10,
      lance: 7.50,
      oferta: 8.90,
      variacao: 0.00,
      porcentagemVariacao: 0.00,
      volume: 20,
      openInterest: 20,
      volatilidadeImplicita: 315.63
    },
    {
      nomeContrato: 'BMY240719000035000',
      dataUltimoNegocio: '2024-07-18 1:55PM EDT',
      precoExercicio: 35.00,
      ultimoPreco: 11.00,
      lance: 8.00,
      oferta: 10.00,
      variacao: 1.00,
      porcentagemVariacao: -8.33,
      volume: 15,
      openInterest: 25,
      volatilidadeImplicita: 290.00
    }
  ];
}