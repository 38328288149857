<div class="grid grid-cols-2 " *ngIf="!currentFile">
    <div class="grid-col mt-3">
        <div>
            <h3 class="text-2xl font-bold text-left mb-3 "> {{ folder['name'] }} </h3>
        </div>

        <p class="cursor-pointer mt-0 ml-0  ">
            <span class="cursor-pointer text-[small] bg-[#fff] px-8 py-2 rounded-3xl font-bold text-[#00527E] m-2" 
                (click)="LoadDocuments()"
                style="border: 1px solid rgb(0, 82, 126);"> 
                Actualizar 
            </span>

            <span class="cursor-pointer text-[small] bg-[#00527E] px-8 py-2 rounded-3xl font-bold text-[#fff] m-2" 
                (click)="fileobj.click()"
                style="border: 1px solid rgb(0, 82, 126);"> 
                <input type="file" class="hidden" 
                    #fileobj (change)="uploadDoc($event)" />
                <img src="assets/img/Vectorwhite.svg" 
                    style="display:inline;" 
                    class="w-5 h-5"/>
                Carregar Documento 
            </span>
        </p>
    </div>

    <div class="grid-col mt-3" style="text-align:right">
        <div *ngIf="objectList && objectList.list && !is_error">        
            <p *ngIf="objectList.list.length==0" class=" opacity-70"
                style="font-size:small">
                Nenhum documento nesta pasta.
            </p>
            <p *ngIf="objectList.list.length>0" class=" opacity-70"
                style="font-size:small">
                Pasta com {{objectList.total_count}} items |
                Mostrando {{objectList.list.length}}
            </p>
        </div>
    </div>
</div>

<div class="grid grid-cols-1 mt-2">
    <div class="grid-col">
        <div *ngIf="is_loading" class="p-2 text-center"> 
            <app-spinner>
            </app-spinner>
            <!--
            <app-alert type="warning" message="Carregando ficheiros">
            </app-alert>
            -->
        </div>

        <app-alert type="error" message="Nao foi possivel carregar os ficheios. Tente novamente"
            *ngIf="is_error">
        </app-alert>

        <app-alert type="success" message="Carregado com sucesso" *ngIf="is_upload_succ">
        </app-alert>

        <app-alert type="error" message="Nao foi possivel concluir o upload. Tente novamente"
            *ngIf="is_upload_error">
        </app-alert>

        <div *ngIf="is_uploading">
            <div class="flex items-center rounded-xl rounded-tl-none p-[1rem] w-[7rem] ">
                <app-spinner>
                </app-spinner>
            </div>
        </div>
    </div>

    <div class="grid-col mt-4"></div>

    <div class="grid-col" *ngIf="!currentFile && objectList && objectList.list && !is_error">
        <div class="grid grid-cols-1 w-full">
            <!-- FORM -->
            <div class="grid-col" >
                <div class="table-auto rounded-3xl overflow-hidden"
                    style="width:100%">
                    <table class="text-sm" style="width:100%">
                        <thead  class="bg-[#EAF2F2] bg-opacity-60 mb-10 ">
                            <tr class="rounded-tr-3xl">
                                <th class=" p-3 pl-5 rounded-bl-3xl  mb-10"  style="text-align:start;">Nome</th>
                                <th class="p-3  mb-10"  style="text-align: left;">Refer&ecirc;ncia</th>
                                <th class="p-3  mb-10"  style="text-align: left;">Criado em</th>
                                <th  class=" rounded-br-3xl  mb-10">Actions</th>
                        </thead>
                        <tbody class="mt-52" >
                            <tr *ngFor="let doc of objectList.list" 
                                (click)="OpenFile(doc)"
                                class="cursor-pointer pb-10 hover:bg-[#EAF2F2] " >
                                <td  class="p-1 cpointer text-left pl-5"
                                     style="text-align: left;">
                                     <p >                         
                                        <span class="text-bold">{{ doc['title'] }}</span>
                                     </p>
                                </td>

                                <td class="p-1" style="text-align: left;">
                                    <span class="opacity-70" style="font-size:small">
                                        {{doc['id']}}
                                    </span>
                                </td>

                                <td class="p-3"  style="text-align: left;">
                                    <span class="opacity-70" style="font-size:small">
                                        {{ doc['created_at'] || 'Vazio' }}
                                    </span>
                                </td>

                                <td class="text-center">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="grid-col" *ngIf="currentFile">
        <p class="sm:-mt-2 md:-mt-3 mb-3 md:-ml-1" 
            (click)="closeCurrentFile()">
            <span class="cursor-pointer text-sm  font-bold text-[#00527E] m-2 flex flex-row  ">
                <img src="assets/img/icon/Group.svg " class="w-4 h-4 mr-1"/>
                Fechar
            </span>
        </p>

        <app-doc-view [folder]="folder" [doc]="currentFile">
        </app-doc-view>
    </div>
</div>

