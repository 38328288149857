<div *ngIf="analysisReq && keyCache" 
	style="padding:10px"
	class="bg-[#EAF2F2] bg-opacity-60 mb-4">
	<!--
	<p>
	Id = {{analysisReq.Id}}
	</p>

	<p>
	fileid = {{analysisReq.fileid}}
	</p>

	<p>
	groupname = {{analysisReq.groupname}}
	</p>
    Analysis of {{analysisReq.groupname}} 
    in file {{ analysisReq.fileid }}
	-->
    <div *ngIf="!objectCache[keyCache]">
	    <p class="opacity-70">
	    	HOLD ON
	    </p>
    </div>

    <div *ngIf="objectCache[keyCache]">
    	<div *ngIf="objectCache[keyCache].Status=='LOADING'">
    		Efectuando pedido, aguarde
    	</div>

    	<div *ngIf="is_following">
    		Consultando estado do pedido
    	</div>

    	<div *ngIf="objectCache[keyCache].Status=='FAILED'">
    		Falhou. Tente novamente
    	</div>

    	<div *ngIf="objectCache[keyCache].Status=='EXECUTED'">

    		<p class="text-[#00527E]">Analise de {{ analysisReq.doc.label || analysisReq.groupname }}: </p>
    		<div *ngIf="objectCache[keyCache].Response as analysisResult">
    			<p style="font-size:small" class="opacity-70">{{ analysisReq.groupname }}</p>
    			<p style="font-size:small" class="opacity-70">{{ analysisResult.created_at }}</p>
    			<p style="font-size:x-small" class="opacity-70">Receipt: {{ analysisResult.receipt }}</p>
    			<p style="font-size:x-small" class="opacity-70 text-[#00527E]"
    				*ngIf="analysisResult.extras['Status']!='OK'">
    				{{ analysisResult.extras['Status'] }}
    			</p>

    			<p style="font-size:x-small" class="opacity-70 text-[#00527E]"
    				*ngIf="analysisResult.extras['Status']=='OK'">
    				{{ analysisResult.extras['Status'] }}
    			</p>
                <span class="cursor-pointer text-small bg-white px-4 py-1 rounded-3xl text-[#00527E] m-2 border border-[#00527E]"
                    *ngIf="!is_deleted && analysisResult.extras['Status']=='OK'"
                    style="font-size:small" 
                    (click)="OpenBI()">
                    <img src="assets/img/lua-magician.svg" 
                        alt="Main Image" 
                        width="16px" 
                        style="display:inline" />
                    BI
                </span>

                <span class="cursor-pointer text-small bg-white px-4 py-1 rounded-3xl text-[#00527E] m-2 border border-[#00527E]"
                    *ngIf="!is_deleted && analysisResult.extras['Status']=='OK'"
                    style="font-size:small" 
                    (click)="EditAnalysis()">
                    Editar
                </span>


    			<hr  class="mt-3" />
    			<!--
    			<div *ngIf="analysisResult.extras.output" class="mt-3"
    				style="padding: 4px;font-size:x-small;max-width:100%;overflow:hidden;overflow-y:scroll;max-height: 120px;">
    				{{ json_stringify(analysisResult.extras['output'])}}
    			</div>
    			-->
	    		<div *ngIf="is_deleted">
	                <app-alert type="warning" message="Atencao esta analise foi eliminada do sistema, esta presente apenas aqui." >
	                </app-alert>
	    		</div>
    		</div>

            <p class="mt-2 mb-2">
                <span class="cursor-pointer text-small bg-white px-4 py-1 rounded-3xl text-[#00527E] m-2 border border-[#00527E]"
                	style="font-size:small" 
                	*ngIf="!previewReport"
                    (click)="PreviewReport(objectCache[keyCache].Response)">
                    Pr&eacute;-visualizar
                </span>
                <span class="cursor-pointer text-small bg-white px-4 py-1 rounded-3xl text-[#00527E] m-2 border border-[#00527E]"
                	style="font-size:small" 
                	*ngIf="previewReport"
                    (click)="ClosePreview()">
                    Fechar
                </span>
                <span class="cursor-pointer text-small bg-white px-4 py-1 rounded-3xl text-[#00527E] m-2 border border-[#00527E]"
                	style="font-size:small" 
                    *ngIf="is_deleted"
                    (click)="ReEvaluate()">
                    Re-Enviar
                </span>

                <span class="cursor-pointer text-small bg-white px-4 py-1 rounded-3xl text-[#00527E] m-2 border border-[#00527E]"
                    *ngIf="!is_deleted"
                	style="font-size:small" 
                    (click)="DeleteAnalysis()">
                    Eliminar
                </span>
            </p>

            <div *ngIf="previewReport">
            	<!-- <p>groupname = {{ previewReport.groupname }}</p> -->
                <app-doc-report-editor
                    [report]="previewReport.report"
                    [groupname]="previewReport.groupname">
                </app-doc-report-editor>
            </div>
    	</div>

    	<div *ngIf="objectCache[keyCache].Status=='FOLLOW' && objectCache[keyCache].Watch">
    		<p style="font-size:small"
    			class="text-[#00527E]">Aguardando processamento no cluster</p>
	    		<!--
	    		WATCH  {{objectCache[keyCache].Watch.qid}} OF
	    		QUEUE {{objectCache[keyCache].Watch.queue_name}}
	    		-->
    	</div>
    </div>
</div>

<app-modal #modal title="Editar & Adicionar" [onClose]="onCloseEdit">
    <div *ngIf="editAnalysisObj">
        <!-- <p>Conteúdo do modal aqui.</p> -->
        <app-doc-report-form 
            [groupname]="analysisReq.groupname" 
            [fileid]="fileid"
            [OnSubmit]="OnSubmitReportEdit"
            [report]="editAnalysisObj">
        </app-doc-report-form>
    </div>
</app-modal>

<app-modal #bimodal title="Lua BI - Adicionar Metricas de BI" [onClose]="onCloseBI">
    <div *ngIf="luaBIRequest">
        <app-bi-metrics-editor
            [fileid]="fileid"
            [editObj]="luaBIRequest"
            [onSave]="onSaveBI">
        </app-bi-metrics-editor>
    </div>
</app-modal>

