<div class="rounded-3xl overflow-hidden  text-[14px]   w-auto {{iconRightUrl? 'rounded-tr-none  ':'rounded-tl-none  '}} inline-block max-w-full 
{{
  !editMessage? CardChatData.cardClass:''
  }}
  {{ iconRightUrl && editMessage ? '!bg-transparent border border-blue-200' : '' }}
  
  animate-in slide-in-from-bottom duration-1000
  px-5 py-2
  ">
  <div class="w-full max-w-screen-sm  gap-3 flex items-center">

    <div class="me-5 mb-[0.5rem]" *ngIf="type!=='text' && !iconRightUrl && iconLeftUrl">
    </div>
    <div *ngIf="!editMessage" class="flex-grow" [innerHTML]="text"></div>
    <div *ngIf="iconRightUrl !== '' && editMessage" class="flex-grow px-4">
      <textarea class="
      text-black
      text-base
      overflow-y-auto
      m-0
      resize-none
      border-0 
      bg-transparent
      p-0 
      focus:ring-0 
      focus-visible:ring-0
      focus:outline-none 
      min-w-[10rem]
      md:min-w-[24rem]
      lg:min-w-[28rem]
      min-h-[72px]
      " placeholder={{textAreaPlaceholder}} [(ngModel)]="text" (input)="adjustTextAreaHeight($event)"> </textarea>
    </div>
    <div class="me-5" *ngIf="type =='you'">
      <app-rounded-icon-buttom [buttonData]="{
          iconUrl:'assets/img/im blue.jpg', 
          btnClass: '!bg-yv-5%-gray ' ,
          iconClass:'w-10  h-10 rounded-full'  
      }"></app-rounded-icon-buttom>
    </div>
  </div>
  <div class=" flex items-center " *ngIf="iconRightUrl !== '' && iconLeftUrl">
    <span id="editButton" *ngIf=" !saveMessage" class="hover:cursor-pointer mr-4" (click)="handleEditMessage()">
      <img src="assets/img/icon/edit.svg" alt="">
    </span>
    <span id="reloadButton" *ngIf="!editMessage && !saveMessage" class="hover:cursor-pointer" (click)="handleReload()">
      <img src="assets/img/icon/reload.svg" alt="Reload">
    </span>
  </div>
  <ng-content></ng-content>
  <div *ngIf="respId" class="flex gap-[1rem] mt-[1rem] animate-in slide-in-from-bottom duration-1000"> 
    <span id="likeButton" *ngIf="iconRightUrl === ''" class="hover:cursor-pointer" (click)="handleVote(index, 'UP')">
        <app-rounded-icon-buttom [buttonData]="{
          iconUrl: content[index]?.vote === 'UP' ? 'assets/img/icon/thumbsUp.svg' : 'assets/img/icon/thumbsUpBlank.svg',
        btnClass: 'h-[2.5rem] w-[2.5rem] !bg-transparent', 
        iconClass:'w-1/2'
      }" />
      </span>
      <span id="dislikeButton" *ngIf="iconRightUrl === ''" class="hover:cursor-pointer"
        (click)="handleVote(index, 'DOWN')">
        <app-rounded-icon-buttom [buttonData]="{
          iconUrl: content[index]?.vote === 'DOWN' ? 'assets/img/icon/thumbsUp.svg' : 'assets/img/icon/thumbsUpBlank.svg',
        btnClass: 'rotate-180 h-[2.5rem] w-[2.5rem] !bg-transparent', 
        iconClass:'w-1/2 mt-[-0.3rem]'
      }" />
      </span> 
    </div> 
</div>
<div
  class="flex flex-wrap gap-[1rem] max-w-screen-md pt-[0.25rem] pb-[0.75rem] animate-in slide-in-from-bottom duration-1000"
  *ngIf="isResponseTriggered">
  <div *ngFor="let p of prompts; let i = index" class="">
    <div
      class="text-lef border-[#FFFFFF1A] border max-w-sm rounded-xl p-[0.5rem] min-h-[3rem] flex items-center cursor-pointer">
      <span>
        {{p.prompt}}
      </span>
    </div>
  </div>
</div>