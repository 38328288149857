<div>
    <div class="grid grid-cols-1">
        <div class="grid-col text-center" *ngIf="wizardMode=='INIT'">
            <div class="pt-20 pb-20 text-center ">
                <h2 class="text-2xl md:text-4xl">Faturas</h2>
                <p class="text-sm md:text-base opacity-70">
                    Como deseja proceder
                </p>
                <p class=" text-center cursor-pointer mt-5 mb-1 rounded-xl p-1 pl-0 "
                    style="">
                    <span class="text-[#00527E] m-2 ml-0 border border-[#00527E] mt-1 mb-1 pl-3 pr-3 rounded-xl p-2"
                        (click)="SetMode('CREATE_INVOICE')"
                        style="font-size:small">
                        Criar Fatura
                    </span>

                    <span class="text-[#00527E] m-2 ml-0 border border-[#00527E] mt-1 mb-1 pl-3 pr-3 rounded-xl p-2"
                        (click)="SetMode('SELECT_DOC')"
                        style="font-size:small">
                        Selecionar Documento
                    </span>
                </p> 
            </div>
        </div>

        <div class="grid-col" *ngIf="wizardMode=='CREATE_INVOICE'"
            style="min-height:300px;">  
            <p class=" cursor-pointer mt-5 mb-2 rounded-xl p-0 pl-5  pb-3 ">
                <span class="text-[#00527E] m-2 ml-0 mt-1  mb-1 pl-3 pr-3 rounded-xl p-2"
                    (click)="SetMode('INIT')"
                    style="font-size:small">
                    <img src="assets/img/icon/Group.svg "
                        style="display:inline;color:#fff;" 
                        class="w-4 h-4 mr-1">
                    Voltar
                </span>
            </p>

            <hr/>

            <div *ngIf="invoiceNew" class="mt-2"> 
                <app-invoice-editor [editObj]="invoiceNew">
                </app-invoice-editor>
            </div>
        </div>

        <div class="grid-col" *ngIf="wizardMode=='SELECT_DOC'"
            style="min-height:300px;">

            <div class="mt-0"></div>

            <div *ngIf="!invoiceDoc && docList">
                <p class=" cursor-pointer mt-5 pl-5 mb-2 rounded-xl p-1 pb-3  "
                  >
                    <span class="text-[#00527E] m-2 ml-0  mt-1 mb-1 pl-3 pr-3 rounded-xl p-2"
                        (click)="SetMode('INIT')"
                        style="font-size:small">
                        <img src="assets/img/icon/Group.svg "
                            style="display:inline;color:#fff;" 
                            class="w-4 h-4 mr-1">
                        Voltar
                    </span>
                </p>

                <hr/>

                <div class="pl-3 pl-5">                
                    <h3 class="opacity-70">Selecione 1 documento</h3>
                    <p class="cursor-pointer mt-0  rounded-xl p-1 pl-0 ">
                        <span class="text-[#00527E]  border-[#00527E] mt-2 border py-2 px-6 rounded-3xl  "
                            (click)="LoadDocuments()"
                            *ngIf="!is_loading"
                            style="font-size:small">
                            Actualizar
                        </span>
                        <span class="text-[#fff] bg-[#00527E] border-[#00527E] m-0 ml-0 border mt-0 mb-0 pl-3 pr-3 rounded-xl p-0"
                            *ngIf="is_loading"
                            style="font-size:small">
                            Aguarde
                        </span>
                    </p>
                </div>

                <div class="mt-2"></div>
                
                <hr/>
                
                <div class="mt-2" style="max-height:40vh;overflow:hidden;overflow-y:scroll;">  
                    <div class="text-center pl-4" *ngIf="is_loading"> 
                        <app-spinner>              
                        </app-spinner> 
                    </div>

                    <app-fatura [objectList]="docList" [enableQueue]="false" 
                        [showFolder]="true"
                        [onSelect]="openSelectDoc" 
                        class="md:pb-8">
                    </app-fatura>
                </div>
            </div>

            <div *ngIf="invoiceDoc" class="mt-0 invoice-doc-editor">
                <div class="">  
                    <div class=" cursor-pointer mt-2 mb-2 rounded-xl p-1 pl-0 "
                        style="width:200px;z-index: 1000;">
                        <span class="text-[#fff] m-2 ml-0 border bg-[#b50808] border-[#b50808] mt-1 mb-1 pl-3 pr-3 rounded-xl p-2"
                            (click)="ChangeDoc()"
                            style="font-size:small">
                            <- Documentos
                        </span>
                    </div>
                    <!--
                    <app-gen-analysis [docObj]="invoiceDoc">
                    </app-gen-analysis>
                    -->
                    <app-invoice-ai-editor [docObj]="invoiceDoc">
                    </app-invoice-ai-editor>
                </div>
            </div>

        </div>
        
    </div>

</div>


