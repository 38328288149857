import { Component, Input, OnInit, ElementRef, ViewChild  } from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-document-analysis',
    templateUrl: './document-realtime.component.html',
    // templateUrl: './docs-v2.component.html',
    // styleUrls: ['./documents.component.scss']
})
export class DocumentAnalysisComponent {
    @Input() prompts: any[] = [
    ];

    @ViewChild('genReportComp') genReportComp?: ElementRef;
    @ViewChild('previewImgFile') previewImgModal: any;

    currentIndex: number = 0;
    is_loading:boolean = false;
    show_group_docs: boolean = false;
    is_error:boolean = false;
    errors: any[] = []
    onRefreshCall:any = () => {}
    objectList:any[] = []
    imgList:any[] = []
    objectStream:any = null;
    is_created:boolean = false;
    is_creating:boolean = false;
    is_create_error:boolean = false;

    queueList:any = null;
    loadCache:any = {};
    objectCache:any = {};
    analysisQ:any = null;
    currentDocOpen:any = null;

    is_loading_queue:boolean = false;
    is_uploading:boolean = false;
    is_upload_error:boolean = false;
    is_upload_succ:boolean = false;
    is_peeking:boolean = false;
    peek_error:boolean = false;
    currentReportGen:any = null;

    is_preview_err:boolean = false;
    is_run_preview:boolean = false;
    is_run_interup:boolean = false;
    preview_url:any = false;
    xlsGrid:any = null;

    cant_fetch_file:boolean = false;
    previewImage:any = null;
    previewPDF:any = null
    procGroup:any = []

    constructor(
        private chatService: ChatService,
        public translate: TranslateService,
        private router: Router,
    ) {   
    }

    goBack() {
        this.router.navigate(['/luaxapps']);
        this.closeCurrentFile();
    }

    ngOnInit() {
        this.openFileItem = this.openFileItem.bind(this);
        this.changeReport = this.changeReport.bind(this);
        this.onAnalysisSubmit = this.onAnalysisSubmit.bind(this);
        this.onAnalysisDone = this.onAnalysisDone.bind(this);

        const params:any = this.chatService.URLParams(window.location.href);
        // console.info("params: ", params);


        if (params&&params.doc_id) {
            const doc_id:any = params.doc_id;
            setTimeout(()=>{
                this.LocateAndOpenDoc(doc_id);
            }, 320);
        } else {
            this.LoadDocuments({});
        }
    }

    fetchImages() {
        const fileid=this.currentDocOpen.fileid;
        const payload = {
            'ops' : 'FetchImages',
            'fileid' : fileid,
        }

        this.currentDocOpen.imgList = [];
        this.chatService.ExecuteTask("lua/DocSystemAPI", payload, (doc_resp:any) => {
            console.info("fetchImages: ", doc_resp);
            // this.previewPDF=doc_resp;
            if (doc_resp.Status=='OK') {
                this.currentDocOpen.imgList = doc_resp.object_;
            }

        }, (error:any) => {
            console.error("E: ", error)
        });
    }

    scrollToGenReport() {
        if (this.genReportComp) {
            this.genReportComp.nativeElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    onAnalysisDone(analysis:any) {
        // console.info("onAnalysisDone: ", analysis);
    }

    closePreview() {
        this.is_run_preview=false;
        this.is_preview_err=false;
        this.is_run_interup=true;
        this.previewPDF=null;

        if (this.preview_url) {
            const doc:any=this.currentDocOpen;
            const elId:any = 'xlsp-'+doc.fileid;
            const node:any = document.getElementById(elId);
            if (node) {
                node.innerHTML = '';
            }
        }

        this.preview_url=null;
        this.xlsGrid = null;
    }

    genPreview() {
        console.info("[genPreview] doc: ", this.currentDocOpen)
        const doc:any = this.currentDocOpen;
        var filename:any = '';
        if (doc.title) {
            filename=doc.title.toLowerCase()
        } else if (doc.name) {
            filename=doc.name.toLowerCase();
        } else if (doc.filename) {
            filename=doc.filename.toLowerCase();
        } else if (doc.realname) {
            filename=doc.realname.toLowerCase();
        }

        if (filename.endsWith('.pdf')) {
            this.genPDFPreview();
        } else if (filename.endsWith(".xls")||filename.endsWith(".xlsx")) {
            this.genXLSPreview();
            // application/vnd.ms-excel
        } else {
            alert("Ficheiro "+filename+ " nao suportado! Apenas Excel e PDF.");
        }
    }

    genPDFPreview() {
        if (this.is_run_preview || !this.currentDocOpen) {
            return;
        }

        this.is_run_preview=true;
        this.is_preview_err=false;
        this.previewPDF=null;
        const fileid=this.currentDocOpen.fileid;

        const payload = {
            'ops' : 'GenPDFPreview',
            'fileid' : fileid,
        }

        this.chatService.ExecuteTask("lua/DocSystemAPI", payload, (doc_resp:any) => {
            this.is_run_preview=false;
            // console.info("GenPDFPreview: ", doc_resp);
            if (doc_resp.Status=='OK') {
                this.previewPDF=doc_resp;
            } else {
                this.is_preview_err=true;
            }

        }, (error:any) => {
            this.is_run_preview=false;
            this.is_preview_err=true;
            console.error("E: ", error)
        });
    }

    genXLSPreview() {
        // from: https://docs.sheetjs.com/docs/demos/grid/xs#reading-data and
        //       view-source:https://docs.sheetjs.com/xspreadsheet/
        if (this.is_run_preview) {
            return;
        }

        const doc:any = this.currentDocOpen;

        this.is_run_preview=true;
        this.is_preview_err=false;
        this.preview_url=null;

        const fileid:any=doc.fileid;
        const payload = {
            // 'ops' : 'GetFilePublicUrl',
            'ops' : 'GenPDFPreview',
            'fileid' : fileid,
        }

        this.chatService.ExecuteTask("lua/DocSystemAPI", payload, (doc_resp:any) => {
            this.is_run_preview=false;
            if (doc_resp.Status!='OK') {
                this.is_preview_err=true;
                return;
            }

            const url:any = doc_resp.preview_url;
            fetch(url).then((res:any) => {
                if(!res.ok) {
                    throw new Error("fetch failed");
                }
                return res.arrayBuffer();

            }).then((ab:any) => {
                this.is_run_preview=false;
                // have to make this hack to please ts compiler
                const Lib:any = window; 
                const XLSX:any = Lib.XLSX;

                try {
                    // var data = new Uint8Array(ab);
                    const elId:any = 'xlsp-'+fileid;
                    const node:any = document.getElementById(elId);
                    // var wb:any = XLSX.read(data, {type:"array"});
                    // const ws = wb.Sheets[wb.SheetNames[0]];
                    // const html_buf = XLSX.utils.sheet_to_html(ws);
                    // if (node) {
                    //     node.innerHTML = html_buf;
                    // }
                    this.preview_url=url;
                    const grid = Lib.x_spreadsheet(node);
                    // grid.loadData(Lib.stox(XLSX.read(data, {type:'array'})));
                    grid.loadData(Lib.stox(XLSX.read(ab)));
                    Lib.grid=grid;
                    this.xlsGrid = grid;

                } catch (e) {
                    console.error("E: ", e);
                    this.is_preview_err=true;
                }

            }, (Error:any) => {
                this.is_run_preview=false;
                this.is_preview_err=true;
            });

        }, (error:any) => {
            this.is_run_preview=false;
            this.is_preview_err=true;
        }); 
    }

    toggleGroupDocs() {
        this.show_group_docs = !this.show_group_docs;
    }

    addToAnalysisGroup(fileid:any, doc:any) {
        // console.info("addToAnalysisGroup: ", fileid, doc)
        var groupname:any = doc.group_name;
        const Id:any = `${fileid}#${groupname}`

        var f = this.procGroup.find((C:any)=> {
            console.info(`${C.Id} => ${Id}`);
            return C.Id == Id
        });

        if (!f) {
            this.procGroup = [{
                'Id' : Id,
                'fileid' : fileid,
                'groupname' : groupname,
                'doc' : doc,
            }].concat(this.procGroup)
        } else {
            // console.info("ALREADY THERE")
        }

        doc.IsAdd = true
    }

    scanDocAnalysisQ(fileid:any) {
        const payload = {
            'ops' : 'ScanDocumentAnalysisQueue',
            'fileid' : fileid,
        }

        this.is_loading_queue = true;
        this.chatService.ExecuteTask("lua/DocSystemAPI", payload, (doc_resp:any) => {
            this.is_loading_queue = false;

            if (doc_resp && doc_resp.Status=='OK' && doc_resp.Scan) {
                const scan:any = doc_resp.Scan.Resp;
                const Keys:any = Object.keys(scan);
                const List:any = [];
                const pGroups:any = [];
                
                Keys.forEach((K:any) => {
                    const Entry:any = scan[K];
                    if (Entry && Entry.hasOwnProperty('extras')) {
                        var parts:any=K.split(`${fileid}_anly__${fileid}`);
                        const groupname:any = parts.slice(1).join("").replace("#","");
                        const Obj:any = Entry.extras.output;
                        const doc:any={
                            'fileid' : fileid
                        }
                        pGroups.push({
                            'Id' : `${fileid}${groupname}`,
                            'fileid' : fileid,
                            'groupname' : groupname,
                            'inputStatus' : 'EXECUTE',
                            'inputObj' : Entry,
                            'doc' : doc,
                            'receipt' : `${fileid}#${groupname}`,
                        })
                    }
                })

                // console.info("pGroups: ", pGroups);
                // this.inputObj
                this.procGroup = pGroups;
                // this.procGroup = [{
                //     'Id' : Id,
                //     'fileid' : fileid,
                //     'groupname' : groupname,
                //     'doc' : doc,
                // }].concat(this.procGroup)

            } else {
            }

        }, (error:any) => {
            this.is_loading_queue = false;
            console.error("E: ", error)
        });
    }

    scanDocReports(fileid:any) {
        const keyCache = fileid+"#REPORTS";
        const payload = {
            'ops' : 'ScanDocReports',
            'fileid' : fileid,
            'filters' : {},
        }

        this.objectCache[keyCache] = {
            'Status' : 'LOADING'
        }

        this.chatService.ExecuteTask("lua/DocSystemAPI", payload, (doc_resp:any) => {
            // console.info("doc_resp: ", doc_resp);
            const object_:any = doc_resp.object_ || {};
            const updateStatus:any = {
                Status : 'LOADED',
                keyCache : keyCache,
                Items: {},
                rep_c: 0,
            }
            if (object_ && object_.Status=="OK" && object_.Resp) {
                const dbReport:any = object_.Resp;
                const Keys = Object.keys(dbReport)
                Keys.forEach((Key:any) => {
                    var Value = dbReport[Key];
                    if (Value===null) {
                        return;
                    }

                    var p = Key.split("__")
                    var queueName = p[0];
                    var object = p.slice(1).join("").trim();

                    if (Value.extras && Value.extras.output) {
                        const output = Value.extras.output;
                        if (output.Status=="OK") {
                            const RepObj = output.object_;
                            RepObj.queueName = queueName;
                            RepObj.qId = object;
                            updateStatus.Items[RepObj.groupname] = RepObj;
                            updateStatus.rep_c += 1;
                        } else {
                        }
                    }

                });

                this.objectCache[keyCache] = updateStatus;
            } else {
                this.objectCache[keyCache] = {
                    'Status' : 'FAILED',
                    'Details' : "Nao foi possivel concluir.",
                }
            }


        }, (error:any) => {
            this.objectCache[keyCache] = {
                'Status' : 'FAILED',
                'Details' : error,
            }
        });
    }

    createDocReport(reqReport:any) {
        console.info("reqReport: ", reqReport);
        const groupname = reqReport.groupname
        const payload = {
            ops : 'CreateDocReport',
            obj: {
                // fileid: reqReport.fileid,
                fileid: this.currentDocOpen.fileid,
                groupname: reqReport.groupname,
                qId: reqReport.qId,
                queueName: reqReport.queueName,
                rep: reqReport.report
            }
        }

        // payload.obj.req[groupname] = 
        this.is_created=false;
        this.is_creating=true;
        this.is_create_error=false;

        this.chatService.ExecuteTask("lua/DocSystemAPI", payload, (doc_resp:any) => {
            this.is_creating=false;
            setTimeout(() => {
                if (doc_resp.Status=="OK") {
                    this.is_created=true;
                    setTimeout(()=>{
                        this.is_created=false;
                    }, 560);
                } else {
                    this.is_create_error=true;
                }
            }, 130);

        }, (error:any) => {
            this.is_create_error=true;
            this.is_creating=false;
            console.error("E: ", error)
        });
    }

    onAnalysisSubmit(analysis:any) {
        console.info("onAnalysisSubmit: ", analysis);
        setTimeout(()=>{
            this.scanDocReports(analysis.fileid);
        }, 250)
        // this.reloadDocReports()
        // this.fetchDocReports()
        // const reportObj:any = {}
        // const groupname:any = analysis.groupname;
        // reportObj[groupname] = analysis.payload;

        // this.changeReport({
        //     groupname: analysis.groupname,
        //     qId: 'X',
        //     queueName: 'Y',
        //     newReport: reportObj,
        // })
    }

    changeReport(req:any) {
        // console.info("changeReport: ", req)
        if (!this.objectCache || !this.currentDocOpen) {
            return;
        }

        const keyCache = this.currentDocOpen.fileid+'#REPORTS';
        const groupname = req.groupname;
        const queueName = req.queueName;
        const qId = req.qId;
        const newReport = req.newReport;

        if (!this.objectCache[keyCache]) {
            return;
        }

        if (this.objectCache[keyCache].Items) {
            this.objectCache[keyCache].Items[groupname]=null;
        }

        if (!this.objectCache[keyCache].rep_c) {
            this.objectCache[keyCache].rep_c = 0;
        }

        setTimeout(()=>{
            const newUpdate = {
                qId: qId,
                groupname: groupname,
                queueName: queueName,
                fileid: this.currentDocOpen.fileid,
                report: newReport,
            }

            console.info("newUpdate: ", newUpdate);
            this.objectCache[keyCache].Items[groupname]=newUpdate;
            this.objectCache[keyCache].rep_c += 1;
        }, 250);
    }

    onRefreshDocList() {
        this.objectList = [];
        this.LoadDocuments({});
    }

    cleanProcGroup() {
        const msg = `Deseja mesmo limpar todos grupos em analise?`;
        if (!confirm(msg)) {
            return;
        }

        this.procGroup = [];
    }

    openImage(imgObj:any) {
        console.info("openImage: ", imgObj);
        this.previewImage = imgObj;
        setTimeout(()=>{
            this.previewImgModal.openModal();
        }, 120);
    }

    closeImgPreview() {
        this.previewImage = null;
        this.previewImgModal.closeModal();
    }

    closeCurrentFile() {
        this.closePreview();

        if (this.procGroup.length>0) {
            // const msg = `Parece que possui ${this.procGroup.length} grupos em analise. Deseja mesmo sair?`;
            // if (!confirm(msg)) {
            //     return;
            // }
            this.procGroup.forEach((PG:any)=>{
                try{
                    PG['doc'].IsAdd=false;
                }catch(e){}
            })
        }

        // doc.IsAdd
        this.currentDocOpen = null;
        this.currentReportGen = null;
        this.procGroup = [];
        this.previewImage = null;
    }

    uploadDoc(event:any) {
        const input = event.target as HTMLInputElement;
        var file:any = false;
        if (input.files && input.files.length > 0) {
            file = input.files[0];
        }

        if (!file) {
            return
        }

        this.is_uploading=true;
        this.is_upload_error=false;
        this.is_upload_succ = false;

        this.chatService.Upload(file, (doc_resp:any) => {
            console.info("doc_resp: ", doc_resp);

            this.is_uploading=false;
            this.is_upload_succ = true;

            setTimeout(()=>{
                this.onRefreshDocList();
                this.is_upload_succ = false;
            }, 856);


        }, (error:any) => {
            this.is_uploading=false;
            this.is_upload_error=true;
        })
    }

    closeGenAnalysis() {        
        const msg = "Deseja mesmo fechar a Analise?";
        if (!confirm(msg)) {
            return;
        }
        this.currentReportGen = null;
    }

    regenerate(reportObj:any) {
        // console.info("regenerate: ", reportObj);
        this.currentReportGen = null;
        setTimeout(()=>{
            this.currentReportGen = reportObj;
            setTimeout(()=>{
                this.scrollToGenReport()
            }, 180);
        }, 180);
    }

    openFileItem(item:any) {
        this.currentDocOpen = null;

        if(this.loadCache[item.fileid]) {
            const entry=this.loadCache[item.fileid];
            this.currentDocOpen = item;

            if (entry.Status && entry.Status=='LOADING') {
            }

            if (entry.Status && entry.Status=='DETAILS_LOAD') {
            }

        } else {
            setTimeout(()=>{
                this.scanDocAnalysisQ(item.fileid)
            }, 1340)

            setTimeout(()=>{
                this.fetchImages()
            }, 1340)

            this.loadCache[item.fileid] = {
                'Status' : 'LOADING'
            }

            const payload = {
                'ops' : 'DescribeFile',
                'fileid' : item.fileid,
                'info_list' : [
                    'blocks',
                    'reports',
                    'doc_cplx'
                ],
                'filters' : {},
            }

            this.chatService.ExecuteTask("lua/DocSystemAPI", payload, (doc_resp:any) => {
                setTimeout(() => {

                    if (doc_resp.Status=="OK") {
                        const docMap:any = {};
                        if (doc_resp.info_group_response) {
                            doc_resp.info_group_response.groups_doc.forEach((X:any) => {
                                docMap[X.group_name] = X;
                            });
                        }

                        this.loadCache[item.fileid] = {
                            'Status' : 'DETAILS_LOAD',
                            'Details' : doc_resp,
                            "DOC_MAP" : docMap,
                        }

                    } else {
                        this.loadCache[item.fileid] = {
                            'Status' : 'FAILED',
                            'Details' : "Oops, parece que nao correu tudo bem"
                        }
                    }

                }, 130);

                setTimeout(()=>{
                    this.scanDocReports(item.fileid)
                }, 150);

            }, (error:any) => {
                this.loadCache[item.fileid] = {
                    'Status' : 'FAILED',
                    'Details' : error,
                }
            });
        }

        setTimeout(()=>{
            this.currentDocOpen = item;
        }, 120);

        // this.objectList = [];
        // this.LoadDocuments();
    }

    LocateAndOpenDoc(fileid:any) {
        const payload = {
            'ops' : 'SearchFileId',
            'fileid' : fileid,
        }

        this.chatService.ExecuteTask("lua/DocSystemAPI", payload, (doc_resp:any) => {
            if (doc_resp.Status=='OK') {
                const fileobj:any = doc_resp.file;
                this.objectList = [fileobj].concat(this.objectList);
                fileobj['fileid'] = fileobj['id'];
                this.openFileItem(fileobj);
            } else {
                this.cant_fetch_file=true;
            }

        }, (error:any) => {
            this.cant_fetch_file=true;
        });
    }

    LoadDocuments(params:any) {
        if (this.is_loading) {
            return;
        }

        this.is_loading = true;
        this.is_error = false;
        this.objectList = [];

        const payload = {
            'ops' : 'ListFiles',
            'filters' : {},
        }

        this.chatService.ExecuteTask("lua/DocSystemAPI", payload, (doc_resp:any) => {
            setTimeout(()=>{
                this.is_loading = false;
                if (doc_resp.hasOwnProperty("Status")) {
                    const Status = doc_resp["Status"];
                    if (Status=="OK" && doc_resp.hasOwnProperty("list")) {
                        const list = doc_resp['list'];
                        this.objectList = list;
                        // if (init_first && list.length>0) {
                        //     setTimeout(()=>{
                        //         this.openFileItem(list[0]);
                        //     }, 25);
                        // }
                    } else {
                    }
                }

            }, 123);

        }, (error:any) => {
            console.info("error: ", error)
            this.is_loading = false
            this.is_error = true
        });
    }
}
