import { Component, Input, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ChatService } from 'src/app/services/chat.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

@Component({
    selector: 'app-native-dpr',
    // templateUrl: './banner.component.html',
    templateUrl: './dpr-v2.html',
    styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
    @Input() appVersion: any = '';
    @Input() appPage: any = '';
    model106Conf:any = null
    xmlReport:any = null
    is_xml_model_ready:boolean = false
    dprModel:any = null;

    is_loading:boolean = false
    is_error:boolean = false
    is_ready:boolean = false

    objectCache:any = null
    listObj:any = []
    filterForm: any;
    allow_add_bill:boolean = false

    is_filter_error:boolean = false

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private chatService: ChatService
    ) { 
        // console.info("[home] chatService: ", chatService);
        this.filterForm = this.fb.group({
            // query: [null, Validators.required],
            // state: ['draft', Validators.required],
            startDate: ['', Validators.required],
            endDate: ['', Validators.required],
        });
    }

    ngOnInit() {
        this.InitApp()
    }

    AddAll() {
        // this.listObj = [];
        setTimeout(()=>{        

            const L:any = [];
            const objectCache:any=this.objectCache;
            objectCache.invoices.records.forEach((I:any)=>{
                this.addToList(I);
            });

        }, 120);
    }

    addToList(invoice:any) {
        const R:any = this.listObj.filter((X:any) => X.id == invoice.id)
        if (R.length==0) {
            this.listObj = [invoice].concat(this.listObj)
        }
    }

    InitApp() {
        this.FetchInvoices()
    }

    FetchInvoices() {
        this.is_loading = true;
        const objectCache:any = {
            invoices:{
                records: [],
                length: 0,
                total_count: 0,
            }
        }

        const payload = {
            operation: 'FetchInvoices',
        };

        this.is_loading = true;
        this.is_error = false;
        this.is_ready = false;

        this.objectCache = objectCache;

        this.chatService.ExecuteTask('core/LuaInvoiceService', payload, (doc_resp: any) => {
            setTimeout(() => {
                this.is_loading = false;
                if (doc_resp.Status == 'OK') {
                    objectCache.invoices.records = doc_resp.list;
                    objectCache.invoices.total_count = doc_resp.list.length;
                    objectCache.invoices.length = doc_resp.list.length;
                    this.objectCache = objectCache;
                } else {
                    this.is_error = true;
                }
            }, 60);
    
        }, (error: any) => {
            this.is_error = true;
            this.is_loading = false;
        });

        // setTimeout(()=>{
        //     this.is_loading = false;
        //     this.is_ready = true;
        //     this.objectCache = objectCache;
        // }, 569);
    }

    filterInvoices() {
        const form = this.filterForm;
        this.is_filter_error = false;

        if (form.valid) {
            const formData = form.value;
            console.info("formData: ", formData);
            var startDate = formData.startDate;
            var endDate = formData.endDate;
            var filterx: any = {};

            if (startDate) {
                filterx['start_date'] = startDate;
            }

            if (endDate) {
                filterx['end_date'] = endDate;
            }
            // console.info("filterx: ", filterx);
            this.RangeScan(filterx);
        } else {
            this.is_filter_error = true;
        }
    }

    RangeScan(filterx:any) {
        const payload = {
            operation: 'RangeScan',
            range: filterx
        };

        this.is_loading = true;
        this.is_error = false;
        this.is_ready = false;

        const localCache:any = {
            invoices:{
                records: [],
                length: 0,
                total_count: 0,
            }
        }

        this.objectCache = null;

        this.chatService.ExecuteTask('core/LuaInvoiceService', payload, (doc_resp: any) => {
            const object_: any = doc_resp.object_ || {};
            setTimeout(() => {
                this.is_loading = false;
                if (doc_resp.Status == 'OK') {
                    localCache.invoices.records = doc_resp.list;
                    localCache.invoices.length = doc_resp.list.length;
                    localCache.invoices.total_count = doc_resp.list.length;
                    this.objectCache = localCache;
                } else {
                    this.is_error = true;
                }
            }, 60);
    
        }, (error: any) => {
            this.is_error = true;
            this.is_loading = false;
        });
    }

    backModel106() {
        this.xmlReport=false;
    }

    removeFromList(invoice:any) {
        const R:any = this.listObj.filter((X:any) => X.id != invoice.id)
        this.listObj = R;
    }

    beginModel106() {
        this.allow_add_bill=true;
        this.xmlReport=false;
    }

    openInvoice(invoice:any) {
        console.info("openInvoice: ", invoice)
    }

    MapToMode(Mode:any, BILLING:any) {
        const model:any ={
            origem: "CV",
            tp_doc: "FT",
            num_doc: BILLING.invoice_id,
            data: BILLING.invoice_date,
            vl_fatura: BILLING.invoice_total,
            vl_base_incid: BILLING.invoice_subtotal,
            tx_iva: BILLING.invoice_iva,
            linha_dest_mod: 0
        }

        if (Mode=='cli') {
            model.serie = '';
            model.iva_liq = 0;
            model.nao_liq_imp = 'C';
            model.nif = BILLING.customer_nif || "";
            model.designacao = BILLING.customer_name;
        } else {
            model.iva_sup = 0;
            model.direito_ded = 0;
            model.iva_ded = 0;
            model.tipologia = "CD";
            model.nif = BILLING.supplier_nif || "";
            model.designacao = BILLING.supplier_name;
        }

        return model;
    }

    continueModel106(model:any) {
        console.info("continueModel106: ", model);
        // this.xmlReport
        setTimeout(()=>{
            const THE_DATE = "2024-07-24";
            const RowList:any[] = [];

            this.listObj.forEach((BILLING:any) => {
                if (!BILLING || BILLING.is_deleted) {
                    if (BILLING.is_deleted) {
                        console.info("BILLINGIS DELETED");
                    }
                    return;
                }
                const ROW = this.MapToMode(model, BILLING);
                RowList.push(ROW);
            });

            const xmlReport:any = {
                header: {
                    ano: "2024",
                    mes: "12",
                    cd_af: "123",
                    nif:"0000",
                },

                linhas:RowList,
                dt_entrega: THE_DATE,
                total_fatura: 0,
                total_base_incid: 0,
                docname: "DOCNAME",
                groupname: "GROUPNAME",
                user_id: "USER_ID",
                date: THE_DATE,
            }

            if (model=='cli') {
                xmlReport.total_liquidado = 0;
            } else {
                xmlReport.total_suportado = 0;
                xmlReport.total_dedutivel = 0;
            }

            this.xmlReport=xmlReport;
            this.is_xml_model_ready=true;
            this.dprModel = model;
        }, 120);

    }

    cancelModel106() {
        this.allow_add_bill=false;
        this.model106Conf = null;
        this.is_xml_model_ready=false;
        this.xmlReport=false;
        // this.objectCache.invoices.records.forEach((Invoice:any) => {
        //     Invoice.is_deleted=false;
        //     Invoice.is_add=false;
        // })
    }

    goBack() {
        this.router.navigate(['/luaxapps']);
    }
}
