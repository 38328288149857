<div class="" *ngIf="doc">
    <!-- CURRENT FILE is {{doc['id']}} -->
    <div class="grid grid-cols-2">
        <div class="grid-col ">
            <div class="header">
                <div class="invoice-info">
                    <h2 class="text-left opacity-70" style="font-size:small">
                        Documentos / {{ doc['id'] }}
                    </h2>
                    <h1 class="font-semibold text-2xl pb-1">
                        {{ doc['realname']||doc['title'] }}
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <div class="mt-3 mb-1">
        <app-alert type="info" message="Carregando detalhes" *ngIf="is_loading">
        </app-alert>
        <app-alert type="error" message="Nao foi possivel carregar os detalhes"
            *ngIf="is_loading_errors">
        </app-alert>
    </div>

    <div class="grid grid-cols-1 ">
        <div class="grid-col">
            <a href="/document-analysis?doc_id={{doc.id}}"
                class="cursor-pointer text-sm px-8 py-2 rounded-3xl font-bold m-2 mr-1 border border-[#2571a1] bg-[#fff] text-[#2571a1]" style="">
                An&aacute;lise
            </a>
            <!-- x_spreadsheet(ref.current).loadData(stox(wb)); -->

            <span (click)="genPreview()"
                *ngIf="!previewPDF && preview_url==null"
                class="cursor-pointer text-sm px-8 py-2 rounded-3xl font-bold text-white m-2 mr-1 border border-[#2571a1] bg-[#2571a1]">
                Pr&eacute;-Visualizar
            </span>

            <span (click)="closePreview()"
                *ngIf="previewPDF || preview_url"
                class="cursor-pointer text-sm px-8 py-2 rounded-3xl font-bold text-white m-2 mr-1 border border-[#00527E] bg-[#00527E]">
                Fechar
            </span>
        </div>

        <div class="grid-col mt-5">
            <div *ngIf="previewPDF" style="max-height:400px;overflow: hidden;overflow-y: scroll;background-color:#f4f4f4">
                <app-pdf-preview [pdfUrl]="previewPDF.preview_url">
                </app-pdf-preview>
            </div>

            <div style="max-height:500px;overflow: hidden;overflow-y: scroll;background-color:#f4f4f4"
                id="xlsp-{{doc.id}}">
            </div>
        </div>
    </div>

    <div class="mt-5"></div>

    <div class="grid grid-cols-1" *ngIf="loadCache && loadCache.info">
        <div class="grid-col"
            *ngIf="loadCache.info.length==0">
            <div class="text-center">
                <p class="text-sm  m-0"
                    style="color:#000;font-weight:bold">
                    Nenhum relatório encontrado
                </p>

                <p class="text-sm opacity-70 m-0">
                    Dirija-se a 
                    <a href="/document-analysis?doc_id={{doc.id}}"
                        target="__blank" 
                        style="color:#2571a1!important;font-weight:bold;" 
                        class="text-[#2571a1] text-underline text-bold">
                         Análise 
                    </a>
                    adicionar relatórios automatizados.
                </p>
            </div>
        </div>

        <div class="grid-col" *ngIf="loadCache.info.length>0">
            <p class="opacity-70 m-0">
                {{ loadCache.info.length}}
                relatórios encontrados.
            </p>

            <div class="grid grid-cols-2">
                <div class="grid-col "
                    *ngFor="let blockObj of loadCache.info">
                    <div class="panel mb-2 mr-1 p-2 border border-gray-300 pl-3 rounded-xl ">
                        <div class="panel-header pt-2 pb-2 pr-4">
                            <div class="flex flex-col md:flex-row justify-between items-center">
                                <!-- Nome -->
                                <span
                                    class="text-sm text-[#1422a9] font-medium md:pl-[9px] truncate sm:text-xs  md:w-auto w-ful">
                                    {{ blockObj.name.toUpperCase() }}
                                </span>

                                <!-- Espaço reservado para o texto branco (não visível na tela) -->
                                <span class="text-sm text-white"
                                    style="padding-right: 9px; font-size: xx-small;"></span>

                                <!-- Botões -->
                                <div class="flex flex-col md:flex-row items-center">
                                </div>
                            </div>
                        </div>


                        <div class="panel-content"
                            style="height:200px;max-height: 400px;overflow: hidden;overflow-y: scroll;">
                            <div class="grid grid-cols-1 mt-0">
                                <div class="grid-col" *ngIf="blockObj.report">
                                    <app-doc-gen-report 
                                        [report]="blockObj.report"
                                        [groupname]="blockObj.name">
                                    </app-doc-gen-report>
                                </div>
                                <div class="grid-col" *ngIf="!blockObj.report">
                                    <p>Sem Relatório para {{
                                        blockObj.name.toUpperCase() }} </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>


    <div class="grid grid-cols-1">
        <div class="grid-col">
            <app-sync-odoo [doc]="doc" [reports]="loadCache.info">
            </app-sync-odoo>
        </div>
    </div>


</div>

