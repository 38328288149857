<div class="container flex items-center mt-2 mb-5 ">
    <div class="flex-shrink-0 me-5">
        <app-rounded-icon-buttom [buttonData]="{
            iconUrl:'assets/img/quadro.svg', 
            btnClass: '!bg-yv-5%-gray ' ,
            iconClass: 'w-12 h-12'
            
        }"></app-rounded-icon-buttom>
    </div>
    <span class="typed-text" [innerHTML]="typedContent"><!-- {{ typedContent }} --></span>
    
</div>
