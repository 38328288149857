<div>
    <p class="mt-4 mb-5"
        (click)="goBack()">
        <span  class="cursor-pointer text-sm  font-bold text-[#00527E] m-2 flex flex-row  ">
            <img src="assets/img/icon/Group.svg " class="w-4 h-4 mr-1"/>
            Voltar
        </span>
    </p>

    <div style="float:left;width:60px;height: 63px;margin-right:10px"> 
        <img src="https://www.mf.gov.cv/o/mf-theme/images/favicon.ico"
            style="margin-right:10px;z-index:10;float:left" 
        />
        <img class="rounded-full w-6 h-6 border-2 border-blue-300" 
            style="margin-top:-17px;margin-left: 2px;margin-right: 4px;z-index: 100000;float:right" 
            src="assets/img/im blue.jpg" alt="Profile Image" 
        />
    </div>
    <h3 class="text-2xl font-bold text-left">
        DPR LUA
    </h3>
    <!-- rgb(45, 43, 43) -->
    <h2 class="text-left opacity-70">
        <span class="cursor-pointer" style="font-size:small;">Lua Apps</span>
        / 
        <span class="text-[#00527E]">
            DPR Modelo 106
        </span>
    </h2>  
    <p class="" style="font-size:small">
        <span class="opacity-30">
            Modelos 106 xml de Iva apartir de faturas LUA.
        </span>
        <a href="https://www.mf.gov.cv/web/dnre/dire%C3%A7%C3%A3o-geral-das-contribui%C3%A7%C3%B5es-e-impostos"
            target="__blank">
            Saber Mais
        </a>
    </p>
</div>

<div class="mt-4">

    <div class="w-full">
        <div class="flex flex-row justify-between">
            <div class="tab-header flex flex-wrap space-x-5 mb-4">
                <div class="tab-header-item py-2 px-5 mb-2 border rounded-3xl bg-[#00527E] text-white">
                    <span (click)="FetchInvoices()" class="cursor-pointer" *ngIf="!is_loading">
                        <img src="assets/img/icon/Arrowswhite.svg"
                            style="display:inline" 
                            alt="" class="w-5 h-5 mr-2 mt-[0.5]"
                        />
                        Actualizar
                    </span>
                    <span (click)="FetchInvoices()" *ngIf="is_loading">
                        Aguarde
                    </span>
                </div>
            </div>

            <div class="flex items-center mb-2">
                <form [formGroup]="filterForm" (ngSubmit)="filterInvoices()"
                class="flex flex-col sm:flex-row gap-2">
                
                <!-- Campo Data Inicio -->
                <div class="flex items-center border border-gray-300 rounded-3xl"
                    style="height:fit-content;">
                    <span class="bg-[#00527E] text-white py-2 px-4"
                        style="font-size:small; border-top-left-radius: 40px; border-bottom-left-radius:40px">
                        Inicio&nbsp;
                    </span>
                    <input type="date"
                        placeholder="Data Inicio"
                        formControlName="startDate"
                        required
                        style="font-size:medium; padding-left:10px; padding-right: 10px;" 
                        class="flex-1 outline-none"
                    />
                </div>
            
                <!-- Campo Data Fim -->
                <div class="flex items-center border border-gray-300 rounded-3xl">
                    <span class="bg-[#00527E] text-white py-2 px-4"
                        style="font-size:small; border-top-left-radius: 40px; border-bottom-left-radius:40px">
                        Fim
                    </span>
                    <input type="date"
                        placeholder="Data Fim"
                        formControlName="endDate"
                        required
                        class="flex-1 outline-none"
                        style="font-size:medium; padding-left:10px; padding-right: 10px;" 
                    />
                </div>
            
                <!-- Botão Filtrar -->
                <div class="flex items-center">
                    <button type="submit" class="cursor-pointer m-0 p-2 px-6 rounded-3xl bg-[#00527E] text-white flex items-center">
                        <img src="assets/img/icon/search.svg" alt="Pesquisar" class="mr-2 w-5 h-5" />
                        Filtrar
                    </button>
                </div>
            
            </form>
            
            </div>
        </div>
    </div>

    <div class="w-full">
        <div class=" p-2 tab-content md:p-3 border border-gray-300 rounded-xl shadow-xl">
            <div class="grid-col">
                <div *ngIf="is_loading" class="flex items-center justify-center w-full h-full">
                    <div role="status">
                        <svg aria-hidden="true" class="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                        </svg>
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>

                <app-alert type="error"
                    message="Nao foi possivel concluir a pesquisa!"
                    *ngIf="is_error">
                </app-alert>

                <app-alert type="error"
                    message="Verifque os parametros de pesquisa!"
                    *ngIf="is_filter_error">
                </app-alert>

                <div class="mt-3 overflow-auto " *ngIf="objectCache && objectCache.invoices">
                    <table id="invoice-table" #invoiceTable class="text-sm w-full">
                        <thead>
                            <tr class="bg-[#EAF2F2] bg-opacity-70 rounded-2xl pb-4">
                                <th class="pb-4 pt-2 pl-2 rounded-tl-3xl rounded-bl-3xl">Fornecedor</th>
                                <th>Cliente</th>
                                <th class="text-center">Fatura Nr</th>
                                <th class="text-center">Data da Fatura</th>
                                <th class="text-center">SubTotal</th>
                                <th class="text-center">Total</th>
                                <th class="text-center">Iva</th>
                                <th class="text-center rounded-tr-3xl rounded-br-3xl"
                                    style="padding-right:10px">Actions</th>
                            </tr>
                        </thead>
                        <tbody class="mb-4 mt-2">
                            <tr class="cursor-pointer pb-10 hover:bg-[#EAF2F2]" 
                                *ngIf="!is_loading && objectCache.invoices.records.length
                            ==0">
                                <td colspan="6">
                                    <span class="opacity-70" style="font-size:small">
                                        Nenhum resultado encontrado
                                    </span>
                                </td>
                            </tr>

                            <tr class="cursor-pointer pb-10 hover:bg-[#EAF2F2]" *ngFor="let invoice of objectCache.invoices.records">
                                <td class="pb-2 pt-2 pl-2">
                                    <span class="text-sm" style="font-size:xx-small;color:rebeccapurple;">#{{ invoice.id }}</span>
                                    {{ invoice['supplier_name']?.slice(0, 15) }}
                                </td>

                                <td class="py-4">{{ invoice['customer_name']?.slice(0, 15) }}</td>

                                <td class="text-center py-4">{{ invoice['invoice_id'] }}</td>

                                <td  class="text-center">{{ invoice['invoice_date'] || 'Sem data' }}</td>

                                <td class="text-center">{{ invoice['invoice_subtotal'] | number }} {{ invoice['currency'] }} </td>

                                <td class="text-center">{{ invoice['invoice_total'] | number }} {{ invoice['currency'] }} </td>

                                <td class="text-center">{{ invoice['invoice_iva'] | number  }}</td>

                                <td class=" flex flex-row gap-3 justify-center items-end ">
                                    
                                       
                                     <img src="assets/img/icon/add-circle.svg" width="20px" height="20px" *ngIf="!invoice.is_add" (click)="addToList(invoice)" />
                                     <img src="assets/img/icon/eye.svg" width="20px" height="20px"  (click)="openInvoice(invoice)" />
                                      
                                 
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <hr />

                    <p class="mt-4 mb-1" *ngIf="objectCache.invoices.records.length > 0">
                        <span class="cursor-pointer text-sm py-2 px-5 rounded-3xl bg-[#fff]  font-bold text-[#00527E] m-2 ml-0"
                            (click)="AddAll()" 
                                style="border: 1px solid rgb(0, 82, 126);">
                            Adicionar Todos
                        </span>
                    </p>

                    <p class="text-sm pt-5">
                        <span class="opacity-70">A pesquisa retornou {{ objectCache.invoices.total_count }} faturas.</span>
                        <span style="color:#333">Mostrando {{ objectCache.invoices.records.length }}</span>
                    </p>
                </div>
            </div>

        </div>

        <div class="p-2 tab-content md:p-5 border border-gray-300  rounded-xl mt-10 shadow-2xl"
            *ngIf="!xmlReport">
            <div class="tab-header flex flex-wrap mt-1"
                *ngIf="!xmlReport">
                <div class="tab-header-item cursor-pointer py-2 px-4 text-2xl  text-[#414141] font-extrabold">
                    Modelo 106
                    <!--
                    <div style="padding:10px;background-color: rgb(0, 82, 126);">
                        <img src="https://www.mf.gov.cv/image/layout_set_logo?img_id=2409745&t=1726794286413" 
                            width="70px" 
                        />
                    </div>
                    -->
                </div>
            </div>

            <div class="grid grid-cols-1  gap-4">
                <!-- Table for selected invoices -->
                <div class="grid-col text-center  overflow-hidden" 
                    style="max-height:500px; overflow-y: scroll;"
                    *ngIf="listObj">
                    <div class="mt-0 overflow-auto">
                        <table class="text-sm w-full"
                            *ngIf="listObj.length>0">
                            <thead>
                                <tr class="bg-gray-300 bg-opacity-60 rounded-2xl pb-4">
                                    <th class="pb-2 pt-2 pl-2">Fornecedor</th>
                                    <th class="pb-2 pt-2 pl-2">Cliente</th>
                                    <th>Data</th>
                                    <th>Num Doc</th>
                                    <th class="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="cursor-pointer pb-10 hover:bg-[#F0F0F0]"
                                    *ngFor="let invoice of listObj">
                                    <td class="pb-2 pt-2 pl-2">
                                        <span class="text-sm" style="color:#714B67">
                                            {{ invoice.supplier_name }}
                                        </span>
                                    </td>
        
                                    <td>
                                        {{ invoice.customer_name }}
                                    </td>

                                    <td>
                                        {{ invoice.invoice_date }}
                                    </td>

                                    <td>
                                        {{ invoice.invoice_id }}
                                    </td>
        
                                    <td class="text-center">
                                        <p class="mt-2 mb-2"
                                            (click)="removeFromList(invoice)">
                                            <span class="cursor-pointer text-sm py-2 px-5 rounded-3xl font-bold text-[#fff] m-2 ml-0"
                                                style="background-color: rgb(0, 82, 126);border: 1px solid rgb(0, 82, 126);">
                                                REMOVER
                                            </span>
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <hr />

                        <p class=" pt-5">
                            <span class="opacity-70 text-sm">
                                Selecionadas {{ listObj.length }}
                            </span>
                            |
                            <span class="opacity-70 text-sm">
                                Escolha o modelo:
                            </span>
                        </p>
        
                        <p class="mt-4 mb-2" *ngIf="listObj.length > 0">
                            <span class="cursor-pointer text-sm py-2 px-5 rounded-3xl bg-[#fff]  font-bold text-[#00527E] m-2 ml-0"
                                (click)="continueModel106('rosto')" style="border: 1px solid rgb(0, 82, 126);">
                                Folha de Rosto
                            </span>

                            <span class="cursor-pointer text-sm py-2 px-5 rounded-3xl bg-[#fff]  font-bold text-[#00527E] m-2 ml-0"
                                (click)="continueModel106('for')" style="border: 1px solid rgb(0, 82, 126);">
                                Folha de Fornecedor
                            </span>

                            <span class="cursor-pointer text-sm py-2 px-5 rounded-3xl bg-[#fff]  font-bold text-[#00527E] m-2 ml-0"
                                (click)="continueModel106('cli')" style="border: 1px solid rgb(0, 82, 126);">
                                Folha de Clientes
                            </span>
                        </p>
        
                        <p class="mt-2 mb-2 opacity-70 text-sm" *ngIf="listObj.length == 0">
                            Para criar o modelo, adicione as faturas a lista e clique em continuar apos
                            selecionar todas.
                        </p>
                    </div>
                </div>
            </div>
        </div>


        <div class="tab-header flex flex-wrap  border-gray-300 mt-8"
            *ngIf="xmlReport">
            <div class="tab-header-item cursor-pointer py-2 px-8 rounded-3xl bg-[#00527E] text-white mb-4">
                Editor
            </div>
        </div>

        <div class="p-2 tab-content border border-gray-300 rounded-xl shadow-xl" 
             *ngIf="xmlReport">
            <div class="grid-col" >
                <p class="mt-2 mb-2" 
                    *ngIf="listObj.length > 0">
                  
                    <span class="cursor-pointer text-sm  font-bold text-[#00527E] m-2 flex flex-row  "  (click)="cancelModel106()">
                        <img src="assets/img/icon/Group.svg " class="w-4 h-4 mr-1" />
                        Voltar
                    </span>
                </p>

                <div *ngIf="dprModel=='cli'">
                    <app-dpr-106-cli [xmlReport]="xmlReport">
                    </app-dpr-106-cli>
                </div>

                <div *ngIf="dprModel=='for'">
                    <app-dpr-106 [xmlReport]="xmlReport">
                    </app-dpr-106>
                </div>

                <div *ngIf="dprModel=='rosto'">
                    <app-dpr-106-rosto [xmlReport]="xmlReport">
                    </app-dpr-106-rosto>
                </div>
            </div>
        </div>


    </div>
</div>


