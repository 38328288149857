<div>
    <p class="mt-4 mb-5"
        (click)="goBack()">
        <span  class="cursor-pointer text-sm  font-bold text-[#00527E] m-2 flex flex-row  ">
            <img src="assets/img/icon/Group.svg " class="w-4 h-4 mr-1"/>
            Voltar
        </span>
    </p>
    <h3 class="text-2xl font-bold text-left ">
        FATURAS LUA
    </h3>
    <h2 class="text-left opacity-70">
        <span class="cursor-pointer">Lua Apps</span>
        / 
        <span class="text-[#00527E]">
            FATURAS LUA
        </span>
    </h2>  
    <p class="opacity-30" style="font-size:small">
        Faturas geradas pelo Lua.
    </p>
</div>

<div class="mt-0">

    <div class="w-full">
        <div class="flex flex-row justify-between">
            <!-- Contêiner para Actualizar e Esconder/Mostrar -->
            <div class="tab-header flex flex-row items-center space-x-5 mb-4">
                <!-- Botão Actualizar -->
                <div class="tab-header-item">
                    <span (click)="FetchInvoices()" 
                        class="py-2 px-6 mb-2 cursor-pointer border rounded-3xl bg-[#00527E] text-white flex items-center" 
                        style="font-size:medium;" 
                        *ngIf="!is_loading">
                        <img src="assets/img/icon/Arrowswhite.svg" alt="" class="w-5 h-5 mr-2" />
                        Actualizar
                    </span>
        
                    <!-- Estado Aguarde -->
                    <span *ngIf="is_loading" 
                        class="py-2 px-6 mb-2 border rounded-3xl bg-[#00527E] text-white flex items-center">
                        Aguarde
                    </span>
                </div>
        
                <!-- Botão Esconder/Mostrar -->
                <div class="tab-header-item">
                    <span class="cursor-pointer m-0 p-2 rounded-3xl flex items-center"
                        style="font-size:medium;" 
                        (click)="toggleTable()">
                        <span *ngIf="!hideTable" class="rounded-3xl p-1 px-5 mb-2  bg-white text-[#00527E] border border-[#00527E]">Esconder</span>
                        <span *ngIf="hideTable" class="rounded-3xl p-1 px-4 bg-[#00527E] text-white">Mostrar</span>
                    </span>
                </div>
            </div>
        
            <!-- Formulário de Filtro por Data -->
            <div class="flex items-center mb-2">
                <form [formGroup]="filterForm" (ngSubmit)="filterInvoices()"
                    class="flex flex-col sm:flex-row gap-2">
                    
                    <!-- Campo Data Inicio -->
                    <div class="flex items-center border border-gray-300 rounded-3xl"
                        style="height:fit-content;">
                        <span class="bg-[#00527E] text-white py-2 px-4"
                            style="font-size:medium;border-top-left-radius: 40px;border-bottom-left-radius:40px">
                            Inicio&nbsp;
                        </span>
                        <input type="date"
                            placeholder="Data Inicio"
                            formControlName="startDate"
                            required
                            style="font-size:medium;padding-left:10px;padding-right: 10px;" 
                            class="flex-1 outline-none"
                        />
                    </div>
        
                    <!-- Campo Data Fim -->
                    <div class="flex items-center border border-gray-300 rounded-3xl">
                        <span class="bg-[#00527E] text-white py-2 px-4"
                            style="font-size:medium;border-top-left-radius: 40px;border-bottom-left-radius:40px">
                            Fim
                        </span>
                        <input type="date"
                            placeholder="Data Fim"
                            formControlName="endDate"
                            required
                            style="font-size:medium;padding-left:10px;padding-right: 10px;" 
                            class="flex-1 outline-none"
                        />
                    </div>
        
                    <!-- Botão Filtrar -->
                    <div class="flex items-center">
                        <button type="submit" class="cursor-pointer m-0 p-2 px-6 rounded-3xl bg-[#00527E] text-white flex items-center">
                            <img src="assets/img/icon/search.svg" alt="Pesquisar" class="mr-2 w-5 h-5" />
                            Filtrar
                        </button>
                    </div>
        
                </form>
            </div>
        </div>
        
    </div>

    <div class="w-full" >
        <div class="shadow-xl">
        <div class=" p-10    tab-content  " *ngIf="!hideTable"> 
            <div class="grid-col border border-gray-300 rounded-xl p-10">
                <div *ngIf="is_loading" class="flex items-center justify-center w-full h-full">
                    <app-spinner>
                    </app-spinner>
                </div>

                <app-alert type="error"
                    message="Nao foi possivel concluir a pesquisa!"
                    *ngIf="is_error">
                </app-alert>

                <app-alert type="error"
                    message="Verifque os parametros de pesquisa!"
                    *ngIf="is_filter_error">
                </app-alert>

                <div class="mt-0 overflow-auto p-0 " *ngIf="objectCache && objectCache.invoices">
                    <table id="invoice-table" #invoiceTable class="text-sm w-full">
                        <thead>
                            <tr class="bg-[#EAF2F2] bg-opacity-70 rounded-2xl pb-4">
                                <th class="pb-4 pt-2 pl-2 rounded-bl-3xl rounded-tl-3xl">Fornecedor</th>
                                <th>Cliente</th>
                                <th class="text-center">Fatura Nr</th>
                                <th class="text-center">Data da Fatura</th>
                                <th class="text-center">SubTotal</th>
                                <th class="text-center">Total</th>
                                <th class="text-center">Iva</th>
                                <th class="text-right rounded-br-3xl  rounded-tr-3xl"
                                    style="padding-right:10px">Actions</th>
                            </tr>
                        </thead>
                        <tbody class="mb-4 mt-2">
                            <tr class="cursor-pointer pb-10 hover:bg-[#EAF2F2]" 
                                *ngIf="!is_loading && objectCache.invoices.records.length
                            ==0">
                                <td colspan="6" class="text-cente p-5">
                                    <span class="text-center p-5 opacity-70" style="font-size:small">
                                        Nenhum resultado encontrado
                                    </span>
                                </td>
                            </tr>

                            <tr class="cursor-pointer pb-10 hover:bg-[#EAF2F2]" *ngFor="let invoice of objectCache.invoices.records">
                                <td class="pb-2 pt-2 pl-2">
                                    <span class="text-sm" style="font-size:xx-small;color:rebeccapurple;">
                                        <!--#{{ invoice.id }}-->
                                        {{ invoice['folder'] }} /
                                    </span>
                                    {{ invoice['supplier_name']?.slice(0, 15) }}
                                </td>

                                <td class="py-4">{{ invoice['customer_name']?.slice(0, 15) }}</td>

                                <td class="text-center py-4">{{ invoice['invoice_id'] }}</td>

                                <td  class="text-center">{{ invoice['invoice_date'] || 'Sem data' }}</td>

                                <td class="text-center">{{ invoice['invoice_subtotal'] | number }} {{ invoice['currency'] }} </td>

                                <td class="text-center">{{ invoice['invoice_total'] | number }} {{ invoice['currency'] }} </td>

                                <td class="text-center">{{ invoice['invoice_iva'] | number  }}</td>

                                <td class="text-right">
                                    <p class="mt-2 mb-2" 
                                        style="font-size:small-x;">
                                        <span class="cursor-pointer py-2 ml-1 px-6 rounded-3xl text-[#00527E] bg-white  border border-[#00527E] transition-colors duration-200 ease-in-out hover:bg-[#003d66] hover:border-[#003d66] hover:text-white" style="font-size:x-small;"
                                        (click)="openInvoice(invoice)">VER </span>
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <hr />

                    <p class="text-sm p-5">
                        <span class="opacity-70">A pesquisa retornou {{ objectCache.invoices.total_count }} faturas.</span>
                        <span style="color:#333">Mostrando {{ objectCache.invoices.records.length }}</span>
                    </p>
                </div>
            </div>
        </div>
        </div>

        <div class="p-10 mt-10 tab-content shadow-xl">
            <div class="  rounded-xl">
                <app-invoice-wizard >
                </app-invoice-wizard>
            </div>
        </div>
    </div>
</div>


