import { Component } from '@angular/core';

@Component({
  selector: 'app-crowd-strike-outage',
  templateUrl: './crowd-strike-outage.component.html',
  styleUrls: ['./crowd-strike-outage.component.scss']
})
export class CrowdStrikeOutageComponent {

}
