import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ChatService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-doccenter',
  templateUrl: './doc-center.component.html',
  styleUrls: ['./aibi.component.scss']
})
export class DocCenterComponent implements OnInit {
    currentIndex: number = 0;
    is_loading:boolean = false;
    show_group_docs: boolean = false;
    is_error:boolean = false;
    errors: any[] = []
    onRefreshCall:any = () => {}
    objectList:any[] = []
    objectStream:any = null

    queueList:any = null;
    loadCache:any = {}
    objectCache:any = {}
    currentDocOpen:any = null;

    is_loading_queue:boolean = false;
    is_uploading:boolean = false;
    is_upload_error:boolean = false;
    is_upload_succ:boolean = false;
    is_peeking:boolean = false
    peek_error:boolean = false
    currentQueue:any = null

    constructor(
        private router: Router,
        private chatService: ChatService
    ) { 
        // console.info("[home] chatService: ", chatService);
    }

    debugObj(OBJ:any) {
        console.info(OBJ)
    }

    ngOnInit() {
        setTimeout(()=>{
            this.LoadQueue();
        }, 80)
    }

    PeekQueue(QN:any) {
        console.info("PeekQueue: ", QN)
        if (QN.is_peeking) {
            return;
        }

        const payload = {
            'ops' : "QueuePeek",
            'conf' : {
                'name' : QN['Name']
            }
        }

        QN.is_peeking=true;
        QN.objectStream = null;
        QN.peek_error=false;

        this.chatService.ExecuteTask("core/RefineryService", payload, (doc:any) => {
            QN.is_peeking=false;
            if (doc.Status=="OK" && doc.object_) {
                QN.objectStream = doc.object_;
            } else {
                QN.peek_error=true;
            }

        }, (error:any) => {
            QN.is_peeking=false;
            console.error("E: ", error);
        })
    }

    LoadQueue() {
        const payload = {
            'ops' : "QueueList"
        }

        this.is_loading_queue=true;
        this.queueList = null;
        this.objectStream = null;

        this.chatService.ExecuteTask("core/RefineryService", payload, (doc:any) => {
            setTimeout(()=>{            
                this.is_loading_queue=false;
                if (doc.Status=="OK" && doc.object_) {
                    const queueList = doc.object_;
                    // if (queueList.Resp) {
                    //     setTimeout(()=>{
                    //         const Qa = queueList.Resp[0]
                    //         this.PeekQueue(Qa)
                    //     }, 230);
                    // }
                    this.queueList = queueList;
                }
                // console.error("doc: ", doc);
            }, 250);

        }, (error:any) => {
            this.is_loading_queue=false;
            console.error("E: ", error);
        })
    }

    closeCurrentQueue() {        
        this.currentQueue=null;
        this.objectStream=null;
    }

    OpenQueue(QU:any) {
        console.info("OpenQueue: ", QU)
        this.currentQueue=null;
        setTimeout(()=>{
            this.currentQueue = QU;
            if (!QU.objectStream || QU.objectStream===null) {
                this.PeekQueue(QU);
            }
        }, 150)
    }

    LoadDocuments(init_first=false) {
        // console.info("LoadDocuments():");
        // return;
        if (this.is_loading) {
            return;
        }

        this.is_loading = true;
        this.is_error = false;
        this.objectList = [];

        const payload = {
            'ops' : 'ListFiles',
            'filters' : {},
        }

        this.chatService.ExecuteTask("lua/DocSystemAPI", payload, (doc_resp:any) => {
            setTimeout(()=>{
                this.is_loading = false;
                if (doc_resp.hasOwnProperty("Status")) {
                    const Status = doc_resp["Status"];
                    if (Status=="OK" && doc_resp.hasOwnProperty("list")) {
                        const list = doc_resp['list'];
                        this.objectList = list;

                        // if (init_first && list.length>0) {
                        //     setTimeout(()=>{
                        //         this.openFileItem(list[0]);
                        //     }, 25);
                        // }
                    } else {
                    }
                }

            }, 123);

        }, (error:any) => {
            console.info("error: ", error)
            this.is_loading = false
            this.is_error = true
        });
    }


}
