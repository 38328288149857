<div class="" *ngIf="editorForm">
    <!-- <p>Tipo de relat&oacute;rio: {{ groupname}} </p> -->
    <!-- FORM EDITOR -->
    <app-alert type="warning" 
        message="Aguarde..."
        *ngIf="is_loading">
    </app-alert>

    <app-alert type="error" 
        message="Nao foi possivel criar as metricas. Verifique todos os campos e Tente novamente"
        *ngIf="is_error">
    </app-alert>

    <app-alert type="error" 
        *ngIf="is_invalid"
        message="{{ error_msg || 'Nao foi possivel concluir. Verifique todos os campos' }}">
    </app-alert>

    <app-alert type="success" 
        *ngIf="is_created"
        message="Metricas criadas com sucesso. Pode fechar">
    </app-alert>

    <p class="opacity-70" style="font-size:smaller;">
        Ajuste os valores do formulario abaixo e apos terminar submeta
        para adicionar as metricas.
    </p>

    <p class="mt-2 mb-3">
        <span class="cursor-pointer text-small bg-white px-4 py-1 rounded-3xl text-[#00527E]  border border-[#00527E]"
            style="font-size:small" 
            *ngIf="!is_loading_help"
            (click)="AskForLuaHelp()">
            <img src="assets/img/lua-magician.svg" 
                alt="Main Image" 
                width="16px" 
                style="display:inline" />
            Ajuda
        </span>

        <span class="cursor-pointer text-small bg-white px-4 py-1 rounded-3xl text-[#00527E]  border border-[#00527E]"
            style="font-size:small" 
            *ngIf="is_loading_help"
            (click)="StopReq()">
            <img src="assets/img/lua-magician.svg" 
                alt="Main Image" 
                width="16px" 
                style="display:inline" />
            Hold on
        </span>
    </p>

    <div class="mt-3 mb-3">
        <hr/>
    </div>

    <form [formGroup]="editorForm" (ngSubmit)="SubmitForm()" 
        class="max-w-lg mx-auto">
        <div class="form-field mb-2">
            <label style="font-size: small" class="mr-2 opacity-70">As m&eacute;tricas v&atilde;o ser postas aqui:</label>
            <input formControlName="context" 
                type="text"
                required
                style="font-size:small"
                class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
            />
        </div>

        <div style="min-height:200px;max-height:250px;overflow:hidden;overflow-y:scroll;background-color:#f4f4f4;">
            <table style="width:100%">
                <tbody>
                    <tr *ngFor="let Row of rowList">
                        <td>
                            <div class="form-field">
                                <!-- <label for="{{ Row.Field }} " class="mr-2">{{ Row.Label }}</label> -->
                                <input formControlName="{{Row.Field}}_N" 
                                    type="text" 
                                    required
                                    style="color:#ccc;font-size:small"
                                    class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                />
                            </div>
                        </td>

                        <td>
                            <div class="form-field">
                                <!-- <label for="{{ Row.Field }} " class="mr-2">{{ Row.Label }}</label> -->
                                <input formControlName="{{Row.Field}}" 
                                    type="{{Row.InputType}}" 
                                    style="font-size:small"
                                    required
                                    class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" 
                                />
                            </div>
                        </td>

                        <td>
                            <div class="form-field">
                                <select formControlName="{{Row.Field}}_T" 
                                    class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500"
                                    style="font-size:small">
                                    <option value="number">numero</option>
                                    <option value="text">texto</option>
                                    <option value="money">Dinheiro/Montante</option>
                                    <option value="currency">Moeda</option>
                                    <option value="nif">NIF</option>
                                    <option value="address">Endereco</option>
                                    <option value="payment_method">Metodo de Pagamento</option>
                                    <option value="NIB">NIB</option>
                                    <option value="email">EMAIL</option>
                                    <option value="iva">Iva</option>
                                    <option value="tax">Tax</option>
                                    <option value="discount">Disconto</option>
                                    <option value="despesa">Despesa</option>
                                    <option value="gasto">Gasto</option>
                                    <option value="country">Pa&iacute;s</option>
                                    <option value="compra">Compra</option>
                                    <option value="date">Data</option>
                                    <option value="contacto">Contacto</option>
                                    <option value="risco">Risco</option>
                                    <option value="idcod">Identificador Ou Codigo</option>
                                    <option value="payref">Referencia de pagamento</option>
                                    <option value="docref">Referencia de documento</option>
                                    <option value="oportunidade">Oportunidade</option>
                                    <option value="doc_nr">Nr Documento</option>
                                    <option value="emissor">Emissor</option>
                                    <option value="prediction">Predicao</option>
                                    <option value="metric">Metrica</option>
                                    <option value="venda">venda</option>
                                    <option value="software">software</option>
                                    <option value="erp">erp</option>
                                    <option value="aluguer">aluguer</option>
                                    <option value="produto">produto</option>
                                    <option value="service">servico</option>
                                    <option value="entidade">Entidade</option>
                                </select>
                            </div>
                        </td>

                        <td>
                            <div class="form-field" *ngIf="rowList.length>1">
                                <span class="cursor-pointer text-small bg-white px-4 py-1 rounded-3xl text-[#00527E]  border border-[#00527E]"
                                    style="font-size:small" 
                                    (click)="DeleteField(Row)">
                                    RM
                                </span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="grid grid-cols-1" *ngIf="!is_done && rowList.length>0">
            <div class="grid-col">
                <div class="flex justify-center mt-6 space-x-3 sm:flex-col sm:space-y-3 sm:space-x-0">
                  <button type="submit" 
                        *ngIf="!is_loading"
                      class="px-4 py-2 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600">
                      Criar  {{rowList.length}} M&eacute;trica(s)
                  </button>
                </div>
            </div>
        </div>


    </form>
</div>
