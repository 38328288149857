<div>
  <p class="mt-4 mb-5" (click)="goBack()">
    <span
      class="cursor-pointer text-sm font-bold text-[#00527E] m-2 flex flex-row"
    >
      <img src="assets/img/icon/Group.svg " class="w-4 h-4 mr-1" />
      VOLTAR
    </span>
  </p>
  <div class="flex flex-row justify-between">
    <img
      src="assets/img/apps/ODOO.webp"
      alt="Odoo Logo"
      class="w-20 h-25 sm:w-40 sm:h-50 pull-left"
    />
    <span
      (click)="FetchInvoices(false)"
      class="cursor-pointer flex flex-row rounded-3xl px-6 py-2 text-sm p-2 bg-[#00527E] font-bold text-white m-2 ml-0 border border-[#00527E]"
    >
      <img
        src="assets/img/icon/Arrowswhite.svg"
        alt=""
        class="w-5 h-5 mr-2 mt-[0.5]"
      />
      ACTUALIZAR
    </span>
  </div>
  <h2 class="text-left opacity-70">
    <span class="cursor-pointer">Lua Apps</span>
    /&nbsp;
    <span class="text-[#00527E]">ODOO</span>
  </h2>
  <p class="opacity-30" style="font-size:small">
      Gerar modelos xml de iva apartir de faturas ODOO.
  </p>
</div>

<div class="mt-4">
  <div class="w-full">
    <div class="flex flex-row justify-between">
      <div class="tab-header flex flex-wrap space-x-5 mb-4">
        <div
          class="tab-header-item cursor-pointer py-2 px-7 mb-2 border rounded-3xl bg-[#00527E] text-white"
        >
          Faturas
        </div>
        <!--
        <div class="tab-header-item cursor-pointer py-2 px-7 mb-2 bg-gray-200 border border-gray-300 rounded-3xl">
          Contabilidade
        </div>
        -->
      </div>
      <div class="flex items-center mb-2">
        <form
          [formGroup]="filterForm"
          (ngSubmit)="filterInvoices()"
          class="flex flex-col sm:flex-row gap-2"
        >
          <!-- Add date interval filters -->
          <div class="flex items-center space-x-2">
            <input
              type="date"
              formControlName="startDate"
              placeholder="Start Date"
              class="outline-none border border-gray-300 rounded-full px-3 py-1"
            />
            <span class="text-gray-500">-</span>
            <input
              type="date"
              formControlName="endDate"
              placeholder="End Date"
              class="outline-none border border-gray-300 rounded-full px-3 py-1"
            />
          </div>
          <!--  -->
          <div
            class="flex items-center border border-gray-300 rounded-full px-3 py-1"
          >
            <img
              src="assets/img/icon/search.svg"
              alt="Pesquisar"
              class="mr-2"
            />
            <input
              type="text"
              placeholder="Pesquisar..."
              formControlName="query"
              required
              class="flex-1 outline-none"
            />
          </div>
          <select formControlName="state" required class=" ">
            <option value="draft">Draft</option>
            <option value="payed">Pago</option>
          </select>
        </form>
      </div>
    </div>

    <div
      class="p-2 tab-content md:p-5 border border-gray-300 rounded-xl shadow-xl"
    >
      <div class="grid grid-cols-1">
        <div class="grid-col">
          <div class="grid grid-cols-1 sm:grid-cols-3 gap-4">
            <div class="grid-col">
              <p class="mt-2 mb-2 font-bold">Faturas / Invoices</p>
            </div>
          </div>

          <div class="mt-3"></div>

          <app-alert
            type="warning"
            message="Esta requisicao parece estar a demorar."
            *ngIf="is_taking_long"
          ></app-alert>
          <app-alert
            type="error"
            message="Nao foi possivel carregar os detalhes"
            *ngIf="is_error"
          ></app-alert>
          <app-alert
            type="error"
            message="Nao foi possivel concluir o filtro"
            *ngIf="is_filter_error"
          ></app-alert>
        </div>

        <div class="grid-col">
          <div
            *ngIf="is_loading"
            class="flex items-center justify-center w-full h-full"
          >
            <div role="status">
              <svg
                aria-hidden="true"
                class="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          </div>

          <div
            class="mt-3 overflow-auto"
            *ngIf="objectCache && objectCache.invoices"
          >
            <table id="invoice-table" #invoiceTable class="text-sm w-full">
              <thead>
                <tr class="bg-[#EAF2F2] bg-opacity-70 rounded-2xl pb-4">
                  <th class="pb-4 pt-2 pl-2 rounded-tl-xl rounded-bl-xl">
                    Nomes
                  </th>
                  <th>Design.</th>
                  <th class="text-center">Data</th>
                  <th class="text-center">Ref de Pagamento</th>
                  <th class="text-center">Ref de Cliente</th>
                  <th class="text-center rounded-tr-xl rounded-br-xl">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody class="mb-4 mt-2">
                <tr
                  class="cursor-pointer pb-10 hover:bg-[#EAF2F2]"
                  *ngFor="let invoice of objectCache.invoices.records"
                >
                  <td class="pb-2 pt-2 pl-2">
                    <span class="text-sm" style="color: #714b67"
                      >#{{ invoice.id }}</span
                    >
                    {{ invoice["name"].slice(0, 15) }}
                  </td>
                  <td class="py-4">
                    {{ invoice["invoice_partner_display_name"].slice(0, 15) }}
                  </td>
                  <td class="text-center">{{ invoice["invoice_date"] }}</td>
                  <td class="text-center py-4">
                    {{ invoice["payment_reference"] }}
                  </td>
                  <td class="text-center">{{ invoice["ref"] }}</td>
                  <td class="text-center">
                    <p
                      class="mt-2 mb-2"
                      *ngIf="
                        allow_add_bill && !invoice.is_add && !is_xml_model_ready
                      "
                      (click)="addToList(invoice)"
                    >
                      <span
                        class="cursor-pointer text-sm py-2 px-6 rounded-3xl font-bold text-[#00527E] bg-white border border-[#00527E] transition-colors duration-200 ease-in-out hover:bg-[#003d66] hover:border-[#003d66] hover:text-white"
                        >ADICIONAR
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <hr />
            <p class="text-sm pt-5">
              <span class="opacity-70"
                >A pesquisa retornou
                {{ objectCache.invoices.total_count }} faturas.
              </span>
              <span style="color: #333"
                >Mostrando {{ objectCache.invoices.records.length }}</span
              >
            </p>
            <!-- Dropdown to select page size -->
            <div class="flex items-center space-x-2">
              <label for="pageSize" class="text-sm">Itens por página:</label>
              <select
                id="pageSize"
                [(ngModel)]="pageSize"
                (change)="onPageSizeChange($event)"
                class="border border-gray-300 rounded-full px-3 py-1 text-sm"
              >
                <option *ngFor="let size of pageSizeOptions" [value]="size">
                  {{ size }}
                </option>
              </select>
            </div>
            <!--  -->
          </div>
          <!-- Pagination -->
          <div class="flex justify-center mt-4">
            <button
              (click)="previousPage()"
              [disabled]="currentPage <= 1"
              class="px-4 py-2 bg-gray-300 text-black rounded-l-md hover:bg-gray-400"
            >
              Anterior
            </button>
            <span class="px-4">{{ currentPage }} / {{ totalPages }}</span>
            <button
              (click)="nextPage()"
              [disabled]="currentPage >= totalPages"
              class="px-4 py-2 bg-gray-300 text-black rounded-r-md hover:bg-gray-400"
            >
              Próximo
            </button>
          </div>
          <!--  -->
        </div>
      </div>
    </div>
  </div>

  <div
    class="p-2 tab-content md:p-5 border border-gray-300 rounded-xl mt-10 shadow-2xl"
  >
    <div class="tab-header flex flex-wrap">
      <div
        class="tab-header-item cursor-pointer py-2 px-4 text-2xl text-[#414141] font-extrabold"
      >
        Modelo 106
      </div>
    </div>
    <!-- md:grid-cols-2 gap-4 -->
    <div class="grid grid-cols-1">
      <!-- Content for creating a model 106 -->
      <div
        class="grid-col pt-20 pb-20 text-center"
        *ngIf="model106Conf === null"
      >
        <h2 class="text-2xl md:text-4xl">Criar um modelo 106.</h2>
        <p class="text-sm md:text-base opacity-70">
          Para criar um modelo 106, clique em começar.
        </p>
        <p class="mt-2 mb-2">
          <span
            class="cursor-pointer text-sm py-2 px-6 rounded-3xl bg-[#fff] font-bold text-[#00527E] m-2 ml-0"
            (click)="beginModel106()"
            style="border: 1px solid rgb(0, 82, 126)"
          >
            COMEÇAR
          </span>
        </p>
      </div>

      <!-- Content for the next step -->
      <div
        class="grid-col text-left pl-6"
        *ngIf="model106Conf !== null && !is_xml_model_ready"
      >
        <!-- <h2 class="text-2xl md:text-4xl">Mais 1 passo</h2> -->
        <p class="text-sm md:text-base opacity-70">
          <span
            class="cursor-pointer text-sm bg-[#fff] py-2 px-6 rounded-3xl font-bold text-[#00527E] m-2 ml-0"
            (click)="cancelModel106()"
            style="border: 1px solid rgb(0, 82, 126)"
          >
            CANCELAR
          </span>
          | Selecione as faturas em cima, clique em Continuar quando estiver
          pronto.
        </p>
        <hr />
      </div>

      <!-- Content for the form DPR 106 -->
      <div
        class="grid-col text-left pl-6"
        *ngIf="model106Conf !== null && is_xml_model_ready"
      >
        <!-- <h2 class="text-2xl md:text-4xl">Formulario DPR 106</h2> -->
        <p class="text-sm md:text-base opacity-70">
          <span
            class="cursor-pointer text-sm p-2 bg-[#fff] rounded-xl font-bold text-[#00527E] m-2 ml-0"
            (click)="backModel106()"
            style="border: 1px solid rgb(0, 82, 126)"
          >
            VOLTAR
          </span>
          | Preencha, faça ajustes e já está.
        </p>
        <hr />
      </div>

      <!-- Table for selected invoices -->
      <div
        class="grid-col text-center mt-5 overflow-hidden"
        style="max-height: 500px; overflow-y: scroll"
        *ngIf="model106Conf && model106Conf.input_bills && !is_xml_model_ready"
      >
        <div class="mt-0 overflow-auto">
          <table #Table class="text-sm w-full">
            <thead>
              <tr class="bg-gray-300 bg-opacity-60 rounded-2xl pb-4">
                <th class="pb-2 pt-2 pl-2">Nome</th>
                <th>Design.</th>
                <th class="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="cursor-pointer pb-10 hover:bg-[#F0F0F0]"
                *ngFor="let Key of Object.keys(model106Conf.input_bills)"
              >
                <td class="pb-2 pt-2 pl-2">
                  <span class="text-sm" style="color: #714b67">
                    {{ model106Conf.input_bills[Key].id }}
                  </span>
                </td>

                <td>
                  {{
                    model106Conf.input_bills[Key][
                      "invoice_partner_display_name"
                    ].slice(0, 15)
                  }}
                </td>

                <td class="text-center">
                  <p
                    class="mt-2 mb-2"
                    *ngIf="
                      allow_add_bill &&
                      model106Conf.input_bills[Key] &&
                      !model106Conf.input_bills[Key].is_deleted
                    "
                    (click)="removeFromList(Key)"
                  >
                    <span
                      class="cursor-pointer text-sm py-2 px-6 rounded-3xl font-bold text-[#fff] m-2 ml-0"
                      style="
                        background-color: rgb(0, 82, 126);
                        border: 1px solid rgb(0, 82, 126);
                      "
                    >
                      REMOVER
                    </span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <hr />
          <p class="text-sm pt-5">
            <span class="opacity-70 text-sm">
              Selecionadas {{ model106Conf.count_c }}
            </span>
          </p>

          <p class="mt-2 mb-2" *ngIf="model106Conf.count_c > 0">
            <span
              class="cursor-pointer text-sm bg-[#fff] py-2 px-6 rounded-3xl font-bold text-[#00527E] m-2 ml-0"
              (click)="continueModel106()"
              style="border: 1px solid rgb(0, 82, 126)"
            >
              CONTINUAR
            </span>
          </p>

          <p
            class="mt-2 mb-2 opacity-70 text-sm"
            *ngIf="model106Conf.count_c == 0"
          >
            Para criar o modelo vai precisar de pelo menos uma (1) fatura.
          </p>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 mt-2">
      <!-- Content for the DPR 106 form -->
      <div class="grid-col" *ngIf="is_xml_model_ready && xmlReport">
        <app-dpr-106 [xmlReport]="xmlReport"> </app-dpr-106>
      </div>
    </div>
  </div>
</div>
