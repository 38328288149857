import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-doc-gen-report',
  templateUrl: './fatura-details.component.html',
  styleUrls: ['./fatura-details.component.scss']
})
export class FaturaDetailsComponent {
    @Input() report : any;
    @Input() groupname: any;
    is_invalid:boolean = false;
    constructor(private chatService: ChatService) {
    }

    ngOnInit() {
        if (!this.report) {
            console.warn("this.report: ", this.report);
            this.is_invalid = true
            return;
        }

        const keys:any = Object.keys(this.report);

        if (keys.length>0) {
            // console.info("[app-doc-gen-report] report: ", this.report)
            const groupName = this.groupname;
            // console.info("groupName: ", groupName);
            if (groupName=='general_details' 
                || (groupName=="total_with_tax") 
                || (groupName=="total_with_tax") 
                || (groupName=="total_without_tax") 
                || (groupName=='emission_details')
                || (groupName=='tax_details')
                || (groupName=='modelo_106_supplier')) {
                const dynTable:any = {rows: []};
                const reportKeys:any = Object.keys(this.report[groupName])

                if (reportKeys) {
                    reportKeys.forEach((K:any) => {
                        if (K=='tms') {
                            return;
                        }

                        const V = this.report[groupName][K];
                        dynTable.rows.push({
                            'label' : K.toUpperCase(),
                            'contents' : V
                        })
                    })
                }

                if (groupName=="total_without_tax"||groupName=="total_with_tax") {                
                    // console.info("dynTable: ", dynTable)
                }
                this.report.dyn_table = dynTable;
            }

            const report = this.report;
            if (groupName=="payment_details" && report.payment_details) {
                const method = report.payment_details?.method ?? 
                               report.payment_details?.['metodo de pagamento'] ?? 
                               report.payment_details?.metododepagamento;
                report.payment_details.method_str = (method+"").toLowerCase();
            }

            if (groupName=="bank_details" && this.report.bank_details 
                && this.report.bank_details.__accounts) {
                this.report.bank_details.__accounts.forEach((acc:any) =>{
                    const name = acc.bank ? acc.bank : acc.BankName
                    acc.bank_name = (name+"").toLowerCase();
                })
            }
        }
    }
  
    ngAfterViewChecked() {
    }
}
