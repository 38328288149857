<div class="video-recorder p-4 bg-gray-100 rounded shadow-md">
  <div class="flex justify-between mb-4">
    <button (click)="startRecording()" [disabled]="isRecording" class="btn btn-primary bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" [ngClass]="{ 'opacity-50 cursor-not-allowed': isRecording }">
      Start Recording
    </button>
    <button (click)="stopRecording()" [disabled]="!isRecording" class="btn btn-primary bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" [ngClass]="{ 'opacity-50 cursor-not-allowed': !isRecording }">
      Stop Recording
    </button>
    <button (click)="downloadVideo()" [disabled]="!videoURL" class="btn btn-primary bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" [ngClass]="{ 'opacity-50 cursor-not-allowed': !videoURL }">
      Download Video
    </button>
  </div>

  <!-- Janela de visualização ao vivo -->
  <div class="mb-4">
    <video *ngIf="isRecording || videoStream" #videoPlayer autoplay class="w-full rounded shadow"></video>
  </div>

  <!-- Vídeo gravado -->
  <div>
    <video *ngIf="videoURL" [src]="videoURL" controls class="w-full rounded shadow"></video>
  </div>
</div>
