<div class="table-auto w-full rounded-3xl overflow-x-auto">
    <table class="text-sm w-full">
        <thead class="bg-[#EAF2F2] bg-opacity-60">
            <tr class="rounded-tr-3xl">
                <th class="p-3 pl-5 rounded-bl-3xl text-left">Nome</th>
                <th class="p-3 text-left" *ngIf="showFolder">Pasta</th>
                <th class="p-3 text-left">Refer&ecirc;ncia</th>
                <th class="p-3 text-left">Criado em</th>
                <th class="p-3 rounded-br-3xl text-center">Ações</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let doc of objectList" 
                (click)="onSelectCallb(doc)"
                class="cursor-pointer hover:bg-[#EAF2F2]">
                <td class="p-3 text-left pl-5">
                    <span class="font-bold">{{ doc['realname'] }}</span>
                </td>

                <td class="p-3 text-left" *ngIf="showFolder">
                    <span class="opacity-70 text-xs">
                        {{ doc['folder'] }}
                    </span>
                </td>

                <td class="p-3 text-left">
                    <span class="opacity-70 text-xs">
                        {{ doc['fileid'] }}
                    </span>
                </td>

                <td class="p-3 text-left">
                    {{ doc['tms'] || 'Vazio' }}
                </td>

                <td class="p-3 text-center">
                    <p class="cursor-pointer rounded-xl text-[#00527E] m-1 border"
                        *ngIf="enableQueue">
                        <span *ngIf="doc.is_queuing">Hold</span>
                        <span *ngIf="!doc.is_queuing"
                            (click)="AddDocToQueue(doc)">Queue</span>
                    </p>                    
                </td>
            </tr>
        </tbody>
    </table>
</div>
