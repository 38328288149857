import { Component, AfterViewInit } from '@angular/core';
import { Chart, registerables } from 'chart.js';

@Component({
  selector: 'app-stock-performance',
  templateUrl: './stock-performance.component.html',
  styleUrls: ['./stock-performance.component.scss']
})
export class StockPerformanceComponent implements AfterViewInit {

  // Dados fictícios para demonstrar
  stockData = {
    totalGain: 15000,
    dayGain: 300,
    cashHoldings: 5000
  };

  constructor() {
    Chart.register(...registerables);
  }

  ngAfterViewInit(): void {
    const ctx = document.getElementById('lineChart') as HTMLCanvasElement;
    const chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: this.generateLabels(),
        datasets: [{
          label: 'Senoidal Line',
          data: this.generateSineWaveData(),
          fill: false,
          borderColor: 'rgba(75, 192, 192, 1)', // Cor da linha
          tension: 0.1
        }]
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false
          }
        },
        scales: {
          x: {
            display: false
          },
          y: {
            display: false
          }
        }
      }
    });
  }

  generateLabels(): string[] {
    return Array.from({ length: 100 }, (_, i) => i.toString());
  }

  generateSineWaveData(): number[] {
    return Array.from({ length: 100 }, (_, i) => 
      Math.sin(i * 0.2) * 10 + Math.cos(i * 0.5) * 5
    );
  }
}
