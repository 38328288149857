

<div class="p-5 grid grid-cols-1 md:grid-cols-4 gap-4">
  <!-- Bloco Maior à Esquerda -->
  <div class="md:col-span-3 bg-white p-5 rounded shadow-md relative">
    <img src="assets/img/icon/dedo.jpg" alt="Main Image" class="w-full h-auto mb-2">
    <h2 class="font-bold text-xl absolute bottom-4 left-4 text-black  p-2 rounded">Global Outage Eases After Disrupting Flights, Trading</h2>
    
  </div>

  <!-- Blocos Menores à Direita -->
  <div class="grid grid-cols-1 md:grid-cols-1 gap-4 md:col-span-1">
    <div class="flex items-start bg-white p-5 rounded shadow-md">
      <img src="assets/image.jpg" alt="Side Image 1" class="w-1/3 h-auto mr-4">
      <div>
        <h2 class="font-bold text-lg">Side Block 1</h2>
        <p class="leading-relaxed">Content for the first side block.</p>
      </div>
    </div>

    <div class="flex items-start bg-white p-5 rounded shadow-md">
      <img src="assets/image.jpg" alt="Side Image 2" class="w-1/3 h-auto mr-4">
      <div>
        <h2 class="font-bold text-lg">Side Block 2</h2>
        <p class="leading-relaxed">Content for the second side block.</p>
      </div>
    </div>

    <div class="flex items-start bg-white p-5 rounded shadow-md">
      <img src="assets/image.jpg" alt="Side Image 3" class="w-1/3 h-auto mr-4">
      <div>
        <h2 class="font-bold text-lg">Side Block 3</h2>
        <p class="leading-relaxed">Content for the third side block.</p>
      </div>
    </div>
  </div>
</div>
