import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    private isDark = false;

    constructor() {
        // Verifica se há uma preferência de tema armazenada e aplica
        const darkMode = JSON.parse(localStorage.getItem('darkMode') || 'false');
        this.isDark = darkMode;
        this.applyTheme();
    }

    toggleTheme() {
        this.isDark = !this.isDark;
        localStorage.setItem('darkMode', JSON.stringify(this.isDark));
        this.applyTheme();
    }

    isDarkMode() {
        return this.isDark;
    }

    private applyTheme() {
        document.body.classList.toggle('dark', this.isDark);
    }
}
