<div class="" *ngIf="report">
    <div class="invoice-details">
        <div class="flex ">
            <div class=" mb-2 " *ngIf="report.supplier_details">
                <div >
                    <p class="mb-2"><b>Fornecedor:</b> {{ report.supplier_details.name }}</p>
                    <p class="mb-2"><b>NIF:</b> {{ report.supplier_details.nif||report.supplier_details['taxid/nif'] }}
                    </p>
                    <p class="mb-2"><b>Contacto:</b>
                        {{ report.supplier_details.phonenumber }},
                        {{ report.supplier_details.website }},
                    </p>
                    <p class="mb-2"><b>Endere&ccedil;o:</b>
                        {{ report.supplier_details.address }},
                        {{ report.supplier_details.zipcode }} 
                    </p>
                    <p class="mb-2" *ngIf="report.general_details">
                        <b>Data Contabilistica:</b> {{ report.general_details.date}}
                    </p>
                    <p class="mb-2" *ngIf="report.general_details">
                        <b>Data Vencimento:</b> {{ report.general_details.data_vencimento }}
                    </p>
                </div>
            </div>

            <div class="mb-5  ml-0" *ngIf="report.customer_details">
                <div >
                    <p class="mb-2"><b>Cliente:</b> {{ report.customer_details.name }}</p>
                    <p class="mb-2"><b>NIF:</b> {{
                        report.customer_details.NIF||report.customer_details.nif||report.customer_details['taxid/nif']
                        }}</p>
                    <p class="mb-2"><b>Contacto:</b>
                        {{ report.customer_details.phonenumber }},
                        {{ report.customer_details.website }},
                    </p>
                    <p class="mb-2"><b>Endere&ccedil;o:</b>
                        {{ report.customer_details.address }},
                        {{ report.customer_details.zipcode }}
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="overflow-x-auto  ">
        <div *ngIf="report.product_list">
            <p class="opacity-70" style="margin: 0px;font-size:small">{{report.product_list.list.length}} produtos</p>
            <table class="  sm:w-[100%] table mb-10 md:w-[80%] " >
                <thead>
                    <tr>
                        <th>Ref</th>
                        <th>Descrição</th>
                        <th>Quantidade</th>
                        <th>Preço</th>
                        <th>Impostos</th>
                        <th>Subtotal</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let prod of report.product_list.list">
                        <td class="text-center">
                            <p style="font-size:small;">                            
                                {{ prod['id/lote'] ? prod['id/lote'] : 'N/A' }}
                                {{ prod['lote']    ? ('#'+prod['lote']) : '' }}
                                {{ prod['id']      ? ('#'+prod['id']) : '' }}
                            </p> 
                        </td>
                        <td class="text-center">
                            <p style="font-size:small;">                            
                                {{ prod.design ? prod.design : (prod.description ? prod.description :
                                prod['designa&ccedil;&atilde;o']) }}
                            </p>
                        </td>

                        <td class="text-center">
                            {{ prod.qtd }} {{prod.unit||'UN'}}
                        </td>

                        <td class="text-center">{{ prod['price/unit'] ? prod['price/unit'] : prod.price }}</td>
                        <td class="text-center">{{ prod.iva }}</td>
                        <td class="text-center">{{ prod.qtd * prod.price}}</td>
                    </tr>
                    <tr *ngIf="report.total_without_tax">
                        <th class="text-left" colspan="5">Subtotal S/IVA</th>
                        <td class="text-center">{{ report.total_without_tax.amount }}</td>
                    </tr>
                    <tr *ngIf="report.total_with_tax">
                        <th class="text-left" colspan="5">Total C/IVA</th>
                        <td class="text-center">{{ report.total_with_tax.amount }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div *ngIf="groupname=='product_list' && report && !report.product_list">
            <p class="opacity-70" style="font-size:small">
                Este relat&oacute;rio n&atilde;o trouxe uma lista.
            </p>
        </div>
    </div>

    <div *ngIf="report.payment_details">
        <div class="items-center flex ">
            <table class="table mb-8 md:w-[100%]  sm:w-[100%]">
                <tbody>
                    <tr>
                        <td> Métodos de pagamento </td>
                        <td>
                            <p style="margin:0px"></p>
                            <img src="assets/img/vinti4.png" 
                                width="27px"
                                style="display:inline" 
                                *ngIf="report.payment_details.method_str.indexOf('vinti4')>-1"
                            />
                            <span style="font-size:small" class="opacity-50">                            
                                <!-- report.payment_details.method -->
                                {{ report.payment_details?.method ?? 
                                   report.payment_details?.['metodo de pagamento'] ?? 
                                   report.payment_details?.metododepagamento 
                                }}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td> Entidade </td>
                        <td> 
                            {{ report.payment_details.entity || report.payment_details.entidade }} 
                        </td>
                    </tr>
                    <tr>
                        <td> Referência </td>
                        <td> {{
                            report.payment_details['referência'] ||
                            report.payment_details['referencia'] ||
                            report.payment_details['reference']
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td> Subtotal </td>
                        <td> {{ report.payment_details.subtotal }} </td>
                    </tr>
                    <tr>
                        <td> Total </td>
                        <td> {{ report.payment_details.total }} </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    
    <div *ngIf="report.bank_details && report.bank_details.__accounts">
        <!-- <p class="mb-3 m"><b>Detalhes Banc&aacute;rios</b></p> -->
        <div class="items-center flex">
            <table class=" table  md:w-[100%] sm:w-[100%]">
                <thead>
                    <tr>
                        <th class="text-center">Banco</th>
                        <th>Conta/NIB</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let acc of report.bank_details.__accounts">
                        <td class="text-center">
                            <p style="margin:0px"  class="text-danger">
                                <img src="assets/img/caixa.png" 
                                    width="73px"
                                    style="display:inline" 
                                    *ngIf="acc.bank_name.indexOf('cecv')>-1 || acc.bank_name.indexOf('caixa')>-1"
                                />
                                <img src="assets/img/bca.jpeg" 
                                    width="42px" 
                                    style="display:inline" 
                                    *ngIf="acc.bank_name.indexOf('bca')>-1"
                                />
                                <img src="assets/img/bi.jpeg" 
                                    width="28px"
                                    style="display:inline" 
                                    *ngIf="acc.bank_name.indexOf('bi')>-1"
                                />
                                <img src="assets/img/iib.png" 
                                    width="28px"
                                    style="display:inline" 
                                    *ngIf="acc.bank_name.indexOf('iib')>-1"
                                />
                                <img src="assets/img/bcn.png" 
                                    width="73px" 
                                    alt="BCN"
                                    style="display:inline" 
                                    *ngIf="acc.bank_name.indexOf('bcn')>-1"
                                />
                                <img src="assets/img/bai.jpeg" 
                                    width="73px" 
                                    alt="BANCO BAI CABO VERDE"
                                    style="display:inline" 
                                    *ngIf="acc.bank_name.indexOf('bai')>-1 || acc.bank_name.indexOf('bancobai')>-1 || acc.bank_name.indexOf('baiao')>-1"
                                />
                            </p>
                        </td>
                        <td class="text-center"> 
                            <p style="color:#333;font-size:small;margin: 0px;font-weight: bold;">
                                <span class="opacity-70" style="font-size:x-small;">
                                    {{ (acc.bank ? acc.bank : acc.BankName).slice(0, 15) }} -    
                                </span>
                                <span>
                                    {{ acc.nib ? acc.nib : acc.NIB }}
                                </span>
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div *ngIf="report.dyn_table">
        <div class="items-center flex">
            <table class="table mb-8 md:w-[100%]  sm:w-[100%]">
                <tbody>
                    <tr *ngIf="report.dyn_table.rows.length==0">
                        <td colspan="2">
                            Nenhuma informacao gerado para este relatorio.
                        </td>
                    </tr>
                    <tr *ngFor="let Row of report.dyn_table.rows">
                        <td> {{ Row['label'] }} </td>
                        <td>
                            {{ Row['contents'] }}
                          </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div *ngIf="is_invalid">
    <app-alert type="error" 
        message="Relatorio invalido. Actualize novamente (report==null)">
    </app-alert>
</div>