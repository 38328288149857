import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { RoundedIconButtomComponent } from '../components/rounded-icon-buttom/rounded-icon-buttom.component';
import { UserLayoutComponent } from './Layouts/user-layout/user-layout.component';
import { RouterModule } from '@angular/router';
import { MobileMenuComponent } from './Layouts/user-layout/mobile-menu/mobile-menu.component';
import { TextCardComponent } from './text-card/text-card.component';
import { InputChatComponent } from '../components/input-chat/input-chat.component';
import { RecentPromptsComponent } from './recent-prompts/recent-prompts.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

export const COMMON_IMPORTS = [
  RoundedIconButtomComponent,
  SlickCarouselModule,
 
];

@NgModule({
  declarations: [
    UserLayoutComponent,
    MobileMenuComponent,
    RecentPromptsComponent,
    TextCardComponent,
    
  ],
  exports: [
    UserLayoutComponent,
    MobileMenuComponent,
    TextCardComponent,
    RecentPromptsComponent,
    ...COMMON_IMPORTS,
    TranslateModule,
  ],
  imports: [
    CommonModule,
    SlickCarouselModule,
    RouterModule,
    InputChatComponent,
    FormsModule,
    TranslateModule.forChild(),
    ...COMMON_IMPORTS,
  ],
})
export class SharedModule {}
