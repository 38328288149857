import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import { ChatService } from 'src/app/services/chat.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

@Component({
    selector: 'app-email-inbox',
    templateUrl: './COMP.component.html',
})
export class EmailInboxApp implements OnInit {
    @Input() appVersion:any = '';
    @Input() appPage:any = '';
    currentInbox:any = null;
    currentQueue:any = null;
    queueList:any = null;

    is_loading_queue:boolean = false;
    is_error:boolean = false;
    objectStream:any = null

    constructor(
        private router: Router,
        private chatService: ChatService,
        private fb: FormBuilder
    ) {
        // this.onCloseEdit = this.onCloseEdit.bind(this);
        // this.OnSubmitReportEdit = this.OnSubmitReportEdit.bind(this);
    }

    ngOnInit() {
        this.LoadQueue()
    }

    goHome() {
        this.router.navigate(['/luaxapps']);
    }

    debugObj(obj:any) {
    }

    Refresh() {
        this.LoadQueue()
    }

    PeekQueue(QN:any) {
        console.info("PeekQueue: ", QN)
        if (QN.is_peeking) {
            return;
        }

        const payload = {
            'ops' : "QueuePeek",
            'conf' : {
                'name' : QN['Name']
            }
        }

        QN.is_peeking=true;
        QN.objectStream = null;
        QN.peek_error=false;

        this.chatService.ExecuteTask("core/RefineryService", payload, (doc:any) => {
            QN.is_peeking=false;
            if (doc.Status=="OK" && doc.object_) {
                QN.objectStream = doc.object_;
            } else {
                QN.peek_error=true;
            }

        }, (error:any) => {
            QN.is_peeking=false;
            console.error("E: ", error);
        })
    }

    LoadQueue() {
        const payload = {
            'ops' : "QueueList"
        }

        this.is_loading_queue=true;
        this.queueList = null;
        this.objectStream = null;

        this.chatService.ExecuteTask("core/RefineryService", payload, (doc:any) => {
            setTimeout(()=>{            
                this.is_loading_queue=false;
                if (doc.Status=="OK" && doc.object_) {
                    const queueList = doc.object_;
                    // if (queueList.Resp) {
                    //     setTimeout(()=>{
                    //         const Qa = queueList.Resp[0]
                    //         this.PeekQueue(Qa)
                    //     }, 230);
                    // }
                    this.queueList = queueList;
                }
                // console.error("doc: ", doc);
            }, 250);

        }, (error:any) => {
            this.is_loading_queue=false;
            console.error("E: ", error);
        })
    }

    closeCurrentQueue() {        
        this.currentQueue=null;
        this.objectStream=null;
    }

    OpenQueue(QU:any) {
        console.info("OpenQueue: ", QU)
        this.currentQueue=null;
        setTimeout(()=>{
            this.currentQueue = QU;
            if (!QU.objectStream || QU.objectStream===null) {
                this.PeekQueue(QU);
            }
        }, 150)
    }

}

