<div class="flex flex-col items-center sm:flex-col xl:flex-row gap-[1rem] mt-[1rem] mb-[5rem]">
    <div class="xl:flex rounded-xl flex-col ">
      <div *ngFor="let prompt of prompts; let i = index"
           class="bg-[#5BACAD33] gap-[1rem] rounded-full rounded-br-none my-2 px-8 min-h-[4rem] flex items-center cursor-pointer clickable-item"
           (click)="onSend(prompt)">
        <div class="text-justify text-[0.875rem] text-black">{{ prompt.title || prompt.display }}</div>
      </div>
    </div>
    <div class="hidden xl:flex rounded-xl flex-col ">
      <div *ngFor="let prompt of prompts_v2; let i = index"
           class="bg-[#5BACAD33] gap-[1rem] rounded-full rounded-br-none my-2 px-8 min-h-[4rem] flex items-center cursor-pointer clickable-item"
           (click)="onSend(prompt)">
        <div class="text-justify text-[0.875rem] text-black">{{ prompt.title || prompt.display }}</div>
      </div>
    </div>
  </div>
  
  <!-- [routerLink]="'/chat'" (click)="onSend(prompt)" -->